import React from 'react';
import { convertAge } from 'helpers/utils';
import * as S from './styled';

export default function ThreatAndAlertsNasaAndInpe({ data }) {
	const { area_ha: area = 0, date, distance = 0, title } = data;

	return (
		<S.conatiner>
			{title && <S.title>{title}</S.title>}

			{area !== 0 && <p>Área desmatada: {area.toLocaleString('pt-BR')} ha</p>}
			{distance !== 0 && (
				<p>Distância da TI: {distance.toLocaleString('pt-BR')} km</p>
			)}
			{date && <p>Registrado em: {convertAge(date)}</p>}
		</S.conatiner>
	);
}
