import React from 'react';
import { color } from 'styles/Theme';
import { useSelector } from 'react-redux';
import Load from 'components/Load/Load';
import DataTable from 'react-data-table-component';
import { listTerritoryData } from 'helpers/utils';
import * as S from './styled';

export default function TerritoryData({ stateSelected = {} }) {
	const {
		isReqDataState,
		messageState: errorMessage,
		isErrorDataState, // estado diz se a req deu certo ou nao
		dataAboutStateSelected
	} = useSelector((state) => state.amazonLegal);

	const data = dataAboutStateSelected[stateSelected.value] || {};

	// lista para detalhar dados sobre o estado
	const dataLegalAmazonia = listTerritoryData(data);

	const { legalSituation } = data;

	function returnListData() {
		if (isReqDataState) {
			return (
				<S.selectAnyState className="notes">
					<Load />
				</S.selectAnyState>
			);
		}

		const data = [
			{
				id: 1,
				title: 'Regularizadas',
				year: legalSituation?.regularizado || 0
			},
			{
				id: 2,
				title: 'Declaradas',
				year: legalSituation?.declarada || 0
			},
			{
				id: 3,
				title: 'Delimitadas',
				year: legalSituation?.emEstudo || 0
			},
			{
				id: 4,
				title: 'Homologadas',
				year: legalSituation?.homologada || 0
			},
			{
				id: 5,
				title: 'Reservadas',
				year: legalSituation?.reservada || 0
			},
			{
				id: 6,
				title: 'Com restrição de uso',
				year: legalSituation?.comRetricaoDeUso || 0
			}
		];
		const columns = [
			{
				name: 'Fases de demarcação',
				selector: (row) => row.title
			},
			{
				name: 'Número de TIs',
				selector: (row) => row.year
			}
		];

		return (
			<>
				<S.titleDataTerritory style={{ alignSelf: 'flex-start' }}>
					{stateSelected.label || 'Amazônia Brasileira'}
				</S.titleDataTerritory>

				{dataLegalAmazonia.length !== 0 &&
					dataLegalAmazonia.map((item) => (
						<S.itemDataTerritory key={item.data + Math.random() * 100}>
							<img src={item.icon} alt="icone" />
							<S.dataAndLine colorFont={item.color ? item.color : color.interface.gray2}>
								<p> {item.data}</p>
								<div />
							</S.dataAndLine>
						</S.itemDataTerritory>
					))}

				<S.boxListTis>
					<DataTable
						columns={columns}
						data={data}
						customStyles={{
							headCells: {
								style: {
									fontFamily: 'Nunito',
									fontSize: '0.855rem',
									fontWeight: 500
								}
							},
							rows: {
								style: {
									fontFamily: 'Nunito',
									fontWeight: 300,
									fontSize: '0.855rem',
									color: 'rgb(105 98 93) !important'
								}
							}
						}}
						responsive
						striped
						dense
					/>
				</S.boxListTis>
			</>
		);
	}

	return (
		<>
			<S.dataTerritory>{!isErrorDataState && returnListData()}</S.dataTerritory>

			{isErrorDataState && <S.errorMessage>{errorMessage}</S.errorMessage>}
		</>
	);
}
