import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerAdd = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 1rem;
	padding: 10px;

	h1,
	h5 {
		color: ${color.interface.orange};
	}
`;

export const itemForm = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	/* background-color: aqua; */
`;

export const itemLocation = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	/* background-color: aqua; */
	> p {
		width: 100%;
		text-align: center;
		font-size: 0.8rem;
	}
`;

export const btn = styled.button`
	display: flex;
	align-items: 'center';
	background-color: ${color.interface.brown};
	color: ${color.interface.white};
	border-radius: 5px;
	padding: 5px 10px 5px 10px;
	font-weight: 400;
	cursor: pointer;
	/* background-color: aqua; */
`;

export const file = styled.label`
	input {
		display: none;
	}

	p {
		width: fit-content;
		background-color: ${color.interface.orange};
		color: ${color.interface.white};
		border-radius: 8px;
		padding: 0.5rem 1rem;
		font-weight: 400;
		font-size: 0.9rem;
		cursor: pointer;
	}
	/* background-color: aqua; */
`;

export const btnSalvar = styled.button`
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	border: none;
	outline: none;
	background: ${color.interface.orange};
	color: ${color.interface.white};
	height: 50px;
	width: 100%;
	font-weight: 700;
	margin-top: 20px;
	cursor: pointer;
	border-radius: 30px;
	border: 2px solid ${color.interface.orange} !important;
`;

export const errorPhoto = styled.p`
	max-width: 50%;
	color: ${color.interface.red};
`;

export const errorInputEmpity = styled.p`
	font-size: 0.9rem;
	color: ${color.interface.orange};
`;

export const headerEdit = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	> button {
		background-color: transparent;

		> img {
			width: 1.2rem;
			height: 1.2rem;
			cursor: pointer;
		}
	}

	> h5 {
		font-weight: 400;
		font-size: 0.9375rem;
	}
`;
