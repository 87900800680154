import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { returnDataGraph } from 'helpers/utils';
import { color } from 'styles/Theme';
import LineGraph from 'components/Graphs/LineGraph/LineGraph';

export default function RainLineGraph() {
	const { rainApi, isLoading: loadingRainLineGraph } = useSelector(
		(state) => state.rain
	);

	const [rainYear, setRainYear] = useState([]);
	const [rainData, setRainData] = useState([]);

	useEffect(() => {
		if (rainApi.length > 0) {
			setRainYear(rainApi.map((item) => item.ano));
			setRainData(rainApi.map((item) => item.data));
		}
	}, [rainApi]);

	const lineData = [
		{
			label: 'Chuva Anual (mm)',
			data: rainData,
			borderColor: color.auxiliary.blueWater,
			backgroundColor: color.auxiliary.blueBic
		}
	];

	return (
		<LineGraph
			labelsProps={rainYear}
			datasetsProps={returnDataGraph(rainData, lineData)}
			isLoading={loadingRainLineGraph}
			useLegend={false}
			useLegendRound
		/>
	);
}
