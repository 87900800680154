import React from 'react';
import styled from 'styled-components';
import Container from '../Container';

export const ContainerWeb = styled.div`
	position: absolute;
	border-top: none !important;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 1rem;
	bottom: 3rem;
	border: 0;
	@media (max-width: 800px) {
		display: none;
	}
`;
export const ContainerWebWrapper = styled.div`
	position: relative;
	border-top: none !important;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	/* 	right: 0;
	bottom: 0;
	border: 0; */
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		width: 48px;
		height: 48px;
		background: #006946;
		border-radius: 50px;
		cursor: pointer;

		:hover {
			background-color: #d44500;
		}
		&.active {
			background-color: #d44500;
		}
	}
`;

function InfoContainerWeb() {
	return (
		<ContainerWeb>
			<ContainerWebWrapper>
				<Container />
			</ContainerWebWrapper>
		</ContainerWeb>
	);
}

export default InfoContainerWeb;
