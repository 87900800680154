/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { actionsUsers } from 'helpers/utils';
import * as S from '../ControlButton/styles';
import ListModal from './ListModal';

export default function SaveMap() {
	const desc = 'Baixar e exportar o mapa atual';
	const [showModal, setShowModal] = useState(false);
	const actionButton = !showModal ? 'abrir' : 'fechar';

	const openModal = () => {
		setShowModal((prev) => !prev);
		actionsUsers({
			category: `plataforma/mapa/webgis`,
			action: `Click no botão ${actionButton}`,
			label: desc
		});
	};
	const id = Math.random() * 1000;

	// para definir qual radio button fica checked
	const [disabled, setDisabled] = useState(true);
	const [options, setOptions] = useState([
		{
			name: 'PDF',
			format: 'pdf',
			isChecked: true,
			id: `${id}geo`
			/* action: saveMapAsGeoPdf */
		},
		/* {
			name: 'SHP',
			isChecked: false,
			format: 'shp',
			id: `${id}shp`
			action: handleExportMap('shp', activatedLayers)
		},
		{
			name: 'GEO TIFF',
			isChecked: false,
			format: 'geotiff',
			id: `${id}geotiff`,
			action: saveMapAsGeoTIFF
		}, */
		{
			name: 'PRINT SCREEN (TIFF)',
			isChecked: false,
			format: 'tiff',
			id: `${id}tiff`
			/* action: exportMapAsTiff */
		} /* ,
		{
			name: 'CSV',
			isChecked: false,
			format: 'csv',
			id: `${id}csv`,
			action: exportMapAsCSV
		},
		{
			name: 'XML',
			isChecked: false,
			format: 'xml',
			id: `${id}xml`,
			action: exportMapAsXML
		} */
	]);

	useEffect(() => {
		options.filter((todo) => todo.isChecked).length === 0
			? setDisabled(true)
			: setDisabled(false);
	}, [options]);

	const toggleBaseLayer = (isChecked, i) => {
		const tmp = options[i];
		tmp.isChecked = !isChecked;
		const optionsClone = [...options];
		optionsClone[i] = tmp;
		setOptions([...optionsClone]);
	};
	return (
		<S.Container style={{ position: 'relative' }}>
			<button
				type="button"
				className="sm__tooltip"
				data-title={desc}
				onClick={openModal}
			>
				<svg
					width="22"
					height="22"
					viewBox="0 0 32 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.3167 21.6834C15.4103 21.7833 15.5233 21.863 15.6489 21.9174C15.7745 21.9719 15.9099 22 16.0467 22C16.1836 22 16.319 21.9719 16.4446 21.9174C16.5701 21.863 16.6832 21.7833 16.7767 21.6834L23.7767 14.182C23.9363 13.9852 24.0152 13.7352 23.9976 13.4824C23.9799 13.2296 23.867 12.9929 23.6816 12.8202C23.4962 12.6475 23.2522 12.5516 22.9989 12.5519C22.7455 12.5522 22.5017 12.6486 22.3167 12.8218L17.0467 18.4678V4.00018C17.0467 3.73492 16.9414 3.48052 16.7538 3.29295C16.5663 3.10538 16.3119 3 16.0467 3C15.7815 3 15.5272 3.10538 15.3396 3.29295C15.1521 3.48052 15.0467 3.73492 15.0467 4.00018V18.4678L9.77673 12.8218C9.69038 12.7153 9.58306 12.6277 9.46143 12.5644C9.3398 12.5011 9.20646 12.4635 9.06969 12.454C8.93293 12.4444 8.79566 12.4631 8.66642 12.5088C8.53717 12.5546 8.41871 12.6264 8.3184 12.7199C8.21808 12.8133 8.13805 12.9264 8.08328 13.0521C8.0285 13.1778 8.00016 13.3135 8 13.4506C7.99984 13.5877 8.02788 13.7234 8.08237 13.8492C8.13685 13.9751 8.21663 14.0883 8.31673 14.182L15.3167 21.6834Z"
						fill="black"
					/>
					<path
						d="M28 20C27.7348 20 27.4804 20.1054 27.2929 20.2929C27.1054 20.4804 27 20.7348 27 21V25.96L26.96 26H5.04L5 25.96V21C5 20.7348 4.89464 20.4804 4.70711 20.2929C4.51957 20.1054 4.26522 20 4 20C3.73478 20 3.48043 20.1054 3.29289 20.2929C3.10536 20.4804 3 20.7348 3 21V26C3.0198 26.5241 3.23687 27.0214 3.60774 27.3923C3.9786 27.7631 4.47589 27.9802 5 28H27C27.5241 27.9802 28.0214 27.7631 28.3923 27.3923C28.7631 27.0214 28.9802 26.5241 29 26V21C29 20.7348 28.8946 20.4804 28.7071 20.2929C28.5196 20.1054 28.2652 20 28 20Z"
						fill="black"
					/>
				</svg>
			</button>
			<ListModal
				showModal={showModal}
				setShowModal={setShowModal}
				toggleBaseLayer={toggleBaseLayer}
				options={options}
				disabled={disabled}
			/>
		</S.Container>
	);
}
