import styled from 'styled-components';
import ColLayout from 'components/Grid/Col';
import Row from 'components/Grid/Row';
import { color } from '../../styles/Theme';

export const containerCamadas = styled.div`
	width: 100%;
`;

export const title = styled.p`
	color: ${color.interface.darkGray};
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 500;
	font-size: 0.875rem;
`;

export const Box = styled(ColLayout)`
	display: flex;
	align-items: center;
`;

export const BoxRow = styled(Row)`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 28px;
	flex-flow: inherit;
	border-bottom: 1px solid #d9d5d299;
	padding: 0.73rem 0rem 0.13rem 0rem;
`;

export const boxIcons = styled.div`
	display: flex;
	justify-content: end;
	width: 100%;
	align-items: center;
	gap: 0.5rem;
`;

export const containerSwitch = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 35px;
`;
export const containerSwitchWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	p {
		color: ${color.interface.darkGray};
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 500;
		font-size: 0.875rem;
	}
`;

export const erro = styled.p`
	color: ${color.interface.red};
	text-align: center;
`;

export const ActionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	align-items: center;
	.layout-paint__btn {
		background-color: transparent;
		cursor: pointer;
	}
`;
