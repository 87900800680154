import styled from 'styled-components';
import { color } from 'styles/Theme';

export const RadioInputWrapper = styled.div`
	display: contents;
	justify-content: center;

	label {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		background-color: ${color.interface.white};
		height: 25px;
		width: 40%;
		font-family: sans-serif, Arial;
		font-size: 16px;
		border-radius: 4px;
	}
	> label:hover {
		background-color: ${color.interface.white};
	}
	> input {
		opacity: 0;
		position: fixed;
		width: 0;
	}
	> input:checked + label {
		background: ${({ props }) =>
			props === 'approved'
				? `${color.interface.green}37`
				: `${color.interface.red}37`};
	}
`;
