import React from 'react';
import * as S from './CustomInput.Styled';

function CustomTextArea({
	alt,
	src,
	value,
	type = 'text',
	name,
	placeholder,
	setValue = () => {},
	required = false,
	styleInput = ''
}) {
	return (
		<S.BoxTextArea style={styleInput !== '' ? styleInput : null}>
			{src ? (
				<S.Icon>
					<img alt={alt} src={src} style={{ width: '15px' }} />
				</S.Icon>
			) : null}
			<S.TextArea
				value={value}
				type={type}
				name={name}
				placeholder={placeholder}
				required={required}
				onChange={(e) => setValue(e.target.value)}
			/>
		</S.BoxTextArea>
	);
}

export default CustomTextArea;
