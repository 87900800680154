import styled from 'styled-components';
import { color } from 'styles/Theme';

export const container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: 'Nunito';
	gap: 0.5rem;
	font-size: 0.8rem;
`;

export const graphContainer = styled.div`
	position: relative;
	align-self: flex-start;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;

	b {
		position: absolute;
		text-transform: uppercase;
		top: 0;
		left: 0;
		width: 44%;
		font-size: 0.9rem;
	}
`;

export const content = styled.div`
	width: 40%;
	margin-top: 3.125rem;

	> p {
		font-size: 1.3rem;
		font-weight: bold;
		padding-top: 12px;
		display: flex;
		//justify-content: center;

		> span {
			font-size: 0.813rem;
			font-weight: normal;
			display: flex;
			align-items: flex-end;
			margin-bottom: 3px;
			margin-left: 1px;
		}
	}

	> span {
		position: relative;
		top: -0.313rem;
		font-size: 0.75rem;
		color: ${color.interface.darkGray};
	}
`;

export const graph = styled.div`
	width: 60%;
	> p {
		font-size: 0.7rem;
		padding-top: 12px;
		display: flex;
		justify-content: center;
	}
`;

export const line = styled.div`
	width: 100%;
	min-height: 1.5px;
	max-height: 1.5px;
	background-color: ${color.interface.lightGray};
`;

export const notice = styled.p`
	color: ${color.interface.red};
`;
