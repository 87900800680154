import React from 'react';
import { actionFilterPeriod } from 'store/actions/webGisActions';
import { useDispatch, useSelector } from 'react-redux';
import CalendarStartAndEnd from 'components/Buttons/CalendarStartAndEnd';

function FilterDate() {
	const dispatch = useDispatch();
	const { filterPeriod } = useSelector((state) => state.webGis);

	const handleDate = (e) => {
		// atualiza o estado do array systemUsers com o item modificado
		dispatch(actionFilterPeriod(e));
	};

	return (
		<CalendarStartAndEnd setDates={(e) => handleDate(e)} dates={filterPeriod} />
	);
}

export default FilterDate;
