import React, { useState } from 'react';
import ListModal from '../ListModal';

function Container() {
	const [showModal, setShowModal] = useState(false);
	const openModal = () => {
		setShowModal((prev) => !prev);
	};
	return (
		<>
			<button
				type="button"
				className={showModal ? `active ` : ''}
				onClick={openModal}
			>
				<div className="circle-btn">
					<svg
						width="12"
						height="22"
						viewBox="0 0 12 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5.99887 0.000359926C5.20872 -0.00834422 4.42479 0.140902 3.69309 0.439342C2.96138 0.737781 2.29665 1.1794 1.73789 1.73828C1.17913 2.29717 0.737614 2.96205 0.439243 3.69392C0.140871 4.42579 -0.00834233 5.2099 0.000359844 6.00023C0.000359844 6.26544 0.10569 6.51979 0.29318 6.70732C0.48067 6.89485 0.734961 7.00021 1.00011 7.00021C1.26526 7.00021 1.51955 6.89485 1.70704 6.70732C1.89453 6.51979 1.99986 6.26544 1.99986 6.00023C1.99452 5.47347 2.0943 4.95093 2.29338 4.46322C2.49245 3.97552 2.78682 3.53244 3.15923 3.15994C3.53164 2.78745 3.97462 2.49302 4.46221 2.2939C4.94981 2.09478 5.47223 1.99497 5.99887 2.00032C7.08256 2.02655 8.11173 2.48107 8.86118 3.26443C9.61064 4.04779 10.0193 5.09619 9.99788 6.18023C10.0261 6.84546 9.88547 7.50701 9.58917 8.10324C9.29287 8.69948 8.85051 9.211 8.3033 9.59015C4.72919 12.5001 4.99912 15.94 4.99912 16.09C5.02164 16.3393 5.13684 16.5711 5.32194 16.7396C5.50705 16.9081 5.7486 17.001 5.99887 17H6.08885C6.22034 16.9887 6.34828 16.9515 6.46531 16.8905C6.58235 16.8295 6.68615 16.7459 6.77072 16.6445C6.8553 16.5432 6.91898 16.4261 6.9581 16.3001C6.99721 16.174 7.01099 16.0414 6.99862 15.91C6.99862 15.91 6.80867 13.3801 9.56799 11.1451C10.3456 10.5771 10.9732 9.82818 11.3964 8.96313C11.8197 8.09807 12.026 7.14291 11.9974 6.18023C12.0201 4.56543 11.4011 3.00763 10.2764 1.84893C9.15175 0.690232 7.61327 0.0253718 5.99887 0.000359926V0.000359926Z"
							fill="#F2EDE9"
						/>
						<path
							d="M5.99999 19C4.81999 19 3.95999 20.36 4.89999 21.6C5.21261 21.8508 5.59928 21.9914 5.99999 22C7.17999 22 8.03999 20.64 7.09999 19.4C6.78812 19.1478 6.40097 19.0071 5.99999 19V19Z"
							fill="#F2EDE9"
						/>
					</svg>
				</div>
			</button>
			<ListModal showModal={showModal} setShowModal={setShowModal} />
		</>
	);
}

export default Container;
