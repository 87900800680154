import styled from 'styled-components';
// import { color } from 'styles';

export const container = styled.div`
	width: 65%;
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 800px) {
		width: 100%;
	}
`;
