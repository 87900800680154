/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import ContentTabTis from './ContentTabTis';
import ShapeContent from './ShapeContent';
import ContentTabAmazon from './ContentTabAmazon';
import ContentTabPanel from './ContentTabPanel';

export const isCurrent = ({
	itemSelect,
	clickOnTheLayer,
	selectedLayer,
	selectedFeature,
	setResetOverlay,
	resetOverlay
}) => {
	switch (clickOnTheLayer) {
		case 'tisFixed':
			return (
				<p>
					Ative a camada de Terras Indígenas para visualizar mais informações.
				</p>
			);
		case 'tabAmazonTis':
			return (
				<>
					<ContentTabAmazon itemSelect={itemSelect || {}} />
					<ContentTabTis itemSelect={itemSelect || {}} />
				</>
			);
		case 'illegalHunting':
		case 'fire':
		case 'deforestation':
		case 'woodExploitation':
		case 'illegalMining':
		case 'invasionLand':
		case 'illegalFishing':
		case 'otherAlerts':
		case 'aldeia':
		case 'caca':
		case 'coleta':
		case 'pesca':
		case 'roca':
		case 'outrosUsotradiconal':
		case 'whereIBeen':
		case 'tracks':
		case 'getPoint':
		case 'monitoring':
			return <ContentTabPanel itemSelect={itemSelect || {}} />;
		case 'measure_draw':
		case 'shapes_draw':
			return (
				<ShapeContent
					selectedLayer={selectedLayer}
					selectedFeature={selectedFeature}
					setResetOverlay={setResetOverlay}
					resetOverlay={resetOverlay}
				/>
			);
		default:
			return <p>Sem informação</p>;
	}
};

function TooltipContent({
	itemSelect,
	clickOnTheLayer,
	selectedLayer,
	selectedFeature,
	setResetOverlay,
	resetOverlay
}) {
	return isCurrent({
		itemSelect,
		clickOnTheLayer,
		selectedLayer,
		selectedFeature,
		setResetOverlay,
		resetOverlay
	});
}

export default memo(TooltipContent);
