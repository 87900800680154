import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { olMap, zoomToExtentLayer, zoomToExtentMap } from 'components/Map/ControlMap/index';
import { zoomPaddingExtent } from 'helpers/utils';
import { StyleLayers } from 'components/Map/Styles/js/StyleLayers';
import { getLayerByName, getMaxZIndex } from 'store/actions/mapActions';
import { setProgress } from 'store/actions/layersAction/toggleLayersTabTi/toggleLayersTabTi';

const sourceState = new VectorSource({ wrapX: false });

const lastZIndex = getMaxZIndex() + 1;

const vectorState = new VectorLayer({
	name: 'tabAmazon',
	source: sourceState,
	style: [StyleLayers.tabAmazon],
	zIndex: lastZIndex,
	haveTitle: false // Se mostra ou não o titulo da legenda
});

const updateSourceStates = (state) => {
	const collection = {
		type: 'FeatureCollection',
		features: [
			{
				type: 'Feature',
				geometry: state.geom,
				properties: {
					type: 'tabAmazon',
					props: state
				}
			}
		]
	};

	const featuresState = new GeoJSON({
		featureProjection: 'EPSG:3857'
	}).readFeatures(collection);

	sourceState.clear(); // Limpa os recursos existentes
	sourceState.addFeatures(featuresState); // Adiciona os novos recursos
};

export const TabAmazonMap = (state, visible) => async (dispatch) => {
	if (!state) {
		zoomToExtentMap();
		sourceState.clear();
		return;
	}

	const isVectorExist = getLayerByName('tabAmazon');

	if (!isVectorExist) await olMap().addLayer(vectorState);

	const { nome, sigla } = state;
	const nameLabel = sigla ? `${nome} - ${sigla}` : nome;

	vectorState.setVisible(visible);
	vectorState.set('label', nameLabel || 'Estado selecionado');

	dispatch(setProgress(sourceState, vectorState.get('name'), false));

	updateSourceStates(state);

	const extent = sourceState.getExtent();
	const padding = zoomPaddingExtent();
	zoomToExtentLayer(extent, 1000, padding);
};
