import styled from 'styled-components';
import { color } from 'styles';

export const BoxInput = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 22px;
	border-radius: 6px;
	background: #f9f9f9;
	border: 1px solid #ebebeb;
	border-radius: 30px;
	background: #f9f9f9;
	margin-bottom: 7px;

	:hover {
		border: none;
		border: 1px solid ${color.interface.orange};
	}

	input[type='date'] {
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
		text-transform: uppercase;
	}
`;

export const Icon = styled.div`
	color: ${color.interface.darkGray};
	margin-right: 8px;
`;

export const Input = styled.input`
	width: 100%;
	flex: 1 1 auto;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	line-height: 21px;
	border: none;
	padding: 0;
	background: transparent;
	color: ${color.interface.darkGray};
	font-size: 0.875rem;
	font-weight: 500;

	:focus {
		outline: none;
		border: none;
	}
	::placeholder {
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	}
`;
