import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerSearch = styled.div`
	@media (max-width: 624px) {
		width: 100%;
	}
`;

export const search = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const buttonClose = styled.button`
	background-color: transparent;

	padding: 1rem;
	/* background-color: red; */
	position: absolute;
	right: 1%;
	cursor: pointer;
	min-width: 1rem;
	min-height: 1rem;
`;

export const noticeError = styled.p`
	color: ${color.interface.red};
	opacity: 0.8;
	margin-left: 0.5rem;
	margin-top: 0.5rem;
`;
