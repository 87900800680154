export const color = {
	system: {
		error: '#f44336',
		warning: '#ffa726',
		info: '#29b6f6',
		success: '#66bb6a',
		font: '#291F17'
	},
	interface: {
		orange: '#D44500',
		red: '#D41500',
		green: '#006946',
		darkGreen: '#21543D',
		white: '#F8F5F3',
		whiteAbsolute: '#FFFFFF',
		offWhite: '#EDEAE6',
		beige: '#DCCEC1',
		beige2: '#EDE7E0',
		beige3: '#F3EDE9',
		beige4: '#FCFBFB',
		gray: '#9F9A97',
		gray2: '#7E7874',
		lightGray: '#D9D5D2',
		lightGray2: '#B4B0AE',
		darkGray: '#69625D',
		mediumGray: '#BFB9B4',
		brown: '#3E352E',
		lightBrown: '#807368',
		inputs: '#a69d95'
	},
	auxiliary: {
		greenMoss: '#50A200',
		greenFluor: '#57DE9E',
		greenBaby: '#57DE9E',
		yellow: '#FF7B24',
		mustard: '#DEA100',
		coral: '#FA562D',
		lightCoral: '#FF7B24',
		darkCoral: '#B93B00',
		brownCoral: '#B93B00',
		brownMoss: '#857535',
		blueBic: '#50A200',
		blueSky: '#32C8ED',
		blueWater: '#1677B8',
		bluePurple: '#7080E0'
	}
};
