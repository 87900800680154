import styled from 'styled-components';
import { color } from 'styles/Theme';

const TooltipContainer = styled.div`
	position: absolute;
	background-color: #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
	padding: 15px;
	margin-bottom: 2rem;
	border-radius: 7px;
	bottom: 12px;
	left: -50px;
	width: max-content;
	min-width: 200px;
	max-width: 16rem;
	/* padding-right: 30px; */
	height: auto;
	color: '#ffffff';
	font-size: 0.9375rem;

	&:after {
		top: 100%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-top-color: ${color.interface.beige2};
		border-width: 10px;
		left: 48px;
		margin-left: -10px;
	}

	code {
		color: 'red';
	}
`;

export const Row = styled.div`
	display: flow-root;
	margin-bottom: 7px;
`;

const media = {
	xs: (styles) => `
      @media only screen and (max-width: 480px) {
        ${styles}
      }
    `
};

export const Col = styled.div`
	flex: ${(props) => props.size};

	${(props) =>
		props.collapse &&
		media[props.collapse](`
    display: none;
  `)}
`;
export const Title = styled.h6`
	text-transform: uppercase;
	letter-spacing: 0;
	text-align: left;
	color: ${color.interface.orange};
	font-size: 0.75rem;
	margin-bottom: 3px;
`;
const TooltipContent = styled.div``;

export { TooltipContainer, TooltipContent };
