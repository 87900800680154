import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { svg } from 'lib/svgList';
import Modal from 'components/Modal';
import CalendarReact from '../CalendarReact';
import * as S from './styled';

export default function CalendarStartAndEnd({
	setDates = () => {},
	dates = {}
}) {
	const [initialDate, setInitialDate] = useState('');
	const [initialDateFormated, setInitialDateFormated] = useState('');
	const [finalDate, setFinalDate] = useState('');
	const [finalDateFormated, setFinalDateFormated] = useState('');
	const [initialCalendarOpen, setInitialCalendarOpen] = useState(false);
	const [finalCalendarOpen, setFinalCalendarOpen] = useState(false);
	const [errorDate, setErrorDate] = useState(false);

	function formateDate(date) {
		let formatedDate = '00/00/00';
		if (date) {
			const month = (date.getMonth() + 1).toString();
			const monthF = month.length === 1 ? `0${month}` : month;
			const day = date.getUTCDate().toString();
			const dayF = day.length === 1 ? `0${day}` : day;
			const year = date.getUTCFullYear().toString();

			formatedDate = `${dayF}/${monthF}/${year}`;
		}

		return { formatedDate, dateOrginal: date };
	}

	useEffect(() => {
		if (initialDate !== '') {
			setInitialDateFormated(formateDate(initialDate));
		}
	}, [initialDate]);

	useEffect(() => {
		if (finalDate !== '') {
			setFinalDateFormated(formateDate(finalDate));
		}
	}, [finalDate]);

	useEffect(() => {
		if (initialDate !== '' && finalDate !== '') {
			if (initialDate.getTime() > finalDate.getTime()) {
				setInitialDate('');
				setInitialDateFormated('');
				setFinalDate('');
				setFinalDateFormated('');
				setErrorDate(true);
			}
		}
	}, [initialDate, finalDate]);

	useEffect(() => {
		if (initialDateFormated !== '' || finalDateFormated !== '') {
			setDates({ initialDateFormated, finalDateFormated });
		}
	}, [initialDateFormated, finalDateFormated]);

	// se der erro, preciso retornar o objeto vazio para quem estiver usando este comp
	useEffect(() => {
		if (errorDate === true) {
			setDates({});
		}
	}, [errorDate]);

	// os dois useffect abaixo são para apagar no objeto pai os valores das datas, caso o usuário apague a data inicial ou final
	useEffect(() => {
		if (initialDate === '') {
			setInitialDate('');
			setInitialDateFormated('');
			setDates({ ...dates, initialDateFormated: '' });
		}
	}, [initialDate]);

	useEffect(() => {
		if (finalDate === '') {
			setFinalDate('');
			setFinalDateFormated('');
			setDates({ ...dates, finalDateFormated: '' });
		}
	}, [finalDate]);

	return (
		<S.containerCalendarStartAndEnd>
			{/* ------------------ componente que mostra data inicial e final ------------------ */}
			<S.boxDatesStarAndEnd>
				<S.dateStart
					onClick={() => {
						setInitialCalendarOpen(true);
						setFinalCalendarOpen(false);
						setInitialDate('');
						setInitialDateFormated('');
					}}
				>
					<h5>DATA INCIAL</h5>

					{initialDateFormated !== '' ? (
						<p>{initialDateFormated.formatedDate}</p>
					) : (
						<p>___ / ___ / ___</p>
					)}
				</S.dateStart>
				{/* tive que colocar um conteudo qualquer, para forçar a linha na vertical  */}
				<S.verticalLine>
					<span>.</span>
				</S.verticalLine>
				<S.dateEnd
					onClick={() => {
						setInitialCalendarOpen(false);
						setFinalCalendarOpen(true);
						setFinalDate('');
						setFinalDateFormated('');
					}}
				>
					<h5>DATA FINAL</h5>
					{finalDateFormated !== '' ? (
						<p>{finalDateFormated.formatedDate}</p>
					) : (
						<p>___ / ___ / ___</p>
					)}
				</S.dateEnd>
				<S.iconCalendar src={svg.icon.calendar} alt="caléndario" />
			</S.boxDatesStarAndEnd>

			{/* ------------------ calendário inicial e final  ------------------ */}
			<S.boxCalendar>
				<CalendarReact
					calendarOpen={initialCalendarOpen}
					setCalendarOpen={setInitialCalendarOpen}
					onChange={setInitialDate}
				/>

				<CalendarReact
					calendarOpen={finalCalendarOpen}
					setCalendarOpen={setFinalCalendarOpen}
					onChange={setFinalDate}
				/>

				<Modal isOpen={errorDate} setIsOpen={setErrorDate}>
					<p>
						A <b>data inicial</b> não pode ser antes que a <b>data final</b> .
					</p>
				</Modal>
			</S.boxCalendar>
		</S.containerCalendarStartAndEnd>
	);
}
