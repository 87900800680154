import React from 'react';
import { useSelector } from 'react-redux';
import TermsOfUse from 'components/TermsOfUse';
import Login from './Login';
import Register from './Register';

function Users() {
	const termsOfUseIsAccept = useSelector((state) => state.termsOfUseIsAccept);
	const isRoute = useSelector((state) => state.isRouteRef);
	const {
		userProfiles,
		rolesPublic,
		loadingRoles,
		message,
		blockLogin,
		loading
	} = useSelector((state) => state.user);

	const { tisList, isLoading: loandigTis } = useSelector(
		(state) => state.listTis
	);

	const { listOrg } = useSelector((state) => state.organizations);

	return isRoute === 'register' ? (
		<section>
			{termsOfUseIsAccept ? (
				<Register
					loading={loading}
					userProfiles={userProfiles}
					rolesPublic={rolesPublic}
					loadingRoles={loadingRoles}
					tisList={tisList}
					loandigTis={loandigTis}
					listOrg={listOrg}
					message={message}
					blockLogin={blockLogin}
				/>
			) : (
				<TermsOfUse />
			)}
		</section>
	) : (
		<Login />
	);
}

export default Users;
