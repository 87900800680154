import React, { useEffect, useState } from 'react';
import { svg } from 'lib/svgList';
import { color } from 'styles/Theme';
import Check from 'components/Buttons/Check';
import {
	zoomToRowMonitoring,
	zoomToExtentLayer
} from 'components/Map/ControlMap';
import { returnIdRoleUser, editArrayMonitoring } from 'helpers/utils';
import LabelStripedList from 'components/LabelStripedList';
import { useDispatch, useSelector } from 'react-redux';
import { editListAction } from 'store/actions/dataUserToShowOnMapAction';
import { getLayerByName } from 'store/actions/mapActions';

import * as S from './styled';

// A array de objetos precisa ter no minimo {icon: 'endereço do icone', information: 'alguma string'}
// alertOrGraph: caso o valor seja de alert, vai mostrar icone de gps, se não, link de download
// showLabel : serve para dizer se vc quer ou nao mostrar a legenda para o usuário, por que em whereIbeen ou tracker não precisa de legenda e tambem no caso de o usuário ser comum não precisa de legenda

export default function StripedList({
	list = [],
	alertOrGraph = 'gps',
	onClick = () => {},
	showLabel = false
}) {
	// renomendo os dados do redux para dataOnMapRedux
	const { dataUser: dataOnMapRedux } = useSelector(
		(state) => state.dataUserToShowOnMap
	);
	// const { dataToEdit } = useSelector((state) => state.editDataUserOnMap);
	const dispatch = useDispatch();

	// retorna o id_role do usuário ex: comum, adm e etc
	const typeUserLogged = returnIdRoleUser();

	// lista que sera retonada no stripedList
	const [stateList, setStateList] = useState([]);

	// constrola se todos itens estão ou não marcados
	const [checkAllAlerts, setCheckAllAlerts] = useState(false);

	// useEffect(async () => {
	// 	setTimeout(async () => {
	// 		const layer = await getLayerByName('monitoring');
	// 		const source = await layer.getSource();
	// 		const extent = source.getExtent();

	// 		if (extent && extent[0] !== Infinity) {
	// 			zoomToExtentLayer(extent);
	// 		}
	// 	}, 1000);
	// }, [stateList, dataToEdit]);

	function returnIconStatus(type) {
		switch (type) {
			case null:
			case '':
				return svg.icon.waitingAlert;
			case 'approved':
				return svg.icon.acceptAlert;
			case 'not_approved':
				return svg.icon.deniedAlert;
			default:
				return '';
		}
	}

	// altera estado de checado do item clicado
	function toggleCheck(alert) {
		setCheckAllAlerts(false);
		const newList = stateList.map((item) => {
			if (item.id === alert.id) {
				item.isChecked = !item.isChecked;
				return item;
			}
			return item;
		});
		setStateList(newList);

		// alterando no redux dos alertas que vao para o mapa, o item em especifico que foi editado
		const refreshRedux = editArrayMonitoring(dataOnMapRedux, alert.id);
		dispatch(editListAction(refreshRedux));
	}

	// marca todos os items da lista como TRUE
	function checkAllList() {
		const newList = stateList.map((item) => {
			item.isChecked = true;
			return item;
		});
		setStateList(newList);

		// alterar para true todos os item do mesmo tipo no redux
		const { type } = list[0];
		const newListRedux = dataOnMapRedux.map((item) => {
			if (item.type === type) {
				item.isChecked = true;
				return item;
			}
			return item;
		});
		dispatch(editListAction(newListRedux));
	}

	// marca todos os items da lista como FALSE
	function uncheckAllList() {
		const newList = stateList.map((item) => {
			item.isChecked = false;
			return item;
		});
		setStateList(newList);

		// alterar para false todos os item do mesmo tipo no redux
		const { type } = list[0];
		const newListRedux = dataOnMapRedux.map((item) => {
			if (item.type === type) {
				item.isChecked = false;
				return item;
			}
			return item;
		});
		dispatch(editListAction(newListRedux));
	}

	// chamo no btn de checar todos os itens para decidir qual função será chamada
	function toggleFunctionCheckList() {
		if (checkAllAlerts) {
			uncheckAllList();
			setCheckAllAlerts(false);
		} else {
			checkAllList();
			setCheckAllAlerts(true);
		}
	}

	async function zoom(data) {
		if (data.type === 'tracks') {
			const layer = await getLayerByName('monitoring');

			const source = await layer.getSource();

			// const extent = source.getExtent();

			const features = source.getFeatures();

			const feature = features.find((feat) => feat.get('props').id === data.id);

			const featureExtent = feature?.getGeometry()?.getExtent();

			zoomToExtentLayer(featureExtent);
		} else {
			zoomToRowMonitoring([data.longitude, data.latitude], 15);
		}
	}

	function returnListOrMessage() {
		if (typeof stateList === 'object' && stateList.length > 0) {
			return (
				stateList.length > 0 &&
				stateList.map((item, i) => (
					<S.itemList
						key={item.id || Math.random() * 1000}
						// style possui essa logica para deixar lista listrada
						style={
							i % 2 === 0 ? { backgroundColor: color.interface.beige3 } : null
						}
						onClick={() => {
							onClick();
						}}
					>
						<S.containerDate>
							<img
								src={
									alertOrGraph === 'gps'
										? svg.icon.locationRound
										: svg.icon.downloadGray
								}
								alt="ilustração do item da lista"
							/>

							<button
								type="button"
								onClick={() => {
									zoom(item);
								}}
							>
								<p>
									{(item.type === 'tracks' || item.type === 'where_i_been') &&
										`${item.name} - `}
									{item.information}
								</p>
							</button>
						</S.containerDate>

						<S.containerStatus
							onClick={() => {
								toggleCheck(item);
							}}
						>
							{/* verifica se a lista tem status (como chave do objeto) para retornar a imagem representativa do status do alerta e também se o usuário não é comum */}
							{list.length > 0 &&
							'status' in list[0] &&
							typeUserLogged !== 5 &&
							typeUserLogged !== 4 ? (
								<img
									src={returnIconStatus(item.status)}
									alt="ilustração status do alerta"
								/>
							) : null}

							<Check isChecked={item.isChecked} />
						</S.containerStatus>

						<S.line />
					</S.itemList>
				))
			);
		}
		return (
			<S.itemList>
				<p>Não há nenhum item para ser listado.</p>
			</S.itemList>
		);
	}

	// assim que chega a lista, esse useEffect já coloca uma chave no objeto que diz se ela está ou nao checada (começa true para os alertas aparecerem no mapa)
	useEffect(() => {
		if (typeof list === 'object' && list.length > 0) {
			setStateList(list);
		}
	}, [list]);

	// observa se todos os items da lista estão marcados para alterar o estado 'checkAllAlerts'
	useEffect(() => {
		if (stateList.length > 0 && 'isChecked' in stateList[0]) {
			let isAllChecked = true;
			stateList.forEach((item) => {
				if (!item.isChecked) {
					isAllChecked = false;
				}
			});
			if (isAllChecked) {
				setCheckAllAlerts(true);
			} else {
				setCheckAllAlerts(false);
			}
		}
	}, [stateList]);

	return (
		<S.containerStripedList>
			{showLabel ? <LabelStripedList /> : null}

			<S.checkAll onClick={() => toggleFunctionCheckList()}>
				<p>{checkAllAlerts ? 'Desmarcar' : 'Marcar'} todos os items</p>
				<Check isChecked={checkAllAlerts} setIsChecked={setCheckAllAlerts} />
			</S.checkAll>

			{returnListOrMessage()}
		</S.containerStripedList>
	);
}
