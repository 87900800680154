import React, { useState, useEffect } from 'react';
import Select from 'components/Buttons/Select/Select';
import { handleCloseTooltip, actionsUsers } from 'helpers/utils';
import { useSelector, useDispatch } from 'react-redux';
import { svg } from 'lib/svgList';
import { tisGeo } from 'store/actions/tiGeoAction';
import { getStates, getDataAboutStateSelected } from 'store/actions/statesAmazonLegalAction';
import { tabAmazonActionTis } from 'store/actions/layersAction/tabAmazon/tabAmazonActionTis';
import { loadStartTile, loadEndTile } from 'store/actions/layersAction/toggleLayersTabTi/toggleLayersTabTi';
import { TabAmazonMap } from 'store/actions/layersAction/tabAmazon/tabAmazonAction';
import * as S from './styled';

// formata os dados antes de passar o mesmo para o componente select
function formatData(data) {
	const newData = data.map((item) => ({
		id: item.id,
		itemList: item.nome,
		state: item
	}));
	return newData;
}

const findStateById = (states, idState) => (idState > 0 ? states.find((item) => item.id === idState) : null);

export default function SelectState({
	isOpenModal = false,
	setIsOpenModal = () => {},
	setStateSelectedIndex = () => {},
	territory
}) {
	const dispatch = useDispatch();

	const { tisGeoData, tisGeoIsLoading } = useSelector((state) => state.tiGeo);
	const { isReq, isReqDataState, statesAmazonLegal } = useSelector((state) => state.amazonLegal);

	const { activeToggleTis } = useSelector((state) => state.allLayersMap);

	const [stateSelected, setStateSelected] = useState({});

	// busca os dados no backend
	useEffect(() => {
		dispatch(getStates());
		dispatch(tisGeo());
	}, []);

	// filtra as TIs conforme estado que selecionar
	useEffect(() => {
		const state = findStateById(statesAmazonLegal, stateSelected.value);

		const filteredTis =
			state && tisGeoData ? tisGeoData.filter((ti) => ti.ufs_geocode.includes(state?.geocode)) : tisGeoData;

		dispatch(TabAmazonMap(state, activeToggleTis)); // limite estado
		dispatch(tabAmazonActionTis(filteredTis, activeToggleTis)); // limite TIs
	}, [tisGeoData, stateSelected, activeToggleTis]);

	// busca os dados das TIs da Amazônia ou do estado selecionado
	useEffect(() => {
		if (stateSelected.value >= 0) dispatch(getDataAboutStateSelected(stateSelected.value));
	}, [stateSelected.value]);

	useEffect(() => {
		dispatch(tabAmazonActionTis(tisGeoData, activeToggleTis));
		setStateSelected({ value: 0, label: 'Amazônia Legal' });
	}, [territory]);

	// para o loading do mapa (ao carregar as TIs pela primeira vez)
	useEffect(() => {
		if (activeToggleTis && tisGeoIsLoading) {
			dispatch(loadStartTile());
		} else {
			dispatch(loadEndTile());
		}

		return () => {
			dispatch(loadEndTile());
		};
	}, [activeToggleTis, tisGeoIsLoading]);

	const handleGA = ({ label }) => {
		actionsUsers({
			category: `plataforma/tabAmazon`,
			action: 'Estado selecionado',
			label
		});
	};

	const handleSelect = (item) => {
		handleGA({ label: item.label });
		setStateSelectedIndex(item);
		setStateSelected(item);
	};

	return (
		<S.containerSelect
			style={territory !== 'amazonia' ? { display: 'none' } : {}}
			onClick={() => {
				handleCloseTooltip();
			}}
		>
			<Select
				disabled={isReqDataState}
				isLoading={isReq}
				setItemSelected={handleSelect}
				itemSelected={stateSelected}
				options={formatData(statesAmazonLegal)}
				img={svg.icon.downloadDoc}
				functionBtn={() => {
					setIsOpenModal(!isOpenModal);
				}}
				btnDisabled={isReq}
				showFirtsItemOptionList
			/>
		</S.containerSelect>
	);
}
