import styled from 'styled-components';
import { color } from 'styles/Theme';

export const ContainerList = styled.div`
	padding: 0;
`;

export const ContainerSubTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 6px;
	padding-bottom: 6px;
`;

export const ButtonInfo = styled.button`
	background: transparent;
	display: flex;
	width: 50px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const BoxFilter = styled.div`
	margin-bottom: 20px;
	margin-top: 10px;
`;

export const Button = styled.button`
	background: ${color.interface.beige2};
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin-bottom: 10px;
	width: 100%;
	cursor: pointer;
	display: flex;

	justify-content: start;
	padding: 10px;
	align-items: center;

	:hover {
		background-color: ${color.interface.beige};
		border-radius: 7px;
	}

	.item-users-list {
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		font-size: 0.75rem;
		justify-content: start;
		display: flex;
		text-align: left;
		color: ${color.system.font};
	}
`;
