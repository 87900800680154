import React from 'react';
import * as S from './styled';
import { color } from '../../../styles/Theme';

export default function ButtonFireFocus({
	buttonNameActive = '',
	setButtonNameActive = () => {}
}) {
	return (
		<S.ContainerButtonFire>
			<button
				onClick={() => setButtonNameActive('button30dias')}
				type="button"
				style={
					buttonNameActive === 'button30dias'
						? {
								color: color.interface.orange,
								borderColor: color.interface.orange
						  }
						: null
				}
			>
				30 DIAS
			</button>
			<button
				onClick={() => setButtonNameActive('buttonPorMes')}
				type="button"
				style={
					buttonNameActive === 'buttonPorMes'
						? {
								color: color.interface.orange,
								borderColor: color.interface.orange
						  }
						: null
				}
			>
				POR MÊS
			</button>
			{/* <button
				onClick={() => setButtonNameActive('buttonRiskFire')}
				type="button"
				style={
					buttonNameActive === 'buttonRiskFire'
						? {
								color: color.interface.orange,
								borderColor: color.interface.orange
						  }
						: null
				}
			>
				RISCO DE FOGO
			</button> */}
		</S.ContainerButtonFire>
	);
}
