import React from 'react';
import { svg } from 'lib/svgList';
import { color } from 'styles/Theme';
import * as S from './styled';

export default function TitleMenu({
	icon = '',
	title = 'Não definido',
	theme = '',
	onClick = () => {},
	isStyle = {}
}) {
	return (
		<S.containerTitleMenu style={isStyle}>
			<S.containerItemAndArrow onClick={() => onClick()}>
				<S.ItemMenu
					style={{
						color:
							theme === 'dark' ? color.interface.brown : color.interface.orange
					}}
				>
					{icon ? (
						<img style={{ width: 12 }} src={icon} alt="item menu" />
					) : null}
					<p>{title}</p>
				</S.ItemMenu>

				<img
					src={theme === 'dark' ? svg.icon.arrowDark : svg.icon.arrowOrange}
					alt="acessar item do menu"
					style={{ transform: ' rotate(270deg)' }}
				/>
			</S.containerItemAndArrow>

			<S.lineMenu props={{ theme }} />
		</S.containerTitleMenu>
	);
}
