import BarGraph from 'components/Graphs/BarGraph/BarGraph';
import React, { useEffect, useState } from 'react';
// import { groupBy } from 'helpers/utils';
import * as S from './styled';

export default function ConsultGraph({ data = [] }) {
	const [list, setList] = useState([]);
	const [dateHaveAlert, setDateHaveAlert] = useState([]);
	const [qtdAlertPerDay, setQtdAlertPerDay] = useState([]);

	const typeData =
		data?.length > 0
			? `Registro de ${data[0].name.toLowerCase()} por período.`
			: '';

	// Dado necessário para passar para o grafico e barras
	const lineData = [
		{
			label: typeData,
			data: qtdAlertPerDay,
			backgroundColor: '#D44500'
		}
	];

	// função que pega as datas que possuem alertas
	function countAlertByDate(array) {
		const datesHaveAlert = [];
		array.forEach((item) => {
			if (datesHaveAlert.length === 0) {
				datesHaveAlert.push({ date: item.dateToGraph });
			} else if (datesHaveAlert.length > 0) {
				const exist = datesHaveAlert.filter(
					(value) => value.date === item.dateToGraph
				);

				if (exist.length === 0) {
					datesHaveAlert.push({ date: item.dateToGraph });
				}
			}
		});
		// preciso inverter a array para mostrar no gráfico da mais antiga pra mais nova
		setDateHaveAlert(datesHaveAlert.reverse());
	}

	// responsável por pegar as datas que possuem alerta (datas sem repetição)
	useEffect(() => {
		if (list.length > 0) {
			countAlertByDate(list);
		}
	}, [list]);

	// responsável por pegar os alertas que foram registrados no mesmo mês e jogar no estado
	useEffect(() => {
		if (dateHaveAlert.length > 0) {
			const qtd = [];
			dateHaveAlert.forEach((item1) => {
				const alertsSameDay = list.filter(
					(item2) => item2.dateToGraph === item1.date
				);
				if (alertsSameDay.length > 0) {
					qtd.push(alertsSameDay.length);
				}
			});
			setQtdAlertPerDay(qtd);
		}

		return () => {
			setQtdAlertPerDay([]);
		};
	}, [dateHaveAlert]);

	// responsavel por add em cada item da lista a informação de dateToGraph, que será usado para calcular numero de alertas no mes
	useEffect(() => {
		if (data.length > 0 && list.length === 0) {
			const newList = data.map((item) => {
				const dataAndHour = item.information.split(',');
				const onlyMonthAndYear = dataAndHour[0].split('/');
				return {
					...item,
					dateToGraph: `${onlyMonthAndYear[1]}/${onlyMonthAndYear[2]}`
				};
			});

			setList(newList);
		}
	}, [data]);

	// limpar todos os estados quando comp for destruido
	useEffect(
		() => () => {
			setList([]);
			setDateHaveAlert([]);
			setQtdAlertPerDay([]);
		},
		[]
	);

	// em labelsProps tive que fazer um mao para retornar somente as string que estavam dentro
	return (
		<S.containerConsultBarGraph>
			<BarGraph
				labelsProps={dateHaveAlert.map((item) => item.date)}
				datasetsProps={qtdAlertPerDay.length > 0 ? lineData : []}
			/>
		</S.containerConsultBarGraph>
	);
}
