/* eslint-disable no-unused-expressions */
import React, { useRef, useEffect, useState } from 'react';
import * as S from './styles';
import ListModal from './ListModal';

function returnInitialList(type) {
	switch (type) {
		case 'database':
			return [
				{
					name: 'Shapefile',
					format: 'shp',
					isChecked: false
				},
				{
					name: 'KML',
					format: 'kml',
					isChecked: false
				}
			];

		case 'raster':
			return [
				{
					name: 'Raster',
					isChecked: false,
					format: 'raster'
				}
			];

		default:
			return [];
	}
}
export default function SaveMap({ item, period = 0 }) {
	const modalRef = useRef(null);

	const desc = 'Baixar e exportar o mapa atual';
	const [showModal, setShowModal] = useState(false);

	const openModal = () => {
		setShowModal((prev) => !prev);
	};

	// para definir qual radio button fica checked
	const [disabled, setDisabled] = useState(true);
	const [options, setOptions] = useState(returnInitialList(item.formatGeo));

	useEffect(() => {
		options.filter((todo) => todo.isChecked).length === 0
			? setDisabled(true)
			: setDisabled(false);
	}, [options]);

	const toggleBaseLayer = (isChecked, i) => {
		const optionsClone = options.map((option, j) => {
			// se o item for diferente do selecionado deixa ele como false
			if (j !== i) {
				return {
					...option,
					isChecked: false
				};
			}
			return {
				...option,
				isChecked: !isChecked
			};
		});
		setOptions(optionsClone);
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				setShowModal(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [modalRef]);

	const btnDownload =
		(item.formatGeo === 'raster' && item.tiffPath) ||
		item.formatGeo !== 'raster';

	return (
		btnDownload && (
			<S.Container style={{ position: 'relative' }} ref={modalRef}>
				<button
					type="button"
					style={{ background: 'transparent' }}
					data-title={desc}
					onClick={openModal}
				>
					<svg
						width="100%"
						viewBox="0 0 14 13"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.65836 9.84169C6.70513 9.89166 6.76166 9.93149 6.82444 9.95872C6.88723 9.98595 6.95493 10 7.02336 10C7.0918 10 7.1595 9.98595 7.22228 9.95872C7.28507 9.93149 7.34159 9.89166 7.38836 9.84169L10.8884 6.09101C10.9681 5.9926 11.0076 5.86758 10.9988 5.74119C10.99 5.6148 10.9335 5.49647 10.8408 5.41011C10.7481 5.32375 10.6261 5.2758 10.4994 5.27595C10.3728 5.27609 10.2509 5.32432 10.1584 5.41089L7.52336 8.2339V1.00009C7.52336 0.867458 7.47069 0.740258 7.37692 0.646473C7.28315 0.552688 7.15597 0.5 7.02336 0.5C6.89076 0.5 6.76358 0.552688 6.66981 0.646473C6.57604 0.740258 6.52336 0.867458 6.52336 1.00009V8.2339L3.88836 5.41089C3.84519 5.35764 3.79153 5.31383 3.73071 5.2822C3.6699 5.25056 3.60323 5.23177 3.53485 5.22699C3.46647 5.22221 3.39783 5.23155 3.33321 5.25442C3.26859 5.27729 3.20936 5.31321 3.1592 5.35994C3.10904 5.40667 3.06902 5.46322 3.04164 5.52607C3.01425 5.58892 3.00008 5.65673 3 5.7253C2.99992 5.79386 3.01394 5.8617 3.04118 5.92462C3.06843 5.98753 3.10831 6.04417 3.15836 6.09101L6.65836 9.84169Z"
							fill="#69625D"
						/>
						<path
							d="M13 9C12.8674 9 12.7402 9.05268 12.6464 9.14645C12.5527 9.24021 12.5 9.36739 12.5 9.5V11.98L12.48 12H1.52L1.5 11.98V9.5C1.5 9.36739 1.44732 9.24021 1.35355 9.14645C1.25979 9.05268 1.13261 9 1 9C0.867392 9 0.740215 9.05268 0.646447 9.14645C0.552678 9.24021 0.5 9.36739 0.5 9.5V12C0.509899 12.2621 0.618434 12.5107 0.803868 12.6961C0.989302 12.8816 1.23794 12.9901 1.5 13H12.5C12.7621 12.9901 13.0107 12.8816 13.1961 12.6961C13.3816 12.5107 13.4901 12.2621 13.5 12V9.5C13.5 9.36739 13.4473 9.24021 13.3536 9.14645C13.2598 9.05268 13.1326 9 13 9Z"
							fill="#69625D"
						/>
					</svg>
				</button>
				<ListModal
					showModal={showModal}
					item={item}
					setShowModal={setShowModal}
					toggleBaseLayer={toggleBaseLayer}
					options={options}
					disabled={disabled}
					period={period}
				/>
			</S.Container>
		)
	);
}
