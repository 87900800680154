import styled from 'styled-components';
import { color } from '../../../styles/Theme';

export const containerTabSection = styled.div`
	width: 100%;
	margin-bottom: 8px;
	/* background-color: yellow; */
`;

export const BoxTitle = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
	/* background-color: antiquewhite; */
	img {
		width: 15px;
		height: 15px;
	}

	h5 {
		color: ${color.interface.orange};
		text-transform: uppercase;
		margin-bottom: 5px;
		margin-top: 10px;
		width: 100%;
		margin-left: 0.5rem;
		text-align: left;
		font-size: 13px;
		font-weight: 900 !important;
		/* background-color: aquamarine; */
	}

	button {
		min-width: 30%;
		background-color: transparent;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: ${color.interface.orange};
		font-size: 11px;
		text-align: right;
		cursor: pointer;
	}
`;

export const OrangeLine = styled.div`
	width: 100%;
	height: 0.5px;
	background-color: ${color.interface.orange};
`;
