import React, { useEffect, useState } from 'react';
import { BiSad } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { color } from 'styles/Theme';

import * as S from './styled';

export default function Error404() {
	const [count, setCount] = useState(10);
	const navigate = useNavigate();

	useEffect(() => {
		if (count > 0) {
			setTimeout(() => {
				setCount(count - 1);
			}, 1000);
		}

		if (count === 0) {
			navigate('/');
		}
	}, [count]);

	return (
		<S.ConatinerError404>
			<BiSad size={200} color={color.interface.orange} />
			<h1>404</h1>
			<h4>Página não encontrada</h4>

			<p>
				A página que você está procurando não existe <br /> Você será
				redirecionado para página incial em {count} segundos.
			</p>

			{/* <button type="button" onClick={() => regressiveCounter()}>
				teste
			</button> */}
		</S.ConatinerError404>
	);
}
