/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React from 'react';
import { getUserToken, getUserLocalStorage } from 'services/session';
import ReactGA from 'react-ga4';
import { color } from 'styles/Theme';
import { svg } from 'lib/svgList';
import { olMap, zoomToExtentLayer, zoomToRowMonitoring, zoomToExtentMap } from 'components/Map/ControlMap/index';
import { getLayerByName } from 'store/actions/mapActions';
import jsPDF from 'jspdf';
import { GeoJSON, KML, GML } from 'ol/format';
import { CanvasToTIFF } from './canvastotiff';

// Validação para latitude e longitude
export function isLatitude(lat) {
	return Number.isFinite(parseFloat(lat)) && Math.abs(parseFloat(lat)) <= 90;
}

export function isLongitude(long) {
	return Number.isFinite(parseFloat(long)) && Math.abs(parseFloat(long)) <= 180;
}

export function actionsUsers({ category, action, label }) {
	return ReactGA.event({
		category: `${window.location.hostname}/${category}`,
		action,
		label
	});
}

export function componentsViewUsers({ page = '/my-path', title = 'Custom Title' }) {
	return ReactGA.send({
		hitType: 'pageview',
		page: `${window.location.hostname}/${page}`,
		title
	});
}

// Garantia apos retornar o novo dado do banco
export const filterUsers = (roleManager, listUsers) => {
	if (roleManager && listUsers) {
		switch (roleManager) {
			case 'manager':
				return listUsers.filter((row) => row.role_id === 3);
			default:
				return listUsers;
		}
	}
	return [];
};

export const UploadGeo = {
	validateUpload(files, formatUpload) {
		let EXTENSIONS = [];
		let message = '';
		let totalFilesIsOk = true;

		if (formatUpload === 'shp') {
			EXTENSIONS = ['SHP', 'PRJ', 'DBF', 'SHX'];
			message =
				'Shapefile inválido! Faça upload do grupo de arquivos de um shapefile por vez. Obs.: Selecione os 4 arquivos com as extensões:".dbf", ".prj", ".shp", ".shx".';
			if (Object.keys(files).length !== 4) totalFilesIsOk = false;
		}

		if (formatUpload === 'kml') {
			EXTENSIONS = ['KML'];
			message = 'Kml inválido! Faça upload de um arquivo kml por vez.';
			if (Object.keys(files).length !== 1) totalFilesIsOk = false;
		}

		if (!totalFilesIsOk) {
			return {
				status: false,
				message
			};
		}

		const names = [];
		const extensions = [];

		Object.keys(files).forEach((key) => {
			if (Object.prototype.hasOwnProperty.call(files, key)) {
				const nameFile = files[key].name.toUpperCase();

				const regExtension = nameFile.match(/\.([^./]+)$/);
				const regName = nameFile.match(/([^/]+)(?=\.\w+$)/);
				const extension = (regExtension && regExtension[1]) || '';
				const name = (regName && regName[1]) || '';

				names.push(name);
				extensions.push(extension);
			}
		});

		if (names.every((e) => e === names[0]) && EXTENSIONS.every((e) => extensions.indexOf(e) >= 0)) {
			return { status: true };
		}
		return {
			status: false,
			message
		};
	}
};

export function getFileType(files) {
	if (files) {
		const validExtensions = ['.shp', '.prj', '.dbf', '.shx', '.kml', '.kmz'];
		const extensions = Array.from(files).map((file) => file.name.slice(-4));
		const validExtension = validExtensions.find((ext) => extensions.includes(ext));
		switch (validExtension) {
			case '.shp':
			case '.prj':
			case '.dbf':
			case '.shx':
				return 'shp';
			case '.kml':
				return 'kml';
			case '.kmz':
				return 'kmz';
			default:
				return null;
		}
	}
	return [];
}

export const randomString = (size) => {
	let string = '';
	const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	for (let i = 0; i < size; i += 1) {
		string += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
	}
	return string;
};

// Nome do arquivo para fazer o download
export const nameFileDownGeoMonitoring = (route) => {
	switch (route) {
		case 'alerts':
			return `download-registro-mapa-alerta-${randomString(20)}.kmz`;
		case 'traditional_uses':
			return `download-registro-mapa-uso-tradicional-${randomString(20)}.kmz`;
		case 'tracks':
			return `download-registro-mapa-trajeto-${randomString(20)}.kml`;
		case 'where_i_been':
			return `download-registro-mapa-localizacao-${randomString(20)}.kml`;
		default:
			return '';
	}
};

// remove da lista os itens deletados
export const filterDeleted = (list, hashis) => list.filter((item) => !hashis.includes(item.hash));

export const headers = () => {
	const token = getUserToken();
	return token
		? {
				Authorization: `Bearer ${token}`
		  }
		: null;
};

export const formatTis = (tis) => {
	if (typeof tis === 'object' && tis.length > 0) {
		const list = tis.map((item) => {
			if (item) return { code: item.code, name: item.name };
			return [];
		});
		return list;
	}
	return [];
};

export const formatArrayAndReturnValueAndLabel = (array) => {
	if (typeof array === 'object' && array.length !== 0) {
		const keys = Object.keys(array[0]);
		const list = array.map((item) => {
			if (item) return { value: item[keys[0]], label: item[keys[1]] };
			return [];
		});
		return list;
	}
	return [];
};

export const formatTisValueAndLabel = (tis) => {
	if (typeof tis === 'object' && tis.length !== 0) {
		const list = tis.map((item) => {
			if (item) return { value: item.code, label: item.name };
			return [];
		});
		return list;
	}
	return [];
};

export const formatTisSelect = (tis) => {
	if (tis && tis.length > 0) {
		const list = tis.map((item) => {
			if (item) return { value: item.code, label: item.name };
			return [];
		});
		return list;
	}
	return [];
};

export const convertAge = (data) => {
	if (data) {
		const yearBirthParts = data.split('-');
		const daybirth = yearBirthParts[2];
		const monthBirth = yearBirthParts[1];
		const yearBirth = yearBirthParts[0];

		const date = `${daybirth}/${monthBirth}/${yearBirth}`;

		return date;
	}
	return [];
};

export const convDateInverser = (data) => {
	if (data) {
		const yearBirthParts = data.split('/');
		const daybirth = yearBirthParts[2];
		const monthBirth = yearBirthParts[1];
		const yearBirth = yearBirthParts[0];

		const date = `${daybirth}-${monthBirth}-${yearBirth}`;

		return date;
	}
	return [];
};

export const convertJoin = (data) => {
	if (data && data.length > 0) {
		return `[${data?.map((e) => e.value).join(',')}]`;
	}
	return '';
};

export const isColorStatus = (id) => {
	switch (id) {
		case 1:
			return color.interface.green;
		case 2:
			return color.interface.red;
		case 3:
			return color.auxiliary.lightCoral;
		case 4:
			return color.auxiliary.mustard;
		case 6:
			return color.auxiliary.blueWater;
		default:
			return color.auxiliary.bluePurple;
	}
};

export const isNamePeriod = (periods) => {
	if (periods && periods?.id) {
		const { id, label } = periods;
		switch (id) {
			case id:
				return label;
			default:
				return '';
		}
	}
	return '';
};

export const typesAlerts = [
	{
		type: 'fogo',
		icon: `${svg.icon.fogoOrange}`,
		text: 'FOGO'
	},
	{
		type: 'desmatamento',
		icon: `${svg.icon.desmatamentoIlegalOrange}`,
		text: 'DESMATAMENTO'
	},
	{
		type: 'pesca_ilegal',
		icon: `${svg.icon.pescaIlegalOrange}`,
		text: 'PESCA ILEGAL'
	},
	{
		type: 'exploracao_ilegal_madeira',
		icon: `${svg.icon.exploracaoIlegalOrange}`,
		text: 'EXPLORAÇÃO ILEGAL DE MADEIRA'
	},
	{
		type: 'garimpo_ilegal',
		icon: `${svg.icon.garimpoIlegalOrange}`,
		text: 'GARIMPO ILEGAL'
	},
	{
		type: 'invasao_terra_indigena',
		icon: `${svg.icon.invasaoTiOrange}`,
		text: 'INVASÃO EM TERRA INDÍGENA'
	},
	{
		type: 'caca_ilegal',
		icon: `${svg.icon.cacaIlegalOrange}`,
		text: 'CAÇA ILEGAL'
	},
	{
		type: 'outros',
		icon: `${svg.icon.outrosOrange}`,
		text: 'OUTROS'
	}
];

export const typesTraditionalUse = [
	{ type: 'aldeia', icon: `${svg.icon.aldeiaOrange}`, text: 'ALDEIA ' },
	{ type: 'caca', icon: `${svg.icon.cacaOrange}`, text: 'CAÇA' },
	{ type: 'coleta', icon: `${svg.icon.coletaOrange}`, text: 'COLETA' },
	{ type: 'outros', icon: `${svg.icon.outrosOrange}`, text: 'OUTROS' },
	{ type: 'pesca', icon: `${svg.icon.pescaOrange}`, text: 'PESCA' },
	{ type: 'roca', icon: `${svg.icon.rocaOrange}`, text: 'ROÇA' }
];

export const returnIconAlert = (value) => {
	const filter = typesAlerts.filter((item) => item.type === value);
	return filter[0]?.icon || svg.icon.outrosOrange;
};

export const returnIconTraditionalUse = (value) => {
	const filter = typesTraditionalUse.filter((item) => item.type === value);
	return filter[0]?.icon || svg.icon.outrosOrange;
};

export const messageAlertUpdateApprove = (id, name) => {
	switch (id) {
		case 3:
			return (
				<div>
					<strong>Obs:</strong> Ao confirmar {name} terá o seu acesso alterado para o tipo de usuario e organização
					informados acima. Caso queira cancelar ou recusar o perfil irá continuar com o acesso antigo.
				</div>
			);
		case 4:
			return (
				<div>
					<strong>Obs:</strong> Ao confirmar {name} terá o seu acesso aprovado para o tipo de usuario e organização
					informados acima.
				</div>
			);
		case 6:
			return (
				<div>
					<strong>Obs:</strong> Antes de confirmar certifique-se de ter recebido o termo de autorização de uso do
					sistema / app pelo responsável do menor de idade.
				</div>
			);
		default:
			return '';
	}
};

export const messageAlertUpdateRecuse = (id, name) => {
	switch (id) {
		case 3:
			return (
				<div>
					<strong>Obs:</strong> Ao confirmar {name} irá continuar com o acesso antigo, e os dados informados acima serão
					ignorados.
				</div>
			);
		default:
			return '';
	}
};

export const waitingForApproval = (id) => {
	switch (id) {
		case 1:
			return false;
		case 2:
			return false;
		case 3:
			return true;
		case 4:
			return false;
		case 6:
			return false;
		default:
			return false;
	}
};

export const nameButtonForSituation = (id, role) => {
	if (id) {
		switch (id) {
			case 1:
				return 'Atualizar';
			case 2:
				return 'Atualizar';
			case 3:
				return 'Aprovar';
			case 4:
				return 'Aprovar';
			case 6:
				return role === 5 ? 'Aprovar' : 'Aprovar documentação';
			default:
				return 'Atualizar';
		}
	}
	return 'Atualizar';
};

export const filterRoles = (name, roles) => {
	switch (name) {
		case 'manager':
			return roles.filter((f) => f.id !== 1 && f.id !== 2 && f.id !== 6 && f.id !== 4);
		case 'adminPartners':
			return roles.filter((f) => f.id !== 1 && f.id !== 6 && f.id !== 4);
		case 'admin':
			return roles.filter((f) => f.id !== 4 && f.id !== 6);
		default:
			return [];
	}
};

// função que retorna para qual endpoint iremos fazer a requisição
export function returnReqEndPoint(typeReq) {
	switch (typeReq) {
		case 'alert':
			return 'alerts';
		case 'traditionalUse':
			return 'traditional_uses';
		case 'tracker':
			return 'tracks';
		case 'whereIBeen':
			return 'where_i_been';
		default:
			return '';
	}
}

export const returnTextOfTypes = (type) => {
	switch (type) {
		case 'alert':
			return {
				text: 'alertas',
				textBtn: 'alerta'
			};
		case 'traditionalUse':
			return {
				text: 'usos trad.',
				textBtn: 'uso'
			};
		case 'tracker':
			return {
				text: 'trajetos',
				textBtn: 'trajeto'
			};
		case 'whereIBeen':
			return {
				text: 'locais',
				textBtn: 'local'
			};
		default:
			return [];
	}
};

// se o objeto for vazio, irá retornar true
export const isEmptyObject = (obj) => {
	const isEmpety = Object.keys(obj).length;
	if (isEmpety === 0) {
		return true;
	}
	return false;
};

export const especialCharMask = (especialChar) => {
	if (especialChar && especialChar.length > 0) {
		const name = especialChar
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLowerCase();
		return name;
	}
	return '';
};

export const firstLetterUperCase = (word) => {
	word = word.toLowerCase();
	const firstLetter = word.charAt(0).toUpperCase();
	const wordArray = word.split('');
	wordArray[0] = firstLetter;
	let newWord = '';
	wordArray.forEach((item) => {
		newWord = `${newWord}${item}`;
	});

	return newWord;
};

export const getAlertsByType = (array, type) => {
	if (typeof array === 'object' && array.length > 0) {
		const arrayAlertsFiltered = array.filter((item) => item.type === type);
		return arrayAlertsFiltered;
	}
	return [];
};

export const dateToBR = (date) => {
	if (date) {
		const month = (date.getMonth() + 1).toString();
		const monthF = month.length === 1 ? `0${month}` : month;
		const day = date.getUTCDate().toString();
		const dayF = day.length === 1 ? `0${day}` : day;
		const year = date.getUTCFullYear().toString();
		return `${dayF}/${monthF}/${year}`;
	}
	return '00/00/00';
};

export const converDateAndTimeEUAformat = (date) => {
	if (date && typeof date === 'string') {
		const newDate = new Date(date);

		const dia = newDate.getDate().toString();
		const diaF = dia.length === 1 ? `0${dia}` : dia;
		const mes = (newDate.getMonth() + 1).toString();
		const mesF = mes.length === 1 ? `0${mes}` : mes;
		const anoF = newDate.getFullYear();
		return `${mesF}/${diaF}/${anoF}`;
	}
	return '00/00/00';
};

export const convertHour = (date) => {
	if (date) {
		const hora = date.getHours().toString();
		const horaF = hora.length === 1 ? `0${hora}` : `${hora}`;
		const min = date.getMinutes().toString();
		const minF = min.length === 1 ? `0${min}` : `${min}`;
		const sec = date.getSeconds().toString();
		const secF = sec.length === 1 ? `0${sec}` : `${sec}`;
		return `${horaF}:${minF}:${secF}`;
	}
	return '00:00';
};

// funciona se a data vier neste formato '2021-08-23T23:18:27.000Z'
export const converDateAndTimeFromBD = (date, withHour = false) => {
	if (date && typeof date === 'string') {
		const newDate = new Date(date);

		const dia = newDate.getDate().toString();
		const diaF = dia.length === 1 ? `0${dia}` : dia;
		const mes = (newDate.getMonth() + 1).toString();
		const mesF = mes.length === 1 ? `0${mes}` : mes;
		const anoF = newDate.getFullYear();
		return withHour ? `${diaF}/${mesF}/${anoF} às ${convertHour(newDate)}` : `${diaF}/${mesF}/${anoF}`;
	}
	return '00/00/00 às 00:00:00';
};

export const groupBy = (list, keyGetter) => {
	const map = new Map();
	list.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
};

// Nesta func 'rawData' são os dados que chegam da API e 'processedData' são os dados tratados com logica de vazios ou não
export function returnDataGraph(rawData, processedData) {
	if (rawData.length > 0 || !isEmptyObject(rawData)) {
		return processedData;
	}
	return [];
}

// caso aconteça algum erro para pegar o id_role, retorna o id do usuário comum
export function returnIdRoleUser() {
	const typeUserLogged = JSON.parse(localStorage.getItem('user')).user.user_role.id || 5;

	return typeUserLogged;
}

export function returnUser() {
	const user = JSON.parse(localStorage.getItem('user'));

	return user === undefined || user === null ? false : user;
}

export const returnDataAboutUser = (userData) => {
	const date = dateToBR(new Date());

	if (userData && userData !== null) {
		const { user } = userData;
		return { name: `${user.firstName} ${user.lastName} `, date };
	}
	return { name: `Usuário não cadastrado`, date };
};

// GERADOR DE HASH
export const generateHash = () => `_${Math.random().toString(36).substr(2, 16)}`;

// FORMATA OS DADOS P/ UPLOAD DE ARQUIVOS
export const createFormData = (body) => {
	const data = new FormData();
	Object.keys(body).forEach((key) => {
		data.append(key, body[key]);
	});
	return data;
};

export function editArrayMonitoring(array, idtemEdit) {
	const newList = array.map((item) => {
		if (item.id === idtemEdit) {
			item.isChecked = !item.isChecked;
			return item;
		}
		return item;
	});
	return newList;
}

export const handleCloseTooltip = () => {
	const container = document.getElementById('tooltip');
	if (container === null) return;
	container.style.display = 'none';

	const containerMapserver = document.getElementById('tooltip-mapserver');
	if (containerMapserver === null) return;
	containerMapserver.style.display = 'none';
};

export const isRouteSaveMonitoring = (routeMonitoring) => {
	if (routeMonitoring) {
		switch (routeMonitoring) {
			case 'alerts':
				return '/alert';
			case 'traditional_uses':
				return '/traditional_use';
			case 'where_i_been':
				return '/where_i_am';
			default:
				return '';
		}
	}
	return '';
};

export const isRouteEditMonitoring = (routeMonitoring) => {
	if (routeMonitoring) {
		switch (routeMonitoring) {
			case 'alerts':
				return '/alert/update';
			case 'traditional_uses':
				return '/traditional_uses/update';
			case 'where_i_been':
				return '/where_i_am/update';
			case 'tracks':
				return '/track/update';
			default:
				return '';
		}
	}
	return '';
};

// responsável por carregar a lista dos tipos do alerta ou uso tradicional que será cadastrado
export const listTypeRegister = (type, setListType) => {
	switch (type) {
		case 'alert': {
			const newListAlert = typesAlerts.map((item) => ({
				id: item.type,
				nome: item.text
			}));
			setListType(newListAlert);

			break;
		}
		case 'traditionalUse': {
			const newListTraditionalUse = typesTraditionalUse.map((item) => ({
				id: item.type,
				nome: item.text
			}));
			setListType(newListTraditionalUse);

			break;
		}
		default:
			setListType([]);
			break;
	}

	return () => {
		setListType([]);
	};
};

// função que pega lozalização atual do usuário

export const getLocationOnNavigator = (dispatch, getCoordsOnMap) => {
	if ('geolocation' in navigator) {
		navigator.geolocation.getCurrentPosition((position) => {
			dispatch(
				getCoordsOnMap({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				})
			);
			zoomToRowMonitoring([position.coords.longitude, position.coords.latitude], 17);
		});
	}
};

// classifica array de objetos em asc ou desc
export const sortArrayOfObject = (key, order = 'asc') =>
	function innerSort(a, b) {
		if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
			return 0;
		}

		const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
		const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return order === 'desc' ? comparison * -1 : comparison;
	};

export function takePrintMap() {
	const mapSomai = olMap();

	let matrix = '';

	const mapCanvas = document.createElement('canvas');
	const { 0: mapWidth, 1: mapHeigth } = mapSomai.getSize();

	mapCanvas.width = mapWidth;
	mapCanvas.height = mapHeigth;

	const mapContext = mapCanvas.getContext('2d');
	mapContext.willReadFrequently = true;

	Array.prototype.forEach.call(
		mapSomai.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
		(canvas) => {
			if (canvas.width > 0) {
				const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
				mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

				const backgroundColor = canvas.parentNode.style.backgroundColor; // eslint-disable-line
				if (backgroundColor) {
					mapContext.fillStyle = backgroundColor;
					mapContext.fillRect(0, 0, canvas.width, canvas.height);
				}

				const transformTeste = canvas.style.transform;
				if (transformTeste) {
					// Get the transform parameters from the style's transform matrix
					matrix = transformTeste
						.match(/^matrix\(([^\(]*)\)$/)[1] // eslint-disable-line
						.split(',')
						.map(Number);
				}
			} else {
				matrix = [
					parseFloat(canvas.style.width) / canvas.width,
					0,
					0,
					parseFloat(canvas.style.height) / canvas.height,
					0,
					0
				];
			}

			CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
			mapContext.drawImage(canvas, 0, 0);
		}
	);

	mapContext.globalAlpha = 1;
	if (navigator.msSaveBlob) {
		// link download attribute does not work on MS browsers
		navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
		return '';
	}

	const base64 = mapCanvas.toDataURL();

	return base64;

	// FUNÇÃO COMENTADA ABAIXO FAZ O DOWNLOAD DA FOTO, NÃO APAGAR, POIS VAI QUE PRECISA ALGUM DIA :D

	// mapContext.globalAlpha = 1;
	// if (navigator.msSaveBlob) {
	// 	// link download attribute does not work on MS browsers
	// 	navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
	// } else {
	// 	const link = document.getElementById('image-download');
	// 	link.href = mapCanvas.toDataURL();
	// 	link.click();
	// }

	// mapSomai.renderSync();
}

export async function ajustZoomExtent(layerName = 'monitoring') {
	const layer = await getLayerByName(layerName);
	const source = await layer?.getSource();
	const extent = source?.getExtent();

	if (extent && extent[0] !== Infinity) {
		zoomToExtentLayer(extent, 0);
	} else {
		zoomToExtentMap(0);
	}
}

export const isCoordsInvalid = (lat, long) => {
	switch (true) {
		case !lat && !long:
			return 'A latitude e longitude estão incorretas.';
		case !lat:
			return 'A latitude está incorreta.';
		case !long:
			return 'A longitude está incorreta';
		default:
			return '';
	}
};

/*eslint-disable */
export const filterDataToReport = (data, typesAlert, dates, tis) => {
	const filteredData = [];

	// se houver ti selecionada o filtro de tis e feito aqui
	if (tis.length > 0) {
		const newData = [];
		tis.forEach((item) => {
			data.forEach((data) => {
				if (data.ti_code === item.value) {
					newData.push(data);
				}
			});
		});
		data = newData;
	}

	// necessário para tratar o dado mais facil no filtro de data
	if (!dates.hasOwnProperty('initialDateFormated')) {
		dates.initialDateFormated = '';
	}

	// necessário para tratar o dado mais facil no filtro de data
	if (!dates.hasOwnProperty('finalDateFormated')) {
		dates.finalDateFormated = '';
	}

	if (dates.initialDateFormated !== '') {
		dates.initialDateFormated.dateOrginal = dates.initialDateFormated.dateOrginal.toString();
	}

	if (dates.finalDateFormated !== '') {
		dates.finalDateFormated.dateOrginal = dates.finalDateFormated.dateOrginal.toString();
	}

	// data incial do filtro que o usuário escolheu
	let initDateWithoutHour =
		dates.hasOwnProperty('initialDateFormated') && dates.initialDateFormated !== ''
			? new Date(converDateAndTimeEUAformat(dates?.initialDateFormated.dateOrginal))
			: null;
	// data final do filtro que o usuário escolheu
	let finalDateWithoutHour =
		dates.hasOwnProperty('finalDateFormated') && dates.finalDateFormated !== ''
			? new Date(converDateAndTimeEUAformat(dates?.finalDateFormated.dateOrginal))
			: null;

	if (typesAlert.length > 0) {
		typesAlert.forEach((type) => {
			data.forEach((item) => {
				// primeira verificação é para saber se o tipo é igual
				if (item.type === type.value) {
					// apartir daqui é para verificar se o item está dentro da data estabelecida
					let before = false;
					let after = false;

					let itemDateWithoutHour = new Date(converDateAndTimeEUAformat(item.created_at));

					if (dates.initialDateFormated === '') {
						before = true;
					} else {
						if (new Date(itemDateWithoutHour) >= new Date(initDateWithoutHour)) {
							before = true;
						} else {
							before = false;
						}
					}

					if (dates.finalDateFormated === '') {
						after = true;
					} else {
						if (new Date(itemDateWithoutHour) <= new Date(finalDateWithoutHour)) {
							after = true;
						} else {
							after = false;
						}
					}

					if (before === true && after === true) {
						filteredData.push(item);
					}
				}
			});
		});
	} else {
		data.forEach((item) => {
			let before = false;
			let after = false;

			let itemDateWithoutHour = new Date(converDateAndTimeEUAformat(item.created_at));

			if (dates.initialDateFormated === '') {
				before = true;
			} else {
				if (new Date(itemDateWithoutHour) >= new Date(initDateWithoutHour)) {
					before = true;
				} else {
					before = false;
				}
			}

			if (dates.finalDateFormated === '') {
				after = true;
			} else {
				if (new Date(itemDateWithoutHour) <= new Date(finalDateWithoutHour)) {
					after = true;
				} else {
					after = false;
				}
			}

			if (before === true && after === true) {
				filteredData.push(item);
			}
		});
	}

	return filteredData;
};
/*eslint-disable */

export const zoomPaddingExtent = () => {
	const screen = window.innerWidth;

	if (screen >= 2160) return [150, 0, 150, 500];
	if (screen >= 1920) return [150, 0, 150, 300];
	if (screen >= 1600) return [150, 0, 150, 250];
	if (screen >= 1024) return [150, 0, 150, 450];
	if (screen <= 900) return [150, 0, 150, 0];
};

// Formata o tamanho dos arquivos 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'
export const formatSizesFiles = ({ size }) => {
	if (size === 0) return '0 Bytes';
	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(size) / Math.log(k));
	return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

// Calcula o tempo da requisição
export const calculateRequestTime = (t) => {
	return Math.round((performance.now() - t + Number.EPSILON) * 100) / 100 + 'ms';
};

export const listTerritoryData = (data) => {
	const { totalArea, population, people, isoledPeople, totalTis } = data;

	const list = [
		{
			icon: svg.icon.cut1,
			data: `Área total das TIs: ${totalArea ? formatNumber(totalArea, 0, '.', ',') : 0} ha`
		},
		{ icon: svg.icon.barGraph2, data: `População Indígena : ${population}` },
		{ icon: svg.icon.profile1, data: `Povos: ${people}` },
		{ icon: svg.icon.userBlack, data: `Povos isolados: ${isoledPeople}` },
		{ icon: svg.icon.indigenousLand, data: `Total de TIs: ${totalTis}` }
		// {
		// 	icon: svg.icon.edit,
		// 	data: `TI(s) regularizada(s): ${legalSituation?.regularizado || 0}`,
		// 	color: color.interface.lightGray2
		// },
		// {
		// 	icon: svg.icon.edit,
		// 	data: `TI(s) declarada(s): ${legalSituation?.declarada || 0}`,
		// 	color: color.interface.lightGray2
		// },
		// {
		// 	icon: svg.icon.edit,
		// 	data: `TI(s) delimitada(s): ${legalSituation?.delimitada || 0}`,
		// 	color: color.interface.lightGray2
		// },
		// {
		// 	icon: svg.icon.edit,
		// 	data: `TI(s) em estudo: ${legalSituation?.emEstudo || 0}`,
		// 	color: color.interface.lightGray2
		// },
		// {
		// 	icon: svg.icon.edit,
		// 	data: `TI(s) homologada(s): ${legalSituation?.homologada || 0}`,
		// 	color: color.interface.lightGray2
		// },
		// {
		// 	icon: svg.icon.edit,
		// 	data: `TI(s) com restrição de uso: ${
		// 		legalSituation?.comRetricaoDeUso || 0
		// 	}`,
		// 	color: color.interface.lightGray2
		// },
		// {
		// 	icon: svg.icon.edit,
		// 	data: `TI(s) reservada(s): ${legalSituation?.reservada || 0}`,
		// 	color: color.interface.lightGray2
		// }
	];

	return list;
};

export const hecToKm2 = (hec) => {
	hec = parseInt(hec);

	if (hec === 0) {
		return 'Não há informação';
	}

	const value = hec / 100;
	return value.toLocaleString('pt-BR');
};

export const getDataAboutTi = (data) => {
	const { geom, ...rest } = data;
	return rest;
};

export const latLogToDms = (obj) => {
	const latDms = getDms(obj.latitude, 'S');
	const longDms = getDms(obj.longitude, 'O');

	return `${latDms} ${longDms}`;
};

function truncate(n) {
	return n > 0 ? Math.floor(n) : Math.ceil(n);
}

export function getDMS(dd, longOrLat) {
	const hemisphere = /^[WE]|(?:lon)/i.test(longOrLat) ? (dd < 0 ? 'W' : 'E') : dd < 0 ? 'S' : 'N';

	const absDD = Math.abs(dd);
	const degrees = truncate(absDD);
	const minutes = truncate((absDD - degrees) * 60);
	const seconds = ((absDD - degrees - minutes / 60) * 60 ** 2).toFixed(2);

	const dmsArray = [degrees, minutes, seconds, hemisphere];
	return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
}

// passe a latitude e a longitude uma por vez
export const getDms = (val, cardealPoint) => {
	var valDeg, valMin, valSec, result;

	val = Math.abs(val);

	valDeg = Math.floor(val);
	result = valDeg + 'º';

	valMin = Math.floor((val - valDeg) * 60);
	result += valMin + "'";

	valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
	result += valSec + `" ${cardealPoint}`;

	return result;
};

export function convertLatLong(input) {
	if (!(input.toUpperCase() !== input.toLowerCase())) {
		// se geodireção abr. não existe, já deveria ser notação decimal
		// return `${input}:a coordenada já parece estar decimal`;
		return '';
	}

	const parts = input /*  */
		.split(/[°'"′″]+/)
		.join(' ')
		.split(/[^\w\S]+/);
	const geoLetters = parts.filter((el) => !+el);
	const coordNumber = parts.filter((n) => +n).map((nr) => +nr);
	const latNumber = coordNumber.slice(0, coordNumber.length / 2);
	const longNumber = coordNumber.slice(coordNumber.length / 2);
	const reducer = function (acc, coord, curInd) {
		return acc + coord / 60 ** curInd++;
	};
	let latDec = latNumber.reduce(reducer);
	let longDec = longNumber.reduce(reducer);

	if (geoLetters[0].toUpperCase() === 'S') latDec = -latDec; // if the geodirection is S or W, decimal notation should start with minus
	if (geoLetters[1].toUpperCase() === 'W') longDec = -longDec;

	const dec = [
		{
			ltCoord: latDec,
			geoLet: geoLetters[0]
		},
		{
			longCoord: longDec,
			geoLet: geoLetters[1]
		}
	];

	return dec;
}

export async function exportMapAsTiff() {
	const mapSomai = olMap();

	const canvas = mapSomai.getViewport().querySelector('canvas');

	CanvasToTIFF.toBlob(canvas, function (blob) {
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = 'mapaSomai.tiff';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	});
}

export const handleExportMap = (format) => {
	const mapSomai = olMap();
	const formatprint = 'a4';
	const resolution = 150;
	const dim = [297, 210];
	const width = Math.round((dim[0] * resolution) / 25.4);
	const height = Math.round((dim[1] * resolution) / 25.4);
	const size = mapSomai.getSize();
	const viewResolution = mapSomai.getView().getResolution();

	mapSomai.once('rendercomplete', function () {
		const mapCanvas = document.createElement('canvas');
		mapCanvas.width = width;
		mapCanvas.height = height;
		const mapContext = mapCanvas.getContext('2d');
		Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), function (canvas) {
			if (canvas.width > 0) {
				const opacity = canvas.parentNode.style.opacity;
				mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
				const transform = canvas.style.transform;
				// Get the transform parameters from the style's transform matrix
				const matrix = transform
					.match(/^matrix\(([^\(]*)\)$/)[1]
					.split(',')
					.map(Number);
				// Apply the transform to the export map context
				CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
				mapContext.drawImage(canvas, 0, 0);
			}
		});
		mapContext.globalAlpha = 1;

		switch (format) {
			case 'pdf':
				const pdf = jsPDF('landscape', undefined, formatprint);
				pdf.addImage(mapCanvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, dim[0], dim[1]);
				pdf.save('mapaSomai.pdf');
				// Reset original map size
				mapSomai.setSize(size);
				mapSomai.getView().setResolution(viewResolution);
				document.body.style.cursor = 'auto';
				break;
			case 'tiff':
				CanvasToTIFF.toBlob(mapCanvas, function (blob) {
					const url = URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.download = 'mapaSomai.tiff';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					URL.revokeObjectURL(url);
				});
				break;
			default:
				break;
		}
	});

	// Set print size
	const printSize = [width, height];
	mapSomai.setSize(printSize);
	const scaling = Math.min(width / size[0], height / size[1]);
	mapSomai.getView().setResolution(viewResolution / scaling);
};

export const handleExportFeature = (format, layers) => {
	const canvas = olMap()?.getTargetElement().querySelector('.ol-viewport canvas');

	const image = canvas?.toDataURL('image/png');

	// Generate the download link
	const link = document.createElement('a');
	link.download = `mapaSomai.${format === 'geotiff' ? 'tiff' : format === 'geopdf' ? 'pdf' : format}`;
	link.href = image;

	// Embed layer information if necessary
	if (format === 'geopdf' || format === 'shp' || format === 'xml' || format === 'csv' || format === 'geotiff') {
		//const layer = olMap()?.getLayers().getArray()[0];
		const layer = layers[0];
		if (layer) {
			const source = layer.getSource();
			let formatInstance = null;

			switch (format) {
				case 'shp':
					const options = {
						folder: 'my_shapefile',
						types: {
							point: 'mypoints',
							polygon: 'mypolygons',
							line: 'mylines'
						}
					};
					const shpBlob = shpwrite.zip(shpwrite.combine([features]), options);
					formatInstance = new GeoJSON();
					break;
				case 'xml':
					//formatInstance = new GML();
					formatInstance = new KML();
					break;
				case 'csv':
					formatInstance = new GeoJSON();
					break;
				case 'geotiff':
					formatInstance = new KML();
					break;
				default:
					break;
			}

			if (formatInstance !== null) {
				const features = source?.getFeatures();
				const content = formatInstance.writeFeatures(features);
				const blob = new Blob([content], { type: formatInstance.getType() });
				link.href = URL.createObjectURL(blob);
			}
		}
	}

	// Click the link to start the download
	link.click();
};

function filterSystemUsersByPeriod(systemUsers, filterPeriod) {
	const { initialDateFormated, finalDateFormated } = filterPeriod;

	const filteredUsers = systemUsers.map((user) => {
		const filteredAlerts = filterArrayByPeriod(user.alerts, initialDateFormated, finalDateFormated);
		const filteredTraditionalUses = filterArrayByPeriod(user.traditional_uses, initialDateFormated, finalDateFormated);
		const filteredTracks = filterArrayByPeriod(user.tracks, initialDateFormated, finalDateFormated);
		const filteredWhereIBeen = filterArrayByPeriod(user.where_i_been, initialDateFormated, finalDateFormated);
		return {
			...user,
			alerts: filteredAlerts,
			traditional_uses: filteredTraditionalUses,
			tracks: filteredTracks,
			where_i_been: filteredWhereIBeen
		};
	});

	return filteredUsers;
}

function filterArrayByPeriod(array, initialDateFormated, finalDateFormated) {
	if (!array.length) {
		return [];
	}

	if (initialDateFormated?.formatedDate) {
		const initialDate = new Date(initialDateFormated?.dateOrginal);

		array = array.filter((item) => {
			const date = new Date(item.created_at);

			if (date >= initialDate) {
				return true; // retorna true para manter o item no array
			} else {
				return false; // retorna false para remover o item do array
			}
		});
	}

	if (finalDateFormated?.formatedDate) {
		const finalDate = new Date(finalDateFormated?.dateOrginal);
		array = array.filter((item) => new Date(item.created_at) <= finalDate);
	}

	return array;
}

export function sortAndFilterList(
	systemUsers,
	filterLetterUser,
	filterSearchUser,
	filterProfileUser,
	filterOrganizationUser,
	filterPeriod
) {
	const session = getUserLocalStorage();
	const roleManager = session?.user?.user_role?.role || '';

	if (roleManager !== 'admin') {
		// Ordena a lista pelo parâmetro firstName
		systemUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));

		// Filtra a lista de acordo com a letra selecionada
		if (filterLetterUser) {
			systemUsers = systemUsers.filter(
				(item) => item.firstName.charAt(0).toUpperCase() === filterLetterUser.toUpperCase()
			);
		}

		// Filtra a lista de acordo com a string de busca
		if (filterSearchUser) {
			systemUsers = systemUsers.filter((i) => {
				const newTextFilter = especialCharMask(filterSearchUser);
				const name = especialCharMask(`${i.firstName} ${i.lastName}`);
				return (
					name.includes(newTextFilter) ||
					especialCharMask(i.user_role.name).includes(newTextFilter) ||
					especialCharMask(i.user_organization.name).includes(newTextFilter)
				);
			});
		}

		// Filtra de acordo com o select

		if (filterProfileUser && filterProfileUser.length > 0) {
			systemUsers = systemUsers.filter((user) =>
				filterProfileUser.some((filter) => filter.value === user.user_role.id)
			);
		}

		// Filtra de acordo com o select
		if (filterOrganizationUser && filterOrganizationUser.length > 0) {
			systemUsers = systemUsers.filter((user) =>
				filterOrganizationUser.some((filter) => filter.value === user.user_organization.id)
			);
		}
	}

	if (filterPeriod && Object.keys(filterPeriod).length !== 0) {
		systemUsers = filterSystemUsersByPeriod(systemUsers, filterPeriod);
	}

	// Retorna a lista final
	return systemUsers;
}

/*
 * PARAMETERS:
 * number: number formatting
 * s1: total of decimal places
 * s2 and s3: point or comma
 */
export function formatNumber(number, s1, s2, s3) {
	if (!number) return 0;
	const text = `${parseFloat(number).toFixed(s1)}`;
	const x = text.split('.');
	let x1 = x[0];
	const x2 = x.length > 1 ? s3 + x[1] : '';
	const rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, `$1${s2}$2`);
	}
	return x1 + x2;
}
