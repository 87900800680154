import styled from 'styled-components';
import { color } from 'styles';

export const Button = styled.button`
	border: none;
	outline: none;
	background: ${color.interface.white};
	height: 37px;
	width: 100%;
	cursor: pointer;
	border-radius: 10px 10px 0px 0px;
	border-bottom: 0.5px solid rgb(248, 245, 243);
`;

export const TabsContainer = styled.div`
	display: flex;
	width: 100%;
	position: inherit;
	transform: translateY(1%);

	@media (max-width: 800px) {
		width: 92%;
	}

	@media (max-width: 500px) {
		transform: translateY(1.2%);
		width: 90%;
	}

	@media (max-width: 394px) {
		width: 89%;
	}

	@media (max-width: 370px) {
		width: 88%;
	}
`;

export const Components = styled.div`
	background: ${color.interface.white};
	overflow-y: auto;
	width: 99.5%;
	height: calc(100% - 106px);
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 50px;

	::-webkit-scrollbar-track {
		background: ${color.interface.white};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${color.interface.gray}99;
		border: 1px solid ${color.interface.gray}99;
	}
`;
