import { reportsForAdminType } from '../typesActions/types';

const initialState = {
	loadingAlerts: false,
	listAlerts: [],

	loadingTraditionalUse: false,
	listTraditionalUse: [],

	message: ''
};

export default function reportsForAdminReducer(state = initialState, action) {
	switch (action.type) {
		case reportsForAdminType.ALERTS_READ_REQUEST:
			return {
				...state,
				loadingAlerts: true
			};
		case reportsForAdminType.ALERTS_READ_SUCCESS:
			return {
				...state,
				loadingAlerts: false,
				listAlerts: action.payload
			};
		case reportsForAdminType.ALERTS_READ_FAIL:
			return {
				...state,
				loadingAlerts: false,
				message: action.payload.errors.message
			};

		case reportsForAdminType.TRADITIONAL_USE_READ_REQUEST:
			return {
				...state,
				loadingTraditionalUse: true
			};
		case reportsForAdminType.TRADITIONAL_USE_READ_SUCCESS:
			return {
				...state,
				loadingTraditionalUse: false,
				listTraditionalUse: action.payload
			};
		case reportsForAdminType.TRADITIONAL_USE_READ_FAIL:
			return {
				...state,
				loadingTraditionalUse: false,
				message: action.payload.errors.message
			};

		case reportsForAdminType.ERROR_READ_CLOSE:
			return {
				...state,
				message: ''
			};

		default:
			return state;
	}
}
