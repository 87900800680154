export const setUserLocalStorage = (session) => {
	localStorage.setItem('user', JSON.stringify(session));
};

export const getUserLocalStorage = () => {
	const sessionLocalStorage = localStorage.getItem('user');

	if (!sessionLocalStorage) return null;

	return JSON.parse(sessionLocalStorage);
};

export const getUserToken = () => {
	const { token } = getUserLocalStorage() || { token: '' };

	return token;
};

export const setUserLogout = () => {
	localStorage.clear();
};
