/* eslint-disable eqeqeq */
/* eslint-disable no-const-assign */
/* eslint-disable radix */
/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable no-bitwise */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import axios from 'axios';
import Load from 'components/Load/Load';
import { olMap } from 'components/Map/ControlMap';
import {
	HexAlphaColorPicker,
	GradientColorPicker,
	HexColorInput
} from 'components/ipam-react-color';

import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import { Fill, Stroke, Style } from 'ol/style';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useColorsContext } from 'services/color.context';
import { getLayerByName } from 'store/actions/mapActions';
import styled from 'styled-components';
import { color } from 'styles';

const Background = styled.div`
	position: absolute;
	width: 8rem;
	z-index: 1300;
	top: 0;
	right: 3rem;
	@media (max-width: 800px) {
		display: flex;
		justify-content: center;
		top: 3.5rem;
		right: 15rem;
		width: 4rem;
	}
`;

const ModalWrapper = styled.div`
	width: 100%;
	@media (max-width: 800px) {
		display: flex;
		justify-content: center;
	}
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #fff;
	color: #000;
	border-radius: 6px;
	padding: 0.5rem;
	border: 2px #d9d5d2 solid;
	position: relative;
	&.disable__style {
		background: none !important;
		border: 0;
		box-shadow: none;
	}
`;

const ColorWrapper = styled.div`
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 0;
	right: 0;
	width: 12.625rem;
	background-color: #fff;
	/* height: 10rem; */
	padding: 0.8rem;
	border-radius: 5px;
	@media (max-width: 800px) {
		left: 0rem;
	}
`;
const ColorViewContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #69625d;
	margin-top: 0.7rem;
`;
const ColorView = styled.div`
	display: flex;
	width: 1.7rem;
	height: 1.7rem;
	border-radius: 50%;
	border: none;
	border: solid 2px #d9d5d2;
`;

const ColorViewInput = styled.div`
	display: flex;
	align-items: center;
	input {
		-moz-appearance: textfield;
		max-width: 4rem;
		height: 1.5rem;
		border: 2px solid #d9d5d2;
		border-radius: 3px;
		margin-left: 0.2rem;
		/* margin: 0.75rem 0rem 0rem 0.1rem; */
		text-align: center;
		color: #69625d;
		background-color: #f8f5f3;
	}
`;
const ActionContainer = styled.div`
	margin-top: 1.2rem;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
	button {
		cursor: pointer;
		pointer-events: fill;
		border-radius: 20px;
		font-weight: 700;
		font-size: 0.8rem;
		width: 100% !important;
		/* padding: 0.5rem 0.5rem; */
		height: 1.75rem !important;
		:hover {
			border-radius: 20px;
		}
	}
	.cancel-somai-color__picker-btn {
		color: ${color.interface.orange};
		border: 0.063rem solid ${color.interface.orange};
	}
	.save-somai-color__picker-btn {
		background-color: ${color.interface.orange};
		color: ${color.interface.white};

		:hover {
			background-color: ${color.interface.orange};
			color: ${color.interface.white};
		}
	}
`;

const LatestColorsViewContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #d9d5d2;
	margin-top: 1rem;
	padding-top: 0.5rem;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 0.7rem;
	color: #69625d;
`;
const LatestColorsView = styled.div`
	display: flex;
	align-items: center;
	gap: 0.2rem;
`;
const LatestColor = styled.div`
	display: flex;
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	pointer-events: fill;
`;

const ModalContent = styled.div`
	/* display: grid;
	grid-template-columns: repeat(2, 1fr); */
	//height: 1.8rem;
`;

const Container = styled.div`
	/* width: 20px; */
	/* height: 20px; */
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	& + div {
		border-left: 1px solid;
		border-color: #d9d5d2;
	}
	@media (max-width: 800px) {
		& + div {
			border-left: 0;
			border-top: 1px solid;
			border-color: #d9d5d2;
		}
	}
	/* .sm__tooltip {
		position: absolute;
	} */
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		width: 20px;
		height: 20px;
		background: none !important;
		cursor: pointer;
		&[disabled='disabled'],
		&:disabled {
			cursor: not-allowed;
		}

		:hover {
			background-color: none;
			height: 20px;
			/* border-radius: 4px; */
		}
		/* 	&.active {
			height: 85%;
			background-color: #e6dcd3;
			border-radius: 4px;
		} */
		& > div {
			width: 20px;
			height: 20px;
			box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
			border-radius: 4px;
		}
	}

	img,
	svg {
		width: 20px;
		height: 20px;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		border-radius: 50%;
	}
`;
const OneContainer = styled.div`
	max-width: 7rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 5px;
	/* height: 1.8rem; */
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
		height: 6rem;
	}
`;
const GroupContainer = styled.div`
	& + div {
		margin: 7px 0;
		border-top: 1px solid #d9d5d2;
	}

	strong {
		/* text-align: center; */
		white-space: nowrap;
		font-size: 0.8rem;
	}
	/* display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	min-height: 1.8rem; */
	//justify-content: center;
`;
const GroupContainerwrapper = styled.div`
	max-height: 10rem;
	overflow: auto;
`;

const gradient = {
	points: [
		{
			left: 0,
			red: 0,
			green: 0,
			blue: 0,
			alpha: 1,
			color: 'rgb(0, 0, 0)'
		},
		{
			left: 100,
			red: 255,
			green: 0,
			blue: 0,
			alpha: 1,
			color: 'rgb(255, 0, 0)'
		}
	],
	degree: 0,
	type: 'linear'
};

function ListModal({ showModal, setShowModal }) {
	const { state, handleAddLastColor, nameLayer, handleAddcurrentStyleItems } =
		useColorsContext();
	const [colorRgba, setColorRgba] = useState('#D44500');
	const [loading, setLoading] = useState(false);
	const [gradientAttrs, setGradientAttrs] = useState(gradient);
	const [howColorPicker, setShowColorPicker] = useState(false);
	const [colorType, setColorType] = useState('border');
	const [objectToChange, setObjectToChange] = useState({});
	const [classes, setClasses] = useState([]);

	const { latestcolors, currentStyleItems } = state;
	const modalRef = useRef();

	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModal(false);
		}
	};

	const closeColorPicker = () => {
		setShowColorPicker((prev) => !prev);
	};

	useEffect(() => {
		if (nameLayer) {
			const layer = getLayerByName(nameLayer);
			const getclasses = layer?.get('classes');
			setClasses(getclasses);
		}
	}, [nameLayer]);

	function hexToColorRange(hexCode) {
		const red = parseInt(hexCode.slice(1, 3), 16) / 255;
		const green = parseInt(hexCode.slice(3, 5), 16) / 255;
		const blue = parseInt(hexCode.slice(5, 7), 16) / 255;

		return `[${red},${green},${blue}],[${red},${green},${blue}]`;
	}

	function rgbToHex(r, g, b) {
		// Convert each RGB value to hex
		let red = r.toString(16);
		let green = g.toString(16);
		let blue = b.toString(16);

		// Pad each hex value with a leading zero if necessary
		if (red.length == 1) red = `0${red}`;
		if (green.length == 1) green = `0${green}`;
		if (blue.length == 1) blue = `0${blue}`;

		// Concatenate the hex values in order
		const hex = `#${red}${green}${blue}`;

		// Return the hex color code
		return hex;
	}

	const handleAddLastesColor = () => {
		setLoading(true);
		const layer = getLayerByName(nameLayer);
		if (layer) {
			if (layer instanceof TileLayer) {
				const source = layer.getSource();
				const mapSize = olMap().getSize();
				const viewResolution = olMap().getView().getResolution();
				// const coordinate = view.getCenter();
				const coordinate = olMap().getCoordinateFromPixel([
					mapSize[0] / 2,
					mapSize[1] / 2
				]);
				//				console.log('source', source);
				const params = source.getParams();
				// const classes = layer.get('classes');

				const layerName = `map.layer[${params?.LAYERS}]`;

				const url = layer
					.getSource()
					.getFeatureInfoUrl(
						coordinate,
						viewResolution,
						olMap().getView().getProjection(),
						{
							INFO_FORMAT: 'application/json',
							REQUEST: 'GetStyles'
						}
					);
				if (url) {
					// console.log('getFeatureInfoUrl:', url);
					fetch(url)
						.then((response) =>
							/* 	console.log(response); */
							response.text()
						)
						.then((json) => {
							console.log('json:', json);
							const styleNames = json.trim().split(/\r?\n/);
							console.log('styleNames:', styleNames);

							/* const badJsonStr = JSON.stringify(json);
							const parsedBadObj = JSON.parse(badJsonStr);
							console.log(parsedBadObj); */
							// Access the styles information in the json response
							// console.log(json.features[0].properties.styles);
						});
				}
				if (objectToChange?.style?.length === 1) {
					let updatedParams;

					if (colorType === 'border') {
						if (
							`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]` in
							params
						) {
							params[
								`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`
							] += `OUTLINECOLOR "${colorRgba}"`;
							const styleObject =
								objectToChange.style[objectToChange.styleIndex];
							styleObject.OUTLINECOLOR = `"${colorRgba}"`;

							handleAddcurrentStyleItems({
								className: objectToChange.className,
								style: styleObject,
								styleIndex: objectToChange.styleIndex
							});
							source.updateParams(params);
						} else {
							updatedParams = {
								[`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`]: `OUTLINECOLOR "${colorRgba}"`
							};
							const styleObject =
								objectToChange.style[objectToChange.styleIndex];
							styleObject.OUTLINECOLOR = `"${colorRgba}"`;

							console.log('style', styleObject);
							handleAddcurrentStyleItems({
								className: objectToChange.className,
								style: styleObject,
								styleIndex: objectToChange.styleIndex
							});
							source.updateParams(updatedParams);
						}
					} else if (colorType === 'background') {
						if (
							`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]` in
							params
						) {
							params[
								`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`
							] += `COLOR "${colorRgba}"`;

							const styleObject =
								objectToChange.style[objectToChange.styleIndex];
							styleObject.COLOR = `"${colorRgba}"`;

							handleAddcurrentStyleItems({
								className: objectToChange.className,
								style: styleObject,
								styleIndex: objectToChange.styleIndex
							});
							source.updateParams(params);
						} else {
							updatedParams = {
								[`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`]: `COLOR "${colorRgba}"`
							};
							const styleObject =
								objectToChange.style[objectToChange.styleIndex];
							styleObject.COLOR = `"${colorRgba}"`;

							console.log('style', styleObject);
							handleAddcurrentStyleItems({
								className: objectToChange.className,
								style: styleObject,
								styleIndex: objectToChange.styleIndex
							});

							source.updateParams(updatedParams);
						}
					}
					if (colorType === 'colorrange') {
						if (
							`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]` in
							params
						) {
							const ranger = `"${rgbToHex(
								gradientAttrs.points[0].red,
								gradientAttrs.points[0].green,
								gradientAttrs.points[0].blue
							)}" "${rgbToHex(
								gradientAttrs.points[1].red,
								gradientAttrs.points[1].green,
								gradientAttrs.points[1].blue
							)}"`;
							params[
								`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`
							] += `COLORRANGE ${ranger}`;
							console.log('ranger1', ranger);
							/* const styleObject =
								objectToChange.style[objectToChange.styleIndex];
							styleObject.COLORRANGE = `"${ranger}"`;
							console.log('ranger 1', ranger); */
							/* handleAddcurrentStyleItems({
								className: objectToChange.className,
								style: styleObject,
								styleIndex: objectToChange.styleIndex
							}); */
							source.updateParams(params);
						} else {
							const ranger = `"${rgbToHex(
								gradientAttrs.points[0].red,
								gradientAttrs.points[0].green,
								gradientAttrs.points[0].blue
							)}" "${rgbToHex(
								gradientAttrs.points[1].red,
								gradientAttrs.points[1].green,
								gradientAttrs.points[1].blue
							)}"`;
							// const ranger = `${gradientAttrs.points[0].red} ${gradientAttrs.points[0].green} ${gradientAttrs.points[0].blue} ${gradientAttrs.points[1].red} ${gradientAttrs.points[1].green} ${gradientAttrs.points[1].blue}`;
							const updatedParams = {
								/* [`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`]: `COLORRANGE 255 145 0 255 255 0 DATARANGE 1305 1673 COLOR 255 145 0` */
								[`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`]: `COLORRANGE ${ranger}`
							};

							const styleObject =
								objectToChange.style[objectToChange.styleIndex];
							styleObject.COLORRANGE = `"${ranger}"`;
							console.log('ranger 2', ranger);
							console.log('style', styleObject);
							/* handleAddcurrentStyleItems({
								className: objectToChange.className,
								style: styleObject,
								styleIndex: objectToChange.styleIndex
							}); */

							// Define the URL for the MapServer CGI script
							const url = 'http://localhost:8182/cgi-bin/mapserv';

							// Define the path to your mapfile
							const mapfile = '/map/amazoniaLegal/aridezAr.map';

							// Define the updated parameters for the layer
							const myupdatedParams = `LAYER\n  NAME "aridez_do_ar"\n  CLASSITEM "[pixel]"\n  CLASS\n    NAME "0.56 - 1.12 KPa"\n    COLORRANGE "#6cff28" "#d95f0e"\n  END\nEND\n`;

							// Define the data to be sent in the POST request
							/* const data = {
								map: mapfile,
								layer: layerName,
								update: myupdatedParams
							}; */

							// Define the layer name and the updated parameters

							const mupdatedParams =
								'LAYER\n  NAME "mylayer"\n  CLASSITEM "value"\n  CLASS\n    NAME "My Class"\n    COLORRANGE "#0000FF" "#FF0000"\n  END\nEND\n';

							// Define the data to be sent in the POST request
							/* const data = new URLSearchParams();
							data.append('map', mapfile);
							data.append('layer', layerName);
							data.append('update', myupdatedParams);

							// Send the POST request using Fetch
							fetch(url, {
								method: 'POST',
								body: data
							})
								.then((response) => {
									if (response.ok) {
										return response.text();
									}
									throw new Error('Network response was not ok.');
								})
								.then((text) => {
									console.log('text test ', text);
								})
								.catch((error) => {
									console.error(error);
								}); */

							source.updateParams(updatedParams);
						}
					}
				} else {
					if (colorType === 'colorrange') {
						// const ranger = `${gradientAttrs.points[0].red} ${gradientAttrs.points[0].green} ${gradientAttrs.points[0].blue} ${gradientAttrs.points[1].red} ${gradientAttrs.points[1].green} ${gradientAttrs.points[1].blue}`;
						const ranger = `"${rgbToHex(
							gradientAttrs.points[0].red,
							gradientAttrs.points[0].green,
							gradientAttrs.points[0].blue
						)}" "${rgbToHex(
							gradientAttrs.points[1].red,
							gradientAttrs.points[1].green,
							gradientAttrs.points[1].blue
						)}"`;

						console.log('ranger 3', ranger);
						const updatedParams = {
							[`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`]: `COLORRANGE ${ranger}`
						};
						/* const styleObject = objectToChange.style[objectToChange.styleIndex];
						styleObject.COLORRANGE = `"${hexToColorRange(colorRgba)}"`;

						console.log('style', styleObject); */
						/* handleAddcurrentStyleItems({
							className: objectToChange.className,
							style: styleObject,
							styleIndex: objectToChange.styleIndex
						}); */
						source.updateParams(updatedParams);
					}
					if (colorType === 'background') {
						const updatedParams = {
							[`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`]: `COLOR "${colorRgba}"`
						};
						const styleObject = objectToChange.style[objectToChange.styleIndex];
						styleObject.COLOR = `"${colorRgba}"`;

						console.log('style', styleObject);
						handleAddcurrentStyleItems({
							className: objectToChange.className,
							style: styleObject,
							styleIndex: objectToChange.styleIndex
						});
						source.updateParams(updatedParams);
					}
					if (colorType === 'border') {
						const updatedParams = {
							[`${layerName}.CLASS[${objectToChange?.classIndex}].STYLE[${objectToChange?.styleIndex}]`]: `OUTLINECOLOR "${colorRgba}"`
						};
						const styleObject = objectToChange.style[objectToChange.styleIndex];
						styleObject.OUTLINECOLOR = `"${colorRgba}"`;

						console.log('style', styleObject);
						handleAddcurrentStyleItems({
							className: objectToChange.className,
							style: styleObject,
							styleIndex: objectToChange.styleIndex
						});
						source.updateParams(updatedParams);
					}
				}
				/* classes.map((classObj, i) => {
					if (classObj.style.length === 1) {
						let updatedParams;

						if (colorType === 'border') {
							if (`${layerName}.CLASS[0].STYLE[0]` in params) {
								params[
									`${layerName}.CLASS[0].STYLE[0]`
								] += `OUTLINECOLOR "${colorRgba}"`;
								source.updateParams(params);
							} else {
								updatedParams = {
									[`${layerName}.CLASS[${i}].STYLE[0]`]: `OUTLINECOLOR "${colorRgba}"`
								};
								source.updateParams(updatedParams);
							}
						} else if (colorType === 'background') {
							if (`${layerName}.CLASS[0].STYLE[0]` in params) {
								params[
									`${layerName}.CLASS[0].STYLE[0]`
								] += `COLOR "${colorRgba}"`;
								source.updateParams(params);
							} else {
								updatedParams = {
									[`${layerName}.CLASS[${i}].STYLE[0]`]: `COLOR "${colorRgba}"`
								};
								source.updateParams(updatedParams);
							}
						}
					} else {
						classObj.style.map((styleObj, index) => {
							if (colorType === 'background' && styleObj.COLORRANGE) {
								const updatedParams = {
									[`${layerName}.CLASS[${i}].STYLE[${index}]`]: `COLORRANGE "${hexToColorRange(
										colorRgba
									)}"`
								};
								source.updateParams(updatedParams);
							}
							if (colorType === 'background' && styleObj.COLOR) {
								const updatedParams = {
									[`${layerName}.CLASS[${i}].STYLE[${index}]`]: `COLOR "${colorRgba}"`
								};
								source.updateParams(updatedParams);
							}
							if (colorType === 'border' && styleObj.OUTLINECOLOR) {
								const updatedParams = {
									[`${layerName}.CLASS[${i}].STYLE[${index}]`]: `OUTLINECOLOR "${colorRgba}"`
								};
								source.updateParams(updatedParams);

								return { ...styleObj, OUTLINECOLOR: colorRgba };
							}
							return styleObj;
						});
					}

					return {};
				}); */

				olMap()?.renderSync();
			} else if (layer instanceof ImageLayer) {
				// Get the layer's style(s)
				/* console.log('ImageLayer');
				console.log(layer.getSource());
				const source = layer.getSource();
				const { layers_ } = layer.getSource();
				// change the color of the raster layer
				console.log('layers', layers_[0].values_);
				const params = source.getParams();
				console.log('params', params);
				const canvas = layer.getSource().getImage();
				console.log('canvas', canvas); */
				/* const context = canvas.getContext('2d');
				console.log('context', context); */
				/* context.fillStyle = 'rgba(255, 0, 0, 0.5)';
				context.fillRect(0, 0, canvas.width, canvas.height); */
			} else {
				// Get the layer's style(s)
				let layerStyles = layer.getStyle();

				if (!Array.isArray(layerStyles)) {
					// If the layer has only one style, wrap it in an array
					layerStyles = [layerStyles];
				}

				// Loop through the styles and update each one
				layerStyles.forEach((layerStyle, index) => {
					layerStyle = layerStyle || new Style({});

					switch (colorType) {
						case 'border':
							layerStyle.setStroke(new Stroke({ color: colorRgba, width: 2 }));
							break;
						case 'background':
							layerStyle.setFill(new Fill({ color: colorRgba }));
							break;
						default:
							break;
					}

					layerStyles[index] = layerStyle;
				});

				// Atualiza o estilo da camada
				layer.setStyle(layerStyles);
			}
		}

		handleAddLastColor(colorRgba);

		setShowColorPicker((prev) => !prev);
		setLoading(false);
	};

	function onChangeConvertToRgba(colorString) {
		if (colorString.startsWith('#')) {
			let hexValue = colorString.substring(1);
			if (hexValue.length === 3) {
				hexValue = hexValue.replace(/./g, '$&$&');
			}
			const r = parseInt(hexValue.substring(0, 2), 16);
			const g = parseInt(hexValue.substring(2, 4), 16);
			const b = parseInt(hexValue.substring(4, 6), 16);
			const alpha = 1;
			const rgbaValue = `rgba(${r}, ${g}, ${b}, ${alpha})`;
			return rgbaValue;
		}
		return null;
	}
	function removeSpaceIfRGB(color) {
		if (color.includes(' ')) {
			// Convert string with spaces to RGB
			const parts = color.split(' ');
			console.log('parts', parts);
			const r = parseInt(parts[0]);
			const g = parseInt(parts[1]);
			const b = parseInt(parts[2]);
			return `rgb(${r}, ${g}, ${b})`;
		}

		// Return original color if not in RGB or hex format
		return color;
	}

	function removeOuterQuotes(str) {
		if (typeof str !== 'string') {
			return str;
		}
		if (str.length < 4) {
			return '';
		}
		if (
			str.charAt(0) === '"' &&
			str.charAt(str.length - 1) === '"' &&
			str.charAt(1) === '"' &&
			str.charAt(str.length - 2) === '"'
		) {
			return str.slice(2, -2);
		}
		// removeSpaceIfRGB(str);

		return str;
	}
	function checkStyleInArray(style) {
		const rest = currentStyleItems?.filter(
			(obj) =>
				obj.className === style.className && obj.styleIndex === style.styleIndex
		);
		if (rest?.length > 0) {
			if (style.type === 'background') {
				return rest[0].style.COLOR;
			}
			if (style.type === 'border') {
				return rest[0].style.OUTLINECOLOR;
			}
			if (style.type === 'colorrange') {
				return rest[0].style.COLORRANGE;
			}
		}
		return style.oldColor;
	}
	function removeSpaceAndToGradient(str) {
		// Split the string into an array of RGB values
		const hexColors = str.match(/#([0-9A-F]{6})/gi);

		const colorStops = [];
		let gradient;
		if (hexColors) {
			gradient = `linear-gradient(to right, ${hexColors.join(', ')})`;
			// return gradient; // if the color matches the hexadecimal format
		} else {
			const colors = str.split(' ').map(Number);
			// Create an array of color stops

			for (let i = 0; i < colors.length; i += 3) {
				const r = colors[i];
				const g = colors[i + 1];
				const b = colors[i + 2];
				colorStops.push(`rgb(${r}, ${g}, ${b})`);
				// colorStops.push(r / 255, `rgb(${r}, ${g}, ${b})`);
			}

			// removeSpaceIfRGB(str);

			gradient = `linear-gradient(to right, ${colorStops.join(', ')})`;
		}

		return gradient;
	}

	const showColorPicker = (
		type,
		className,
		classIndex,
		style,
		styleIndex,
		currentColor
	) => {
		setColorType(type);
		setObjectToChange({ className, classIndex, style, styleIndex });
		if (type === 'colorrange') {
			const getCurrentColorrange = removeOuterQuotes(
				`"${checkStyleInArray({
					className,
					styleIndex,
					oldColor: currentColor,
					type: 'colorrange'
				})}"`
			);
			const hexColors = getCurrentColorrange.match(/#([0-9A-F]{6})/gi);
			if (hexColors) {
				// return gradient; // if the color matches the hexadecimal format
			} else {
				const colorStops = [];
				const colors = getCurrentColorrange.split(' ').map(Number);
				// Create an array of color stops
				console.log('colors', colors);
				for (let i = 0; i < colors.length; i += 3) {
					const r = colors[i];
					const g = colors[i + 1];
					const b = colors[i + 2];
					const point = {
						left: 0,
						red: r,
						green: g,
						blue: b,
						alpha: 1,
						color: `rgb(${r}, ${g}, ${b})`
					};
					colorStops.push(point);
				}
				console.log(colorStops);
				colorStops[1].left = 100;
				const gradient = {
					points: colorStops,
					degree: 0,
					type: 'linear'
				};
				setGradientAttrs(gradient);
			}
		} else if (currentColor) {
			const getCurrentColorrange = removeSpaceIfRGB(
				removeOuterQuotes(
					`"${checkStyleInArray({
						className,
						styleIndex,
						oldColor: currentColor,
						type: 'colorrange'
					})}"`
				)
			);
			setColorRgba(getCurrentColorrange);
		}

		setShowColorPicker(true);
	};

	function getColorFormat(color) {
		if (color.match(/^#([0-9a-f]{3}){1,2}$/i)) {
			return 'hex'; // if the color matches the hexadecimal format
		}
		if (color.match(/^\d{1,3}\s+\d{1,3}\s+\d{1,3}(\s+\d{1,3})?$/)) {
			return 'rgb'; // if the color matches the RGB format
		}

		return null; // if the color is not in either format
	}

	const onChange = (gradientAttrs) => {
		setGradientAttrs(gradientAttrs);
	};

	return (
		<>
			{showModal ? (
				<Background onClick={closeModal} ref={modalRef}>
					<ModalWrapper
						showModal={showModal}
						className={howColorPicker ? 'disable__style' : ''}
					>
						{!howColorPicker && (
							<ModalContent>
								{nameLayer === 'tabAmazonTis' ? (
									<OneContainer>
										<Container>
											<button
												/* className={
										type === 'Drag' && !active
											? `active sm__tooltip`
											: 'sm__tooltip'
									} */
												type="button"
												/* data-title={title}
												 */
												onClick={() => showColorPicker('border')}
											>
												<svg
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M10 2C11.5823 2 13.129 2.46919 14.4446 3.34824C15.7602 4.22729 16.7855 5.47672 17.391 6.93853C17.9965 8.40034 18.155 10.0089 17.8463 11.5607C17.5376 13.1126 16.7757 14.538 15.6569 15.6569C14.538 16.7757 13.1126 17.5376 11.5607 17.8463C10.0089 18.155 8.40035 17.9965 6.93854 17.391C5.47673 16.7855 4.2273 15.7602 3.34825 14.4446C2.4692 13.129 2 11.5823 2 10C2 7.87827 2.84286 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0Z"
														fill="#291F17"
													/>
												</svg>
											</button>
										</Container>
										<Container>
											<button
												/* className={
										type === 'Drag' && !active
											? `active sm__tooltip`
											: 'sm__tooltip'
									} */
												type="button"
												/* data-title={title}
				onClick={HandleControl} */
												onClick={() => showColorPicker('background')}
											>
												<svg
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
														fill="#291F17"
													/>
												</svg>
											</button>
										</Container>
									</OneContainer>
								) : (
									<GroupContainerwrapper>
										{classes?.map((classObj, i) => (
											<GroupContainer key={classObj.name + i}>
												<strong>{classObj?.name}</strong>
												<OneContainer>
													{classObj.style.map((styleObj, index) => (
														<>
															{styleObj.OUTLINECOLOR && (
																<Container>
																	<button
																		/* className={
										type === 'Drag' && !active
											? `active sm__tooltip`
											: 'sm__tooltip'
									} */
																		type="button"
																		/* data-title={title}
																		 */
																		onClick={() =>
																			showColorPicker(
																				'border',
																				classObj.name,
																				i,
																				classObj.style,
																				index,
																				styleObj.OUTLINECOLOR
																			)
																		}
																	>
																		<svg
																			width="20"
																			height="20"
																			viewBox="0 0 20 20"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M10 2C11.5823 2 13.129 2.46919 14.4446 3.34824C15.7602 4.22729 16.7855 5.47672 17.391 6.93853C17.9965 8.40034 18.155 10.0089 17.8463 11.5607C17.5376 13.1126 16.7757 14.538 15.6569 15.6569C14.538 16.7757 13.1126 17.5376 11.5607 17.8463C10.0089 18.155 8.40035 17.9965 6.93854 17.391C5.47673 16.7855 4.2273 15.7602 3.34825 14.4446C2.4692 13.129 2 11.5823 2 10C2 7.87827 2.84286 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0Z"
																				/* fill={removeSpaceIfRGB(
																					removeOuterQuotes(
																						`"${styleObj.OUTLINECOLOR}"`
																					)
																				)} */
																				fill={removeSpaceIfRGB(
																					removeOuterQuotes(
																						`"${checkStyleInArray({
																							className: classObj.name,
																							styleIndex: index,
																							oldColor: styleObj.OUTLINECOLOR,
																							type: 'border'
																						})}"`
																					)
																				)}
																			/>
																		</svg>
																	</button>
																</Container>
															)}
															{styleObj.COLOR && (
																<Container>
																	<button
																		type="button"
																		/* data-title={title}
								onClick={HandleControl} */
																		onClick={() =>
																			showColorPicker(
																				'background',
																				classObj.name,
																				i,
																				classObj.style,
																				index,
																				styleObj.COLOR
																			)
																		}
																	>
																		<svg
																			width="20"
																			height="20"
																			viewBox="0 0 20 20"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
																				fill={removeSpaceIfRGB(
																					removeOuterQuotes(
																						`"${checkStyleInArray({
																							className: classObj.name,
																							styleIndex: index,
																							oldColor: styleObj.COLOR,
																							type: 'background'
																						})}"`
																					)
																				)}
																			/>
																		</svg>
																	</button>
																</Container>
															)}

															{styleObj.COLORRANGE && (
																<Container>
																	<button
																		type="button"
																		/* disabled */
																		/* data-title="COLORRANGE não pode ser alterado" */
																		onClick={() =>
																			showColorPicker(
																				'colorrange',
																				classObj.name,
																				i,
																				classObj.style,
																				index,
																				styleObj.COLORRANGE
																			)
																		}
																	>
																		<div
																			style={{
																				background: removeSpaceAndToGradient(
																					removeOuterQuotes(
																						`"${checkStyleInArray({
																							className: classObj.name,
																							styleIndex: index,
																							oldColor: styleObj.COLORRANGE,
																							type: 'colorrange'
																						})}"`
																					)
																				)
																			}}
																		/>
																	</button>
																</Container>
															)}
														</>
													))}
												</OneContainer>
											</GroupContainer>
										))}
									</GroupContainerwrapper>
								)}
							</ModalContent>
						)}
						{loading && <Load type="progress" />}
						{howColorPicker && (
							<>
								{colorType === 'colorrange' ? (
									<ColorWrapper>
										<GradientColorPicker
											onStartChange={onChange}
											onChange={onChange}
											onEndChange={onChange}
											gradient={gradientAttrs}
											isGradient
										/>

										<ActionContainer>
											<button
												onClick={closeColorPicker}
												className="cancel-somai-color__picker-btn"
												type="button"
											>
												Cancelar
											</button>
											<button
												onClick={handleAddLastesColor}
												className="save-somai-color__picker-btn"
												type="button"
											>
												Selecionar
											</button>
										</ActionContainer>
										{latestcolors && latestcolors.length > 0 && (
											<LatestColorsViewContainer>
												<div>últimas cores</div>
												<LatestColorsView>
													{latestcolors.map((lastColor) => (
														<LatestColor
															onClick={() => {
																setColorRgba(lastColor);
															}}
															key={lastColor}
															style={{
																backgroundColor: lastColor
															}}
														/>
													))}
												</LatestColorsView>
											</LatestColorsViewContainer>
										)}
									</ColorWrapper>
								) : (
									<ColorWrapper>
										<HexAlphaColorPicker
											color={colorRgba}
											onChange={setColorRgba}
										/>
										<ColorViewContainer>
											<ColorViewInput>
												#
												<HexColorInput
													color={colorRgba}
													onChange={setColorRgba}
												/>
											</ColorViewInput>
											<ColorView
												style={{
													backgroundColor: colorRgba
												}}
											/>
										</ColorViewContainer>
										<ActionContainer>
											<button
												onClick={closeColorPicker}
												className="cancel-somai-color__picker-btn"
												type="button"
											>
												Cancelar
											</button>
											<button
												onClick={handleAddLastesColor}
												className="save-somai-color__picker-btn"
												type="button"
											>
												Selecionar
											</button>
										</ActionContainer>
										{latestcolors && latestcolors.length > 0 && (
											<LatestColorsViewContainer>
												<div>últimas cores</div>
												<LatestColorsView>
													{latestcolors.map((lastColor) => (
														<LatestColor
															onClick={() => {
																setColorRgba(lastColor);
															}}
															key={lastColor}
															style={{
																backgroundColor: lastColor
															}}
														/>
													))}
												</LatestColorsView>
											</LatestColorsViewContainer>
										)}
									</ColorWrapper>
								)}
							</>
						)}
					</ModalWrapper>
				</Background>
			) : null}
		</>
	);
}

export default ListModal;
