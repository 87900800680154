export function getItemProperties(itemType) {
	const { type, typeRegister } = itemType;

	const TextOthers =
		typeRegister === 'alert'
			? 'Esta camada apresenta os registros escolhidos como "outros" realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.'
			: 'A funcionalidade de outros usos tradicionais no aplicativo Alerta Clima Indígena permite que os usuários registrem outros tipos de atividades, que não se enquadrem nas outras categorias já disponíveis no aplicativo.';

	switch (type) {
		case 'fogo':
			return {
				content:
					'Esta camada apresenta os registros de fogo realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'desmatamento':
			return {
				content:
					'Esta camada apresenta os registros de desmatamento realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'pesca_ilegal':
			return {
				content:
					'Esta camada apresenta os registros de pesca ilegal realizados no aplicativo Alerta Clima Indígena e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'exploracao_ilegal_madeira':
			return {
				content:
					'Esta camada apresenta os registros de exploração ilegal de madeira realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'garimpo_ilegal':
			return {
				content:
					'Esta camada apresenta os registros de garimpo ilegal realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'invasao_terra_indigena':
			return {
				content:
					'Esta camada apresenta os registros de invasão em Terra Indígena realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'caca_ilegal':
			return {
				content:
					'Esta camada apresenta os registros de caça ilegal realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'outros':
			return {
				content: TextOthers,
				font: 'Usuários do sistema.'
			};
		case 'aldeia':
			return {
				content:
					'Esta camada apresenta os registros de aldeia realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'caca':
			return {
				content:
					'Esta camada apresenta os registros de caça realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'coleta':
			return {
				content:
					'Esta camada apresenta os registros de coleta realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'pesca':
			return {
				content:
					'Esta camada apresenta os registros de pesca realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'roca':
			return {
				content:
					'Esta camada apresenta os registros de roça realizados no aplicativo ACI e que já foram aprovados na aba painel do SOMAI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver esses dados os dados registrados como coordenadas do local, data, imagens e áudio.',
				font: 'Usuários do sistema.'
			};
		case 'tracker':
			return {
				content:
					'Esta camada apresenta os registros de trajetos realizados no aplicativo ACI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver rotas registradas.',
				font: 'Usuários do sistema.'
			};
		case 'where_i_been':
			return {
				content:
					'Esta camada apresenta os registros de localizações realizados no aplicativo ACI. Esta camada não é pública, apenas você e pessoas autorizadas podem ver coordenadas registradas.',
				font: 'Usuários do sistema.'
			};
		default:
			return {
				content: '',
				font: 'Usuários do sistema.'
			};
	}
}
