import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Load from 'components/Load/Load';
import ItemLayer from './ItemLayer';
import * as S from './styled';

// useToggle é para decidir se o comp de carregar layer vai com toggle ou checkbox
// A DIFERENÇA DESSE COMPONENTE PARA O LAYERSGROUPBYLIST É QUE AQUI, A LISTA É PEGA PELO REDUCER E NO OUTRO A LISTA É PEGA POR PROPS
// ISSO ACONTECEU QUANDO A MARTA PEDIU PRA NÃO FICAREM TODAS CAMADAS JUNTAS, AI ACABOU QUE TIVEMOS QUE FAZER UMA NOVA LÓGICA E ACABOU QUE DEPOIS
// VOLTOU A SER COMO ERA ANTES, POR ISSO DEIXAMOS 2 OPÇÕES, CASO MUDE NOVAMENTE

export default function LayersGroup({
	layerGroup,
	useToggle = true,
	showTitle = true,
	textTitle = 'VER NO MAPA'
}) {
	const {
		isReq,
		isErrorReq,
		allLayers,
		message: messageErro
	} = useSelector((state) => state.allLayersMap);

	const { layers } =
		allLayers.length > 0 && allLayers.find((item) => item.key === layerGroup);

	if (isErrorReq && !layers) {
		return <S.erro>{messageErro}</S.erro>;
	}

	return (
		<S.containerCamadas>
			<div style={{ paddingTop: '10px' }}>
				{!allLayers.length && isReq ? (
					<Load />
				) : (
					<S.containerGeral>
						{showTitle && (
							<S.containerAccordionText>
								<h3>{textTitle}</h3>
							</S.containerAccordionText>
						)}

						{layers &&
							layers.length > 0 &&
							layers.map((item, index) => {
								const [isActiveted, setIsActiveted] = useState(false);
								if (layers[0]?.category) {
									const { category, list } = item;

									return (
										<div key={category}>
											<S.containerTitle>
												<h4>{category}</h4>
												<S.line />
											</S.containerTitle>
											{list &&
												list?.map((l, index2) => {
													const [isActiveCategory, setIsActiveCategory] =
														useState(false);
													return (
														<ItemLayer
															key={`key-${index2}`}
															item={l}
															isActiveted={isActiveCategory}
															setIsActiveted={setIsActiveCategory}
															useToggle={useToggle}
														/>
													);
												})}
										</div>
									);
								}
								return (
									<ItemLayer
										key={`key-${index}`}
										item={item}
										isActiveted={isActiveted}
										setIsActiveted={setIsActiveted}
										useToggle={useToggle}
									/>
								);
							})}
					</S.containerGeral>
				)}
			</div>
		</S.containerCamadas>
	);
}
