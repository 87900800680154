/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-parens */
/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { svg } from 'lib/svgList';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import SimpleDialogMenu from 'components/SimpleDialogMenu';
import { editListAction } from 'store/actions/dataUserToShowOnMapAction';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLocalStorage } from 'services/session';
import Dialog from '@mui/material/Dialog';
import { clearVector, onLoadMonitoring } from 'store/actions/mapActions';
import { destroy, geoDataDownload } from 'store/actions/monitoringDataAction';
import {
	converDateAndTimeFromBD,
	filterDeleted,
	handleCloseTooltip,
	nameFileDownGeoMonitoring
} from 'helpers/utils';
import { editItem } from 'store/actions/editDataUserOnMapAction';
import ApproveOrDisapprove from './ApproveOrDisapprove';

import * as S from './ViewItem.Style';
import BtnGeneratePDF from './BtnGeneratePDF';

function returnNameStatus(type) {
	switch (type) {
		case null:
			return 'Em análise';

		case 'approved':
			return 'Aprovado';

		case 'not_approved':
			return 'Não aprovado';

		default:
			return '';
	}
}

function ViewItem({ itemSelect }) {
	const dispatch = useDispatch();
	const { dataUser } = useSelector((state) => state.dataUserToShowOnMap);
	const currentTab = useSelector((state) => state.isTabRef);

	const { loadingDestroy, routeMonitoring } = useSelector(
		(state) => state.monitoring
	);

	const { tisList } = useSelector((state) => state.listTis);
	const [open, setOpen] = React.useState(false);

	// estado para resetar os estados do btn gerar pdf, quando o conteudo do tooltip mudar
	const [resetBtnPdf, setResetBtnPdf] = React.useState(false);

	// const para ajudar a calcular a quebra de linha do P, que no caso da invasão de terra precisa ser diferente das outras
	const typeInvasion = 'invasao_terra_indigena';

	const session = getUserLocalStorage();
	const roleManager = session?.user?.user_role?.role || '';
	const userID = session?.user.id || '';

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onRowDelete = (e) => {
		e.preventDefault();
		const params = { hashis: [itemSelect.hash], routeMonitoring };
		handleClose();

		dispatch(destroy(params))
			.then(() => {
				clearVector('monitoring');
				handleCloseTooltip();
				dispatch(editListAction(filterDeleted(dataUser, itemSelect.hash)));
				dispatch(onLoadMonitoring(filterDeleted(dataUser, itemSelect.hash)));
			})
			.catch(() => {});
	};

	const editItemOnMap = (itemToEdit) => {
		clearVector('monitoring');
		dispatch(editItem(itemToEdit));
		dispatch(onLoadMonitoring([itemToEdit]));
		handleCloseTooltip();
	};

	const isTisName =
		tisList?.length > 0 && itemSelect
			? tisList.filter((filter) => itemSelect.ti_code === filter.code)
			: [];

	// quando o conteudo do btn muda reseta o estado
	useEffect(() => {
		setResetBtnPdf(true);
	}, [itemSelect]);

	return itemSelect && itemSelect.id ? (
		<S.Grid>
			<S.Row>
				<S.Col>
					<S.IconClose onClick={() => handleCloseTooltip()}>
						<img
							style={{ width: 11 }}
							alt="Botão para fechar o Tooltip"
							src={svg.icon.closeWhite}
						/>
					</S.IconClose>
					{roleManager === 'admin' ? (
						itemSelect.user_id === userID && (
							<S.IconDownload
								currentTab={currentTab}
								onClick={() => {
									dispatch(
										geoDataDownload({
											routeMonitoring,
											hashis: [itemSelect.hash],
											nameFile: nameFileDownGeoMonitoring(routeMonitoring)
										})
									);
								}}
							>
								<img
									style={{ width: 14 }}
									alt="Download"
									src={svg.icon.download}
								/>
							</S.IconDownload>
						)
					) : (
						<S.IconDownload
							currentTab={currentTab}
							onClick={() => {
								dispatch(
									geoDataDownload({
										routeMonitoring,
										hashis: [itemSelect.hash],
										nameFile: nameFileDownGeoMonitoring(routeMonitoring)
									})
								);
							}}
						>
							<img
								style={{ width: 14 }}
								alt="Download"
								src={svg.icon.download}
							/>
						</S.IconDownload>
					)}

					{currentTab === 'tab2' && (
						<>
							<S.IconEdit
								onClick={() => {
									editItemOnMap(itemSelect);
								}}
							>
								<img
									style={{ width: 14 }}
									alt="close"
									src={svg.icon.editWhite}
								/>
							</S.IconEdit>
							<S.IconDelete id="destroy" onClick={handleClickOpen}>
								<DeleteForeverSharpIcon sx={{ color: 'white', fontSize: 16 }} />
							</S.IconDelete>
						</>
					)}
				</S.Col>
			</S.Row>

			<S.Row>
				<S.Col>
					<S.Title
						pWidth={typeInvasion === itemSelect.type ? '120px' : '130px'}
					>
						{itemSelect.name}
					</S.Title>
				</S.Col>
				{itemSelect && itemSelect.user && (
					<S.Col>
						<S.EditedBy>
							POR {`${itemSelect.user.firstName} ${itemSelect.user.lastName} `}
						</S.EditedBy>
					</S.Col>
				)}

				{itemSelect && itemSelect.published && (
					<S.Col>
						<S.EditedBy>POR {itemSelect.published}</S.EditedBy>
					</S.Col>
				)}

				{itemSelect && itemSelect?.userEdited && (
					<S.Col>
						<h3 style={{ fontSize: 12, marginTop: 3, marginBottom: 1 }}>
							Editado por:
						</h3>
						<span className="text2">
							{`${itemSelect.userEdited.firstName} ${itemSelect.userEdited.lastName}`}
						</span>
					</S.Col>
				)}
			</S.Row>

			<S.Row>
				<S.Col>
					<h3 style={{ fontSize: 12 }}>Registrado em</h3>
				</S.Col>

				<S.Col>
					<span className="text2">
						{converDateAndTimeFromBD(itemSelect.created_at, true)}
					</span>
				</S.Col>
			</S.Row>

			{routeMonitoring === 'tracks' ? (
				<S.Row>
					<S.Col>
						<h3 style={{ fontSize: 12 }}>Distância</h3>
					</S.Col>
					<S.Col>
						<span className="text2">{itemSelect.distance} km</span>
					</S.Col>
				</S.Row>
			) : (
				<S.Row>
					<S.Col>
						<h3 style={{ fontSize: 12 }}>Localização</h3>
					</S.Col>
					{isTisName && isTisName.length > 0 ? (
						<S.Col>
							<span className="text2">TI: {isTisName[0].name}</span>
						</S.Col>
					) : null}

					<S.Col>
						<span className="text2">Lat: {itemSelect.latitude}</span>
					</S.Col>
					<S.Col>
						<span className="text2">Long: {itemSelect.longitude}</span>
					</S.Col>
				</S.Row>
			)}
			{routeMonitoring !== 'tracks' && routeMonitoring !== 'where_i_been' ? (
				<>
					{itemSelect?.description && (
						<S.Row>
							<S.Col>
								<h3 style={{ fontSize: 12 }}>Descrição</h3>
							</S.Col>

							<S.Col>
								<span className="text2">{itemSelect?.description}</span>
							</S.Col>
						</S.Row>
					)}

					<S.Row>
						<S.Col>
							<h3 style={{ fontSize: 12 }}>Status</h3>
						</S.Col>

						<S.Col>
							<span className="text2">
								{returnNameStatus(itemSelect.status)}
							</span>
						</S.Col>
					</S.Row>

					{itemSelect.image_url || itemSelect.audio_url ? (
						<S.Row>
							<S.Col>
								<h3 style={{ fontSize: 12, marginBottom: 3 }}>Mídia</h3>
							</S.Col>

							{itemSelect.image_url ? (
								<S.Col>
									<SimpleDialogMenu
										title="Foto"
										textButton="Abrir"
										icon={svg.icon.aldeiaOrange}
										content={
											<img
												alt="alert"
												src={itemSelect.image_url}
												style={{ width: '100%' }}
											/>
										}
									/>
								</S.Col>
							) : null}

							{itemSelect.audio_url ? (
								<S.Col>
									<SimpleDialogMenu
										title="Audio"
										textButton="Abrir"
										icon={svg.icon.volumeOrange}
										content={
											<audio controls>
												<source src={itemSelect.audio_url} type="audio/mpeg" />
												<track
													src={itemSelect.audio_url}
													kind="captions"
													srcLang="pt"
													label="Alerta Clima Indígena (ACI)"
												/>
												Seu navegador não suporta o elemento de áudio.
											</audio>
										}
									/>
								</S.Col>
							) : null}
						</S.Row>
					) : null}

					{roleManager === 'manager' &&
					currentTab === 'tab2' &&
					(routeMonitoring === 'alerts' ||
						routeMonitoring === 'traditional_uses') ? (
						<S.Row>
							<S.Col>
								<h3 style={{ fontSize: 12, marginTop: 5, marginBottom: 5 }}>
									Reprovar / Aprovar
								</h3>
							</S.Col>
							<S.Col
								style={{
									justifyContent: 'space-evenly',
									display: 'flex',
									paddingTop: 5,
									marginBottom: 5
								}}
							>
								<ApproveOrDisapprove itemSelect={itemSelect} />
							</S.Col>
						</S.Row>
					) : null}
				</>
			) : null}
			<Dialog onClose={handleClose} open={open}>
				<S.Row style={{ padding: 20 }}>
					<S.Col>
						<h3 style={{ fontSize: 15, marginBottom: 3 }}>
							Tem certeza que deseja apagar esse registro?
						</h3>
					</S.Col>
					<S.Col>
						<S.ButtonCancel type="button" onClick={handleClose}>
							Cancelar
						</S.ButtonCancel>
						<S.ButtonConfirm type="button" onClick={onRowDelete}>
							{loadingDestroy ? 'Aguarde...' : 'Confirmar'}
						</S.ButtonConfirm>
					</S.Col>
				</S.Row>
			</Dialog>

			<BtnGeneratePDF
				resetBtnPdf={resetBtnPdf}
				setResetBtnPdf={setResetBtnPdf}
				values={{ ...itemSelect, nameTi: isTisName[0] || '' }}
			/>
		</S.Grid>
	) : null;
}

export default ViewItem;
