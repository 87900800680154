import React, { useState } from 'react';
import { svg } from 'lib/svgList';
import { useOutsideClick } from '@chakra-ui/react';
import GridBox from 'components/Grid/Grid';
import Row from 'components/Grid/Row';
import { updateSystemUsers, filterSearchUsers } from 'store/actions/webGisActions';
import { useDispatch, useSelector } from 'react-redux';
import { sortAndFilterList } from 'helpers/utils';
import SeeAll from './SeeAll';
import * as S from './styled';

function MultiFilter() {
	const ref = React.useRef();
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const { systemUsers, filterSearchUser, filterLetterUser, filterOrganizationUser, filterProfileUser, filterPeriod } =
		useSelector((state) => state.webGis);

	const filterList = sortAndFilterList(
		systemUsers,
		filterLetterUser,
		filterSearchUser,
		filterProfileUser,
		filterOrganizationUser,
		filterPeriod
	);

	// função chakra para fechar modal quando cliclado fora dela
	useOutsideClick({
		ref,
		handler: () => setIsOpen(false)
	});

	const handleCheckboxClick = (event, id) => {
		const updatedSystemUsers = systemUsers.map((user) => {
			if (user.id === id) {
				return {
					...user,
					isChecked: event.target.checked
				};
			}
			return user;
		});

		// atualiza o estado do array systemUsers com o item modificado
		dispatch(updateSystemUsers(updatedSystemUsers));
	};

	return (
		<S.Container ref={ref}>
			<S.ContainerSearch imgSearch={svg.icon.searchBold} showMagnifying>
				<input
					id="searchComponent"
					className="search-name-users"
					type="text"
					placeholder="Buscar"
					value={filterSearchUser}
					onChange={(e) => {
						dispatch(filterSearchUsers(e.target.value));
					}}
					onFocus={() => setIsOpen(true)}
					autoComplete="off"
				/>
				{isOpen ? (
					<S.BoxFilter>
						<S.ListUsers>
							{filterList.map((row) => (
								<GridBox key={row.id}>
									<Row>
										<input
											type="checkbox"
											onChange={(event) => handleCheckboxClick(event, row.id)}
											checked={row.isChecked}
										/>
										<S.TextName>{`${row.firstName} ${row.lastName}`}</S.TextName>
									</Row>
								</GridBox>
							))}
						</S.ListUsers>
						<GridBox>
							<S.line />
							<SeeAll systemUsers={filterList} />
						</GridBox>
					</S.BoxFilter>
				) : null}
			</S.ContainerSearch>
		</S.Container>
	);
}

export default MultiFilter;
