import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import * as mapActions from 'store/actions/mapActions';
import * as S from './Opacity.styles';

function Opacity({ item }) {
	// reducer
	const dispatch = useDispatch();

	const [count, setCount] = useState(item.opacity || 0);

	const handleOpacity = (e) => {
		const { value } = e.target;

		dispatch(mapActions.handleOpacityLayer({ value, item }));
		item.opacity = value;

		setCount(value);
	};

	return (
		<S.SliderContainer>
			<S.SliderInput
				onChange={(e) => handleOpacity(e)}
				type="range"
				min="0"
				max="100"
				value={count}
			/>
			<S.SliderValue>{count}%</S.SliderValue>
		</S.SliderContainer>
	);
}

export default memo(Opacity);
