import styled from 'styled-components';
// import { color } from 'styles';

export const containerCarousel = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	gap: 2rem;

	@media (max-width: 1060px) {
		width: 100%;
	}
`;
export const arrowLeft = styled.button`
	cursor: pointer;
	background-color: transparent;
	transform: rotate(90deg);

	img {
		width: 16px;
		height: 16px;
	}
`;
export const arrowRight = styled.button`
	cursor: pointer;
	transform: rotate(-90deg);
	background-color: transparent;

	img {
		width: 16px;
		height: 16px;
	}
`;
export const containerItemCarousel = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	gap: 1rem;

	overflow-x: auto;
	scroll-behavior: smooth;
	::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: 1060px) {
		gap: 0.9rem;
	}
`;
export const itemCarousel = styled.div`
	width: 474px;
	cursor: pointer;
	p {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
	}
	> a {
		text-decoration: none;
	}
`;
export const imgPlus = styled.div`
	cursor: pointer;
	position: absolute;
	position: -webkit-sticky;
	bottom: 1rem;
	right: 1rem;
`;
