import { tiSelectedType } from 'store/typesActions/types';

const initialState = {
	tiSelected: {},
	informationTi: {},
	isReqInformation: false,
	isErrorInformation: false
};

export default function tiSelectedreducer(state = initialState, action) {
	switch (action.type) {
		case tiSelectedType.TI_SELECTED_SAVE:
			return { ...state, tiSelected: action.payload };

		case tiSelectedType.TI_SELECTED_DELETE:
			return { ...state, tiSelected: {} };

		case tiSelectedType.REQ_INFORMATION_TI_SELECTED:
			return {
				...state,
				isReqInformation: true,
				isErrorInformation: false
			};

		case tiSelectedType.SUCCESS_INFORMATION_TI_SELECTED:
			return {
				...state,
				informationTi: action.payload,
				isReqInformation: false,
				isErrorInformation: false
			};

		case tiSelectedType.ERROR_INFORMATION_TI_SELECTED:
			return {
				...state,
				informationTi: {},
				isReqInformation: false,
				isErrorInformation: true
			};

		case tiSelectedType.DELETE_INFORMATION_TI_SELECTED:
			return {
				...state,
				informationTi: {},
				isReqInformation: false,
				isErrorInformation: false
			};
		default:
			return state;
	}
}
