import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmptyObject, handleCloseTooltip, formatNumber } from 'helpers/utils';
import { svg } from 'lib/svgList';
// import { AiOutlinePrinter } from 'react-icons/ai';
// import { color } from 'styles/Theme';
import { toggleModalTi } from 'store/actions/statesAmazonLegalAction';
import * as S from './styled';

function ContentTabAmazon({ itemSelect }) {
	const dispatch = useDispatch();
	const noInformation = 'Sem informação';
	const { geom, ...props } = itemSelect;

	function returnHtml() {
		if (!isEmptyObject(itemSelect) && itemSelect.name) {
			const {
				etnia_nome: etniaNome = '',
				fase_ti: faseTi = '',
				isolados = false,
				name = '',
				populacao = '',
				area_ha: areaHa = null,
				uf_sigla: uf = ''
			} = itemSelect || {};

			return (
				<>
					<S.containerClose>
						<S.buttonClose type="submit" onClick={() => handleCloseTooltip()}>
							<img src={svg.icon.closeWhite} alt="fechar" />
						</S.buttonClose>
					</S.containerClose>
					<S.boxInformation>
						<S.title>Informações sobre a TI:</S.title>
						<S.information>{name || noInformation}</S.information>
					</S.boxInformation>

					<S.boxInformation>
						<S.subTitle>Etinia(s)</S.subTitle>
						<S.information>{etniaNome || noInformation}</S.information>
					</S.boxInformation>

					<S.boxInformation>
						<S.subTitle>Situação jurídica</S.subTitle>
						<S.information>{faseTi || noInformation}</S.information>
					</S.boxInformation>

					<S.boxInformation>
						<S.subTitle>Existem povos isolados ? </S.subTitle>
						<S.information>{isolados ? 'Sim' : 'Não'}</S.information>
					</S.boxInformation>

					<S.boxInformation>
						<S.subTitle>População </S.subTitle>
						<S.information>
							{populacao?.toLocaleString('pt-BR') || noInformation}
						</S.information>
					</S.boxInformation>

					<S.boxInformation>
						<S.subTitle>Superfície ha </S.subTitle>
						<S.information>
							{areaHa ? formatNumber(areaHa, 1, '.', ',') : noInformation}
						</S.information>
					</S.boxInformation>

					<S.boxInformation>
						<S.subTitle>UF </S.subTitle>
						<S.information>{uf || noInformation}</S.information>
					</S.boxInformation>
					<S.containerPdfButton>
						<S.buttonPdf
							type="submit"
							onClick={() => {
								handleCloseTooltip();
								dispatch(toggleModalTi(props));
							}}
						>
							{/* <AiOutlinePrinter THIS_ONE
								style={{ color: `${color.interface.orange}`, fontSize: '20px' }}
							/>
							<p>Exportar PDF </p> */}
						</S.buttonPdf>
					</S.containerPdfButton>
				</>
			);
		}

		return null;
	}

	return <S.container>{returnHtml()}</S.container>;
}

export default memo(ContentTabAmazon);
