import React from 'react';
import LayersGroupByList from 'components/LayersGroupByList';
import GenericAccordion from 'components/GenericAccordion';
import { color } from 'styles/Theme';
import { useSelector } from 'react-redux';
import TemperatureLineGraph from '../../Graphs/ClimateGraphs/TemperatureLineGraph';
import * as S from './styled';

export default function Temperature({ climateList = [] }) {
	const type = 'temperatura';
	const { layers } = climateList;
	const aridityList = layers.filter((item) => item.type === type);
	const { temperatureApi } = useSelector((state) => state.temperature);
	return (
		<GenericAccordion
			title="Temperatura"
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontWeight: '500'
			}}
		>
			<LayersGroupByList
				layers={aridityList}
				showTitle={false}
				useToggle={false}
			/>
			{/* Para mostrar o grafico apenas quando tiver um TI selecionada */}
			{temperatureApi?.length > 0 && (
				<>
					<S.containerAccordionText>
						<h4>TEMPERATURA DO SOLO</h4>
						<p>Cálculo da Temperatura Média do Solo (ºC)</p>
					</S.containerAccordionText>
					<S.boxGraph>
						<TemperatureLineGraph />
					</S.boxGraph>
				</>
			)}
		</GenericAccordion>
	);
}
