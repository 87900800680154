import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ScaleLine from 'ol/control/ScaleLine';
import { olMap } from 'components/Map/ControlMap';

const MapScaleContainer = styled.div`
	/* border: 1px solid #69625d; */
	margin-left: 2rem;
	border-top: none;
	will-change: contents, width;
	display: flex;
	justify-content: center;
	align-items: baseline;
	position: relative;
	font-weight: 400;
	&:after {
		content: '';
		position: absolute;
		left: 0px;
		bottom: 5px;
		height: 20%;
		border-left: 1px solid #69625d;
	}
	&:after {
		content: '';
		position: absolute;
		right: 0px;
		bottom: 5px;
		height: 20%;
		border-right: 1px solid #69625d;
	}
	&:after {
		content: '';
		position: absolute;
		bottom: 5px;
		border-bottom: 1px solid #69625d;
	}
`;

function ScaleContainer() {
	const scaleLineRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			const scaleLineControl = new ScaleLine({
				units: 'metric',
				className: `ol-scale-line somai-scale-line`,
				text: true,
				target: scaleLineRef.current
			});

			olMap()?.addControl(scaleLineControl);
		}, 1000);
	}, []);

	return (
		<MapScaleContainer
			className="somai-scale-line__container"
			ref={scaleLineRef}
		/>
	);
}

export default ScaleContainer;
