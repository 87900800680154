import styled from 'styled-components';
import { color } from 'styles';

export const ContainerDrawer = styled.nav`
	display: initial;
`;

export const StyledDrawer = styled.div`
	height: ${({ open }) => (open ? '50vh' : '57px')};
	flex: 1 0 auto;
	z-index: 1200;
	position: fixed;
	outline: 0px;
	width: 97%;
	position: fixed;
	bottom: 0;
	z-index: 2;
	left: 0;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: none;
`;

export const ButtonArrow = styled.button`
	background-color: ${color.interface.orange};
	color: white;
	height: 42px;
	width: 40px;
	position: absolute;
	right: 0px;
	border-radius: 10px 10px 0px 0px;
	padding-top: 5px;
	transform: translateX(-8%);

	@media (max-width: 565px) {
		width: 35px;
		transform: translateX(-7%);
	}

	img {
		width: 15px;
		transform: rotate(90deg);

		@media (max-width: 565px) {
			width: 12px;
		}
	}
	:hover {
		background-color: ${color.auxiliary.coral};
		cursor: pointer;
	}
`;

export const ButtonFooter = styled.button`
	background-color: ${color.interface.orange};
	height: 100%;
	width: 100%;
	padding: 6px;
	border-radius: ${({ open }) =>
		open ? '0px 0px 0px 0px' : '0px 10px 0px 0px'};

	img {
		width: 20px;
		transform: rotate(-90deg);
	}

	h5 {
		color: ${color.interface.white};
		width: 50%;
	}
`;
