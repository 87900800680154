/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import { olMap } from 'components/Map/ControlMap';
import React, { useEffect } from 'react';
import { measureSource } from 'services/map/measure.context';
import { shapesSource } from 'services/map/shapes.context';
import styled from 'styled-components';

const Container = styled.div`
	.popover-title {
		font-size: 0.9375rem;
		font-weight: 900;
		color: #69625d;
		padding: 7px 25px 7px 5px;
		font-size: 14px;
	}
	button {
		width: 100%;
		margin-top: 5px;
		margin-bottom: 5px;
		padding: 7px;
		color: #6d6d6d;
		background-color: #fff;
		display: inline-block;
		padding: 6px 12px;
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 800;
		line-height: 1.42857143;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		cursor: pointer;
		user-select: none;
		background-image: none;
		border: 1px #ccc solid;
		border-radius: 4px;
		:hover {
			background: #d44500;
			border-color: #d44500;
			color: #fff;
		}
	}
`;

function ShapeContent({
	selectedLayer,
	selectedFeature,
	setResetOverlay,
	resetOverlay
}) {
	useEffect(() => {
		if (resetOverlay) {
			const featureId = selectedFeature.get('id');
			const tooltipcontainer = document.getElementById(featureId);
			if (tooltipcontainer) {
				tooltipcontainer.remove();
			}
		}
	}, [resetOverlay]);

	const deleteSelectedFeatures = () => {
		/* selectedFeatures.forEach((feature) => {
			vectorSource.removeFeature(feature);
		}); */

		const layerFeatureType = selectedFeature.get('type');

		if (layerFeatureType === 'shapes_draw') {
			shapesSource.removeFeature(selectedFeature);
			setResetOverlay(true);
		}
		if (layerFeatureType === 'measure_draw') {
			measureSource.removeFeature(selectedFeature);
			setResetOverlay(true);
		}
	};

	return (
		<Container className="popover popover-map top in">
			<h3 className="popover-title">Selecione uma das opções</h3>
			<div className="popover-content">
				<button
					type="button"
					className="btn btn-default btn-map ng-binding"
					value="deletePolygon"
					onClick={deleteSelectedFeatures}
				>
					Deletar polígono
				</button>
			</div>
		</Container>
	);
}

export default ShapeContent;
