import { olMap } from 'components/Map/ControlMap/index';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { getMaxZIndex } from 'store/actions/mapActions';
import { getLayerByName, getStyleVector } from '../../mapActions';

export const vectorsDataUsersWebGis =
	({ dataMap }) =>
	async () => {
		const lastZIndex = getMaxZIndex() + 2;

		const vectorDataUsersWebGis = new VectorLayer({
			name: dataMap.name,
			source: new VectorSource({ wrapX: false }),
			monitoring: false,
			label: dataMap.label,
			haveTitle: false,
			icon: dataMap.icon,
			zIndex: lastZIndex,
			style: await getStyleVector({ item: dataMap.name })
		});

		const layerdataUsersWebGis = await getLayerByName(dataMap.name);

		// pq senão dá problemas de interação com mapa, por exemplo, adicionar ou remover pontos
		if (!layerdataUsersWebGis) olMap().addLayer(vectorDataUsersWebGis);
	};
