import styled from 'styled-components';
import { color } from 'styles/Theme';

export const container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 0.5rem;
	gap: 1rem;
`;

export const containerGraphFireAlert = styled.div`
	width: 100%;
	/* height: 300px; */
`;

export const containerAccordionText = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 0.2rem;

	h4 {
		font-weight: 700;
		color: ${color.interface.darkGray};
		text-transform: uppercase;
		font-size: 13px;
	}

	p {
		width: 100%;
		font-weight: 200;
		color: ${color.interface.darkGray};
		text-align: left;
		font-size: 11px;
	}

	.p-end-accordion-text {
		display: flex;
		width: 100%;
		justify-content: end;
	}
`;

export const boxToggle = styled.div`
	width: 100%;
	align-self: flex-start;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	p {
		opacity: 0.7;
		font-size: 0.9rem;
	}
`;
