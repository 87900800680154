/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef, useCallback } from 'react';

import {
	generateGradientStyle,
	updateGradientActivePercent
} from '../../../utils';

import { hsvaToHslaString } from '../../../utils/convert';

import { GradientPointer } from '../GradientPointer';
import { formatClassName } from '../../../utils/format';

function GradientPoints({
	points,
	activePointIndex,
	changeActivePointIndex,
	updateGradientLeft,
	addPoint,
	removePoint,
	hsva,
	className
}) {
	const [pointsStyle, setpointsStyle] = useState({});
	const [width, setWidth] = useState(0);
	const [positions, setPositions] = useState({});

	const pointsContainerRef = useRef();

	useEffect(() => {
		if (pointsContainerRef.current) {
			setWidth(pointsContainerRef.current.clientWidth);

			const pointerPos = pointsContainerRef.current.getBoundingClientRect();
			setPositions({ x: pointerPos.x, y: pointerPos.y });
		}
	}, []);
	useEffect(() => {
		const style = generateGradientStyle(points, 'linear', 90);

		setpointsStyle({ background: style });
	}, [points]);

	const pointsContainerClick = useCallback(
		(event) => {
			const left = updateGradientActivePercent(
				event.pageX - positions.x,
				width
			);

			addPoint(left);
		},
		[addPoint, positions.x, width]
	);

	const nodeClassName = formatClassName([
		'somai-react-colorful__gradient-slider',
		className
	]);
	return (
		<>
			<div className={nodeClassName} /* onClick={pointsContainerClick} */>
				<div
					className="somai-react-colorful__gradient-slider-container"
					style={pointsStyle}
					ref={pointsContainerRef}
				>
					{points &&
						points.map((point, index) => (
							<GradientPointer
								key={index}
								activePointIndex={activePointIndex}
								index={index}
								point={point}
								width={width}
								positions={positions}
								changeActivePointIndex={changeActivePointIndex}
								updateGradientLeft={updateGradientLeft}
								removePoint={removePoint}
								className="somai-react-colorful__gradient-slider-pointer"
							/>
						))}
				</div>
			</div>
			{/* <div className={nodeClassName} onClick={pointsContainerClick}>
				<div
					className="somai-react-colorful__gradient-slider-container"
					style={pointsStyle}
					ref={pointsContainerRef}
				>
					{points &&
						points.map((point, index) => (
							<GradientPointer
								key={index}
								activePointIndex={activePointIndex}
								index={index}
								point={point}
								width={width}
								positions={positions}
								changeActivePointIndex={changeActivePointIndex}
								updateGradientLeft={updateGradientLeft}
								removePoint={removePoint}
								className="somai-react-colorful__gradient-slider-pointer"
							/>
						))}
				</div>
			</div> */}
		</>
	);
}

export default GradientPoints;
