import React, { useState } from 'react';
import CustomInput from 'components/CustomInput';
import Button from 'components/Buttons/Button/Button';
import { api } from 'services/api';
import Load from 'components/Load/Load';
import UncheckAnimation from 'components/Animations/UncheckAnimation/UncheckAnimation';
import CheckAnimation from 'components/Animations/CheckAnimation/CheckAnimation';
import * as S from './styled';

export default function RecoveryPasswordTemplate({
	email = '',
	setEmail = () => {}
}) {
	const [isReq, setIsReq] = useState(false);
	const [messageReq, setMessageReq] = useState('');
	const [errorReq, setErrorReq] = useState(false);
	const [emailError, setEmailError] = useState(false);

	const recoveryPassword = async () => {
		if (email.includes('@') && email !== '') {
			setIsReq(true);
			setErrorReq(false);
			setEmailError(false);
			await api
				.post('/user/forgot_password', { email })
				.then((res) => {
					setMessageReq(res.data.message);
					setIsReq(false);
				})
				.catch((error) => {
					const { response: err } = error;
					const message =
						err && err.data ? err.data.message : 'Erro desconhecido';
					setMessageReq(message);
					setIsReq(false);
					setErrorReq(true);
				});
		} else {
			setEmailError(true);
		}
	};

	return isReq ? (
		<S.showMessageRe>
			<Load size={50} />
		</S.showMessageRe>
	) : (
		<S.containerRecoveryPasswor>
			{messageReq === '' ? (
				<>
					<S.titleModal>Esqueceu a senha?</S.titleModal>
					<S.textModal>
						Forneça seu email cadastrado no sistema para recuperação da senha.
					</S.textModal>
					<CustomInput
						placeholder="E-mail"
						type="email"
						value={email}
						setValue={setEmail}
					/>
					{emailError ? (
						<S.noticeError>Insira um e-mail válido</S.noticeError>
					) : null}

					<Button text="Enviar" onClick={recoveryPassword} />
				</>
			) : (
				<S.showMessageRe>
					<S.textMessageReq>
						{!errorReq ? <CheckAnimation /> : <UncheckAnimation />}
						<p>{messageReq}</p>
					</S.textMessageReq>
				</S.showMessageRe>
			)}
		</S.containerRecoveryPasswor>
	);
}
