import React from 'react';
import * as S from './styled';
import ContainerCarousel from './ContainerCarousel';

function LettersSlider({ systemUsers = [] }) {
	const letters = [
		{ letter: 'A', isExist: false },
		{ letter: 'B', isExist: false },
		{ letter: 'C', isExist: false },
		{ letter: 'D', isExist: false },
		{ letter: 'E', isExist: false },
		{ letter: 'F', isExist: false },
		{ letter: 'G', isExist: false },
		{ letter: 'H', isExist: false },
		{ letter: 'I', isExist: false },
		{ letter: 'J', isExist: false },
		{ letter: 'K', isExist: false },
		{ letter: 'L', isExist: false },
		{ letter: 'M', isExist: false },
		{ letter: 'N', isExist: false },
		{ letter: 'O', isExist: false },
		{ letter: 'P', isExist: false },
		{ letter: 'Q', isExist: false },
		{ letter: 'R', isExist: false },
		{ letter: 'S', isExist: false },
		{ letter: 'T', isExist: false },
		{ letter: 'U', isExist: false },
		{ letter: 'V', isExist: false },
		{ letter: 'W', isExist: false },
		{ letter: 'X', isExist: false },
		{ letter: 'Y', isExist: false },
		{ letter: 'Z', isExist: false }
	];

	function checkFirstLetter() {
		const newLetters = letters.map((letter) => {
			letter.isExist = systemUsers.some(
				(user) => user.firstName.charAt(0).toUpperCase() === letter.letter
			);
			return letter;
		});
		return newLetters;
	}

	// Permite clicar apenas nas letras que existem na lista
	const filterLetters = checkFirstLetter();
	return (
		<S.container>
			<ContainerCarousel letters={filterLetters} />
		</S.container>
	);
}

export default LettersSlider;
