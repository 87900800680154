import styled from 'styled-components';
import { color } from 'styles/Theme';

const LoadingLayerContainer = styled.div`
	position: absolute;
	justify-content: center;
	top: 0;
	align-items: center;
	width: 100vw;
	height: 100vh;
	z-index: 2;
	color: ${color.interface.white};
	background-color: ${color.interface.green}60;
	padding-top: 45vh;
	border-radius: 10px;
`;

const LoadingImageContainer = styled.div`
	position: absolute;
	bottom: 0;
	width: 100vw;
	z-index: 10000;
`;

export { LoadingLayerContainer, LoadingImageContainer };
