import React from 'react';
import { svg } from 'lib/svgList';
import * as S from './TabsRegister.Styled';

function Step({ children, active, stepNumber }) {
	return (
		active && (
			<>
				<S.BoxHeader>
					<S.GridTitle>
						<img alt="Logo" className="icon-next" src={svg.icon.padlock} />
						<h5>CADASTRO</h5>
					</S.GridTitle>
					<S.GridCont>
						<h5>{stepNumber} / 2</h5>
					</S.GridCont>
				</S.BoxHeader>
				<S.BoxForm>{children}</S.BoxForm>
			</>
		)
	);
}

Step.defaultProps = {
	active: false
};

export default Step;
