import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import isRouteRefReducer from './reducers/isRouteRefReducer';
import isTabRefReducer from './reducers/isTabRefReducer';
import userReducer from './reducers/userReducer';
import statesAmazonLegalReducer from './reducers/statesAmazonLegalReducer';
import listTisReducer from './reducers/listTisReducer';
import alertDeforatationReducer from './reducers/alertDeforestationReducer';
import threatsReducer from './reducers/threatsReducer';
import termsOfUserAcceptReducer from './reducers/termsOfUserAcceptReducer';
import isNavBarOpenReducer from './reducers/isNavBarOpenReducer';
import organizationsReducer from './reducers/organizationsReducer';
import alertDeforestationSadLastReducer from './reducers/alertDeforestationSadLastReducer';
import alertDeforestationSadSumReducer from './reducers/alertDeforestationSadSumReducer';
import alertDeforestationLastMonthReducer from './reducers/alertDeforestationDeterLastReducer';
import alertDeforestationDeterSumReducer from './reducers/alertDeforestationDeterSumReducer';
import tiGeoReducer from './reducers/tiGeoReducer';
import fireLast30DaysReducer from './reducers/fireLast30DaysReducer';
import fireTotalLast30DaysReducer from './reducers/fireTotalLast30DaysReducer';
import fireFocusByMonthReducer from './reducers/fireFocusByMonthReducer ';
import fireFocusByYearReducer from './reducers/fireFocusByYearReducer';
import riskFireReducer from './reducers/riskFireReducer';
import climateReducer from './reducers/climateReducer';
import systemReducer from './reducers/systemReducer';
import temperatureReducer from './reducers/temperatureReducer';
import monitoringDataReducer from './reducers/monitoringDataReducer';
import rainReducer from './reducers/rainReducer';
import tiSelectedReducer from './reducers/tiSelectedReducer';
import layersTisReducer from './reducers/layersTisReducer';
import reportsForAdminReducer from './reducers/reportsForAdminReducer';
import dataUserToShowOnMapReducer from './reducers/dataUserToShowOnMapReducer';
import editDataUserOnMapReducer from './reducers/editDataUserOnMapReducer';
import mapLoadedReducer from './reducers/mapLoadedReducer';
import allLayersMapReducer from './reducers/allLayersMapReducer';
import mapReducer from './reducers/mapReducer';
import deforestationThreatBufferReducer from './reducers/deforestationThreatBufferReducer';
import webGisReducer from './reducers/webGisReducer';

const middleware = [thunk];

const allReducers = combineReducers({
	map: mapReducer,
	isLoadMap: mapLoadedReducer,
	isRouteRef: isRouteRefReducer,
	isTabRef: isTabRefReducer,
	isNavBarOpen: isNavBarOpenReducer,
	allLayersMap: allLayersMapReducer,
	user: userReducer,
	amazonLegal: statesAmazonLegalReducer,
	listTis: listTisReducer,
	deforestationTi: alertDeforatationReducer,
	threatsTis: threatsReducer,
	termsOfUseIsAccept: termsOfUserAcceptReducer,
	organizations: organizationsReducer,
	deforestationSadLast: alertDeforestationSadLastReducer,
	defSadSum: alertDeforestationSadSumReducer,
	deforestationDeterLast30Days: alertDeforestationLastMonthReducer,
	defDeterSum: alertDeforestationDeterSumReducer,
	tiGeo: tiGeoReducer,
	fireLast30days: fireLast30DaysReducer,
	fireTotalLast30Days: fireTotalLast30DaysReducer,
	fireFocusByMonth: fireFocusByMonthReducer,
	fireFocusByYear: fireFocusByYearReducer,
	riskFire: riskFireReducer,
	apiClimate: climateReducer,
	system: systemReducer,
	temperature: temperatureReducer,
	monitoring: monitoringDataReducer,
	rain: rainReducer,
	tiSelectedTabTi: tiSelectedReducer,
	layersTabTi: layersTisReducer,
	reportsForAdmin: reportsForAdminReducer,
	dataUserToShowOnMap: dataUserToShowOnMapReducer,
	editDataUserOnMap: editDataUserOnMapReducer,
	deforestationThreat: deforestationThreatBufferReducer,
	webGis: webGisReducer
});

const store = createStore(
	allReducers,
	composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
