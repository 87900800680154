/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Load from 'components/Load/Load';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { color } from 'styles/Theme';
import LinearProgress from 'components/Loading/LinearProgress';
import * as S from './LoadingLayer.style';

function LoadingLayer() {
	const { loadingLayer, loadingImage, loadingInfo } = useSelector(
		(state) => state.map
	);
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	if (loadingLayer === true || loadingInfo === true)
		return (
			<S.LoadingLayerContainer>
				<Load size={45} />
			</S.LoadingLayerContainer>
		);

	if (loadingImage === true)
		return (
			<S.LoadingImageContainer>
				<Load size={45} type="circle-progress" />
				<LinearProgress value={progress} />
			</S.LoadingImageContainer>
		);
	return null;
}

export default LoadingLayer;
