import { deforestationThreatBufferType } from '../typesActions/types';

const INITIAL_STATE = {
	data: {},
	isLoading: false,
	error: false,
	message: ''
};

export default function climateReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case deforestationThreatBufferType.REQ_DEFORASTATION_THREAT_BUFFER:
			return {
				...state,
				isLoading: true,
				error: false,
				message: 'Buscando dados de desmatamento.'
			};
		case deforestationThreatBufferType.SUCCESS_DEFORASTATION_THREAT_BUFFER:
			return {
				data: action.payload,
				isLoading: false,
				error: false,
				message: 'Sucesso ao pegar os dados de desmatamento'
			};
		case deforestationThreatBufferType.ERROR_DEFORASTATION_THREAT_BUFFER:
			return {
				data: {},
				isLoading: false,
				error: true,
				message: 'Algum erro aconteceu, tente novamente mais tarde'
			};
		case deforestationThreatBufferType.DELETE_DEFORASTATION_THREAT_BUFFER:
			return {
				data: {},
				isLoading: false,
				error: true,
				message: 'Dados deletados'
			};
		default:
			return state;
	}
}
