/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actionsUsers } from 'helpers/utils';
import { closeToggleAllLayers } from 'store/actions/mapActions';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import ColLayout from 'components/Grid/Col';
import GridBox from 'components/Grid/Grid';
import Row from 'components/Grid/Row';
import { svg } from 'lib/svgList';
import { AlertDialog } from 'components/AlertDialog';
import { CgClose } from 'react-icons/cg';

const CloseModalButton = styled(CgClose)`
	cursor: pointer;
	position: absolute;
	top: -10px;
	right: -18px;
	width: 38px;
	height: 38px;
	padding: 6px;
	z-index: 10;
	background-color: #d44500;
	border-radius: 50%;
	color: #fff;
	@media (max-width: 450px) {
		& {
			right: -5px;
			width: 30px;
			height: 30px;
		}
	}
`;

const Button = styled.button`
	width: 191px;
	height: 28px;
	margin: 0.5rem;
	gap: 0.2rem;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	pointer-events: fill;
	padding-top: 1px;
	font-family: 'Nunito';
	font-size: 10px;
	font-weight: 800;
	color: #ffff;
	background-color: #d44500;
`;

const Icon = styled.span`
	align-self: center; /* Centralizar verticalmente o ícone */
`;
const ModalWrapper = styled.div`
	position: relative;
`;

const Box = styled(ColLayout)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.3rem;
	margin-bottom: 0.3rem;
`;

function CloseAllLayers() {
	const dispatch = useDispatch();
	const currentTab = useSelector((state) => state.isTabRef);
	const { activatedLayers } = useSelector((state) => state.map);
	const [tab, setTab] = useState('');

	useEffect(() => {
		setTab(currentTab);
	}, [currentTab]);

	const handleGA = () => {
		actionsUsers({
			category: 'plataforma/mapa/webgis',
			action: 'Click no botão',
			label: 'Fechar todas as camadas ativas'
		});
	};

	const closeLayers = () => {
		// limpa apenas os itens do checked e toggle
		dispatch(closeToggleAllLayers());
		// encaminha a ação para o google GA
		handleGA();
	};

	const handleClick = () => {
		AlertDialog({
			customUI: ({ onClose }) => (
				<ModalWrapper>
					<div className="somai-confirm-alert-body">
						<h1>Camadas no mapa</h1>
						<p>Você tem certeza de fazer isso ?</p>
						<div className="somai-confirm-alert-button-group">
							<button onClick={onClose}>Não Limpar seleçao</button>
							<button
								onClick={() => {
									closeLayers();
									onClose();
								}}
							>
								Sim Limpar seleçao
							</button>
						</div>
					</div>
					<CloseModalButton aria-label="Close modal" onClick={onClose} />
				</ModalWrapper>
			)
		});
		actionsUsers({
			category: 'plataforma/mapa/webgis',
			action: 'Click no botão',
			label: 'Fechar todas as camadas ativas'
		});
	};

	return (
		tab === 'tab1' &&
		activatedLayers?.length > 0 && (
			<GridBox>
				<Row>
					<Box size={12}>
						<Button type="button" onClick={handleClick}>
							<Icon>
								<DeleteForeverSharpIcon sx={{ color: 'white', fontSize: 20 }} />
							</Icon>
							LIMPAR CAMADAS ATIVAS
						</Button>
					</Box>
				</Row>
			</GridBox>
		)
	);
}

export default CloseAllLayers;
