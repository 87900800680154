import { fireLast30daysType } from 'store/typesActions/types';

const initialState = {
    data: {},
    isLoading: false,
    error: false,
    message: ''
};

export default function fireLast30DaysReducer(state = initialState, action) {
    const { errors } = action?.payload || { errors: {} };

    switch (action.type) {
        case fireLast30daysType.FIRE_LAST_30:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: 'Buscando dados do desmatamento'
            };

        case fireLast30daysType.FIRE_LAST_30_SUCCESS:
            return {
                data: action.payload,
                isLoading: false,
                error: false,
                message: ''
            };

        case fireLast30daysType.FIRE_LAST_30_ERROR:
            return {
                ...state,
				data: [],
				isReq: false,
                error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
            };
        default:
            return state;
    }
}