import { deforestationDeterSumType } from 'store/typesActions/types';

const initialState = {
    deforestationDeterSum: {},
    isLoading: false,
    error: false,
    message: ''
};

export default function deforestationDeterSumReducer(state = initialState, action) {
    const { errors } = action?.payload || { erros: {} };

    switch (action.type) {
        case deforestationDeterSumType.DEFORESTATION_DETER_SUM:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: 'Buscando dados do desmatamento'
            };

        case deforestationDeterSumType.DEFORESTATION_DETER_SUM_SUCCESS:
            return {
                deforestationDeterSum: action.payload,
                isLoading: false,
                error: false,
                message: ''
        };

		case deforestationDeterSumType.DEFORESTATION_DETER_SUM_ERROR:
			return {
				...state,
				deforestationDeterSum: [],
				isReq: false,
                error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
			};
		default:
			return state;
	}
}
