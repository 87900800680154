import React from 'react';
import GenericAccordion from 'components/GenericAccordion';
import { color } from 'styles/Theme';
import LayersGroup from 'components/LayersGroup';
import { svg } from 'lib/svgList';
import { useSelector } from 'react-redux';
import DeforestationAlert from './DeforestationAlert';
import DeforestationData from './DeforestationData';
import FireData from './FireData';
import FireAlert from './FireAlert';
import * as S from './styled';

export default function ThreatAndAlerts() {
	const { allLayers } = useSelector((state) => state.allLayersMap);
	const key = 'alertas';
	const { name } = allLayers?.find((item) => item.key === key);

	const noticeError =
		'Houve algum problema para carregar as camadas de estatísticas.';
	// const noticeSelect = 'Selecione uma terra indígena';
	const tiSelectedRedux = useSelector(
		(state) => state.tiSelectedTabTi.tiSelected
	);
	const { isReq, isErrorReq } = useSelector((state) => state.allLayersMap);

	function returnStatistics() {
		if (!isReq && isErrorReq) return <S.notice>{noticeError}</S.notice>;
		return (
			<>
				<DeforestationData />
				<DeforestationAlert />
				<FireData />
				<FireAlert />
			</>
		);
	}

	return (
		<GenericAccordion
			title={name?.toUpperCase() || 'Sem nome'}
			keyAccordion={key}
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontFamily: 'Nunito',
				fontStyle: 'normal',
				fontWeight: '800',
				lineHeight: '0.875rem',
				fontSize: '0.625rem',
				letterSpacing: '0.1em',
				textTransform: 'uppercase'
			}}
		>
			<S.containerAccordionInside>
				{'label' in tiSelectedRedux ? (
					returnStatistics()
				) : (
					<GenericAccordion
						imgTitle={svg.icon.exclamationCircleOrange}
						widthIconTitleAccordion={0.99}
						title="Dados e alertas"
						styleTitle={{
							color: `${color.interface.darkGray}`,
							fontWeight: '400'
						}}
					>
						<S.notice>
							Selecione uma terra indígena para visualizar dados e alerta de
							desmatamento e fogo.
						</S.notice>
					</GenericAccordion>
				)}

				<GenericAccordion
					imgTitle={svg.icon.mapLocationOrange}
					widthIconTitleAccordion={0.92}
					title="Ver no mapa"
					styleTitle={{
						color: `${color.interface.darkGray}`,
						fontWeight: '400'
					}}
				>
					<LayersGroup layerGroup={key} showTitle={false} useToggle={false} />
				</GenericAccordion>
			</S.containerAccordionInside>
		</GenericAccordion>
	);
}
