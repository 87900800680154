import styled from 'styled-components';

export const Container = styled.div`
	z-index: 1;
	display: flex;
	height: 16px;
	width: 16px;
	align-items: center;
	justify-content: center;

	img {
		height: 20px;
	}

	button {
		cursor: pointer;
	}
`;
