import React from 'react';
import * as S from './CustomInput.Styled';

function CustomInput({
	alt,
	src,
	value,
	type = 'text',
	name,
	placeholder,
	setValue = () => {},
	onChange = () => {},
	useOnChange = false,
	required = false,
	styleInput = '',
	disabled = false,
	minLength = 0,
	maxLength = 255
}) {
	return (
		<S.BoxInput style={styleInput !== '' ? styleInput : null}>
			{src ? (
				<S.Icon>
					<img alt={alt} src={src} style={{ width: '15px' }} />
				</S.Icon>
			) : null}
			<S.Input
				value={value}
				type={type}
				name={name}
				placeholder={placeholder}
				required={required}
				onChange={useOnChange ? onChange : (e) => setValue(e.target.value)}
				disabled={disabled}
				minLength={minLength}
				maxLength={maxLength}
			/>
		</S.BoxInput>
	);
}

export default CustomInput;
