import styled from 'styled-components';

export const Container = styled.div`
	/* position: relative; */
	display: flex;
	z-index: 1;
	align-items: center;
	justify-content: center;
	/* width: 49px; */
	height: 100%;
	margin-right: 5px;
	button {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.show-atrib {
		/* width: fit-content; */
		width: 30px !important;
		height: 32px !important;
		background: rgba(248, 245, 243, 0.9);
		border-radius: 5px 5px 0 0;
		cursor: pointer;
		display: block;
		/* :hover {
			background-color: #d44500;
		} */
		&.active {
			display: none !important;
		}
	}

	@media (max-width: 800px) {
		display: none;
	}
`;
