import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerRecorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	/* background-color: yellow; */
`;

export const invisiableRecorder = styled.div`
	display: none;
`;

export const btnRecorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	button {
		border-radius: 8px;
		padding: 0.5rem 1rem;
		color: ${color.interface.white};
		background-color: ${color.interface.brown};
		cursor: pointer;
		font-size: 0.9rem;
	}
	/* background-color: aquamarine; */
`;

export const animationRecorder = styled.p`
	color: ${color.interface.red};
	@keyframes flashing {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
	font-weight: 400;
	animation: linear;
	animation-name: flashing;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
`;
