import { rainType } from 'store/typesActions/types';

const initialState = {
    rainApi: [],
    isLoading: false,
    error: false,
    message: ''
};

export default function tiGeoreducer(state = initialState, action) {
    const { errors } = action?.payload || { errors: {} };

    switch (action.type) {
        case rainType.RAIN_REC:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: 'Buscando dados sobre chuvas nas TIs'
            };

        case rainType.RAIN_REC_SUCCESS:
            return {
                rainApi: action.payload,
                isLoading: false,
                error: false,
                message: ''
            };

        case rainType.RAIN_REC_ERROR:
            return {
                ...state,
				rainApi: [],
				isLoading: false,
                error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
            };
        default:
            return state;
    }
}