import { rainType } from 'store/typesActions/types';
import { api } from 'services/api';

export const getRainRec = (code) => async (dispatch) => {
	dispatch({ type: rainType.RAIN_REC });

	try {
		const res = await api.get(`/ti/${code}/climate_rain`);
		const { data } = res || {};

		let newData = data.map((item) => {
			const data = parseFloat(Math.trunc(item.data));
			return { ano: item.ano, data };
		});

		dispatch({
			type: rainType.RAIN_REC_SUCCESS,
			payload: newData
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: rainType.RAIN_REC_ERROR,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: rainType.RAIN_REC_ERROR,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};
