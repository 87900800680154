import axios from 'axios';

// Sistema
export const api = axios.create({
	baseURL: `${process.env.REACT_APP_BASE_URL}/v1`
});

// api de clima
export const apiClimate = 'https://api.openweathermap.org/data/2.5/onecall';

export const climateConfig = {
	key: '2ec2e4ec9611234249510017db835b95',
	exclude: 'hourly',
	language: 'pt_br'
};
