import styled from 'styled-components';
import { color } from 'styles/Theme';

export const container = styled.div`
	width: 100%;
	display: flex;
	gap: 0.5rem;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	//position: relative;
	overflow: hidden;
`;

export const boxInformation = styled.div`
	width: 100%;
	text-overflow: ellipsis;
	/* overflow: hidden; */
`;

export const title = styled.p`
	color: ${color.interface.orange};
	font-weight: 900;
	font-size: 0.9rem;
`;

export const subTitle = styled.p`
	color: ${color.interface.darkGray};
	font-weight: 900;
	font-size: 0.75rem;
	color: black;
`;

export const information = styled.p`
	color: ${color.interface.darkGray};
	width: 100%;
	font-size: 0.75rem;
	color: black;
`;

export const containerClose = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	top: 0;
	right: 0;
`;

export const buttonClose = styled.button`
	background-color: ${color.interface.orange};
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translate(100%, -100%);
	border-radius: 50%;
	cursor: pointer;

	img {
		width: 10px;
		height: 10px;
	}
`;

export const containerPdfButton = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	p {
		color: ${color.interface.orange};
	}
`;

export const buttonPdf = styled.button`
	display: flex;
	gap: 0.5rem;
	background-color: transparent;
	cursor: pointer;
`;
