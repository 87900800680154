import { dataUserToShowOnMap } from '../typesActions/types';

export const initialListAction = (data) => {
	return {
		type: dataUserToShowOnMap.ADD_LIST_DATA_USER_TO_ON_MAP,
		payload: data
	};
};

export const editListAction = (data) => {
	return {
		type: dataUserToShowOnMap.EDIT_LIST_DATA_USER_TO_ON_MAP,
		payload: data
	};
};

export const deleteListAction = () => {
	return { type: dataUserToShowOnMap.DELETE_LIST_DATA_USER_TO_ON_MAP };
};
