export const maxGridColumnCount = 12; // 12 column layout
export const colGutterSize = 5; // gap between 2 columns
export const gridMarginX = colGutterSize;
export const unit = 'px';
export const mediaOrder = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
export const mediaMinWidth = {
	xxs: 320,
	xs: 320,
	xsMax: 767,
	sm: 768,
	md: 992,
	lg: 1200,
	xl: 1440,
	xxl: 2560
};
export const containerMaxWidth = {
	xxs: 304,
	xs: 540,
	sm: 720,
	md: 960,
	lg: 1140,
	xl: 1350,
	xxl: 2420
};
