import React from 'react';
import { svg } from 'lib/svgList';
import * as S from './TabsRegister.Styled';

function Stepper({
	children,
	stepNumber,
	sendData,
	step,
	handleNextClick,
	handlePreviousClick
}) {
	const steps = React.Children.toArray(children);

	return (
		<form
			onSubmit={stepNumber === '1' ? handleNextClick : sendData}
			style={{ padding: '20px' }}
		>
			{steps.map((child, idx) =>
				React.cloneElement(child, { active: idx === step })
			)}

			<S.Actions>
				<S.Button
					type="button"
					style={{ display: step === 0 ? 'none' : 'flex' }}
					disabled={step === 0}
					onClick={handlePreviousClick}
				>
					<img alt="Logo" src={svg.icon.upOrange} />
					Voltar
				</S.Button>
				<S.Button
					type="submit"
					style={{ display: step === 1 ? 'none' : 'flex' }}
					disabled={step === steps.length - 1}
				>
					Próxima etapa
					<img alt="Logo" className="icon-next" src={svg.icon.upOrange} />
				</S.Button>
			</S.Actions>
		</form>
	);
}

export default Stepper;
