import { tiSelectedType } from 'store/typesActions/types';
import { api } from 'services/api';

export const saveTiSelected = (value) => {
	return { type: tiSelectedType.TI_SELECTED_SAVE, payload: value };
};

export const deleteTiSelected = () => {
	return { type: tiSelectedType.TI_SELECTED_DELETE };
};

export const reqInformation = () => {
	return { type: tiSelectedType.REQ_INFORMATION_TI_SELECTED };
};

export const errorInformation = () => {
	return { type: tiSelectedType.ERROR_INFORMATION_TI_SELECTED };
};

export const successGetInformation = (value) => {
	return {
		type: tiSelectedType.SUCCESS_INFORMATION_TI_SELECTED,
		payload: value
	};
};

export const deleteInformation = () => {
	return { type: tiSelectedType.DELETE_INFORMATION_TI_SELECTED };
};

export const getInformation = (code) => async (dispatch) => {
	dispatch(reqInformation());
	await api
		.get(`tiDatailInformation/${code}`)
		.then((res) => {
			dispatch(successGetInformation(res.data.ti));
		})
		.catch(() => {
			dispatch(errorInformation());
		});
};
