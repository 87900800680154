import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionAlerts from 'components/ActionAlerts';
import { closeMessageEdit } from 'store/actions/editDataUserOnMapAction';
import { closeErrorDestroy } from 'store/actions/monitoringDataAction';

function MessageMonitoring() {
	const dispatch = useDispatch();
	const {
		messageDestroy,
		messageSave,
		messageUpdate,
		messageApprove,
		messageIsRegister,
		messageDownload
	} = useSelector((state) => state.monitoring);

	const { messageIsEdit } = useSelector((state) => state.editDataUserOnMap);

	const handleClose = () => {
		dispatch(closeErrorDestroy());
		dispatch(closeMessageEdit());
	};

	const message =
		messageDestroy ||
		messageSave ||
		messageUpdate ||
		messageIsRegister ||
		messageIsEdit ||
		messageApprove ||
		messageDownload;
	useEffect(() => {
		if (message && message?.length > 0) {
			setTimeout(() => {
				dispatch(closeErrorDestroy());
				dispatch(closeMessageEdit());
			}, 6000);
		}
	}, [message]);

	return <ActionAlerts message={message} closeAction={handleClose} />;
}

export default MessageMonitoring;
