/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, memo, useRef } from 'react';
import { Vector } from 'ol/layer';
import Overlay from 'ol/Overlay';
import { useSelector } from 'react-redux';
import * as S from './Tooltip.style';
import TooltipContent from './content';

function TooltipGlobal({ mapSomai }) {
	const [itemSelect, setItemSelect] = useState([]);
	const [selectedFeature, setSelectedFeature] = useState();
	const [selectedLayer, setSelectedLayer] = useState();
	const [clickOnTheLayer, setClickOnTheLayer] = useState('');
	const [resetOverlay, setResetOverlay] = useState(false);
	const container = useRef();

	const { monitoraingFormActivated } = useSelector((state) => state.monitoring);

	useEffect(() => {
		const tooltip = new Overlay({
			element: container.current,
			autoPan: true,
			autoPanAnimation: {
				duration: 250
			}
		});

		mapSomai.addOverlay(tooltip);

		mapSomai.on('singleclick', (evt) => {
			// "monitoraingFormActivated" para verificar se está adicionando ou editando um Alerta
			// se tiver adicionando ou editando será "true" senão "false"
			if (evt.dragging || monitoraingFormActivated) return;

			container.current.style.display = 'block';
			tooltip.setPosition(null);

			const { pixel, coordinate } = evt;
			const feat = mapSomai.forEachFeatureAtPixel(pixel, (feature) => feature);
			if (!feat) return;

			// const layer = mapSomai.forEachLayerAtPixel(pixel, (islayer) => islayer);
			const layer = mapSomai.forEachFeatureAtPixel(
				pixel,
				(feature, islayer) => islayer
			);
			// Add cursor pointer
			const hit = mapSomai.hasFeatureAtPixel(pixel);

			const layerFeatureType = feat.get('type');
			const nameLayer = layer.getProperties().name;

			if (layerFeatureType || nameLayer) {
				setClickOnTheLayer(layerFeatureType || nameLayer);
			}

			mapSomai.getViewport().style.cursor = hit ? 'pointer' : '';

			if (feat && layer && layer instanceof Vector) {
				tooltip.setPosition(coordinate);
				const itemObject = feat.get('props');
				setItemSelect(itemObject);
				setSelectedFeature(feat);
				setSelectedLayer(layer);
			}
		});

		// Se o mouse estiver encima de uma camada que possui html o cursor fica como pointer
		mapSomai.on('pointermove', (evt) => {
			if (evt.dragging) {
				return;
			}
			const { pixel } = evt;
			const hit = mapSomai.hasFeatureAtPixel(pixel);

			mapSomai.getViewport().style.cursor = hit ? 'pointer' : '';
		});
	}, [monitoraingFormActivated]);

	useEffect(() => {
		if (resetOverlay) {
			setResetOverlay((prev) => !prev);
			container.current.style.display = 'none';
		}
	}, [resetOverlay]);

	return (
		<S.TooltipContainer ref={container} id="tooltip" className="ol-tooltip">
			<S.TooltipContent id="tooltip-content">
				<TooltipContent
					itemSelect={itemSelect}
					clickOnTheLayer={clickOnTheLayer}
					selectedLayer={selectedLayer}
					selectedFeature={selectedFeature}
					setResetOverlay={setResetOverlay}
					resetOverlay={resetOverlay}
				/>
			</S.TooltipContent>
		</S.TooltipContainer>
	);
}

export default memo(TooltipGlobal);
