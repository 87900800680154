import styled from 'styled-components';
import { color } from '../../styles/Theme';

export const containerCamadas = styled.div`
	width: 100%;
`;

export const containerGeral = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;
export const containerItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;

	width: 100%;
	background-color: ${color.interface.beige4}50;
	align-items: center;
	border-bottom: 1px solid ${color.interface.gray}50;

	p {
		padding-left: ${(props) => (props.isPadding ? '10px' : '0px')};
		color: ${color.interface.darkGray};
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 500;
		font-size: 0.875rem;
	}
`;

export const title = styled.p`
	color: ${color.interface.darkGray};
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 500;
	font-size: 0.875rem;
`;

export const boxIcons = styled.div`
	display: flex;
	justify-content: end;
	width: 100%;
	align-items: center;
	gap: 0 0.4rem;
`;

export const imgDoubt = styled.img`
	position: absolute;
	width: 0.5rem;
	right: 0;
	top: auto;
	bottom: auto;
	cursor: pointer;
`;

export const containerSlider = styled.div`
	width: 100%;
	margin-bottom: 0.5rem;
`;

export const erro = styled.p`
	color: ${color.interface.red};
	text-align: center;
`;

export const containerAccordionText = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding-top: 5px;

	h3 {
		width: 100%;
		font-size: 0.7rem;
		opacity: 0.7;
		border-bottom: 1px solid ${color.interface.lightGray2};
		padding-bottom: 0.2rem;
	}
`;

export const line = styled.div`
	min-width: 100%;
	height: 0.5px;
	background-color: ${color.interface.lightGray};
	margin-bottom: 10px;
`;

export const containerTitle = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding-top: 1rem;
	gap: 0.2rem;

	h4 {
		font-weight: 700;
		color: ${color.interface.orange};
		text-transform: uppercase;
		font-size: 0.75rem;
	}
`;
