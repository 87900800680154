import styled from 'styled-components';
import { color } from 'styles/Theme';

export const item = styled.div`
	width: 100%;
	display: contents;
	align-items: center;
	justify-content: space-evenly;
	gap: 0.5rem;
	/* background-color: aqua; */
`;

export const file = styled.label`
	display: flex;
	justify-content: center;

	input {
		display: none;
	}

	p {
		display: flex;
		/* position: fixed; */
		/* align-items: 'center'; */
		background-color: ${color.interface.brown};
		color: ${color.interface.white};
		border-radius: 5px;
		padding: 5px 10px 5px 10px;
		font-weight: 400;
		margin-right: 10px;
		cursor: pointer;
		/* z-index: 500; */
	}
	/* background-color: aqua; */
`;

export const errorPhoto = styled.p`
	display: flex;
	padding-top: 5px;
	width: 100%;
	justify-content: end;
	font-size: 0.75rem;
	color: ${color.interface.red};
`;
