import { threatsType } from 'store/typesActions/types';
import { api } from 'services/api';

// set qual ameaça foi selecionada
export const setThreatSelected = (checkRadios) => async (dispatch) => {
	dispatch({ type: threatsType.TIS_THREAT_SELECTED, checkRadios });
};

// obtém dados de uso e cobertura
export const getUseAndCoverageTis = () => async (dispatch) => {
	dispatch({ type: threatsType.TIS_USE_AND_COVERAGE_REQUEST });
	try {
		const res = await api.get(`/tis/use_and_coverage/prodes`);
		const { data } = res || {};
		dispatch({
			type: threatsType.TIS_USE_AND_COVERAGE_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		dispatch(onError(error, 'TIS_USE_AND_COVERAGE_FAILURE'));
	}
};

// obem dados de área queimada
export const getBurnedTis = () => async (dispatch) => {
	dispatch({ type: threatsType.TIS_BURNED_REQUEST });
	try {
		const res = await api.get(`/tis/burned`);
		const { data } = res || {};
		dispatch({
			type: threatsType.TIS_BURNED_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		dispatch(onError(error, 'TIS_BURNED_FAILURE'));
	}
};

// se ocorrer algum erro no acesso as apis
const onError = (error, type) => (dispatch) => {
	const { response } = error || {};
	const { data } = response || {};
	const { message } = data || {
		message: 'Erro desconhecido. Tente novamente mais tarde'
	};
	dispatch({
		type: threatsType[type],
		payload: {
			errors: {
				message
			}
		}
	});

	return message;
};
