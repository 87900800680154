import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { returnDataGraph, isEmptyObject } from 'helpers/utils';
import BarGraph from 'components/Graphs/BarGraph/BarGraph';
import { getDeforestationDeterSum } from 'store/actions/alertDeforestationDeterSumAction';

export default function DeforestationBarGraph() {
	const dispatch = useDispatch();
	const { deforestationDeterSum, isLoading: loadingDefBarGraph } = useSelector(
		(state) => state.defDeterSum
	);
	const { tiSelected } = useSelector((state) => state.tiSelectedTabTi);

	// DEFORESTATION STATES SAD SUM BY YEAR
	const [monthInside, setMonthInside] = useState([]);
	const [valueInside, setValueInside] = useState([]);
	const [monthOutside, setMonthOutside] = useState([]);
	const [valueOutside, setValueOutside] = useState([]);

	// useEffect para observar a tiSelected, ou seja fará o dispatch se houve ti selecionada
	useEffect(() => {
		if (!isEmptyObject(tiSelected)) {
			dispatch(getDeforestationDeterSum(tiSelected.value));
		}
	}, [tiSelected]);

	// ALERT DEFORESTATION SAD SUM BY YEAR -- BAR GRAPH
	useEffect(() => {
		if (
			deforestationDeterSum?.insideTi?.length > 0 ||
			deforestationDeterSum?.outsideTi?.length > 0
		) {
			setMonthInside(deforestationDeterSum.insideTi.map((item) => item.month));
			setValueInside(deforestationDeterSum.insideTi.map((item) => item.value));
			setMonthOutside(
				deforestationDeterSum.outsideTi.map((item) => item.month)
			);
			setValueOutside(
				deforestationDeterSum.outsideTi.map((item) => item.value)
			);
		}
	}, [deforestationDeterSum]);

	const dataDefSadSum = [
		{
			label: 'Dentro da TI',
			data: [...valueInside],
			backgroundColor: '#21543D'
		},
		{
			label: 'Fora da TI',
			data: [...valueOutside],
			backgroundColor: '#D44500'
		}
	];
	return (
		<BarGraph
			labelsProps={(monthInside, monthOutside)}
			datasetsProps={returnDataGraph(deforestationDeterSum, dataDefSadSum)}
			title="Dentro"
			isLoading={loadingDefBarGraph}
			useLegend={false}
			useLegendRound
		/>
	);
}
