/* eslint-disable react/no-unknown-property */
import React from 'react';
import IconButton from '@mui/material/IconButton';
import { actionsUsers } from 'helpers/utils';
import Grid from '@mui/material/Grid';
import { item } from './config';

const handleGA = ({ label }) => {
	actionsUsers({
		category: `plataforma/menuLateral/redesSociais`,
		action: 'Acesso a rede social',
		label
	});
};

export default function ButtonSocial() {
	return (
		<>
			{item.map((i) => (
				<a
					aria-hidden
					styling="link"
					key={i.href}
					href={i.href}
					onClick={() => handleGA({ label: i.name })}
					target="_blank"
					rel="noreferrer"
				>
					<Grid item>
						<IconButton edge="start">
							<img alt="Icon" src={i.urlIcon} style={{ width: 45 }} />
						</IconButton>
					</Grid>
				</a>
			))}
		</>
	);
}
