import React, { useEffect } from 'react';
import BarGraph from 'components/Graphs/BarGraph/BarGraph';
import { color } from 'styles/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { getAlerts } from 'store/actions/reportsForAdminAction';
import * as S from './styled';

export default function ReportAlerts() {
	const dispatch = useDispatch();
	const { loadingAlerts, listAlerts } = useSelector(
		(state) => state.reportsForAdmin
	);
	useEffect(() => dispatch(getAlerts()), []);

	const generalLabels = [
		'CAÇA ILEGAL',
		'DESMATAMENTO',
		'EXPLORAÇÃO ILEGAL DE MADEIRA',
		'FOGO',
		'GARIMPO ILEGAL',
		'INVASÃO EM TERRA INDÍGENA',
		'PESCA ILEGAL',
		'OUTROS'
	];

	const dataBar = [
		{
			label: `Total`,
			data: [
				listAlerts?.caca_ilegal || 0,
				listAlerts?.desmatamento || 0,
				listAlerts?.exploracao_ilegal_madeira || 0,
				listAlerts?.fogo || 0,
				listAlerts?.garimpo_ilegal || 0,
				listAlerts?.invasao_terra_indigena || 0,
				listAlerts?.pesca_ilegal || 0,
				listAlerts?.outros || 0
			],
			backgroundColor: color.auxiliary.bluePurple
		},
		{
			label: `Aprovados`,
			data: [
				listAlerts?.approved?.caca_ilegal || 0,
				listAlerts?.approved?.desmatamento || 0,
				listAlerts?.approved?.exploracao_ilegal_madeira || 0,
				listAlerts?.approved?.fogo || 0,
				listAlerts?.approved?.garimpo_ilegal || 0,
				listAlerts?.approved?.invasao_terra_indigena || 0,
				listAlerts?.approved?.pesca_ilegal || 0,
				listAlerts?.approved?.outros || 0
			],
			backgroundColor: color.interface.green
		},
		{
			label: `Não aprovados`,
			data: [
				listAlerts?.notApproved?.caca_ilegal || 0,
				listAlerts?.notApproved?.desmatamento || 0,
				listAlerts?.notApproved?.exploracao_ilegal_madeira || 0,
				listAlerts?.notApproved?.fogo || 0,
				listAlerts?.notApproved?.garimpo_ilegal || 0,
				listAlerts?.notApproved?.invasao_terra_indigena || 0,
				listAlerts?.notApproved?.pesca_ilegal || 0,
				listAlerts?.notApproved?.outros || 0
			],
			backgroundColor: color.interface.red
		},
		{
			label: `Em análise`,
			data: [
				listAlerts?.inAnalyze?.caca_ilegal || 0,
				listAlerts?.inAnalyze?.desmatamento || 0,
				listAlerts?.inAnalyze?.exploracao_ilegal_madeira || 0,
				listAlerts?.inAnalyze?.fogo || 0,
				listAlerts?.inAnalyze?.garimpo_ilegal || 0,
				listAlerts?.inAnalyze?.invasao_terra_indigena || 0,
				listAlerts?.inAnalyze?.pesca_ilegal || 0,
				listAlerts?.inAnalyze?.outros || 0
			],
			backgroundColor: color.interface.orange
		}
	];
	return (
		<S.ContainerGeral>
			{loadingAlerts ? (
				<S.Text>Carregando...</S.Text>
			) : (
				<BarGraph
					labelsProps={generalLabels}
					datasetsProps={dataBar}
					isMinWidth={600}
				/>
			)}
		</S.ContainerGeral>
	);
}
