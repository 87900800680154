import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerAccordionText = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding-top: 1rem;
	gap: 0.2rem;

	h4 {
		font-family: 'Nunito';
		font-weight: 700;
		color: ${color.interface.darkGray};
		text-transform: uppercase;
		font-size: 13px;
	}

	p {
		width: 100%;
		font-family: 'Nunito';
		font-weight: 200;
		color: ${color.interface.darkGray};
		text-align: left;
		font-size: 11px;
	}
`;

export const boxGraph = styled.div`
	margin-top: 1rem;
	width: 100%;
`;
