import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionAlerts from 'components/ActionAlerts';
import { closeErrorTile } from 'store/actions/layersAction/toggleLayersTabTi/toggleLayersTabTi';

/*
 * se ocorrer algum erro ao ativar uma camada
 * mostra a mensagem
 */

function LayerError() {
	const dispatch = useDispatch();
	const { loadingLayerError } = useSelector((state) => state.map);

	const handleClose = () => {
		dispatch(closeErrorTile());
	};

	useEffect(() => {
		if (loadingLayerError) {
			setTimeout(() => dispatch(closeErrorTile()), 3000);
		}
	}, [loadingLayerError]);

	return (
		loadingLayerError && (
			<ActionAlerts
				severity="error"
				message="Erro ao carregar a camada, tente novamente!"
				closeAction={handleClose}
			/>
		)
	);
}

export default LayerError;
