import styled from 'styled-components';
import { color } from 'styles/Theme';

export const container = styled.div`
	width: 95%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* background-color: bisque; */

	font-family: 'Nunito';
	gap: 0.5rem;
	font-size: 0.8rem;
`;

export const containerLoading = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
		color: ${color.interface.red};
	}
`;

export const graphContainer = styled.div`
	position: relative;
	align-self: flex-start;
	/* background-color: red; */
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	box-sizing: border-box;

	b {
		position: absolute;
		text-transform: uppercase;
		top: 0;
		left: 0;
		/* background-color: yellow; */
		width: 44%;
	}
`;

export const graph = styled.div`
	width: 50%;
	/* background-color: ${color.interface.lightGray}; */

	> p {
		font-size: 0.7rem;
		padding-top: 12px;
		display: flex;
		justify-content: center;
	}
`;

export const containerlegend = styled.div`
	position: absolute;
	bottom: 5%;
	left: 0;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	/* background-color: ${color.interface.lightGray}; */
`;

export const legend = styled.div`
	/* background-color: ${color.interface.lightGray}; */
	display: flex;
	align-items: center;
	gap: 0.5rem;
	p {
		opacity: 0.8;
		font-size: 0.7rem;
	}
`;

export const legendOrange = styled.div`
	min-width: 10px;
	max-width: 10px;
	min-height: 10px;
	max-height: 10px;
	border-radius: 50%;
	background-color: ${color.interface.orange};
`;

export const legendGreen = styled.div`
	min-width: 10px;
	max-width: 10px;
	min-height: 10px;
	max-height: 10px;
	border-radius: 50%;
	background-color: ${color.interface.green};
`;

export const line = styled.div`
	width: 100%;
	min-height: 1.5px;
	max-height: 1.5px;
	background-color: ${color.interface.lightGray};
`;

export const notice = styled.p`
	color: ${color.interface.red};
`;
