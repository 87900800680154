import React from 'react';
import Modal from 'components/Modal';
import { color } from 'styles/Theme';
import * as S from './LoadingDotsModal.Styled';

const randomIntFromInterval = (min, max) =>
	Math.floor(Math.random() * (max - min + 1) + min);

const RenderLoadingBars = ({ delay, isColor }) => {
	const bars = [];
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < 1; i++) {
		bars.push(
			<S.Dot
				key={i}
				delay={delay}
				color={isColor}
				style={{
					height: randomIntFromInterval(110, 80)
				}}
			/>
		);
	}
	return bars;
};

export default function LoadingDotsModal({ isLoading }) {
	return (
		<Modal
			styleContent={{ width: 300, height: 220 }}
			isOpen={isLoading}
			useBtnEnd={false}
			btnClose={false}
		>
			<S.DotWrapper>
				<RenderLoadingBars delay=".6s" isColor={color.interface.green} />
				<RenderLoadingBars delay=".1s" isColor={color.interface.orange} />
				<RenderLoadingBars delay=".3s" isColor={color.interface.green} />
				<RenderLoadingBars delay=".4s" isColor={color.interface.orange} />
				<RenderLoadingBars delay=".5s" isColor={color.interface.green} />
			</S.DotWrapper>
			<h4>Aguarde...</h4>
		</Modal>
	);
}
