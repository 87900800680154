import styled from 'styled-components';

export const containerShowDataListAccordion = styled.div`
	width: 100%;
`;

export const conatinerInsideAccordion = styled.div`
	width: 100%;
	align-self: flex-end;
	/* background-color: azure; */
`;
