import { deforestationSadSumType } from 'store/typesActions/types';
import { api } from 'services/api';


export const getDeforestationSadSum = (code) => async (dispatch) => {
    dispatch({ type: deforestationSadSumType.DEFORESTATION_SAD_SUM});
    try {
        const res = await api.get(`/ti/${code}/alerts_deforestation_sad_sum_by_month_last_year/`);
        const { data } = res || {};
        dispatch({
            type: deforestationSadSumType.DEFORESTATION_SAD_SUM_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: deforestationSadSumType.DEFORESTATION_SAD_SUM_ERROR,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: deforestationSadSumType.DEFORESTATION_SAD_SUM_ERROR,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
    }
};