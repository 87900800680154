import StripedList from 'components/StripedList';
import React, { useEffect, useState } from 'react';
import { svg } from 'lib/svgList';
import { useSelector } from 'react-redux';
import { converDateAndTimeFromBD } from 'helpers/utils';

import * as S from './styled';

export default function ShowDataOnlyList() {
	const { dataMonitoring } = useSelector((state) => state.monitoring);
	const [dataList, setDataList] = useState([]);

	function addInformationOnItemList() {
		const newList = dataMonitoring.map((item) => ({
			...item,
			icon: svg.icon.locationRound,
			information: converDateAndTimeFromBD(item.created_at, true)
		}));
		return newList;
	}

	useEffect(() => {
		if (typeof dataMonitoring === 'object' && dataMonitoring.length > 0) {
			setDataList(addInformationOnItemList(dataMonitoring));
		}

		return () => {
			setDataList([]);
		};
	}, [dataMonitoring]);

	return (
		<S.containerShowDataOnlyList>
			<StripedList list={dataList} />
		</S.containerShowDataOnlyList>
	);
}
