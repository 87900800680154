/* eslint-disable react/no-unknown-property */
/* eslint no-plusplus: "error" */
/* eslint no-cond-assign: 2 */
import React, { useState, useEffect } from 'react';
import { useOutsideClick } from '@chakra-ui/react';
import Load from 'components/Load/Load';
import { useDispatch } from 'react-redux';
import { deleteTiSelected } from 'store/actions/tiSelectedAction';
import { svg } from 'lib/svgList';
import * as S from './styled';

// A LISTA DE ITEM DEVE SER PASSADO COMO UM ARRAY DE OBJETOS ONDE O PRIMEIRO PARAMETRO DO OBJETO SEJA ID E OUTRO UM VALOR QUALQUER(EX: NOME);
// NÃO IMPORTA O NOME DO PRIMEIRO PARAMETRO OU DO SEGUNDO. EX: {CODIGO: 5, NOME: 'LUCAS' }
// CASO SUA LISTA DE ITEMS SEJA DE TIS, USE A FUNÇÃO FORMTTIS DENTRO DE HELPERS.

// itemsList: Passe nessa props a lista
// setItemSelected: sua função que controla o estado que foi selecionado
// placeholder: Nome que vai pro placeholder do input (Opcional)
// isLoading: caso sua lista venha de uma requisição, passe se está ou nao fazendo requisição aqui. Caso seja uma lista estática, só passe o valor para false
// styleInput: estilo do input (Opcional)
// styleList: estilo da caixa com a lista (Opcional)
// para usar o clearKeyWord veja o exepmlo em src/pages/tabTis/select/index
// autoClear: Se o autoClear estiver true, apos o tempo passado no timeClear, o search é limpo

export default function Search({
	itemsList = [],
	setItemSelected = () => {},
	placeholder = 'Buscar...',
	isLoading = false,
	styleInput = {},
	styleList = {},
	isDisable = false,
	clearKeyWord = false,
	showMagnifying = true,
	autoClear = false,
	timeClear = 1000
}) {
	const ref = React.useRef();
	const [keyWord, setKeyWord] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [copyItemsList, setCopyItemsList] = useState([]);
	const [filteredListTi, setFilteredListTi] = useState([]);
	const dispatch = useDispatch();

	const regexSearch = (str) => {
		let name;
		let found;
		const regex = /[a-z]/gi; // regex que pega somentes letras de A a Z caixa alta e baixa

		while ((found = regex.exec(str))) {
			name += found;
		}

		if (typeof name === 'string') {
			return name.replace('undefined', '');
		}
		return str;
	};

	const nameNoSpace = (name) => {
		let nameNoAccent = '';
		const nameArray = name.split(' ');

		for (let i = 0; i < nameArray.length; i += 1) {
			nameNoAccent = `${nameNoAccent}${nameArray[i]}`;
		}

		// função abaixo tira acentos do nome
		nameNoAccent = nameNoAccent.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

		// função que tira todos caracteres especiais
		const finalName = regexSearch(nameNoAccent);

		return finalName;
	};

	function returnObjEdited(list) {
		const newListFormated = list.map((item) => {
			const value = Object.keys(item)[0];
			const label = Object.keys(item)[1];
			return {
				value: item[value],
				label: item[label]
			};
		});
		return newListFormated;
	}

	function filterData() {
		if (keyWord !== '' && copyItemsList.length > 0) {
			const listFiltered = copyItemsList.filter((item) => {
				const itemFormated = nameNoSpace(item.label.toLowerCase());
				const keyWordFormated = nameNoSpace(keyWord.toLowerCase());
				return itemFormated.includes(keyWordFormated);
			});
			return listFiltered;
		}
		return copyItemsList;
	}

	function returnList() {
		if (filteredListTi.length !== 0) {
			return (
				filteredListTi &&
				filteredListTi.map((item) => (
					<S.itemList key={item.value}>
						<button
							type="button"
							onClick={() => {
								setItemSelected(item);
								setIsOpen(false);
								setKeyWord(item.label);
								if (autoClear) {
									setTimeout(() => {
										document.getElementById('searchComponent').value = '';
									}, timeClear);
								}
							}}
							onKeyDown={() => {
								setItemSelected(item);
								setIsOpen(false);
								setKeyWord(item.label);
							}}
						>
							{item.label}
						</button>
					</S.itemList>
				))
			);
		}
		return <p key="teste">Não há itens </p>;
	}

	useEffect(() => {
		if (copyItemsList.length === 0) {
			setFilteredListTi(copyItemsList);
		} else {
			setFilteredListTi(filterData());
		}
	}, [keyWord]);

	useEffect(() => {
		setFilteredListTi(copyItemsList);
	}, [copyItemsList]);

	// É preciso fazer uma copia da lista atual, para a partir dessa copia, alterarmos as chaves originais dos objetos que chegam. Dessa forma
	// vc pode mandar um array de objetos com objetos que possuam qualquer nome.

	// CASO DE ERRO EM ALGUM COMPONENTE, FOI ESSA LINHA DO COPY Q COMENTEI
	useEffect(() => {
		if (
			itemsList.length > 0 &&
			// copyItemsList.length === 0 &&
			typeof itemsList !== 'string'
		) {
			setCopyItemsList(returnObjEdited(itemsList));
		}
	}, [itemsList]);

	// responsável por limpar o redux da ti selecionada, quando o valor do input é igual a uma string vazia
	useEffect(() => {
		if (keyWord === '' && filteredListTi.length !== 0) {
			dispatch(deleteTiSelected());
		}
	}, [keyWord]);

	// tem a função deletar a ti selecionada quando o componente for destruido (ex: trocar de tab para amazonia ou painel)
	useEffect(
		() => () => {
			dispatch(deleteTiSelected());
		},
		[]
	);

	// função chakra para fechar modal quando cliclado fora dela
	useOutsideClick({
		ref,
		handler: () => setIsOpen(false)
	});

	useEffect(() => {
		if (clearKeyWord) {
			setKeyWord('');
		}
	}, [clearKeyWord]);

	return (
		<S.ContainerSearch
			ref={ref}
			imgSearch={showMagnifying ? svg.icon.searchBold : svg.icon.close}
			showMagnifying={showMagnifying}
		>
			<input
				id="searchComponent"
				type="text"
				placeholder={placeholder}
				value={keyWord}
				onChange={(event) => setKeyWord(event.target.value)}
				handlsearch={filterData({ keyWord })}
				onFocus={() => setIsOpen(true)}
				style={styleInput}
				disabled={isDisable}
				autoComplete="off"
			/>
			{isOpen ? <S.ListTi style={styleList}>{isLoading ? <Load /> : returnList()}</S.ListTi> : null}
		</S.ContainerSearch>
	);
}
