/* eslint-disable no-unused-vars */
import React, { useEffect, useState, memo } from 'react';
import Overlay from 'ol/Overlay';
import { useSelector } from 'react-redux';
import ThreatsDonoutGraph from 'pages/TabTerritory/Statistics/Graphs/ThreatsGraph/ThreatsDonoutGraph';
import * as S from './styled';

function TooltipThreats({ mapSomai }) {
	const { checkRadios } = useSelector((state) => state.threatsTis);

	const isLoadMap = useSelector((state) => state.isLoadMap);

	const [content, setContent] = useState('');

	const renderInfosDeforestation = (feat) => {
		const propsDeforestation = feat.get('propsDeforestation');

		const { current_vegetation: vegetation, deforested } = propsDeforestation;

		return {
			labels: ['Conservado', 'Desmatado'],
			data: [vegetation, deforested],
			title: 'Desmatamento e vegetação conservada',
			source: 'PRODES'
		};
	};

	const renderInfosBurned = (feat) => {
		const propsBurned = feat.get('propsBurned');

		const { unburned, burned } = propsBurned;

		return {
			labels: ['Não queimou', 'Queimou'],
			data: [unburned, burned],
			title: 'Área queimada e não queimada',
			source: 'Mapbiomas'
		};
	};

	useEffect(() => {
		// cria o tooltip/overlay
		const tooltip = new Overlay({
			element: document.getElementById('tooltip-threats'),
			positioning: 'bottom-center',
			offset: [0, 10],
			autoPan: true,
			autoPanAnimation: {
				duration: 250
			}
		});
		mapSomai.addOverlay(tooltip);

		// Se o mouse estiver encima de uma camada que possui html o cursor fica como pointer
		mapSomai.on('pointermove', (event) => {
			const features = mapSomai.getFeaturesAtPixel(event.pixel);

			if (features.length > 0) {
				const [feature] = features || [];
				const type = feature?.get('type');

				if (type === 'tisThreats') {
					const props = feature.get('props');
					const { name } = props;

					const { desmatamento, fogo } = checkRadios || {};

					const infos = desmatamento
						? renderInfosDeforestation(feature)
						: fogo
						? renderInfosBurned(feature)
						: { labels: [], data: [], title: '' };

					const newContent = (
						<>
							<S.Title>Terra indígena: {name}</S.Title>
							<S.Row>
								<ThreatsDonoutGraph
									labels={infos.labels}
									data={infos.data}
									title={infos.title}
									source={infos.source}
								/>
							</S.Row>
						</>
					);

					setContent(newContent);

					tooltip.setPosition(event.coordinate);
				}
			} else {
				setContent('');
				tooltip.setPosition(undefined);
			}
		});
	}, [isLoadMap]);

	return (
		<S.TooltipContainer id="tooltip-threats" className="ol-tooltip">
			{content}
		</S.TooltipContainer>
	);
}

export default memo(TooltipThreats);
