import React from 'react';
import { svg } from 'lib/svgList';
import { actionsUsers } from 'helpers/utils';
import { stateViewLegend } from 'store/actions/systemActions';
import { useDispatch, useSelector } from 'react-redux';
import * as S from '../ControlButton/styles';

function CamadaLayer() {
	const { statusLegend } = useSelector((state) => state.system);

	const actionButton = !statusLegend ? 'abrir' : 'fechar';

	const dispatch = useDispatch();

	const HandleControl = () => {
		actionsUsers({
			category: `plataforma/mapa/webgis`,
			action: `Click no botão ${actionButton}`,
			label: 'Legenda das Camadas'
		});
		dispatch(stateViewLegend(!statusLegend));
	};

	const { activatedLayers } = useSelector((state) => state.map);
	if (!activatedLayers.length > 0) return null;
	const desc = 'Legenda das camadas';
	return (
		<S.Container
			{...{ statusLegend }}
			className="sm__tooltip"
			data-title={desc}
		>
			<button
				className={statusLegend ? 'active' : ''}
				type="button"
				onClick={HandleControl}
			>
				<img alt="close" src={svg.icon.legend} />
			</button>
		</S.Container>
	);
}

export default CamadaLayer;
