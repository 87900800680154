import { mapLoad } from '../typesActions/types';

export default function mapLoadedReducer(state = false, action) {
	switch (action.type) {
		case mapLoad.MAP_LOADED:
			return (state = true);

		default:
			return state;
	}
}
