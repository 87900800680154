import React from 'react';
import { svg } from 'lib/svgList';
import useWindowSize from 'hooks/getWindowSize';
import * as S from './DrawerLeft.Styled';
import SubNav from './SubNav';

function DrawerLeft({ open, setOpen, content }) {
	const { height } = useWindowSize();
	return (
		<S.StyledDrawer props={{ open, height }}>
			<S.ButtonArrow open={open} onClick={() => setOpen(!open)}>
				<img alt="arrow" src={svg.icon.carouselUpWhite} />
			</S.ButtonArrow>
			<SubNav open={open} />
			<div style={{ display: !open ? 'none' : 'initial' }}>{content}</div>
		</S.StyledDrawer>
	);
}

export default DrawerLeft;
