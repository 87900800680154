import styled from 'styled-components';
import { color } from '../../styles/Theme';

export const accordionContainer = styled.div`
	width: 100%;
	margin-bottom: 1.4rem;
`;

export const BoxTitle = styled.div`
	width: 100%;
	display: flex;

	align-items: center;
	justify-content: space-between;

	/* padding: 0.6rem 0rem 0rem 0rem; */
	background-color: transparent;
	p {
		font-size: 13.5px;
		font-weight: 400;
		font-weight: 700;
		color: ${color.interface.darkGray};
	}
`;

export const BackButton = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: center;
	margin-right: 6px;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 9px;
		font-style: normal;
		font-weight: 800;
		font-size: 10px;

		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: ${color.interface.orange};
		width: 110px;
		height: 18px;
		background-color: ${color.interface.whiteAbsolute};
		cursor: pointer;
	}
`;

export const imageBtnToggleOpenClose = styled.img`
	width: 11px;
	transition: all 0.5s ease;
	cursor: pointer;
`;

export const conteinerImage = styled.div`
	width: 20px;
`;
export const imgAccordion = styled.img`
	width: ${(props) => `${props.widthIcon}rem`};
	height: ${(props) => `${props.widthIcon}rem`};
`;

export const TitleAndImg = styled.div`
	display: flex;
	gap: 0.4rem;
	align-items: center;
	justify-content: center;

	> p {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 800;
		font-size: 0.625rem;

		/* identical to box height */

		letter-spacing: 0.1em;
		text-transform: uppercase;

		text-transform: uppercase;
		color: ${color.interface.orange};
	}
`;

export const line = styled.div`
	min-width: 100%;
	height: 0.5px;
	background-color: ${color.interface.lightGray};
`;

export const contentAccordion = styled.div`
	margin-bottom: 0.5rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const containerList = styled.div`
	padding: 0px 5px 0px 0px;
	padding-bottom: 0.5rem;
	width: 100%;
	background-color: ${color.interface.beige3};

	margin-top: 0.5rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
