import { deforestationThreatBufferType } from '../typesActions/types';
import { api } from 'services/api';

export const reqDeforestation = () => {
	return {
		type: deforestationThreatBufferType.REQ_DEFORASTATION_THREAT_BUFFER
	};
};

export const successDeforestation = (data) => {
	return {
		type: deforestationThreatBufferType.SUCCESS_DEFORASTATION_THREAT_BUFFER,
		payload: data
	};
};

export const errorDeforestation = () => {
	return {
		type: deforestationThreatBufferType.ERROR_DEFORASTATION_THREAT_BUFFER
	};
};

export const deleteDeforestation = () => {
	return {
		type: deforestationThreatBufferType.DELETE_DEFORASTATION_THREAT_BUFFER
	};
};

export const getDeforestation = (code) => async (dispatch) => {
	dispatch(reqDeforestation());
	await api
		.get(`ti/${code}/deforestation_inside_and_buffer_ti_prodes`)
		.then((res) => {
			dispatch(successDeforestation(res.data));
		})
		.catch(() => {
			dispatch(errorDeforestation());
		});
};
