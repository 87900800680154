import { allLayersMap } from 'store/typesActions/types';
import { api } from 'services/api';

export const reqToGetLinksLayersMap = () => async (dispatch) => {
	dispatch({ type: allLayersMap.REQ_All_LAYERS_MAP });
	await api
		.get('layersPublic')
		.then((res) => {
			const { data } = res;
			dispatch({ type: allLayersMap.SUCCESS_All_LAYERS_MAP, payload: data });
		})
		.catch(() => {
			dispatch({ type: allLayersMap.FAIL_GET_All_LAYERS_MAP });
		});
};

export const toggleTisMap = () => (dispatch) => {
	dispatch({ type: allLayersMap.TOGGLE_TIS });
};
