import styled from 'styled-components';
import { color } from 'styles';

export const Title = styled.h4`
	letter-spacing: 0;
	text-align: left;
	color: ${color.interface.orange};
	font-size: 1.3rem;
	margin-bottom: 3px;
`;

export const ContainerItem = styled.div`
	display: flex;
	justify-content: initial;
	width: 100%;
	align-items: inherit;
	padding-top: 10px;
	> p {
		margin-left: 10px;
		text-align: justify;
		width: 90%;
	}
`;

export const Color = styled.div`
	background-color: ${(props) => props.color};
	width: 22px;
	height: 22px;
	border-radius: 2px;
`;
