import React from 'react';
import * as S from './styled';

export default function CheckAnimation() {
	return (
		<S.ContainerCheckAnimation>
			<S.CircleCheck>
				<S.leg1 />
				<S.leg2 />
			</S.CircleCheck>
		</S.ContainerCheckAnimation>
	);
}
