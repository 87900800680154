import { systemType } from '../typesActions/types';

export const getCoordsOnMap = (data) => {
	return {
		type: systemType.GET_COORDS_ON_MAP,
		payload: data
	};
};

export const getGeomOnMap = (data) => {
	return {
		type: systemType.GET_GEOM_ON_MAP,
		payload: data
	};
};

export const stateViewLegend = (data) => {
	return {
		type: systemType.VIEW_LEGEND,
		payload: data
	};
};
