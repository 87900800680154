import React from 'react';
import { svg } from 'lib/svgList';
import ItensMenu from 'components/ItensMenu';
import { useSelector } from 'react-redux';
import TitleTab from 'components/TitleTab';
import OptionsMonitoring from 'components/OptionsMonitoring';
import * as S from './styled';

export default function Monitoring() {
	const { listUsers } = useSelector((state) => state.user);

	const data = [
		{
			id: 1,
			title: 'Monitoramento',
			subTitle: 'Meus Alertas',
			icon: svg.icon.warningOrange,
			content: <OptionsMonitoring type="alert" />
		},
		{
			id: 2,
			title: 'Monitoramento',
			subTitle: 'Meus Usos Tradicionais',
			icon: svg.icon.mapLocationOrange,
			content: <OptionsMonitoring type="traditionalUse" />
		},
		{
			id: 3,
			title: 'Monitoramento',
			subTitle: 'Meus Trajetos',
			icon: svg.icon.routeOrange,
			content: <OptionsMonitoring type="tracker" />
		},
		{
			id: 4,
			title: 'Monitoramento',
			subTitle: 'Onde estive / Localizações',
			icon: svg.icon.locationRoundOrange,
			content: <OptionsMonitoring type="whereIBeen" />
		}
	];

	return (
		<S.containerGeralMonitoring isUser={listUsers && listUsers.length > 0}>
			<S.lineGray />
			<S.containerList>
				<TitleTab title="Monitoramento" />
				{data.map((item) => (
					<ItensMenu
						key={item.id}
						title={item.title}
						subTitle={item.subTitle}
						icon={item.icon}
						content={item.content}
					/>
				))}
			</S.containerList>
		</S.containerGeralMonitoring>
	);
}
