import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { returnDataGraph, isEmptyObject } from 'helpers/utils';
import LineGraph from 'components/Graphs/LineGraph/LineGraph';
import { fireFocusByYear } from 'store/actions/fireFocusByYearAction ';

export default function FireYearLineGraph() {
	const dispatch = useDispatch();
	const { fireYear, isLoading: loadingFireYearLineGraph } = useSelector(
		(state) => state.fireFocusByYear
	);
	const { tiSelected } = useSelector((state) => state.tiSelectedTabTi);

	const [countYearInsideTi, setCountYearInsideTi] = useState([]);
	const [yearYearInsideTi, setYearYearInsideTi] = useState([]);
	const [countYearOutsideTi, setCountYearOutsideTi] = useState([]);
	const [yearYearOutsideTi, setYearYearOutsideTi] = useState([]);

	// useEffect para observar a tiSelected, ou seja fará o dispatch se houve ti selecionada
	useEffect(() => {
		if (!isEmptyObject(tiSelected)) {
			dispatch(fireFocusByYear(tiSelected.value));
		}
	}, [tiSelected]);

	// FIRE FOCUS BY YEAR -- LINE GRAPH
	useEffect(() => {
		if (fireYear?.insideTi?.length > 0 || fireYear?.outsideTi?.length > 0) {
			setYearYearInsideTi(fireYear.insideTi.map((item) => item.year));
			setCountYearInsideTi(fireYear.insideTi.map((item) => item.count));
			setYearYearOutsideTi(fireYear.outsideTi.map((item) => item.year));
			setCountYearOutsideTi(fireYear.outsideTi.map((item) => item.count));
		}
	}, [fireYear]);

	const fireYearInside = yearYearInsideTi;
	const fireYearOutside = yearYearOutsideTi;

	const lineDataYear = [
		{
			label: 'DENTRO DA TI',
			data: countYearInsideTi,
			borderColor: '#21543D',
			backgroundColor: '#21543D'
		},
		{
			label: 'FORA DA TI',
			data: countYearOutsideTi,
			borderColor: '#D44500',
			backgroundColor: '#D44500'
		}
	];

	return (
		<LineGraph
			labelsProps={(fireYearInside, fireYearOutside)}
			datasetsProps={returnDataGraph(fireYear, lineDataYear)}
			isLoading={loadingFireYearLineGraph}
			useLegend={false}
			useLegendRound
		/>
	);
}
