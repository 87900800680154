/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/Buttons/Select/Select';
import {
	createFormData,
	listTypeRegister,
	isRouteEditMonitoring,
	isLatitude,
	isLongitude,
	isCoordsInvalid,
	convertLatLong,
	getDMS
} from 'helpers/utils';
// import { getCoordsOnMap } from 'store/actions/systemActions';
// import MyLocationIcon from '@mui/icons-material/MyLocation';
import BalloonDoubtsGlobal from 'components/BalloonDoubtsGlobal';
import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';
import AudioRecorder from 'components/AudioRecorder';
import { color } from 'styles/Theme';
import Load from 'components/Load/Load';
import { olMap } from 'components/Map/ControlMap';
import { clearVector, onLoadMonitoring } from 'store/actions/mapActions';
import { finishEdit } from 'store/actions/editDataUserOnMapAction';
import ImageUploading from 'components/ImageUploading';
import { editDataMonitoring } from 'store/actions/monitoringDataAction';
import { useAlertTraditionlUseContext } from 'services/map/alert.context';
import { transform } from 'ol/proj';

import * as S from './styled';

export default function EditAlertTradicionalTrackEndWhereIBeen({ type = '' }) {
	const dispatch = useDispatch();

	const { dataToEdit } = useSelector((state) => state.editDataUserOnMap);
	const { routeMonitoring, loadingUpdate } = useSelector(
		(state) => state.monitoring
	);
	const { dataUser } = useSelector((state) => state.dataUserToShowOnMap);
	const { coordinates } = useSelector((state) => state.system);

	// armazena a lista de alertas ou usos tradicionais no select
	const [listType, setListType] = useState([]);

	// Estados que são inseridos no formulário
	const [id, setId] = useState(0);
	const [typeSelected, setTypeSelected] = useState({});
	const [nameWhereIBeen, setNameWhereIBeen] = useState('');
	const [description, setDescription] = useState({});
	const [audioRecorder, setAudioRecorder] = useState(null);
	const { lat, setLat, long, setLong } = useAlertTraditionlUseContext();
	const [gms, setGms] = useState('');
	const [isOnchangeGms, setisOnchangeGms] = useState(false);
	const [extent, setExtent] = useState(false);
	/* const [lat, setLat] = useState('');
	const [long, setLong] = useState(''); */
	const [photo, setPhoto] = useState(null);

	// Estados que controlam os erros
	const [locationEmpty, setLocationEmpty] = useState(false);
	const [nameWhereIBeenEmpty, setNameWhereIBeenEmpty] = useState(false);
	const [typeSelectedEmpty, setTypeSelectedEmpty] = useState(false);

	const removeToFormData =
		routeMonitoring === 'tracks' || routeMonitoring === 'where_i_been';

	useEffect(() => {
		setLat(coordinates?.latitude || '');
		setLong(coordinates?.longitude || '');
	}, [coordinates]);

	useEffect(() => {
		if (olMap()) {
			const vectorSource = olMap()
				?.getLayers()
				.getArray()
				.find((layer) => layer.get('name') === 'monitoring')
				.getSource();

			const pointFeature = vectorSource?.getFeatures()[0];
			// define the source and target projections
			const sourceProjection = 'EPSG:4326'; // WGS84
			const targetProjection = 'EPSG:3857'; // Web Mercator
			if (pointFeature) {
				const transformedCoordinates = transform(
					[long, lat],
					sourceProjection,
					targetProjection
				);

				pointFeature.getGeometry().setCoordinates(transformedCoordinates);

				const extentIt = pointFeature.getGeometry().getExtent();
				if (extent) {
					olMap()
						.getView()
						.fit(extentIt, { padding: [50, 50, 50, 50] });
					setExtent(false);
				}
			}
		}
	}, [long, lat, olMap(), extent]);

	function saveAlert(event) {
		event.preventDefault();
		const date = new Date();

		if (typeSelected === '' && type !== 'whereIBeen') {
			setTypeSelectedEmpty(true);
			return;
		}
		setTypeSelectedEmpty(false);

		if (
			nameWhereIBeen === '' &&
			(type === 'whereIBeen' || type === 'tracker')
		) {
			setNameWhereIBeenEmpty(true);
			return;
		}
		setNameWhereIBeenEmpty(false);

		if (
			routeMonitoring !== 'tracks' &&
			(!isLatitude(lat) || !isLongitude(long))
		) {
			setLocationEmpty(true);
			return;
		}
		setLocationEmpty(false);

		// OBJETO COM OS DADOS DE REGISTRO DE ALERTA
		const objToSave = {
			controllerWebFile: true, // estado para o controle de upload de file web
			id,
			name: typeSelected.label
				? typeSelected?.label.toUpperCase()
				: nameWhereIBeen,
			type: typeSelected.value,
			description,
			latitude: lat,
			longitude: long,
			updated_at: date.toString(),
			audio: audioRecorder && audioRecorder?.blob,
			image: photo && photo
		};

		if (removeToFormData) {
			delete objToSave.description;
			delete objToSave.type;
			delete objToSave.audio;
			delete objToSave.image;
		}

		if (routeMonitoring === 'tracks') {
			delete objToSave.latitude;
			delete objToSave.longitude;
		}

		dispatch(
			editDataMonitoring({
				params: removeToFormData ? objToSave : createFormData(objToSave),
				route: isRouteEditMonitoring(routeMonitoring),
				typeAlert: routeMonitoring
			})
		);

		// essas funções devem ser executadas se a requisição der certo
		dispatch(finishEdit());
		clearVector('monitoring');
		setTimeout(() => {
			dispatch(onLoadMonitoring(dataUser));
		}, 500);
	}

	// popula no formulário dados que ja existem no alerta que vai ser editado
	useEffect(() => {
		setId(dataToEdit.id);
		setLat(dataToEdit.latitude);
		setLong(dataToEdit.longitude);
		setDescription(dataToEdit.description);
		setNameWhereIBeen(dataToEdit.name);
	}, [dataToEdit]);

	useEffect(() => {
		if (listType.length > 0) {
			const filterType = listType.filter((item) => item.id === dataToEdit.type);
			const newObj = { value: filterType[0].id, label: filterType[0].nome };
			setTypeSelected(newObj);
		}
	}, [listType]);

	// responsável por carregar a lista dos tipos do alerta ou uso tradicional que será cadastrado
	useEffect(() => {
		listTypeRegister(type, setListType);
	}, [type]);

	const content = [
		{
			id: 0,
			p: '- Clique no ponto azul no centro do icone e arraste para alterar a localização'
		},
		{
			id: 2,
			p: '- Digitando as coordenadas geográficas.'
		}
	];

	const latDMS = getDMS(lat, 'lat');
	const lonDMS = getDMS(long, 'long');
	useEffect(() => {
		setGms(`${latDMS} ${lonDMS}`);
	}, []);
	useEffect(() => {
		if (latDMS || (lonDMS && !isOnchangeGms)) setGms(`${latDMS} ${lonDMS}`);
	}, [latDMS, lonDMS]);

	const onchangeGms = (e) => {
		try {
			const result = convertLatLong(e.target.value);

			if (result && result.length >= 2) {
				const latitude = result[0].ltCoord.toFixed(6);
				const longitude = result[1].longCoord.toFixed(6);
				setisOnchangeGms(true);
				setExtent(true);
				setLat(latitude);
				setLong(longitude);
				setGms(e.target.value);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const onchangeCoord = (e) => {
		if (e.target.name === 'long') {
			setExtent(true);
			setisOnchangeGms(false);
			setLong(e.target.value);
		} else {
			setExtent(true);
			setisOnchangeGms(false);
			setLat(e.target.value);
		}
	};

	if (loadingUpdate) return <Load />;

	return (
		<form style={{ paddingBottom: '20rem' }} onSubmit={saveAlert}>
			<S.containerAdd>
				{type !== 'whereIBeen' && type !== 'tracker' ? (
					<S.itemForm>
						<p>Tipo do {type === 'alert' ? 'alerta' : 'uso tradional'}:</p>
						<Select
							style={styles.select}
							options={listType}
							itemSelected={typeSelected}
							setItemSelected={setTypeSelected}
						/>
						{typeSelectedEmpty ? (
							<S.errorInputEmpity>
								*Favor preencher o tipo do registro.
							</S.errorInputEmpity>
						) : null}
					</S.itemForm>
				) : null}
				{type === 'whereIBeen' || type === 'tracker' ? (
					<S.itemForm>
						<p>Nome do local:</p>
						<CustomInput
							styleInput={styles.input}
							value={nameWhereIBeen}
							setValue={setNameWhereIBeen}
							minLength={1}
							maxLength={35}
						/>
						{nameWhereIBeenEmpty ? (
							<S.errorInputEmpity>
								*Favor preencher o nome do local
							</S.errorInputEmpity>
						) : null}
					</S.itemForm>
				) : null}

				{type !== 'whereIBeen' && type !== 'tracker' ? (
					<S.itemForm>
						<p>Descrição do registro:</p>
						<CustomTextArea
							type="text"
							styleInput={styles.input}
							value={description}
							setValue={setDescription}
						/>
					</S.itemForm>
				) : null}

				{type !== 'whereIBeen' && type !== 'tracker' ? (
					<S.itemForm>
						<p>Gravar áudio:</p>
						<AudioRecorder
							isAudio={dataToEdit?.audio_url || ''}
							audioRecorder={audioRecorder}
							setAudioRecorder={setAudioRecorder}
						/>
					</S.itemForm>
				) : null}

				{routeMonitoring !== 'tracks' ? (
					<>
						<S.itemForm style={{ paddingTop: 10 }}>
							<p>Obter coordenadas em Graus Decimais (GD):</p>
							<BalloonDoubtsGlobal
								positionRight={5}
								header="Formas para editar a localização"
								content={content}
							/>
							<S.itemLocation>
								<CustomInput
									type="number"
									placeholder="Latitude"
									styleInput={styles.input}
									name="lat"
									value={lat}
									useOnChange
									onChange={onchangeCoord}
								/>

								<CustomInput
									type="number"
									placeholder="Longitude"
									styleInput={styles.input}
									value={long}
									name="long"
									useOnChange
									onChange={onchangeCoord}
								/>

								{/* <S.btn
								type="button"
								onClick={() => getLocationOnNavigator(dispatch, getCoordsOnMap)}
							>
								<MyLocationIcon style={{ width: 20 }} />
							</S.btn> */}
							</S.itemLocation>
							<S.itemLocation>
								<p>Coordenadas geográficas</p>
							</S.itemLocation>
							{locationEmpty ? (
								<S.errorInputEmpity>
									{long === '' || lat === ''
										? 'Os campos latitude e longitude são obrigatórios.'
										: isCoordsInvalid(isLatitude(lat), isLongitude(long))}
								</S.errorInputEmpity>
							) : null}
						</S.itemForm>
						<S.itemForm style={{ paddingTop: 0 }}>
							<p>Obter coordenadas em Graus, Minutos e Segundos (GMS):</p>

							<S.itemLocation>
								<CustomInput
									type="text"
									placeholder={gms}
									styleInput={styles.input}
									value={gms}
									onChange={onchangeGms}
									setValue={setGms}
									useOnChange
								/>
							</S.itemLocation>
							<S.itemLocation>
								<p>Coordenadas geográficas</p>
							</S.itemLocation>
							{locationEmpty ? (
								<S.errorInputEmpity>
									{long === '' || lat === ''
										? 'Os campos latitude e longitude são obrigatórios.'
										: isCoordsInvalid(isLatitude(lat), isLongitude(long))}
								</S.errorInputEmpity>
							) : null}
						</S.itemForm>
					</>
				) : null}

				{type !== 'whereIBeen' && type !== 'tracker' ? (
					<S.itemForm>
						<ImageUploading
							setPhoto={setPhoto}
							photo={photo}
							isImage={dataToEdit?.image_url}
						/>
					</S.itemForm>
				) : null}

				<S.btnSalvar type="submit">Salvar</S.btnSalvar>
			</S.containerAdd>
		</form>
	);
}

const styles = {
	input: {
		borderRadius: '8px',
		marginBottom: '0px',
		border: `1px solid ${color.interface.lightGray}`,
		backgroundColor: '#ffffff'
	},
	select: {
		height: '40px',
		border: `1px solid ${color.interface.lightGray}`,
		backgroundColor: '#ffffff'
	}
};
