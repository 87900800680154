import styled from 'styled-components';
import { color } from 'styles/Theme';

export const ConatinerError404 = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	text-align: center;

	h1,
	p {
		font-weight: 400;
	}

	h1 {
		margin-top: -2rem;
		font-size: 5rem;
		color: ${color.interface.green};
	}

	h4 {
		margin-top: -3rem;
		color: ${color.interface.green};
	}

	p {
		color: ${color.interface.orange};
		font-weight: 900;
	}
`;
