/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable arrow-parens */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import Select from 'components/Buttons/Select/Select';
import {
	generateHash,
	createFormData,
	listTypeRegister,
	isRouteSaveMonitoring,
	getLocationOnNavigator,
	isLatitude,
	isLongitude,
	isCoordsInvalid,
	convertLatLong,
	latLogToDms,
	getDMS
} from 'helpers/utils';
import CustomInput from 'components/CustomInput';
import { getCoordsOnMap } from 'store/actions/systemActions';
import BalloonDoubtsGlobal from 'components/BalloonDoubtsGlobal';
import CustomTextArea from 'components/CustomTextArea';
import AudioRecorder from 'components/AudioRecorder';
import ImageUploading from 'components/ImageUploading';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {
	saveDataMonitoring,
	toogleIsRegister
} from 'store/actions/monitoringDataAction';
import { color } from 'styles/Theme';
import { svg } from 'lib/svgList';
import { useDispatch, useSelector } from 'react-redux';

import { getUserLocalStorage } from 'services/session';
import Load from 'components/Load/Load';
import { useAlertTraditionlUseContext } from 'services/map/alert.context';
import { olMap } from 'components/Map/ControlMap';
import { fromLonLat, transform } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import * as S from './styled';

export default function AddAlertOrTraditionalUse({
	type = '',
	setModalAdd = () => {},
	resetForm = () => {}
}) {
	const dispatch = useDispatch();
	const { routeMonitoring, loadingSave } = useSelector(
		(state) => state.monitoring
	);
	const { coordinates } = useSelector((state) => state.system);
	const { lat, setLat, long, setLong } = useAlertTraditionlUseContext();

	// armazena as lista de alertas ou uso tradicional no select
	const [listType, setListType] = useState([]);

	// Estados que são inseridos no formulário
	const [typeSelected, setTypeSelected] = useState('');
	const [description, setDescription] = useState('');
	const [audioRecorder, setAudioRecorder] = useState(null);
	const [gms, setGms] = useState('');
	const [isOnchangeGms, setisOnchangeGms] = useState(false);
	const [extent, setExtent] = useState(false);
	const [photo, setPhoto] = useState(null);
	const [nameWhereIBeen, setNameWhereIBeen] = useState('');

	// Estados que controlam os erros
	const [locationEmpty, setLocationEmpty] = useState(false);
	const [nameWhereIBeenEmpty, setNameWhereIBeenEmpty] = useState(false);
	const [typeSelectedEmpty, setTypeSelectedEmpty] = useState(false);

	const removeToFormData =
		routeMonitoring === 'tracks' || routeMonitoring === 'where_i_been';

	useEffect(() => {
		setLat(coordinates?.latitude || -8.07411);
		setLong(coordinates?.longitude || -53.78978);
	}, [coordinates]);

	useEffect(() => {
		if (olMap()) {
			const vectorSource = olMap()
				?.getLayers()
				.getArray()
				.find((layer) => layer.get('name') === 'getPoint')
				.getSource();

			let pointFeature = vectorSource?.getFeatures()[0];
			// define the source and target projections
			const sourceProjection = 'EPSG:4326'; // WGS84
			const targetProjection = 'EPSG:3857'; // Web Mercator
			if (!pointFeature) {
				const point = new Point(fromLonLat([long, lat], sourceProjection));
				pointFeature = new Feature(point);
				vectorSource.addFeature(pointFeature);
			}

			const transformedCoordinates = transform(
				[long, lat],
				sourceProjection,
				targetProjection
			);

			pointFeature.getGeometry().setCoordinates(transformedCoordinates);

			const extentIt = pointFeature.getGeometry().getExtent();
			if (extent) {
				olMap()
					.getView()
					.fit(extentIt, { padding: [50, 50, 50, 50] });
				setExtent(false);
			}
		}
	}, [long, lat, olMap(), extent]);

	function saveAlert(event) {
		event.preventDefault();
		const date = new Date();

		const { user } = getUserLocalStorage();

		if (typeSelected === '' && type !== 'whereIBeen') {
			setTypeSelectedEmpty(true);
			return;
		}
		setTypeSelectedEmpty(false);

		if (nameWhereIBeen === '' && type === 'whereIBeen') {
			setNameWhereIBeenEmpty(true);
			return;
		}
		setNameWhereIBeenEmpty(false);

		if (!isLatitude(lat) || !isLongitude(long)) {
			setLocationEmpty(true);
			return;
		}
		setLocationEmpty(false);

		// OBJETO COM OS DADOS DE REGISTRO DE ALERTA
		const objToSave = {
			controllerWebFile: true, // estado para controle de upload de file web
			name: typeSelected.label
				? typeSelected?.label.toUpperCase()
				: nameWhereIBeen,
			type: typeSelected.value,
			description,
			latitude: lat,
			longitude: long,
			hash: generateHash(),
			created_at: date.toISOString(),
			user_id: user?.id,
			image: photo && photo,
			audio: audioRecorder && audioRecorder?.blob
		};

		if (removeToFormData) {
			delete objToSave.description;
			delete objToSave.type;
			delete objToSave.audio;
			delete objToSave.image;
		}

		if (routeMonitoring === 'tracks') {
			delete objToSave.latitude;
			delete objToSave.longitude;
		}

		dispatch(
			saveDataMonitoring({
				params: removeToFormData ? objToSave : createFormData(objToSave),
				route: isRouteSaveMonitoring(routeMonitoring),
				typeAlert: routeMonitoring
			})
		);

		/* dispatch(
			saveDataMonitoring({
				params: removeToFormData ? objToSave : createFormData(objToSave),
				route: isRouteSaveMonitoring(routeMonitoring),
				typeAlert: routeMonitoring
			})
		).then(() => {
			resetForm();
		}); */
	}

	// responsável por carregar a lista dos tipos do alerta ou uso tradicional que será cadastrado
	useEffect(() => {
		listTypeRegister(type, setListType);
	}, [type]);

	useEffect(() => {
		getLocationOnNavigator(dispatch, getCoordsOnMap);
		// para carregar a função de pegar o ponto no mapa
		dispatch(toogleIsRegister(true));
		return () => {
			// para desativar a função de pegar o ponto no mapa
			dispatch(toogleIsRegister(false));
		};
	}, []);
	const content = [
		{
			id: 0,
			p: '- Clicando no mapa'
		},
		{
			id: 1,
			p: '- Clicando no botão de GPS abaixo'
		},
		{
			id: 2,
			p: '- Digitando as coordenadas geográficas'
		}
	];

	const latDMS = getDMS(lat, 'lat');
	const lonDMS = getDMS(long, 'long');
	useEffect(() => {
		setGms(`${latDMS} ${lonDMS}`);
	}, []);
	useEffect(() => {
		if (latDMS || (lonDMS && !isOnchangeGms)) setGms(latDMS + ` ` + lonDMS);
	}, [latDMS, lonDMS]);

	latLogToDms({ lat, long });

	const onchangeGms = (e) => {
		try {
			const result = convertLatLong(e.target.value);

			if (result && result.length >= 2) {
				const latitude = result[0].ltCoord.toFixed(6);
				const longitude = result[1].longCoord.toFixed(6);
				setisOnchangeGms(true);
				setExtent(true);
				setLat(latitude);
				setLong(longitude);
				setGms(e.target.value);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const onchangeCoord = (e) => {
		if (e.target.name === 'long') {
			setExtent(true);
			setisOnchangeGms(false);
			setLong(e.target.value);
		} else {
			setExtent(true);
			setisOnchangeGms(false);
			setLat(e.target.value);
		}
	};

	if (loadingSave) return <Load />;

	return (
		<form onSubmit={saveAlert}>
			<S.containerAdd>
				<S.headerEdit>
					<h5>Novo registro</h5>
					<button type="button" onClick={() => setModalAdd(false)}>
						<img src={svg.icon.close} style={{ width: 18 }} alt="Fechar" />
					</button>
				</S.headerEdit>

				{type !== 'whereIBeen' ? (
					<S.itemForm>
						<p>Tipo do {type === 'alert' ? 'alerta' : 'uso tradional'}:</p>
						<Select
							style={styles.select}
							options={listType}
							itemSelected={typeSelected}
							setItemSelected={setTypeSelected}
						/>
						{typeSelectedEmpty ? (
							<S.errorInputEmpity>
								*Favor preencher o tipo do registro.
							</S.errorInputEmpity>
						) : null}
					</S.itemForm>
				) : null}

				{type === 'whereIBeen' ? (
					<S.itemForm>
						<p>Nome do local onde você está:</p>
						<CustomInput
							styleInput={styles.input}
							value={nameWhereIBeen}
							setValue={setNameWhereIBeen}
							minLength={1}
							maxLength={35}
						/>
						{nameWhereIBeenEmpty ? (
							<S.errorInputEmpity>
								*Favor preencher o nome do local
							</S.errorInputEmpity>
						) : null}
					</S.itemForm>
				) : null}

				{type !== 'whereIBeen' ? (
					<S.itemForm>
						<p>Descrição do registro:</p>
						<CustomTextArea
							type="text"
							styleInput={styles.input}
							value={description}
							setValue={setDescription}
						/>
					</S.itemForm>
				) : null}

				{type !== 'whereIBeen' ? (
					<S.itemForm>
						<p>Gravar áudio:</p>
						<AudioRecorder
							audioRecorder={audioRecorder}
							setAudioRecorder={setAudioRecorder}
						/>
					</S.itemForm>
				) : null}

				<S.itemForm style={{ paddingTop: 10 }}>
					<p>Obter localização em decimal ou grau</p>
					<BalloonDoubtsGlobal
						positionRight={5}
						header="Formas para obter a localização"
						content={content}
					/>
					<p>Coordenadas geográficas em decimal:</p>
					<S.itemLocation>
						<CustomInput
							type="number"
							placeholder="Latitude"
							styleInput={styles.input}
							name="lat"
							value={lat}
							useOnChange
							onChange={onchangeCoord}
						/>

						<CustomInput
							type="number"
							placeholder="Longitude"
							styleInput={styles.input}
							value={long}
							name="long"
							useOnChange
							onChange={onchangeCoord}
						/>
						<S.btn
							type="button"
							onClick={() => getLocationOnNavigator(dispatch, getCoordsOnMap)}
						>
							<MyLocationIcon style={{ width: 20 }} />
						</S.btn>
					</S.itemLocation>
					{locationEmpty ? (
						<S.errorInputEmpity>
							{long === '' || lat === ''
								? 'Os campos latitude e longitude são obrigatórios.'
								: isCoordsInvalid(isLatitude(lat), isLongitude(long))}
						</S.errorInputEmpity>
					) : null}
				</S.itemForm>
				<S.itemForm style={{ paddingTop: 0 }}>
					<p>Coordenadas geográficas em grau:</p>
					<S.itemLocation>
						<CustomInput
							type="text"
							placeholder={gms}
							styleInput={styles.input}
							value={gms}
							onChange={onchangeGms}
							setValue={setGms}
							useOnChange
						/>
					</S.itemLocation>
					{locationEmpty ? (
						<S.errorInputEmpity>
							{long === '' || lat === ''
								? 'Os campos latitude e longitude são obrigatórios.'
								: isCoordsInvalid(isLatitude(lat), isLongitude(long))}
						</S.errorInputEmpity>
					) : null}
				</S.itemForm>

				{type !== 'whereIBeen' ? (
					<S.itemForm>
						<ImageUploading setPhoto={setPhoto} photo={photo} />
					</S.itemForm>
				) : null}

				<S.btnSalvar type="submit">Salvar</S.btnSalvar>
			</S.containerAdd>
		</form>
	);
}

const styles = {
	input: {
		borderRadius: '8px',
		marginBottom: '0px',
		border: `1px solid ${color.interface.lightGray}`,
		backgroundColor: '#ffffff'
	},
	select: {
		height: '40px',
		border: `1px solid ${color.interface.lightGray}`,
		backgroundColor: '#ffffff'
	}
};
