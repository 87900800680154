/* eslint-disable no-shadow */
import React from 'react';

function truncate(value) {
	if (value < 0) {
		return Math.ceil(value);
	}

	return Math.floor(value);
}

function LinearProgress({ value }) {
	return (
		<span
			role="progressbar"
			aria-valuenow={truncate(value)}
			aria-valuemin="0"
			aria-valuemax="100"
			className="somai-multi-linear-progress-loading"
		>
			<span
				className="somai-multi-linear-progress-bar"
				style={{ transform: `translateX(${value - 100}%)` }}
			/>
		</span>
	);
}

export default LinearProgress;
