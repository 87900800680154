/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import { olMap } from 'components/Map/ControlMap/index';
import { activeDraw } from 'store/actions/mapActions';
import { StyleLayers } from 'components/Map/Styles/js/StyleLayers';
import { transform, fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { getCoordsOnMap } from 'store/actions/systemActions';
import VectorLayer from 'ol/layer/Vector';
import { Draw, Modify, Snap } from 'ol/interaction';
import { getLayerByName } from '../../mapActions';

export const vectorsMonitoring = () => async (dispatch) => {
	// Mostra pontos de alertas, usos tradicionais, trajetos e onde estive

	const sourceMonitoring = new VectorSource({ wrapX: false });

	const vectorMonitoring = new VectorLayer({
		name: 'monitoring',
		source: sourceMonitoring,
		zIndex: 95246,
		monitoring: true,
		style: (feature) => {
			const isStyle = [
				StyleLayers.pointBackground,
				StyleLayers[feature.get('type')]
			];
			return isStyle;
		}
	});

	const sourcePoint = new VectorSource({
		wrapX: false
	});
	const vectorPoint = new VectorLayer({
		name: 'getPoint',
		source: sourcePoint,
		monitoring: true,
		style: [StyleLayers.pointBackground, StyleLayers.getPoint],
		zIndex: 95246
	});

	const layerMonitoring = await getLayerByName('monitoring');
	const layerGetPoint = await getLayerByName('getPoint');

	// para evitar adicionar no mapa mais de uma vez, só adiconar se não existir
	// pq senão dá problemas de interação com mapa, por exemplo, adicionar ou remover pontos
	if (!layerMonitoring) olMap().addLayer(vectorMonitoring);
	if (!layerGetPoint) olMap().addLayer(vectorPoint);

	// Função para editar pontos no mapa

	const modify = new Modify({ source: sourceMonitoring, monitoring: true });
	const snap = new Snap({ source: sourcePoint, monitoring: true });
	const draw = new Draw({
		source: sourcePoint,
		monitoring: true,
		type: 'Point',
		maxPoints: 2,
		freehand: true
	});

	// Adiciona novas coordenadas no mapa após editar

	modify.on('modifyend', (evt) => {
		evt.features.forEach((feature) => {
			const coords = feature.getGeometry().getCoordinates();

			const lonlat = transform(coords, 'EPSG:3857', 'EPSG:4326');
			const longitude = lonlat[0].toFixed(6);
			const latitude = lonlat[1].toFixed(6);
			dispatch(getCoordsOnMap({ latitude, longitude }));

			// dispatch(getGeomOnMap(coords));
		});
	});

	// Adiciona um novo ponto no mapa
	draw.on('drawstart', (evt) => {
		const { feature } = evt;
		let features = vectorPoint.getSource().getFeatures();
		features = features.concat(feature);

		// permite apenas um ponto no mapa, removendo o ponto antigo.
		features.forEach((e) => {
			if (features.length > 1) {
				vectorPoint.getSource().removeFeature(features[0]);
			}
		});
	});

	// Adiciona a coordenada no reducer
	draw.on('drawend', (evt) => {
		const { feature } = evt;
		let features = vectorPoint.getSource().getFeatures();
		features = features.concat(feature);
		features.forEach((e) => {
			const coords = e.getGeometry().getCoordinates();
			const lonlat = transform(coords, 'EPSG:3857', 'EPSG:4326');

			const longitude = lonlat[0].toFixed(6);
			const latitude = lonlat[1].toFixed(6);

			dispatch(getCoordsOnMap({ latitude, longitude }));
		});
	});

	olMap().addInteraction(modify);
	olMap().addInteraction(draw);
	olMap().addInteraction(snap);

	dispatch(activeDraw(false));
};
