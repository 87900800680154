import * as React from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// typeSnack da messagem que vc pode passar: error, info, success, warning

export default function SnackBarGeneral({
	message = 'Algum erro aconteceu!',
	typeSnack = 'error',
	setOpen = () => {},
	open = true
}) {
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={typeSnack} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	);
}
