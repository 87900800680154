import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerTis = styled.div`
	width: 100%;
	padding: 0.3rem;
	padding-bottom: 10rem;
	position: relative;
	min-height: 100vh;
`;

export const notice = styled.p`
	color: ${color.interface.red};
`;
