import React, { useState } from 'react';
import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import PackReq from 'components/PackReq';
import Button from 'components/Buttons/Button/Button';
import { actionsUsers } from 'helpers/utils';
import { generatePdf, toggleModalTi } from 'store/actions/statesAmazonLegalAction';
import { getExtentFromFeature } from 'store/actions/mapActions';
import { zoomToExtentLayer } from 'components/Map/ControlMap/index';
import { textAdviceTi } from '../text';
import * as S from './styled';

export default function ModalTi() {
	const dispatch = useDispatch();
	const { isReqPdf, isReqPdfError, isOpenModalTi, dataAboutTiSelected } = useSelector((state) => state.amazonLegal);
	// tabAmazonTis

	const extent = getExtentFromFeature('tabAmazonTis', dataAboutTiSelected.code);

	const [showPackReq, setShowPackReq] = useState(false);
	const handleGA = () => {
		actionsUsers({
			category: `plataforma/tabAmazon/relatorios`,
			action: 'Relatório da TI selecionada',
			label: dataAboutTiSelected.name
		});
	};

	return (
		<Modal
			isOpen={isOpenModalTi}
			setIsOpen={() => dispatch(toggleModalTi())}
			useBtnEnd={false}
			isClose={() => setShowPackReq(false)}
		>
			<S.containerModal>
				{showPackReq ? (
					<PackReq
						isReq={isReqPdf}
						errorReq={isReqPdfError}
						functionBtn={() => {
							setShowPackReq(false);
							dispatch(toggleModalTi());
						}}
					/>
				) : (
					<>
						{textAdviceTi()}
						<Button
							style={{ marginTop: '0.5rem' }}
							text="Gerar relatório"
							onClick={() => {
								setShowPackReq(true);
								zoomToExtentLayer(extent[0], 0);
								handleGA();
								dispatch(generatePdf(dataAboutTiSelected, dataAboutTiSelected.name, 'ti'));
							}}
						/>
					</>
				)}
			</S.containerModal>
		</Modal>
	);
}
