/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import * as S from './style';
import ControlesMap from '../../Controls/index';

export const ContainerR = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;

	.slide-container {
		display: flex;
		overflow: hidden;
	}

	.slide {
		flex: 0 0 33.33%; /* Adjust the width of each slide as needed */
		transition: transform 0.5s ease-in-out;
	}

	.slide.active {
		transform: scale(1.2); /* Adjust the scale factor for active slide */
	}

	.slide img {
		width: 100%;
		height: auto;
	}

	.prev-button,
	.next-button {
		background-color: #f2f2f2;
		border: none;
		color: #333;
		padding: 10px 20px;
		margin: 5px;
		cursor: pointer;
	}

	.prev-button:hover,
	.next-button:hover {
		background-color: #e0e0e0;
	}
`;
export const ContainerCarou = styled.div`
	display: flex;
	align-items: center;
	& > div {
		width: 49px;
		height: 49px;
		button {
			width: 49px;
			height: 49px;
		}
	}
`;

function MobilMenuCarousel({ mapSomai }) {
	const carousel = useRef(null);

	const nextSlide = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += carousel.current.offsetWidth;
	};

	const prevSlide = (e) => {
		e.preventDefault();
		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4, // Number of items to show at once
		slidesToScroll: 1,
		nextArrow: <S.PrevArrow />,
		prevArrow: <S.NextArrow />
	};

	return (
		<>
			<S.container>
				<S.containerWrapper>
					<S.SliderContainer className="carousel" ref={carousel}>
						<ControlesMap mapSomai={mapSomai} />
					</S.SliderContainer>
					<S.SliderButtonsContainer>
						<S.PrevArrow onClick={prevSlide} />
						<S.NextArrow onClick={nextSlide} />
					</S.SliderButtonsContainer>
				</S.containerWrapper>
			</S.container>
			{/* <Slider {...settings}>
				<ContainerCarou className="carousel" ref={carousel}>
					<ControlesMap mapSomai={mapSomai} />
				</ContainerCarou>
			</Slider> */}
		</>
	);
}

export default MobilMenuCarousel;
