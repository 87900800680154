import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from 'store';
import Global from 'styles/Global';
import 'styles/slick.min.css';
import { ShapeStoreProvider } from 'services/map/shapes.context';
import { MeasureStoreProvider } from 'services/map/measure.context';
import { AttributionContextProvider } from 'services/map/attribution.context';
import { AlertTraditionlUseContextProvider } from 'services/map/alert.context';
import { ColorsContextProvider } from 'services/color.context';
import App from './App';
// import * as serviceWorker from './serviceWorker';

function Home() {
	'use-strict';

	return (
		<Provider store={store}>
			<ShapeStoreProvider>
				<MeasureStoreProvider>
					<AttributionContextProvider>
						<AlertTraditionlUseContextProvider>
							<ColorsContextProvider>
								<Global />
								<Helmet>
									<title>
										SOMAI PLATAFORMA - Sistema de Observação e Monitoramento da
										Amazônia Indígena.
									</title>
									<meta
										name="description"
										content="O SOMAI é uma plataforma online com dados científicos sobre as Terras Indígenas da Amazônia brasileira, que busca mostrar a importância destes territórios para ações de adaptação das mudanças climáticas e manutenção do equilíbrio ambiental regional. O SOMAI foi desenvolvido para o empoderamento indígena nas questões climáticas, levando-se em conta os impactos climáticos sobre suas terras. É uma ferramenta para povos indígenas, gestores e instituições que trabalham com o tema."
										key="desc"
									/>
									<meta property="og:type" content="website" />
									<meta
										property="og:url"
										content="https://plataforma.somai.org.br"
									/>
									<meta
										property="og:image"
										content="https://plataforma.somai.org.br/assets/imgs/logoSomaiMultiColor.svg"
									/>
									<link rel="icon" href="/favicon.ico" />
									<meta
										name="keywords"
										content="impactos ambientais, mudanças do clima, povos indígenas, Informações científicas, Terras Indígenas"
									/>
								</Helmet>
								<Router>
									<App />
								</Router>
							</ColorsContextProvider>
						</AlertTraditionlUseContextProvider>
					</AttributionContextProvider>
				</MeasureStoreProvider>
			</ShapeStoreProvider>
		</Provider>
	);
}

const rootElement = document.getElementById('root');
ReactDOM.render(<Home />, rootElement);

// serviceWorker.register();
