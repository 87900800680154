import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerConsult = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	/* background-color: antiquewhite; */
`;

export const containerInsideAccordion = styled.div`
	width: 100%;
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	/* background-color: azure; */
`;

export const listEmptyNotice = styled.p`
	font-weight: 400;
	color: ${color.interface.darkGray};
`;

export const checkAll = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0.2rem;
	gap: 1rem;
	> p {
		font-size: 0.8rem;
		color: ${color.interface.gray};
	}
`;
