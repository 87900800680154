import styled from 'styled-components';
import { color } from '../../../styles/Theme';

export const containerSwitch = styled.div`
	position: relative;
	align-items: center;
	display: flex;
	width: ${(props) => (props.useToggle ? '36px' : 'auto')};
	/* height: 50px; */
	padding: 10px 0px;

	button {
		background-color: transparent;
	}
`;

export const labelFather = styled.label`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: aliceblue;
	width: 100%;
	height: 12px;
	border-radius: 20px;
	background-color: ${color.interface.mediumGray};
	transform: translateY(30%);
	transition: all ease 0.5s;
	input {
		display: none;
	}

	span {
		position: absolute;
		display: flex;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 1px solid ${color.interface.beige};
		transform: translateY(-20%);
		transition: all ease 0.5s;
		background-color: ${color.interface.beige4};
		cursor: pointer;
	}
`;
