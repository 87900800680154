/* eslint no-plusplus: "error" */
/* eslint no-cond-assign: 2 */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useOutsideClick } from '@chakra-ui/react';
import Load from 'components/Load/Load';
import BoxItemSelected from 'components/Buttons/BoxItemSelected/BoxItemSelected';
// import { formatTis } from 'helpers/utils';
import { color } from 'styles/Theme';
import * as S from './styled';

// A LISTA DE ITEM DEVE SER PASSADO COMO UM ARRAY DE OBJETOS ONDE O PRIMEIRO PARAMETRO DO OBJETO SEJA ID E OUTRO UM VALOR QUALQUER(EX: NOME);
// NÃO IMPORTA O NOME DO PRIMEIRO PARAMETRO OU DO SEGUNDO. EX: {CODIGO: 5, NOME: 'LUCAS' }
// CASO SUA LISTA DE ITEMS SEJA DE TIS, USE A FUNÇÃO FORMTTIS DENTRO DE HELPERS.

// itemsList: Passe nessa props a lista
// setItemsSelected: sua função que controla o estado que foi selecionado
// placeholder: Nome que vai pro placeholder do input (Opcional)
// isLoading: caso sua lista venha de uma requisição, passe se está ou nao fazendo requisição aqui. Caso seja uma lista estática, só passe o valor para false
// styleInput: estilo do input (Opcional)
// styleList: estilo da caixa com a lista (Opcional)

export default function SearchMultiple({
	itemsList = [],
	itemsSelected = [],
	setItemsSelected = () => {},
	placeholder = 'Buscar...',
	isLoading = true,
	styleInput = {},
	styleList = {}
}) {
	const ref = React.useRef();
	const [keyWord, setKeyWord] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [copyItemsList, setCopyItemsList] = useState([]);
	const [filteredListTi, setFilteredListTi] = useState([]);

	const regexSearch = (str) => {
		let name;
		let found;
		const regex = /[a-z]/gi; // regex que pega somentes letras de A a Z caixa alta e baixa

		while ((found = regex.exec(str))) {
			name += found;
		}

		if (typeof name === 'string') {
			return name.replace('undefined', '');
		}
		return str;
	};

	const nameNoSpace = (name) => {
		let nameNoAccent = '';
		const nameArray = name.split(' ');

		for (let i = 0; i < nameArray.length; i += 1) {
			nameNoAccent = `${nameNoAccent}${nameArray[i]}`;
		}

		// função abaixo tira acentos do nome
		nameNoAccent = nameNoAccent
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');

		// função que tira todos caracteres especiais
		const finalName = regexSearch(nameNoAccent);

		return finalName;
	};

	function returnObjEdited(list) {
		const newListFormated = list.map((item) => {
			const value = Object.keys(item)[0];
			const label = Object.keys(item)[1];
			return {
				value: item[value],
				label: item[label]
				// objOriginal: { ...item }
			};
		});
		return newListFormated;
	}

	function deleteItemsFromList(list, itemsToDelete) {
		const newList = list.filter((item) => {
			const isExist = itemsToDelete.filter(
				(itemSelected) => itemSelected.value === item.value
			);
			return isExist.length > 0 ? null : item;
		});

		return newList;
	}

	function checkItemsExistInList(list, itemTocheck) {
		const newList = list.filter((item) => {
			const isExist = itemTocheck.filter(
				(itemSelected) => itemSelected.value !== item.value
			);
			return isExist.length > 0 ? null : item;
		});

		return newList.length > 0 ? true : false;
	}

	function filterData() {
		// caso o a keyword seja '' e existam items selecionados, preciso tirar esses items selecinados da array filtrada
		if (
			keyWord !== '' &&
			copyItemsList.length > 0 &&
			itemsSelected.length > 0
		) {
			const listWithoutSelecteds = deleteItemsFromList(
				copyItemsList,
				itemsSelected
			);
			const listFiltered = listWithoutSelecteds.filter((item) => {
				const itemFormated = nameNoSpace(item.label.toLowerCase());
				const keyWordFormated = nameNoSpace(keyWord.toLowerCase());
				return itemFormated.includes(keyWordFormated);
			});
			return listFiltered;
		}
		// caso não ha item selecionados posso filtrar a lista normalmente
		else if (keyWord !== '' && copyItemsList.length > 0) {
			const listFiltered = copyItemsList.filter((item) => {
				const itemFormated = nameNoSpace(item.label.toLowerCase());
				const keyWordFormated = nameNoSpace(keyWord.toLowerCase());
				return itemFormated.includes(keyWordFormated);
			});
			return listFiltered;
		}

		// se nenhum if bater, retorna a lista original na array de lista filtrada
		return copyItemsList;
	}

	function onSelectITem(item) {
		//quando um item é selecionado preciso colocar o mesmo na array de items selecionados e tirar da lista array de lista filtrada
		setItemsSelected([...itemsSelected, item]);
		setKeyWord('');
		const newList = filteredListTi.filter((i) => i.value !== item.value);
		setFilteredListTi(newList);
	}

	function onDeleteItem(item) {
		//tirando item da array te items selecionados
		const newList = itemsSelected.filter((i) => i.value !== item.value);
		setItemsSelected(newList);

		//se o item já existe na array filtrada, nao adiocono o mesmo a array filtrada
		// é preciso passar o item dentro de uma array, pq a função espera um array
		const itemExistOnTheLis = checkItemsExistInList(filteredListTi, [item]);
		if (!itemExistOnTheLis) {
			setFilteredListTi([...filteredListTi, item]);
		}
	}

	function returnList() {
		if (filteredListTi.length !== 0) {
			return (
				filteredListTi &&
				filteredListTi.map((item, i) => (
					<S.itemList key={item.value}>
						<button
							type="button"
							onClick={() => {
								onSelectITem(item);
							}}
							onKeyDown={() => {
								onSelectITem(item);
							}}
						>
							{item.label}
						</button>
						{/* lógica abaixo feita para não mostrar linha no ultimo item */}
						{i !== filteredListTi.length - 1 ? <S.line /> : null}
					</S.itemList>
				))
			);
		}
		return <p key="teste">Não há itens </p>;
	}

	useEffect(() => {
		// caso não haja nada na keywordm e tenha items selecionados, eu preciso que lista filtrada receba lista filtrada - os items selecionado
		if (keyWord === '' && itemsSelected.length > 0) {
			setFilteredListTi(deleteItemsFromList(copyItemsList, itemsSelected));
		}
		// quando for colocado 1 letra do campo de busca e já tenha items selecionados, é preciso tirar da lista filtrada esses item que ja foram selecionados
		else if (keyWord !== '' && itemsSelected.length > 0) {
			setFilteredListTi(
				filterData(deleteItemsFromList(copyItemsList, itemsSelected))
			);
		}
		// caso a copia da lista esteja vazia a listra filtrada precisa esta vazia
		else if (copyItemsList.length === 0) {
			setFilteredListTi(copyItemsList);
		}
		// caso nada acima atenda, assim que for inserido uma keyword, filtraremos da copia da lista e colocaremos na lista filtrada
		else {
			setFilteredListTi(filterData());
		}
	}, [keyWord]);

	// quando a copia da lista original é criada, a lista filtrada precisa receber o valor da copia, pois a mesma ainda não sofreu nenhum filtro
	useEffect(() => {
		setFilteredListTi(copyItemsList);
	}, [copyItemsList]);

	// É preciso fazer uma copia da lista atual, para apartir dessa copia, alterarmos as chaves originais dos objetos que chegam. Dessa forma
	// vc pode mandar um array de objetos com objetos que possuam qualquer nome.
	useEffect(() => {
		if (
			itemsList.length > 0 &&
			copyItemsList.length === 0 &&
			typeof itemsList !== 'string'
		) {
			setCopyItemsList(returnObjEdited(itemsList));
		}
	}, [itemsList]);

	// função chakra para fechar modal quando cliclado fora dela
	useOutsideClick({
		ref,
		handler: () => setIsOpen(false)
	});

	return (
		<S.ContainerSearch ref={ref}>
			<input
				type="text"
				placeholder={placeholder}
				value={keyWord}
				onChange={(event) => setKeyWord(event.target.value)}
				handlsearch={filterData({ keyWord })}
				onFocus={() => setIsOpen(true)}
				style={styleInput}
			/>
			{isOpen ? (
				<S.ListTi style={styleList}>
					{isLoading ? <Load /> : returnList()}
				</S.ListTi>
			) : null}

			{itemsSelected.length !== 0 && (
				<S.containerBoxItems>
					{itemsSelected.map((item) => (
						<BoxItemSelected
							key={item.value}
							onClick={() => onDeleteItem(item)}
							text={item.label}
							style={{
								backgroundColor: color.interface.lightGray,
								color: color.interface.darkGray
							}}
						/>
					))}
				</S.containerBoxItems>
			)}
		</S.ContainerSearch>
	);
}
