import styled from 'styled-components';
import { color } from '../../../styles/Theme';

export const containerExport = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 0.5rem;
	/* background-color: antiquewhite; */
`;

export const containerInsideAccordion = styled.div`
	width: 90%;
	display: flex;

	flex-direction: column;
	gap: 1rem;
	/* background-color: yellowgreen; */
`;

export const containerBoxItemSelected = styled.div`
	max-height: 200px;
	overflow: auto;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.5rem;

	/* background-color: aliceblue; */
`;

export const buttonsReports = styled.div`
	width: 95%;
	display: flex;
	align-items: center;
	gap: 1rem;

	/* background-color: red; */

	@media (max-width: 620px) {
		width: 100%;
		flex-direction: column;
	}
`;

export const boxBtn = styled.div`
	width: 100%;
`;

export const modalReq = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	padding: 1rem;
`;

export const btnDownloadPDF = styled.a`
	padding: 0.8rem 1.5rem;
	border-radius: 8px;
	color: ${color.interface.white};
	font-weight: 900;
	background-color: ${color.interface.orange};
	font-weight: 700;
	cursor: pointer;
	pointer-events: fill;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;

	gap: 1rem;
	:hover {
		background-color: ${color.interface.green};
	}
	:active {
		transform: scale(0.9);
	}
`;

export const pEffect = styled.p`
	@keyframes flashing {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
	font-weight: 400;
	animation: linear;
	animation-name: flashing;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
`;

export const noticeWarning = styled.p`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: ${color.interface.red};
`;
