/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import * as S from './styled';
import { color } from '../../styles/Theme';

// Passe a cor e o tamanho do load

export default function Load({
	colorBackground = color.interface.orange,
	size = 20,
	type = 'simple-circle-progress'
}) {
	return (
		<S.containerLoad>
			<Loading
				style={{
					color: `${colorBackground}`
				}}
				size={size}
				type={type}
			/>
		</S.containerLoad>
	);
}

Load.propTypes = {
	/**
	 * Cor de fundo do load.
	 */

	type: PropTypes.string,
	colorBackground: PropTypes.string,
	/**
	 * Tamanho em pixel do load.
	 */
	size: PropTypes.number
};

Load.defaultProps = {
	colorBackground: '#D44500',
	size: 20,
	type: 'simple-circle-progress'
};

// https://pt-br.reactjs.org/docs/typechecking-with-proptypes.html
