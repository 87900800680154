import React from 'react';
import Container from './Container';
import * as S from './style';

function InfoContainer() {
	return (
		<S.Container>
			<Container />
		</S.Container>
	);
}

export default InfoContainer;
