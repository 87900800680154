import { api } from 'services/api';
import { webGisType } from '../typesActions/types';
import { headers } from 'helpers/utils';

export const getSystemUsers = () => async (dispatch, getState) => {
	const { systemUsers } = getState().webGis;

	if (systemUsers.length === 0) {
		dispatch({ type: webGisType.WEBGIS_SYSTEM_USERS_REQUEST });
		try {
			const res = await api.get(`/allUsersByProfile`, { headers: headers() });
			const { data } = res || {};
			dispatch({
				type: webGisType.WEBGIS_SYSTEM_USERS_SUCCESS,
				payload: data
			});
			return data;
		} catch (error) {
			const { response } = error;
			if (response && response.status === 400) {
				const { message } = response.data;
				dispatch({
					type: webGisType.WEBGIS_SYSTEM_USERS_FAILURE,
					payload: { errors: { message } }
				});
			} else {
				dispatch({
					type: webGisType.WEBGIS_SYSTEM_USERS_FAILURE,
					payload: {
						errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
					}
				});
			}
		}
	}
};

export const updateSystemUsers = (data) => {
	return {
		type: webGisType.WEBGIS_SYSTEM_USERS_UPDATE,
		payload: data
	};
};

export const filterProfileUsers = (data) => {
	return {
		type: webGisType.WEBGIS_FILTER_USERS_PROFILE,
		payload: data
	};
};

export const filterOrganizationUsers = (data) => {
	return {
		type: webGisType.WEBGIS_FILTER_USERS_ORGANIZATON,
		payload: data
	};
};

export const filterLetterUsers = (data) => {
	return {
		type: webGisType.WEBGIS_FILTER_USERS_LETTER,
		payload: data
	};
};

export const filterSearchUsers = (data) => {
	return {
		type: webGisType.WEBGIS_FILTER_USERS_SEARCH,
		payload: data
	};
};

export const actionFilterPeriod = (data) => {
	return {
		type: webGisType.WEBGIS_FILTER_PERIOD,
		payload: data
	};
};

export const actionFilterDataUsers = (data) => {
	return {
		type: webGisType.WEBGIS_FILTER_DATA_USERS,
		payload: data
	};
};

export const setStateModalMultFilter = (data) => {
	return {
		type: webGisType.WEBGIS_FILTER_MODAL_SEARCH,
		payload: data
	};
};
