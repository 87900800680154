import styled from 'styled-components';
import ColLayout from 'components/Grid/Col';
import { color } from 'styles/Theme';

export const containerShowDataListAccordion = styled.div`
	width: 100%;
`;

export const conatinerInsideAccordion = styled.div`
	width: 100%;
	align-self: flex-end;
	/* background-color: azure; */
`;

export const title = styled.p`
	color: ${color.interface.darkGray};
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 500;
	font-size: 0.875rem;
`;

export const Line = styled.div`
	width: 100%;
	border: 1px solid #d9d5d2;
	opacity: 0.5;
`;

export const Box = styled(ColLayout)`
	display: flex;
	align-items: center;
	padding: 0.2rem;
`;

export const boxIcons = styled.div`
	display: flex;
	justify-content: end;
	width: 100%;
	align-items: center;
	gap: 0 0.4rem;
`;

export const DownloadLayers = styled.button`
	width: 16px;
	cursor: pointer;
`;
