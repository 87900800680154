import React from 'react';
import { svg } from 'lib/svgList';
import ItensMenuModal from 'components/ItensMenuModal';
// import { useSelector } from 'react-redux';
import TitleTab from 'components/TitleTab';
import ReportAlerts from './Charts/ReportAlerts';
import ReportTraditionalUses from './Charts/TraditionalUses';
import * as S from './styled';

export default function GroupedRecords() {
	const data = [
		{
			id: 1,
			title: 'Alertas de Organizações e Comunidades',
			subTitle: 'Alertas',
			icon: svg.icon.warningOrange,
			content: <ReportAlerts />
		},
		{
			id: 2,
			title: 'Usos Tradicionais de Organizações e Comunidades',
			subTitle: 'Usos Tradicionais',
			icon: svg.icon.mapLocationOrange,
			content: <ReportTraditionalUses />
		}
	];

	return (
		<S.containerGeral>
			<S.lineGray />
			<S.containerList>
				<TitleTab title="Organizações e Comunidades" />
				{data.map((item) => (
					<ItensMenuModal
						key={item.id}
						title={item.title}
						subTitle={item.subTitle}
						icon={item.icon}
						content={item.content}
					/>
				))}
			</S.containerList>
		</S.containerGeral>
	);
}
