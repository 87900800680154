import { dataUserToShowOnMap } from '../typesActions/types';

const INITIAL_STATE = {
	dataUser: [],
	message: ''
};

export default function dataUserToShowOnMapReducer(
	state = INITIAL_STATE,
	action
) {
	switch (action.type) {
		case dataUserToShowOnMap.ADD_LIST_DATA_USER_TO_ON_MAP:
			return {
				...state,
				dataUser: action.payload,
				message: 'Lista inicial carregada'
			};
		case dataUserToShowOnMap.EDIT_LIST_DATA_USER_TO_ON_MAP:
			return {
				...state,
				dataUser: action.payload,
				message: 'Lista atualizada'
			};
		case dataUserToShowOnMap.DELETE_LIST_DATA_USER_TO_ON_MAP:
			return {
				...state,
				dataUser: [],
				message: 'Lista deleteda'
			};
		default:
			return state;
	}
}
