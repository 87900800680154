import styled from 'styled-components';
import { color } from 'styles/Theme';

export const ContainerCheckAnimation = styled.div`
	min-width: 50px;
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
`;

export const CircleCheck = styled.div`
	position: relative;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	border: 3px solid ${color.interface.green};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const leg1 = styled.div`
	position: absolute;
	margin: 0 !important ;
	width: 10px;
	height: 2px;
	left: 10px;
	bottom: 16px;
	background-color: ${color.interface.green};
	transform: rotate(45deg);

	animation: growCheck1 ease 1s;

	@keyframes growCheck1 {
		0% {
			width: 0px;
			opacity: 0;
			left: -100px;
		}

		50% {
			opacity: 0;
			width: 0px;
		}

		100% {
			width: 10px;
			opacity: 1;
			left: 10px;
		}
	}
`;

export const leg2 = styled.div`
	position: absolute;
	margin: 0 !important  ;
	width: 20px;
	height: 2px;
	left: 15px;
	bottom: 20px;
	background-color: ${color.interface.green};
	transform: rotate(-45deg);

	animation: growCheck ease 1s;

	@keyframes growCheck {
		0% {
			width: 0px;
			opacity: 0;
			left: -100px;
		}

		50% {
			opacity: 0;
			width: 0px;
		}

		100% {
			width: 20px;
			opacity: 1;
			left: 15px;
		}
	}
`;
