import React from 'react';
import { svg } from 'lib/svgList';
import { color } from 'styles/Theme';
import GenericAccordion from 'components/GenericAccordion';

import FireYearLineGraph from '../../Graphs/FireGraph/FireYearLineGraph';
import * as S from './styled';

export default function FireData() {
	return (
		<GenericAccordion
			imgTitle={svg.icon.fogoOrange}
			widthIconTitleAccordion={1.2}
			title="Dados de Fogo"
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontWeight: '300'
			}}
		>
			<S.container>
				<S.boxGraph>
					<S.containerAccordionText>
						<h4>Histórico de focos de calor por ano</h4>
						<p>(Número de focos de calor x biênio)</p>
					</S.containerAccordionText>
					<FireYearLineGraph />
				</S.boxGraph>
			</S.container>
		</GenericAccordion>
	);
}
