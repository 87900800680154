import styled from 'styled-components';

export const countainerRiskFire = styled.div`
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	height: 100%;

	p {
		font-weight: 400;
		font-size: 0.716rem;
		letter-spacing: 0.12rem;
		text-transform: uppercase;
	}
`;
