import React, { useEffect, useState } from 'react';
import { getUserLocalStorage } from 'services/session';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersADM, getAllUsersADMPartners } from 'store/actions/userActions';
import { clearVector } from 'store/actions/mapActions';
import { clearDataMonitoringAction } from 'store/actions/monitoringDataAction';
import { deleteListAction } from 'store/actions/dataUserToShowOnMapAction';
import { hideshowfeatures } from 'components/Map/Utils';
import { useMeasureStore } from 'services/map/measure.context';
import { tabAmazonActionTis } from 'store/actions/layersAction/tabAmazon/tabAmazonActionTis';
import Users from './Users';
import Panel from './Panel';
import * as S from './styled';

function TabPanel() {
	const { tisGeoData } = useSelector((state) => state.tiGeo);

	const [isSession, setIsSession] = useState({});
	const { setShowFeatures } = useMeasureStore();

	const roleManager = isSession?.user?.user_role?.role || '';
	const dispatch = useDispatch();

	const { loading, loadingVerifyUpdate } = useSelector((state) => state.user);

	useEffect(() => {
		setIsSession(getUserLocalStorage());
		// se caso for ADM parceiro carrega a lista
		if (roleManager === 'adminPartners') {
			dispatch(getAllUsersADMPartners());
		}
		// se caso for ADM IPAM carrega a lista
		if (roleManager === 'admin') {
			dispatch(getAllUsersADM());
		}
	}, [loading, loadingVerifyUpdate]);

	useEffect(() => {
		dispatch(tabAmazonActionTis(tisGeoData, true));
		setShowFeatures(false);
	}, []);

	useEffect(() => {
		// se caso for ADM parceiro carrega a lista
		if (roleManager === 'adminPartners') {
			dispatch(getAllUsersADMPartners());
		}
		// se caso for ADM IPAM carrega a lista
		if (roleManager === 'admin') {
			dispatch(getAllUsersADM());
		}
	}, [roleManager]);

	useEffect(() => {
		hideshowfeatures('measure_draw', false);
		hideshowfeatures('shapes_draw', false);
	}, []);

	useEffect(
		() =>
			// Quando desmonta o componente limpa os estados abaixo
			() => {
				dispatch(clearDataMonitoringAction());
				dispatch(deleteListAction());
				clearVector('monitoring');
				clearVector('getPoint');
			},
		[]
	);

	return <S.containerPanel>{isSession && isSession?.token !== '' ? <Panel /> : <Users />}</S.containerPanel>;
}

export default TabPanel;
