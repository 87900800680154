import React, { useState, useEffect } from 'react';
import Search from 'components/Buttons/Search/Search';
import { formatTis, isEmptyObject, handleCloseTooltip, actionsUsers } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getDeforestation } from 'store/actions/alertDeforestationAction';
import { getDeforestationSadLast } from 'store/actions/alertDeforestationSadLastAction';
import { getDeforestationSadSum } from 'store/actions/alertDeforestationSadSumAction';
import { getDeforestationDeterLast } from 'store/actions/alertDeforestationDeterLastAction';
import { tiGeoRec } from 'store/actions/tiGeoAction';
import { fireLast30days } from 'store/actions/fireLast30DaysAction';
import { getRainRec } from 'store/actions/rainAction';
import { getTemperatuRec } from 'store/actions/temperatureAction';

import { saveTiSelected, deleteTiSelected, getInformation } from 'store/actions/tiSelectedAction';
import { removeLayerFromMap, setActivatedLayers } from 'store/actions/mapActions';
import { tiSelectedTabTiLayerAction } from 'store/actions/tiSelectedTabTiLayerAction';
import { riskFire } from 'store/actions/riskFireAction';
import GeneratePdf from './GeneratePdf';
import ReqModal from './ReqModal';
import * as S from './styled';

export default function SelectTi({ territory }) {
	const dispatch = useDispatch();
	const reduxTisList = useSelector((state) => state.listTis);

	const { informationTi, isErrorInformation } = useSelector((state) => state.tiSelectedTabTi);

	const [tiSelected, setTiSelected] = useState({
		value: 0,
		label: 'Selecione'
	});
	const [clearKeyWord, setClearKeyWord] = useState(false);
	const [isModalPdf, setIsModalPdf] = useState(false);

	function clear() {
		removeLayerFromMap('tiSelectedTabTi');
		setTiSelected({
			value: 0,
			label: 'Selecione'
		});
		setClearKeyWord(true);
		setTimeout(() => {
			setClearKeyWord(false);
		}, 1000);
		handleCloseTooltip();
		dispatch(setActivatedLayers());
	}

	const handleGA = ({ label }) => {
		actionsUsers({
			category: `plataforma/tabTis`,
			action: 'TI Selecionada',
			label
		});
	};

	useEffect(() => {
		if (tiSelected.value !== 0) {
			dispatch(saveTiSelected(tiSelected));
			dispatch(getDeforestation(tiSelected.value));
			dispatch(getDeforestationSadLast(tiSelected.value));
			dispatch(getDeforestationSadSum(tiSelected.value));
			dispatch(getDeforestationDeterLast(tiSelected.value));
			dispatch(fireLast30days(tiSelected.value));
			dispatch(tiGeoRec(tiSelected.value));
			dispatch(getRainRec(tiSelected.value));
			dispatch(getTemperatuRec(tiSelected.value));
			dispatch(getInformation(tiSelected.value));
			handleGA({ label: tiSelected.label });
			dispatch(riskFire(tiSelected.value));
		}

		if (tiSelected.value === 0) {
			dispatch(deleteTiSelected());
		}
	}, [tiSelected]);

	// quando componente destruir, voltar o redux para estado original
	useEffect(
		() => () => {
			dispatch(deleteTiSelected());
		},
		[]
	);

	// observa se possui informação da ti para gerar vetor
	useEffect(() => {
		if (!isEmptyObject(informationTi)) {
			dispatch(tiSelectedTabTiLayerAction(informationTi));
		}
	}, [informationTi]);

	useEffect(
		// limpa a ti selecionada ao trocar entre estado e ti
		() => () => {
			clear();
		},
		[territory]
	);

	return (
		<S.containerSearch
			style={territory === 'amazonia' ? { display: 'none' } : {}}
			widthContainer={tiSelected.value !== 0}
		>
			<S.search>
				<Search
					itemsList={formatTis(reduxTisList.tisList)}
					setItemSelected={setTiSelected}
					isLoading={reduxTisList.isLoading}
					placeholder="Buscar por TI"
					clearKeyWord={clearKeyWord}
					isDisable={tiSelected.value > 1}
					showMagnifying={tiSelected.value === 0}
				/>
				{tiSelected.value !== 0 && (
					// div invisivel
					<S.buttonClose type="button" onClick={() => clear()} />
				)}
			</S.search>
			{isErrorInformation && <S.noticeError>Erro ao buscar os dados sobre a TI selecionada.</S.noticeError>}

			<GeneratePdf
				isModalPdf={isModalPdf}
				setIsModalPdf={setIsModalPdf}
				tiSelected={tiSelected}
				isTiSelected={tiSelected.value}
			/>
			<ReqModal />
		</S.containerSearch>
	);
}
