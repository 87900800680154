import styled from 'styled-components';
import { color } from 'styles';

export const BoxButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const Button = styled.button`
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	border: none;
	outline: none;
	background: ${color.interface.orange};
	color: ${color.interface.white};
	height: 50px;
	width: 100%;
	font-weight: 700;
	margin-top: 20px;
	cursor: pointer;
	border-radius: 30px;
	border: 2px solid ${color.interface.orange} !important;
`;

export const SubTitle = styled.h4`
	font-size: 18px !important;
	margin-bottom: 20px !important;
	color: ${color.interface.orange};
`;

export const Label = styled.div`
	padding-left: 10px;
	margin-bottom: 5px;
	margin-top: 15px;
	font-size: 0.875rem;
	color: ${color.interface.orange};
	font-weight: 400;
`;

export const BoxLabelAlerts = styled.div`
	display: flex;
	align-items: baseline;
`;

export const Span = styled.span`
	display: flex;
	width: 100%;
	font-weight: 400;
	margin-left: 5px;
	font-size: 13px;
	color: ${color.interface.inputs};

	.register-organizations {
		justify-content: end;
		align-items: end;
		display: flex;
		cursor: pointer;
		font-size: 11px;
		font-weight: 400;
		margin-left: 5px;
		border-bottom: 1px solid black;
		padding-bottom: 2px;
		padding-bottom: 2px;
		background: transparent;
	}
`;

export const Message = styled.div`
	text-align: justify;
	font-weight: 400;
	font-size: 0.89rem;
`;
