import styled from 'styled-components';
import { color } from '../../styles/Theme';

export const accordionContainer = styled.div`
	width: 100%;
`;

export const btnTitle = styled.button`
	width: 100%;
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0;
	background-color: transparent;

	p {
		font-size: 10px;
		font-family: 'Nunito';
		font-weight: 800;
		font-style: normal;
		color: ${color.interface.darkGray};
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}

	.title-menu {
		text-transform: uppercase;
	}
`;
export const btnTitleBoxRight = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const imageBtnToggleOpenClose = styled.img`
	width: 0.8rem;
	height: 0.8rem;
	transition: all 0.5s ease;
`;

export const imgAccordion = styled.img`
	width: ${(props) => `${props.widthIcon}rem`};
	height: ${(props) => `${props.widthIcon}rem`};
`;

export const TitleAndImg = styled.div`
	display: flex;
	gap: 0.4rem;
	align-items: center;
	justify-content: center;
`;

export const newFeature = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 113px;
	height: 20px;
	background: #006946;
	border-radius: 3px;

	> p {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 800;
		font-size: 10px;

		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: #f2ede9;
	}
`;

export const line = styled.div`
	min-width: 100%;
	height: 0.5px;
	background-color: ${color.interface.lightGray};
`;

export const contentAccordion = styled.div`
	margin-top: 0.3rem;
	margin-bottom: 0.3rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
