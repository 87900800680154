import { fireTotalLast30daysType } from 'store/typesActions/types';

const initialState = {
    fireTotal: {},
    isLoading: false,
    error: false,
    message: ''
};

export default function fireLast30DaysReducer(state = initialState, action) {
    const { errors } = action?.payload || { errors: {} };

    switch (action.type) {
        case fireTotalLast30daysType.FIRE_TOTAL_LAST_30:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: 'Buscando dados do desmatamento'
            };

        case fireTotalLast30daysType.FIRE_TOTAL_LAST_30_SUCCESS:
            return {
                fireTotal: action.payload,
                isLoading: false,
                error: false,
                message: ''
            };

        case fireTotalLast30daysType.FIRE_TOTAL_LAST_30_ERROR:
            return {
                ...state,
				fireTotal: [],
				isReq: false,
                error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
            };
        default:
            return state;
    }
}