import styled from 'styled-components';
import { color } from 'styles/Theme';

export const label = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 0.5rem;
	padding: 0.1rem;
	/* background-color: ${color.interface.beige3}; */
`;

export const itemLabel = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	gap: 5px;
	/* background-color: aquamarine; */

	> img {
		width: 20px;
		height: 20px;
	}

	> p {
		font-weight: 400;
		font-size: 0.8rem;
		color: ${color.interface.lightBrown};
		transition: all ease 0.5s;
	}
`;
