import styled from 'styled-components';
import { color } from 'styles/Theme';

export const noticeEmptyTiSelected = styled.div`
	width: 100%;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	p {
		color: ${color.interface.darkGray};
	}

	span {
		color: ${color.interface.red};
	}
`;

export const containerAccordionOutside = styled.div`
	width: inherit;
	padding: 0.5rem 0.1rem 0rem 0rem;
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const notice = styled.p`
	color: ${color.interface.red};
`;
