/* eslint-disable no-plusplus */
// Altera o Array para o formato do Select
export function replaceArrayProps(arr) {
	const newArr = arr.map((obj) => {
		const newObj = {};
		newObj.label = obj.name;
		newObj.value = obj.id;
		return newObj;
	});
	return newArr;
}

// Função para filtrar por perfil

export function filterUserProfilesByUserRole(rolesPublic, systemUsers) {
	const allowedIds = systemUsers.map((user) => user.user_role.id);
	return rolesPublic.filter((user) => allowedIds.includes(user.id));
}

// Função para filtrar a organização
export function filterUserOrganization(listOrg, systemUsers) {
	const allowedIds = systemUsers.map((org) => org.user_organization.id);
	return listOrg.filter((org) => allowedIds.includes(org.id));
}

// Função para marcar e desmarcar todos os itens
export function checkIfAllChecked(systemUsers) {
	if (systemUsers.length === 0) return false;

	for (let i = 0; i < systemUsers.length; i++) {
		if (!systemUsers[i].isChecked) {
			return false;
		}
	}
	return true;
}
