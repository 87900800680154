import styled from 'styled-components';
// import { color } from '../../styles/Theme';

export const container = styled.div`
	p {
		@keyframes flashing {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
		font-size: 400;
		animation: linear;
		animation-name: flashing;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-direction: alternate-reverse;
	}
`;
