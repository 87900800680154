import { webGisType } from '../typesActions/types';

const initialState = {
	loadingSystemUsers: false,
	errorSystemUsers: false,
	messageSystemUsers: '',
	filterProfileUser: [],
	filterOrganizationUser: [],
	filterLetterUser: '',
	filterSearchUser: '',
	filterPeriod: {},
	stateModalMultiFilter: false,
	systemUsers: [],
	listDataUsers: []
};

export default function webGisReducer(state = initialState, action) {
	const { errors } = action?.payload || { erros: {} };

	switch (action.type) {
		case webGisType.WEBGIS_SYSTEM_USERS_REQUEST:
			return {
				...state,
				loadingSystemUsers: true,
				errorSystemUsers: false
			};
		case webGisType.WEBGIS_SYSTEM_USERS_SUCCESS:
			return {
				...state,
				loadingSystemUsers: false,
				errorSystemUsers: false,
				systemUsers: action.payload,
				messageSystemUsers: action.payload.message
			};
		case webGisType.WEBGIS_SYSTEM_USERS_FAILURE:
			return {
				...state,
				loadingSystemUsers: false,
				messageSystemUsers: errors.message,
				errorSystemUsers: true
			};

		case webGisType.WEBGIS_SYSTEM_USERS_UPDATE:
			return {
				...state,
				systemUsers: action.payload
			};
		case webGisType.WEBGIS_FILTER_USERS_PROFILE:
			return {
				...state,
				filterProfileUser: action.payload
			};

		case webGisType.WEBGIS_FILTER_USERS_ORGANIZATON:
			return {
				...state,
				filterOrganizationUser: action.payload
			};

		case webGisType.WEBGIS_FILTER_USERS_LETTER:
			return {
				...state,
				filterLetterUser: action.payload
			};

		case webGisType.WEBGIS_FILTER_USERS_SEARCH:
			return {
				...state,
				filterSearchUser: action.payload
			};
		case webGisType.WEBGIS_FILTER_PERIOD:
			return {
				...state,
				filterPeriod: action.payload
			};
		case webGisType.WEBGIS_FILTER_MODAL_SEARCH:
			return {
				...state,
				stateModalMultiFilter: action.payload
			};
		case webGisType.WEBGIS_FILTER_DATA_USERS:
			return {
				...state,
				listDataUsers: action.payload
			};
		default:
			return state;
	}
}
