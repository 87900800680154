import React, { useState } from 'react';
import PopUp from 'components/PopUp';
import { svg } from 'lib/svgList';
import * as S from './styled';

export default function LabelStripedList() {
	const [approved, setAproved] = useState(false);
	const [notApproved, setNotAproved] = useState(false);
	const [waiting, setSetWaiting] = useState(false);

	return (
		<S.label>
			<S.itemLabel
				onMouseOver={() => setAproved(true)}
				onMouseOut={() => setAproved(false)}
			>
				<img src={svg.icon.acceptAlert} alt="alerta aprovado" />
				<p>Aprovado</p>
				{approved && (
					<PopUp isVisiable={approved} text="O alerta foi aprovado." />
				)}
			</S.itemLabel>

			<S.itemLabel
				onMouseOver={() => setNotAproved(true)}
				onMouseOut={() => setNotAproved(false)}
			>
				<img src={svg.icon.deniedAlert} alt="alerta negado" />
				<p>Negado</p>
				{notApproved && (
					<PopUp isVisiable={notApproved} text="O alerta não foi  aprovado." />
				)}
			</S.itemLabel>

			<S.itemLabel
				onMouseOver={() => setSetWaiting(true)}
				onMouseOut={() => setSetWaiting(false)}
			>
				<img src={svg.icon.waitingAlert} alt="alerta em aprovação" />
				<p>Em andamento</p>
				{waiting && (
					<PopUp isVisiable={waiting} text="Aguardando aprovação do alerta." />
				)}
			</S.itemLabel>
		</S.label>
	);
}
