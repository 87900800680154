import styled from 'styled-components';
import { color } from 'styles';

export const container = styled.div`
	position: absolute;
	z-index: 1;
	right: ${({ open }) => (open ? `0.9em` : '0px')};
	top: 0.8em;
	display: flex;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	-ms-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;

	@media (max-width: 800px) {
		display: none;
	}
`;
export const controlContainer = styled.div`
	background-color: #fff;
	border-radius: 5px;
	padding: 2px 3px;
	& > div {
		height: 38px;
	}
	display: ${({ open }) => (open ? 'initial' : 'none')};
`;

export const ButtonArrow = styled.button`
	background-color: ${color.interface.orange};
	color: white;
	height: 37px;
	width: 30px;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	margin-top: 5px;
	border-radius: 5px 0px 0px 5px;
	box-shadow: -1px 0px 1px 0px rgb(0 0 0 / 20%);
	img {
		height: 30px;
	}

	:hover {
		//background-color: ${color.auxiliary.coral};
		cursor: pointer;
	}
`;
