import { deforestationSadLastType } from 'store/typesActions/types';

const initialState = {
    data: {},
    isLoading: false,
    error: false,
    message: ''
};

export default function deforestationSadLastReducer(state = initialState, action) {
    const { errors } = action?.payload || { erros: {} };

    switch (action.type) {
        case deforestationSadLastType.DEFORESTATION_SAD_LAST:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: 'Buscando dados do desmatamento'
            };

        case deforestationSadLastType.DEFORESTATION_SAD_LAST_SUCCESS:
            return {
                data: action.payload,
                isLoading: false,
                error: false,
                message: ''
        };

		case deforestationSadLastType.DEFORESTATION_SAD_LAST_ERROR:
			return {
				...state,
				data: [],
				isReq: false,
                error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
			};
		default:
			return state;
	}
}
