import styled from 'styled-components';

export const Container = styled.div`
	display: none;
	@media (max-width: 800px) {
		display: flex;
		z-index: 1;
		align-items: center;
		justify-content: center;
		width: 35px;
		height: 35px;
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: fit-content;

			cursor: pointer;
			:hover {
				text-decoration: none;
				background-color: #e6dcd3;
			}
			&.active {
				background-color: #e6dcd3;
			}

			.circle-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				background: #006946;
				border-radius: 50px;
				:hover {
					background-color: #d44500;
				}
				&.active {
					background-color: #d44500;
				}
			}
		}
	}
`;
