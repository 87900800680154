/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { svg } from 'lib/svgList';
import * as S from './style';
import ControlesMap from '../Controls/index';
import MobilMenuCarousel from './MobilMenuCarousel';
import InfoContainerWeb from '../Controls/Info/web';
import MapInfo from '../Controls/MapInfo/web';

function SideBar({ mapSomai }) {
	const [open, setOpen] = useState(true);

	return (
		<>
			<S.container open={open}>
				<S.ButtonArrow
					open={open}
					onClick={() => setOpen(!open)}
					data-title="Ferramentas de controle de mapa"
					className="sm__tooltip"
				>
					<img alt="arrow" src={svg.icon.hideShow} />
				</S.ButtonArrow>
				<S.controlContainer open={open}>
					<ControlesMap mapSomai={mapSomai} />
				</S.controlContainer>
			</S.container>
			<InfoContainerWeb />
			<MapInfo mapSomai={mapSomai} />
			<MobilMenuCarousel mapSomai={mapSomai} />
		</>
	);
}

export default SideBar;
