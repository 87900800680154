import { color } from 'styles';

const tabBgColor = (currentTab) => {
	const style1 = {
		tab1: {
			theme: {
				color: color.interface.orange,
				bg: color.interface.white
			}
		},
		tab2: {
			theme: {
				color: color.interface.white,
				bg: color.interface.green
			}
		}
	};

	const style2 = {
		tab1: {
			theme: {
				color: color.interface.white,
				bg: color.interface.green
			}
		},
		tab2: {
			theme: {
				color: color.interface.orange,
				bg: color.interface.white
			}
		}
	};

	switch (currentTab) {
		case 'tab1':
			return style1;
		default:
			return style2;
	}
};
export { tabBgColor };
