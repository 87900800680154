import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerAccordionInside = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
`;

export const container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-top: 0.5rem;
`;

export const line = styled.div`
	width: 100%;
	min-height: 0.5px;
	max-height: 0.5px;
	background-color: ${color.interface.lightGray};
`;
