import styled from 'styled-components';
import { color } from 'styles';

export const ContainerItems = styled.div`
	width: 180px;
	padding: 10px;
`;

export const ButtonLogin = styled.button`
	border: none;
	outline: none;
	background: ${color.interface.orange};
	color: ${color.interface.white};
	height: 29px;
	width: 100%;
	font-weight: 700;
	margin-top: 15px;
	cursor: pointer;
	border-radius: 30px;
`;

export const ButtonRegister = styled.button`
	border: none;
	outline: none;
	background: #f8f5f3;
	height: 29px;
	width: 100%;
	font-weight: 700;
	color: ${color.interface.orange};
	cursor: pointer;
	border-radius: 30px;
	margin-top: 7px;
	border: 1px solid ${color.interface.orange} !important;
`;

export const ButtonUser = styled.button`
	background-color: transparent;
	cursor: pointer;
	vertical-align: middle;
	text-decoration: none;
	color: inherit;
	text-align: center;
	flex: 0 0 auto;
	font-size: 1.5rem;
	padding: 8px;
	border-radius: 50%;
	overflow: visible;
	padding-right: 16px;
`;
