import React, { memo } from 'react';
import SelectState from './SelectState';

function TabAmazon({
	isOpenModalIndex,
	setIsOpenModalIndex,
	setStateSelectedIndex,
	territory
}) {
	return (
		<SelectState
			territory={territory}
			isOpenModal={isOpenModalIndex}
			setIsOpenModal={setIsOpenModalIndex}
			setStateSelectedIndex={setStateSelectedIndex}
		/>
	);
}

export default memo(TabAmazon);
