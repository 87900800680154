import React, { useState, useEffect } from 'react';
import { useOutsideClick } from '@chakra-ui/react';
import Load from '../../Load/Load';
import { svg } from '../../../lib/svgList';
import * as S from './styled';

// Para usar este componente é preciso passar os seguintes parametros
// itemSelected: o item que será selecionado na lista
// setItemSelected : para selecionar o item da lista que for clicado
// options: passe uma array de objetos com duas propriedades com qualquer nome,
// mas na sequencia da primeira propriedade do objeto ser id e o segundo o item,
// q o retorno dos valores seram: primeira value (id) e a sedunda o label (opção selecionada)
// img: Não é obrigatório, só caso queira a opção de baixar os dados do item selecionado
// isLineOnTheList: se quiser linha entre os itens
// functionBtn: função que será executa ao clicar no btn
// style: caso queira passar algum estilo pro select
// btnDisabled: passe obrigatoriamente este item para setar o btn da função e estilos
// showFirtsItemOptionList: decidir se o primeiro item da lista ja vem selecionado

export default function Select({
	itemSelected = '',
	placeHolder = 'Selecione',
	setItemSelected = () => {},
	options = [],
	img = '',
	isLineOnTheList = false,
	textBtn = 'Gerar Relatório',
	functionBtn = () => {},
	btnDisabled = false,
	style = null,
	isLoading = false,
	isError = false,
	displayListInitial = false,
	showFirtsItemOptionList = false,
	disabled = false
}) {
	const ref = React.useRef();
	const [isOpen, setIsOpen] = useState(false);

	// essa função converte o objeto option para chaves {value:... label:...}
	function returnObjEdited(item) {
		const value = Object.keys(item)[0];
		const label = Object.keys(item)[1];
		return { value: item[value], label: item[label] };
	}

	// função chakra para fechar modal quando clicado fora dela
	useOutsideClick({
		ref,
		handler: () => setIsOpen(false)
	});

	// decidir se o primeiro item da lista ja vem selecionado
	useEffect(() => {
		if (options.length > 0 && showFirtsItemOptionList) {
			setItemSelected(returnObjEdited(options[0]));
		}
	}, [options.length > 1]);

	return (
		<S.container>
			<S.containerSelect ref={ref} props={{ displayListInitial }}>
				<S.itemSelected
					onClick={() => {
						if (!disabled) {
							setIsOpen(!isOpen);
						}
					}}
					style={img === '' ? { borderRadius: '8px', ...style } : style}
				>
					<p>
						{isLoading ? 'Carregando...' : itemSelected?.label || placeHolder}
					</p>
					<img
						src={svg.icon.rollScreem}
						alt="Seta abrir ou fechar menu"
						style={
							isOpen
								? { transform: 'rotate(180deg)' }
								: { transform: 'rotate(0deg)' }
						}
					/>
				</S.itemSelected>

				{isOpen ? (
					<S.List props={{ displayListInitial }}>
						{isLoading ? (
							<Load />
						) : (
							<>
								{/* <S.itemOnTheListSelect>
									{itemSelected.label || 'Selecione'}
								</S.itemOnTheListSelect>
								<S.line /> */}
								{options.length !== 0 ? (
									options &&
									options.map((item, i) => (
										<S.itemOnList key={returnObjEdited(item).value}>
											<button
												type="button"
												onClick={() => {
													if (!disabled) {
														setItemSelected(returnObjEdited(item));
														setIsOpen(false);
													}
												}}
												onKeyDown={() => {
													if (!disabled) {
														setItemSelected(returnObjEdited(item));
														setIsOpen(false);
													}
												}}
											>
												{returnObjEdited(item).label}
											</button>
											{isLineOnTheList && i !== options.length - 1 ? (
												<S.line />
											) : null}
										</S.itemOnList>
									))
								) : (
									<p>Não há itens </p>
								)}
							</>
						)}
					</S.List>
				) : null}
			</S.containerSelect>

			{!itemSelected.label && isError ? (
				<S.ContainerDivError>
					<span>Campo obrigatório</span>
				</S.ContainerDivError>
			) : null}

			{img !== '' && !displayListInitial ? (
				<S.buttonDonwload
					type="button"
					disabled={btnDisabled}
					onClick={() => {
						if (!disabled) {
							functionBtn();
						}
					}}
				>
					<img src={img} alt="icone do botão" />
					<p>{textBtn}</p>
				</S.buttonDonwload>
			) : null}
		</S.container>
	);
}
