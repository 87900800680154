import { editDataUserOnMap } from '../typesActions/types';

export const editItem = (data) => {
	return {
		type: editDataUserOnMap.INIT_EDIT_DATA_ON_MAP,
		payload: data
	};
};

export const finishEdit = () => {
	return {
		type: editDataUserOnMap.FINISHED_EDIT_DATA_ON_MAP
	};
};

export const closeMessageEdit = () => {
	return { type: editDataUserOnMap.CLOSE_MESSAGE };
};
