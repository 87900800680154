import styled, { css } from 'styled-components';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
// import { color } from 'styles';

export const container = styled.div`
	display: none;

	@media (max-width: 800px) {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 10;
		//left: 50%;
		width: 100%;
		z-index: 1;
		background-color: #fff;
	}
`;

export const containerWrapper = styled.div`
	max-width: 75vw;
	.carousel::-webkit-scrollbar {
		display: none;
	}
`;

export const SliderContainer = styled.div`
	display: flex;
	//grid-template-columns: repeat(7, 1fr);
	overflow-x: auto;
	scroll-behavior: smooth;
	align-items: center;

	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	& > div {
		width: 49px;
		height: 49px;
		& + div {
			border-top: 0;
			border-right: 1px solid;
			border-color: #d9d5d2;
		}
		button {
			width: 49px;
			height: 49px;
			:hover {
				background-color: #e6dcd3;
				height: 100%;
				border-radius: 0;
			}
			&.active {
				height: 100%;
				background-color: #e6dcd3;
				border-radius: 0;
			}
		}
	}
`;
export const SliderButtonsContainer = styled.div`
	position: relative;
	display: flex;
	z-index: 10;
`;

const arrowButton = css`
	height: 28px;
	width: 28px;
	color: #fff;
	position: absolute;
	bottom: 10px;
	cursor: pointer;
	background: #d3c1b2;
	border-radius: 50px;

	//margin-right: 1rem;
	user-select: none;
	transition: 0.3s;
	&:hover {
		background: #d44500;
		transform: scale(1.05);
	}
`;

export const PrevArrow = styled(FiChevronLeft)`
	${arrowButton}
	left: -45px;
`;
export const NextArrow = styled(FiChevronRight)`
	${arrowButton}
	right: -45px;
`;
