import { fireFocusByMonthType } from 'store/typesActions/types';

const initialState = {
    fireMonth: [],
    isLoading: false,
    error: false,
    message: ''
};

export default function fireFocusByMonthReducer(state = initialState, action) {
    const { errors } = action?.payload || { errors: {} };

    switch (action.type) {
        case fireFocusByMonthType.FIRE_FOCUS_BY_MONTH:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: 'Buscando dados do desmatamento'
            };

        case fireFocusByMonthType.FIRE_FOCUS_BY_MONTH_SUCCESS:
            return {
                fireMonth: action.payload,
                isLoading: false,
                error: false,
                message: ''
            };

        case fireFocusByMonthType.FIRE_FOCUS_BY_MONTH_ERROR:
            return {
                ...state,
				fireMonth: [],
				isReq: false,
                error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
            };
        default:
            return state;
    }
}