import styled from 'styled-components';
import { color } from '../../../styles/Theme';

export const ContainerButton = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		padding: 0.8rem 1.5rem;
		border-radius: 8px;
		color: ${color.interface.white};
		font-weight: 900;
		background-color: ${color.interface.orange};
		font-weight: 700;
		cursor: pointer;
		pointer-events: fill;
		transition: all 0.3s ease;
		display: flex;
		align-items: center;
		justify-content: center;

		gap: 1rem;
		:hover {
			background-color: ${(props) =>
				props.isHover ? color.interface.green : null};
		}
		:active {
			transform: scale(0.9);
		}
	}
`;

export const ContainerButtonDisable = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;

	button {
		width: 100%;
		padding: 0.8rem 1.5rem;
		border-radius: 8px;
		color: ${color.interface.white};
		font-weight: 900;
		background-color: ${color.interface.brown};
		cursor: pointer;
		pointer-events: fill;
		transition: all 0.3s ease;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
	}

	img {
		margin-left: 1rem;
	}
`;

export const ContainerButtonFire = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin-bottom: 1rem; */
	button {
		white-space: nowrap;
		width: 100%;
		padding: 0.8rem 1.2rem;
		border-radius: 7px;
		color: ${color.interface.gray};
		font-weight: 200;
		font-size: 0.75rem;
		background-color: ${color.interface.white};
		cursor: pointer;
		pointer-events: fill;
		transition: all 0.3s ease;
		text-transform: uppercase;
		border: 1px solid;
		border-color: ${color.interface.lightGray};
		margin: 7px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1000;
		:active {
			transform: scale(0.9);
		}

		@media (max-width: 1085px) {
			font-size: 0.6rem;
			height: 3rem;
		}
	}
`;

export const iconImg = styled.img`
	width: ${(props) => `${props.widthIcon}px`};
`;
