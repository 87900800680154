import React from 'react';

export function textAdviceState() {
	return (
		<>
			<b>Orientações para gerar relatório:</b>
			<p>
				- Será gerado relatório com os dados aglomerados do estado selecionado.
			</p>
			<p>
				- Caso queira a informação individual de alguma TI, gere o relatório
				pela caixa de informações da própria TI.
			</p>
		</>
	);
}

export function textAdviceTi() {
	return (
		<>
			<b>Orientações para gerar relatório:</b>
			<p>
				- Será gerado relatório da <b>TI que foi selecionada</b> .
			</p>
			<p>
				- <b>Antes</b> de gerar o relatório, <b>ajuste o zoom do mapa</b> para o
				local desejado, pois será feito print do mapa para exportar em seu
				relatório no formato PDF.
			</p>
		</>
	);
}
