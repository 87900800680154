import { svg } from 'lib/svgList';
import { urlLanding } from 'lib/urlLanding';

export const itens = [
	{
		title: 'PÁGINA INICIAL',
		href: urlLanding,
		divider: true,
		urlIcon: svg.icon.access
	},
	{
		title: 'SOBRE',
		href: `${urlLanding}/sobre`,
		divider: true,
		urlIcon: svg.icon.maximize
	},
	{
		title: 'AJUDA',
		href: `${urlLanding}/ajuda`,
		divider: true,
		urlIcon: svg.icon.helpCircle
	},
	{
		title: 'GLOSSÁRIO',
		href: `${urlLanding}/glossario`,
		divider: true,
		urlIcon: svg.icon.glossary
	},
	{
		title: 'OUTRAS INICIATIVAS',
		href: `${urlLanding}/outras_iniciativas`,
		divider: true,
		urlIcon: svg.icon.forwardBlack
	},
	{
		title: 'FALE CONOSCO',
		href: `${urlLanding}/fale_conosco`,
		divider: false,
		urlIcon: svg.icon.contact
	}
];
