import styled from 'styled-components';
// import { color } from '../../styles/Theme';

export const containerSelect = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 0px 5px 0px 5px;
`;

export const boxToggle = styled.div`
	width: 100%;
	align-self: flex-start;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;

	p {
		opacity: 0.7;
		font-size: 0.9rem;
	}
`;
