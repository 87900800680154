import { termsOfUse } from '../typesActions/types';

export default function termsOfUserAcceptReducer(state = false, action) {
	switch (action.type) {
		case termsOfUse.ACCEPT:
			return (state = true);

		case termsOfUse.NO_ACCEPT:
			return (state = false);

		default:
			return state;
	}
}
