/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { svg } from 'lib/svgList';
import CheckInput from 'components/Buttons/Check';
import { handleExportMap } from 'helpers/utils';
import { color } from '../../../../../styles/Theme';

const Background = styled.div`
	position: absolute;
	width: 220px;
	z-index: 1300;
	top: 0;
	right: 3rem;
	border: 1px solid #d9d5d2 !important;
	@media (max-width: 800px) {
		top: 3.5rem;
		right: 7rem;
		width: 11rem;
	}
`;

const ModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #fff;
	color: #000;
	border-radius: 10px;
	padding: 1rem;
`;

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	//justify-content: center;
	//align-items: center;
	margin: 1rem 0;
`;

const ModalContentTitle = styled.h1`
	font-size: 0.9375rem;
	font-weight: 900;
	color: #69625d;
	@media (max-width: 800px) {
		font-size: 0.9rem;
	}
`;

const ModalFooter = styled.div`
	display: flex;
	align-items: center;

	button {
		width: 70px;
		height: 22px;
		border-radius: 20px;
		color: ${color.interface.white};
		font-weight: 800;
		background-color: ${color.interface.orange};
		cursor: pointer;
		pointer-events: fill;
		transition: all 0.3s ease;
		display: flex;
		justify-content: center;
		font-size: 10px;
		@media (max-width: 800px) {
			width: 9rem !important;
		}

		:hover {
			background-color: ${color.interface.green};
			border-radius: 20px;
		}
		:disabled {
			background-color: #cccccc;
			color: #fff;
			cursor: not-allowed;
		}
	}
`;
const CheckContainer = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	min-height: 28px;
`;

function ListModal({
	showModal,
	setShowModal,
	toggleBaseLayer,
	options,
	disabled
}) {
	const modalRef = useRef();
	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModal(false);
		}
	};

	const keyPress = useCallback(
		(e) => {
			if (e.key === 'Escape' && showModal) {
				setShowModal(false);
			}
		},
		[setShowModal, showModal]
	);

	useEffect(() => {
		document.addEventListener('keydown', keyPress);
		return () => document.removeEventListener('keydown', keyPress);
	}, [keyPress]);

	const toggleSaveMap = () => {
		options.forEach((option) =>
			option.isChecked ? handleExportMap(option.format) : null
		);
	};

	return (
		<>
			{showModal ? (
				<Background onClick={closeModal} ref={modalRef}>
					<ModalWrapper showModal={showModal}>
						<ModalContentTitle>
							Selecione as opções de download
						</ModalContentTitle>
						<ModalContent>
							{options.map(({ name, isChecked }, i) => (
								<CheckContainer key={i}>
									<CheckInput
										id={i}
										onClick={() => toggleBaseLayer(isChecked, i)}
										isChecked={isChecked}
										text={name}
										defaultCheckColor={false}
										widthCheck="16px"
									/>
								</CheckContainer>
							))}
						</ModalContent>
						<ModalFooter>
							<button onClick={toggleSaveMap} type="button" disabled={disabled}>
								Baixar
								<img
									style={{ width: 12, marginLeft: 5 }}
									src={svg.icon.download}
									alt="icone ilustrativo"
								/>
							</button>
						</ModalFooter>
					</ModalWrapper>
				</Background>
			) : null}
		</>
	);
}

export default ListModal;
