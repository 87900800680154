/* eslint-disable no-unused-vars */
import React, { useEffect, memo } from 'react';
import Overlay from 'ol/Overlay';
import { handleLayerGetInfo } from 'store/actions/layersAction/toggleLayersTabTi/toggleLayersTabTi';
// import { actionTisFixed } from 'store/actions/layersAction/tabAmazon/actionTisFixed';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './TooltipMapServer.style';

function TooltipMapServer({ mapSomai }) {
	const dispatch = useDispatch();
	const isLoadMap = useSelector((state) => state.isLoadMap);

	useEffect(() => {
		const container = document.getElementById('tooltip-mapserver');

		// cria tooltip
		const tooltip = new Overlay({
			element: container,
			autoPan: true,
			autoPanAnimation: {
				duration: 250
			}
		});
		mapSomai.addOverlay(tooltip);
		if (isLoadMap) dispatch(handleLayerGetInfo(tooltip));
	}, [isLoadMap]);

	return (
		<S.TooltipContainer id="tooltip-mapserver" className="ol-tooltip">
			<S.TooltipContent id="tooltip-content">
				<div id="info-mapserver" style={{ fontFamily: 'Nunito' }}>
					&nbsp;
				</div>
			</S.TooltipContent>
		</S.TooltipContainer>
	);
}

export default memo(TooltipMapServer);
