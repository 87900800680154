import React, { useState } from 'react';
import { svg } from 'lib/svgList';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'components/Modal';
import Button from 'components/Buttons/Button/Button';
import PackReq from 'components/PackReq';
import { returnUser } from 'helpers/utils';
import { color } from 'styles/Theme';
import { generatePdf } from 'store/actions/statesAmazonLegalAction';
import * as S from './styled';

// isTiSelected: serve para saber se uma TI foi selecionada ou não. Se for diferente de 0, há TI selecionada.
export default function GeneratePdf({ text = 'Gerar Relatório', tiSelected = {}, isTiSelected = 0 }) {
	// dados do usuário logado
	const userData = returnUser(); // JSON.parse(window.localStorage.getItem('user'));

	const dispatch = useDispatch();

	const { isReqPdf, isReqPdfError } = useSelector((state) => state.amazonLegal);

	const [isModalPdf, setIsModalPdf] = useState(false);
	const [isShowPaqReq, setIsShowPaqReq] = useState(false);

	function returnNotice() {
		return (
			<>
				<div>
					<S.titleModal>Orientações para gerar relatório:</S.titleModal>
					<p>
						- <b>Ajuste o zoom</b> do mapa para definir a imagem do relatório.
					</p>
					<p>
						- Será gerado relatório da <b>TI selecionada</b>.
					</p>
					{tiSelected.value === 0 && <S.notice>- Selecione uma terra indígena.</S.notice>}

					{tiSelected.value === 0 && <S.noticeFlashing>- Buscando dados sobre a TI...</S.noticeFlashing>}
				</div>
				<Button
					style={{
						backgroundColor: tiSelected.value === 0 && color.interface.darkGray
					}}
					text="Gerar Relatório"
					isDisable={tiSelected.value === 0}
					onClick={() => {
						setIsShowPaqReq(true);
						dispatch(generatePdf(tiSelected.value, true));
					}}
				/>
			</>
		);
	}

	return (
		<S.containerReport>
			<S.btnGenerate onClick={() => setIsModalPdf(!isModalPdf)} type="button" isTiSelected={isTiSelected}>
				<img alt="close" src={svg.icon.downloadDoc} />
				{text}
			</S.btnGenerate>

			<Modal isOpen={isModalPdf} setIsOpen={setIsModalPdf} useBtnEnd={false} isClose={() => setIsShowPaqReq(false)}>
				<S.containerModalNotice>
					{isShowPaqReq ? (
						<PackReq
							isReq={isReqPdf}
							errorReq={isReqPdfError}
							functionBtn={() => {
								setIsShowPaqReq(false);
								setIsModalPdf(false);
							}}
						/>
					) : (
						returnNotice()
					)}
				</S.containerModalNotice>
			</Modal>
		</S.containerReport>
	);
}
