/* eslint-disable no-plusplus */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionFilterDataUsers } from 'store/actions/webGisActions';
import { sortAndFilterList } from 'helpers/utils';

function createLists(filterList) {
	const alerts = [];
	const traditionalUses = [];
	const tracks = [];
	const whereiBeen = [];
	const allRegisters = [];

	filterList.forEach((user) => {
		if (user.isChecked) {
			user.alerts.forEach((alert) => {
				if (user.firstName) {
					alert.published = `${user.firstName} ${user.lastName}`;
				}

				alert.isChecked = false;
				alert.typeRegister = 'alert';
			});

			user?.traditional_uses.forEach((traditionalUse) => {
				if (user.firstName) {
					traditionalUse.published = `${user.firstName} ${user.lastName}`;
				}
				traditionalUse.isChecked = false;
				traditionalUse.typeRegister = 'traditionalUse';
			});

			user?.tracks.forEach((track) => {
				if (user.firstName) {
					track.published = `${user.firstName} ${user.lastName}`;
				}
				track.isChecked = false;
				track.type = 'tracker';
				track.typeRegister = 'tracker';
			});

			user?.where_i_been.forEach((place) => {
				if (user.firstName) {
					place.published = `${user.firstName} ${user.lastName}`;
				}
				place.isChecked = false;
				place.type = 'where_i_been';
				place.typeRegister = 'whereIBeen';
			});

			alerts.push(...user.alerts);
			traditionalUses.push(...user.traditional_uses);

			tracks.push(...user.tracks);
			whereiBeen.push(...user.where_i_been);

			allRegisters.push(...user.alerts);
			allRegisters.push(...user.traditional_uses);

			allRegisters.push(...user.tracks);
			allRegisters.push(...user.where_i_been);
		}
	});

	return { alerts, traditionalUses, tracks, whereiBeen, allRegisters };
}

function FilterDataUsersAci() {
	const dispatch = useDispatch();
	const {
		systemUsers,
		filterSearchUser,
		filterLetterUser,
		filterOrganizationUser,
		filterProfileUser,
		filterPeriod
	} = useSelector((state) => state.webGis);

	const filterList = sortAndFilterList(
		systemUsers,
		filterLetterUser,
		filterSearchUser,
		filterProfileUser,
		filterOrganizationUser,
		filterPeriod
	);

	useEffect(() => {
		dispatch(actionFilterDataUsers(createLists(filterList)));
	}, [
		systemUsers,
		filterLetterUser,
		filterSearchUser,
		filterProfileUser,
		filterOrganizationUser,
		filterPeriod
	]);

	return null;
}

export default FilterDataUsersAci;
