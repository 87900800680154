/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { vectorsDataUsersWebGis } from 'store/actions/layersAction/dataUsersWebGis';
import {
	removeLayerFromMap,
	onLoadDataUsersWebGis,
	setActivatedLayers,
	clearVector,
	getIconVector
} from 'store/actions/mapActions';
import { returnReqEndPoint } from 'helpers/utils';

import { addRouteMonitoring } from 'store/actions/monitoringDataAction';
import { useDispatch, useSelector } from 'react-redux';
import { getItemProperties } from './inforToggle';
import Toggle from './toggle';
import * as S from './styled';

// recebe o tipo de alerta e faz o filtro
function filterAlertsByType(allRegisters, typeData) {
	const filteredAlerts = allRegisters.filter(
		(alert) => alert.typeRegister === typeData
	);
	return filteredAlerts;
}

// Obrigatorio passar o typeData que também e definido em components/map/FilterDataUsersAci
export default function Item({ alertsList, typeData }) {
	const dispatch = useDispatch();
	const { listDataUsers } = useSelector((state) => state.webGis);

	const { allRegisters } = listDataUsers;

	// se caso algum toggle for clicado ele ira para a lista itensToggle
	const [itensToggle, setItensToggle] = useState([]);

	// recebe a lista filtrada por alerta existente e marca como true ou false de acordo com o toggle
	const updatedAlertsList = alertsList.map((alert) => {
		const toggleItem = itensToggle.find((item) => item.type === alert.type);

		if (toggleItem) {
			return { ...alert, isChecked: true };
		}
		return { ...alert, isChecked: false };
	});

	const newList = filterAlertsByType(allRegisters, typeData).map((d) => {
		const item = updatedAlertsList.find((i) => i.type === d.type);
		if (item) {
			return { ...d, isChecked: item.isChecked };
		}
		return d;
	});

	useEffect(() => {
		alertsList.forEach((onMap) => {
			onMap.name = onMap.title;
			onMap.infor = getItemProperties(onMap);

			// para evitar conflitos no nome da camada
			const name = onMap.typeRegister
				? onMap.type + onMap.typeRegister
				: onMap.type;

			const dataMap = {
				name,
				label: onMap.name,
				icon: getIconVector({ item: onMap.type })
			};
			// cada vez que a lista e atualizada limpa os vetores
			// removeLayerFromMap(dataMap.name);

			if (newList?.length > 0 && itensToggle?.length > 0) {
				itensToggle.map((item) => {
					if (item.type === onMap.type) {
						dispatch(vectorsDataUsersWebGis({ dataMap })).then(() => {
							dispatch(
								onLoadDataUsersWebGis({
									data: newList.filter((f) => f.type === onMap.type),
									dataMap
								})
							).then(() => dispatch(setActivatedLayers()));
						});
					}
					return null;
				});
			}
		});
	}, [newList]);

	// Add a rota para as apis
	useEffect(async () => {
		if (typeData !== '') {
			dispatch(addRouteMonitoring(returnReqEndPoint(typeData)));
		}
	}, [typeData]);

	useEffect(
		() => () => {
			// ao desmontar o componente remove a camada e limpa a legenda
			alertsList.forEach((onMap) => {
				// para evitar conflitos no nome da camada
				const name = onMap.typeRegister
					? onMap.type + onMap.typeRegister
					: onMap.type;

				const dataMap = {
					name,
					label: onMap.name
				};
				removeLayerFromMap(dataMap.name);
				setTimeout(() => dispatch(setActivatedLayers()), 100);
			});
		},
		[alertsList]
	);

	return (
		alertsList?.length > 0 &&
		alertsList.map((item) => {
			item.name = item.title;
			item.infor = getItemProperties(item);

			const data = newList.find((d) => d.type === item.type);

			// para evitar conflitos no nome da camada
			const name = item.typeRegister
				? item.type + item.typeRegister
				: item.type;

			return (
				<S.containerShowDataListAccordion key={item.type}>
					<Toggle
						item={item}
						itemToggle={data}
						listDownload={newList.filter((f) => f.type === item.type)}
						onToggle={(isChecked) => {
							const updatedItem = { ...item, isChecked };

							if (isChecked) {
								// verifica se o item já existe na lista
								if (!itensToggle.some((i) => i.id === updatedItem.id)) {
									setItensToggle([...itensToggle, updatedItem]);
								}
							} else {
								setItensToggle(
									itensToggle.filter((i) => i.id !== updatedItem.id)
								);

								// se o toggle for false remove a camada e limpa a legenda
								removeLayerFromMap(name);
								setTimeout(() => dispatch(setActivatedLayers()), 100);
							}
						}}
					/>
				</S.containerShowDataListAccordion>
			);
		})
	);
}
