/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import SwitchToggleLayers from 'components/Buttons/SwitchToggleLayers/index';
import SliderYears from 'components/SliderYears';
import DownloadLayers from 'components/DownloadLayers';
import { color } from 'styles/Theme';
import { actionsUsers } from 'helpers/utils';
// import { svg } from 'lib/svgList';
import BalloonDoubts from 'components/BalloonDoubts';
import GridBox from 'components/Grid/Grid';
import Row from 'components/Grid/Row';
import { useColorsContext } from 'services/color.context';
import { useShapeStore } from 'services/map/shapes.context';
import TileLayer from 'ol/layer/Tile';
import { getLayerByName } from 'store/actions/mapActions';
import { useMeasureStore } from 'services/map/measure.context';
import { Box, NewContent } from '../../LayersGroupByList/styled';
import * as S from '../styled';

// useToggle é para decidir se o comp de carregar layer vai com toggle ou checkbox
export default function ItemLayer({
	item,
	isActiveted,
	setIsActiveted = () => {},
	useToggle = true
}) {
	const [period, setPeriody] = useState(0);
	const { showModal, setShowModal, handleAddLayer, nameLayer } =
		useColorsContext();
	const {
		showModalMeasure,
		setShowModalMeasure,
		resetInteraction,
		state: stateMeasure
	} = useMeasureStore();
	const {
		showModalDraw,
		setShowModalDraw,
		resetInteraction: resetInteractionDraw,
		state: stateDraw
	} = useShapeStore();

	const openModal = (nameLayer) => {
		if (showModalDraw || stateDraw.drawShapesIsActive) {
			setShowModalDraw(false);
			resetInteractionDraw();
		}
		if (showModalMeasure || stateMeasure.drawMeasuresIsActive) {
			setShowModalMeasure(false);
			resetInteraction();
		}
		actionsUsers({
			category: `plataforma/mapa`,
			action: 'Camada Selecionada para a troca de cor',
			label: nameLayer
		});

		setShowModal(true);
		handleAddLayer(nameLayer);
	};

	function checkHandlerTileLayer(name, isActiveted) {
		const layer = getLayerByName(name);
		if (layer && layer instanceof TileLayer && isActiveted) {
			return true;
		}
		return false;
	}

	// const openaAttributions = (nameLayer) => {
	// 	console.log(nameLayer);
	// };

	return (
		<S.containerItem isPadding={useToggle}>
			<GridBox>
				<Row>
					<Box size={0.8}>
						<SwitchToggleLayers
							itemLayer={item}
							setIsActiveted={setIsActiveted}
							useToggle={useToggle}
						/>
					</Box>
					<Box size={8.2}>
						<S.title>
							{item?.newLayer && <NewContent>NOVO</NewContent>}
							{item.name}
						</S.title>
					</Box>
					<Box size={3}>
						<S.boxIcons>
							{checkHandlerTileLayer(item.name_mapfile, isActiveted) && (
								<button
									type="button"
									className={
										showModal && nameLayer === item.name_mapfile
											? `layout-paint__btn active`
											: `layout-paint__btn`
									}
									onClick={() => openModal(item.name_mapfile)}
								>
									<svg
										width="18"
										height="14"
										viewBox="0 0 59 55"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M51 38C50.6663 37.9995 50.3377 38.0812 50.0443 38.2377C49.7509 38.3942 49.5019 38.6205 49.32 38.896C48 40.8751 45 45.7194 45 48.0923C45 49.6591 45.6321 51.1618 46.7574 52.2697C47.8826 53.3776 49.4087 54 51 54C52.5913 54 54.1174 53.3776 55.2426 52.2697C56.3679 51.1618 57 49.6591 57 48.0923C57 45.7194 54 40.8751 52.67 38.896C52.489 38.6219 52.2416 38.3965 51.9501 38.24C51.6585 38.0836 51.332 38.0011 51 38Z"
											fill={
												nameLayer === item.name_mapfile ? '#D44500' : '#69625D'
											}
										/>
										<path
											d="M58.4144 29.2264L34.9413 5.43536L34.7728 5.30475L33.1365 3.63697C30.9386 1.39406 27.9743 0.0920853 24.856 0H23.8643C22.0128 0.0887269 20.2055 0.602377 18.5786 1.50218C16.9518 2.40199 15.5479 3.66443 14.4731 5.19424C12.8078 7.64772 12.0518 10.6184 12.3385 13.5818C12.6251 16.5452 13.9361 19.3106 16.0399 21.3898L16.1986 21.5606L8.87006 29.0154C8.31293 29.5805 8 30.3466 8 31.1453C8 31.9441 8.31293 32.7101 8.87006 33.2753L26.4823 51.1185C27.0401 51.683 27.7963 52 28.5847 52C29.3731 52 30.1292 51.683 30.687 51.1185L47.4266 34.1594H47.6646V33.9183L48.9141 32.15H57.0162C58.7814 32.6524 59.664 30.4923 58.4144 29.2264ZM16.1887 12.4079C16.1887 10.183 17.0611 8.04915 18.614 6.47588C20.1669 4.90261 22.2731 4.01875 24.4692 4.01875C25.5582 4.01637 26.6368 4.23269 27.6428 4.65518C28.6488 5.07768 29.5621 5.69798 30.33 6.48024L30.4094 6.56061L18.6976 18.426L18.6084 18.3456C17.8394 17.5658 17.2298 16.6399 16.8146 15.621C16.3993 14.6021 16.1867 13.5102 16.1887 12.4079ZM28.5847 47.572L15.3457 34.1594H41.8137L28.5847 47.572ZM48.9141 28.1313H47.2679L46.1077 29.8091L45.7804 30.1406H13.3624L33.5431 9.70529L52.2263 28.1313H48.9141Z"
											fill={
												nameLayer === item.name_mapfile ? '#D44500' : '#69625D'
											}
										/>
									</svg>
								</button>
							)}

							{/* <button
								type="button"
								className="layout-paint__btn"
								onClick={() => openaAttributions('tabAmazonTis')}
							>
								<svg
									width="14"
									height="12"
									viewBox="0 0 14 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.5 0H1.5C1.24056 0.0175488 0.996279 0.12854 0.812409 0.312409C0.62854 0.496279 0.517549 0.740562 0.5 1V11C0.517549 11.2594 0.62854 11.5037 0.812409 11.6876C0.996279 11.8715 1.24056 11.9825 1.5 12H12.5C12.7594 11.9825 13.0037 11.8715 13.1876 11.6876C13.3715 11.5037 13.4825 11.2594 13.5 11V1C13.4825 0.740562 13.3715 0.496279 13.1876 0.312409C13.0037 0.12854 12.7594 0.0175488 12.5 0ZM12.5 1.0425V3.75H7.5V1H12.4575C12.474 1.01157 12.4884 1.02596 12.5 1.0425ZM7.5 4.75H12.5V7.25H7.5V4.75ZM6.5 7.25H1.5V4.75H6.5V7.25ZM1.5425 1H6.5V3.75H1.5V1.0425C1.51157 1.02596 1.52596 1.01157 1.5425 1ZM1.5 10.9575V8.25H6.5V11H1.5425C1.52596 10.9884 1.51157 10.974 1.5 10.9575ZM12.4575 11H7.5V8.25H12.5V10.9575C12.4884 10.974 12.474 10.9884 12.4575 11Z"
										fill="#69625D"
									/>
								</svg>
							</button> */}
							{!item.tisJson ? (
								<DownloadLayers item={item} period={period} />
							) : null}
							<BalloonDoubts item={item} />
						</S.boxIcons>
					</Box>
				</Row>
			</GridBox>

			{item?.isPeriod && (
				<S.containerSlider>
					<SliderYears
						color={color.interface.green}
						disabled={!isActiveted}
						minPeriod={item.minPeriod}
						maxPeriod={item.maxPeriod}
						step={1}
						// se não existir o valor default pega o ano final
						defaultValue={item.defaultPeriod || item.maxPeriod}
						nameLayer={item.name_mapfile}
						periods={item?.periods || []}
						setPeriody={setPeriody}
					/>
				</S.containerSlider>
			)}
		</S.containerItem>
	);
}
