import React, { useEffect, useState } from 'react';
import GenericAccordion from 'components/GenericAccordion';
// import { svg } from 'lib/svgList';
import StripedList from 'components/StripedList';
import { useSelector } from 'react-redux';
import {
	firstLetterUperCase,
	converDateAndTimeFromBD,
	returnIconAlert,
	returnIconTraditionalUse,
	returnIdRoleUser
} from 'helpers/utils';
import ConsultGraph from './ConsultBarGraph';
import * as S from './styled';

export default function ShowDataListAccordion({ typeData = '' }) {
	const { dataMonitoring } = useSelector((state) => state.monitoring);
	const userIdRole = returnIdRoleUser();

	// alertList fica a lista de cada tipo (sem repetir) de alerta que o usuário possui disponivel.
	const [alertsList, setAlertsList] = useState([]);

	// essa função pega a lista com todos os alertas que o usuário possui, e retorna uma lista apenas com os tipos de alerta que o usuário possui
	async function getTypesFromUserAlerts(allAlerts) {
		// pegando o tipo dos alertas no array de alertas
		if (typeof allAlerts === 'object' && allAlerts.length > 0) {
			const listAllAlerts = await allAlerts.map((item) => {
				const newName = firstLetterUperCase(item.name);
				return {
					id: item.id,
					// icon: item.type, DEPOIS ALTERAR AQUI QUANDO TIVER OS ICONES REAIS
					icon:
						typeData === 'alert'
							? returnIconAlert(item.type)
							: returnIconTraditionalUse(item.type),
					title: newName,
					type: item.type
				};
			});
			const listTypesNoRepeat = [];

			// tirando os alertas repetidos
			await listAllAlerts.forEach((item) => {
				const verifyIfExist = listTypesNoRepeat.filter(
					(alertItem) => alertItem.type === item.type
				);
				if (verifyIfExist.length === 0) {
					listTypesNoRepeat.push(item);
				}
			});

			setAlertsList(listTypesNoRepeat);
		}
	}

	// função pega alertas do mesmo tipo, da lista que possui todos os alertas
	function getAlertsByType(array, type, withHour = false) {
		if (typeof array === 'object' && array.length > 0) {
			// primeiro vou filtrar os alertas que são do mesmo tipo
			const arrayAlertsFiltered = array.filter((item) => item.type === type);
			// depois adiciono a chave information no objeto do alerta
			const newListAlert = arrayAlertsFiltered.map((item) => ({
				...item,
				information: withHour // verificação necessária para poder retornar a data sem horário caso preciso
					? converDateAndTimeFromBD(item.created_at, true)
					: converDateAndTimeFromBD(item.created_at)
			}));
			return newListAlert;
		}
		return [];
	}

	useEffect(() => {
		if (typeof dataMonitoring === 'object' && dataMonitoring.length > 0) {
			getTypesFromUserAlerts(dataMonitoring);
		}

		return () => {
			setAlertsList([]);
		};
	}, [dataMonitoring]);

	// const listStriped = [
	// 	{ icon: svg.icon.location, information: 'Em desenvolvimento' }
	// ];

	return (
		<S.containerShowDataListAccordion>
			{alertsList.length > 0 &&
				alertsList.map((item) => (
					<GenericAccordion
						key={item.id || Math.random() * 1000}
						title={item.title}
						imgTitle={item.icon}
					>
						<S.conatinerInsideAccordion>
							<GenericAccordion title="Lista de alertas">
								<StripedList
									list={getAlertsByType(dataMonitoring, item.type, true)}
									// caso o usuário seja do tipo 4 ou 5, nao mostra as legendas
									showLabel={
										userIdRole === 1 ||
										userIdRole === 2 ||
										userIdRole === 3 ||
										userIdRole === 6
									}
								/>
							</GenericAccordion>
						</S.conatinerInsideAccordion>
						<S.conatinerInsideAccordion>
							<GenericAccordion title="Gráfico">
								<ConsultGraph
									data={getAlertsByType(dataMonitoring, item.type)}
								/>
							</GenericAccordion>
						</S.conatinerInsideAccordion>
					</GenericAccordion>
				))}
		</S.containerShowDataListAccordion>
	);
}
