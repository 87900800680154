import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerCalendarStartAndEnd = styled.div`
	width: 100%;
	position: relative;
	/* background-color: brown; */
`;

export const boxDatesStarAndEnd = styled.div`
	position: relative;
	display: flex;
	max-height: 35px;
	min-height: 35px;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
	border-radius: 30px;
	border: 1px solid ${color.interface.lightGray};
	background-color: ${color.interface.white};
	transition: all 0.5s ease;
	border: 1px solid ${color.interface.lightGray} !important;
	background-color: #ffff;
	p,
	h5 {
		font-size: 0.625rem;
		font-weight: 400;
		color: ${color.interface.darkGray};

		:hover {
			color: ${color.interface.orange};
		}
	}
`;

export const dateStart = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 0.2rem;

	/* background-color: yellow; */
`;
export const dateEnd = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	margin-left: 1rem;
	gap: 0.2rem;
	/* background-color: red; */
`;

export const iconCalendar = styled.img`
	position: absolute;
	right: 20px;
	width: 20px;
`;

export const verticalLine = styled.div`
	min-width: 2px;
	min-height: 80%;
	background-color: ${color.interface.lightGray};

	span {
		font-size: 0.5px;
		background-color: ${color.interface.lightGray};
	}
`;

export const boxCalendar = styled.div`
	width: 100%;
	position: absolute;
	top: 120%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
`;
