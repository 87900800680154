/* eslint-disable no-unused-vars */
import React from 'react';
import { actionsUsers } from 'helpers/utils';
import { useMeasureStore } from 'services/map/measure.context';
import { useShapeStore } from 'services/map/shapes.context';
import { useColorsContext } from 'services/color.context';
import * as S from '../../ControlButton/styles';
// import * as ol from 'openlayers';

function IrregularPolygon() {
	const {
		state,
		handleChangeInteractionType,
		showModalDraw,
		setShowModalDraw
	} = useShapeStore();
	const { value } = state;
	const { showModal, setShowModal } = useColorsContext();
	const {
		state: stateMeasure,
		resetInteraction,
		showModalMeasure,
		setShowModalMeasure
	} = useMeasureStore();

	const actionButton = value === 'Circle' ? 'desativar' : 'ativar';
	const HandleControl = () => {
		if (stateMeasure.drawMeasuresIsActive) {
			resetInteraction();
		}
		if (showModalMeasure) {
			setShowModalMeasure(false);
		}
		if (showModal) {
			setShowModal(false);
		}
		if (showModalDraw) {
			setShowModalDraw(false);
		}
		handleChangeInteractionType('Circle');

		actionsUsers({
			category: `plataforma/mapa/webgis`,
			action: `Click no botão ${actionButton}`,
			label: 'Desenhar Círculo'
		});
	};

	return (
		<S.Container>
			<button
				id="type"
				type="button"
				data-title="Desenhar Círculo"
				onClick={HandleControl}
				className={value === 'Circle' ? 'active sm__tooltip' : 'sm__tooltip'}
			>
				<svg
					width="22"
					height="22"
					viewBox="0 0 35 34"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M14 8C11.6266 8 9.30655 8.70379 7.33316 10.0224C5.35977 11.3409 3.8217 13.2151 2.91345 15.4078C2.0052 17.6005 1.76756 20.0133 2.23058 22.3411C2.6936 24.6689 3.83649 26.807 5.51472 28.4853C7.19295 30.1635 9.33115 31.3064 11.6589 31.7694C13.9867 32.2324 16.3995 31.9948 18.5922 31.0865C20.7849 30.1783 22.6591 28.6402 23.9776 26.6668C25.2962 24.6934 26 22.3734 26 20"
						stroke="#291F17"
						strokeWidth="3"
						strokeLinecap="round"
					/>
					<path
						d="M33.5455 6.54545H28.4545V1.45455C28.4545 1.06878 28.3013 0.698807 28.0285 0.426026C27.7557 0.153246 27.3858 0 27 0C26.6142 0 26.2443 0.153246 25.9715 0.426026C25.6987 0.698807 25.5455 1.06878 25.5455 1.45455V6.54545H20.4545C20.0688 6.54545 19.6988 6.6987 19.426 6.97148C19.1532 7.24426 19 7.61423 19 8C19 8.38577 19.1532 8.75574 19.426 9.02852C19.6988 9.3013 20.0688 9.45455 20.4545 9.45455H25.5455V14.5455C25.5455 14.9312 25.6987 15.3012 25.9715 15.574C26.2443 15.8468 26.6142 16 27 16C27.3858 16 27.7557 15.8468 28.0285 15.574C28.3013 15.3012 28.4545 14.9312 28.4545 14.5455V9.45455H33.5455C33.9312 9.45455 34.3012 9.3013 34.574 9.02852C34.8468 8.75574 35 8.38577 35 8C35 7.61423 34.8468 7.24426 34.574 6.97148C34.3012 6.6987 33.9312 6.54545 33.5455 6.54545Z"
						fill="#291F17"
					/>
				</svg>
			</button>
		</S.Container>
	);
}

export default IrregularPolygon;
