import styled from 'styled-components';
import { color } from 'styles/Theme';

export const container = styled.div`
	width: 100%;
	/* background-color: red; */
`;

export const btnReport = styled.div`
	margin-top: 1rem;
	width: fit-content;
	background-color: ${color.interface.orange};
	border-radius: 6px;
	padding: 0.5rem;
	align-self: flex-start;
	cursor: pointer;
	button {
		color: ${color.interface.white};
		background-color: ${color.interface.orange};
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
		font-size: 0.7rem;
		img {
			width: 0.7rem;
		}
		cursor: pointer;
	}
	:active {
		transform: scale(0.9);
	}
`;

export const boxLoad = styled.div`
	margin-top: 1rem;
	p {
		width: 100%;
		font-size: 0.7rem;
		@keyframes flashing {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		animation: linear;
		animation-name: flashing;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-direction: alternate-reverse;
	}
`;

export const notice = styled.p`
	color: ${color.interface.red};
	font-size: 0.7rem;
`;
