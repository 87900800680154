import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerRecoveryPassword = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	/* background-color: red; */
	gap: 1rem;
`;

export const logo = styled.img`
	margin-top: 5rem;
	width: 200px;
`;

export const form = styled.div`
	margin-top: 1rem;
	width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	div {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	/* background-color: yellow; */
`;

export const containerMessage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;

	/* background-color: yellow; */
`;

export const erroMessage = styled.p`
	text-align: center;
	width: 100%;
	font-weight: 700;
`;

export const successMessage = styled.p`
	text-align: center;
	width: 100%;
	color: ${color.interface.green};
	font-weight: 700;
`;
