import styled from 'styled-components';
// import { color } from 'styles/Theme';

export const conatiner = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	overflow: hidden;
	gap: 0.2rem;

	p {
		text-align: center;
		font-size: 0.75rem;
		opacity: 0.8;
	}
`;

export const title = styled.h4`
	font-size: 0.9rem;
	font-weight: 900;
`;
