import styled from 'styled-components';
import { color } from 'styles/Theme';

export const container = styled.div`
	display: flex;
	flex-direction: column;
	width: '100%';
	height: 5.6rem;
	margin-top: 0.63rem;
	margin-bottom: 0.63rem;
`;

export const title = styled.div`
	font-size: 0.875rem;
	color: '#777';
	font-weight: 600;
	padding-bottom: 0.3rem;
`;

export const item = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.313rem;
	width: '100%';
	height: 1.25rem;
`;

export const symbol = styled.div`
	width: 1.25rem;
	height: 0.938rem;
	background-color: ${(props) => props.backgroundColor};
`;

export const label = styled.div`
	font-size: 0.875rem;
	color: ${color.interface.brown};
	line-height: 1.2;
`;
