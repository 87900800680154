import { olMap } from 'components/Map/ControlMap/index';
import { arrayPoints, arrayLineString } from 'components/Map/Utils';
import { StyleLayers, selectedFeatureStyle } from 'components/Map/Styles/js/StyleLayers';
import { sortArrayOfObject, zoomPaddingExtent } from 'helpers/utils';
import { png } from 'lib/svgList';
import { Modify, Draw, Snap } from 'ol/interaction';
import { zoomToExtentMap, zoomToExtentLayer, zoomToFeature } from 'components/Map/ControlMap';
import GeoJSON from 'ol/format/GeoJSON';
import { mapType } from 'store/typesActions/types';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

// ------------------ INICIO FUNÇÕES GERAIS ------------------

// pega um layer pelo nome
export const getAllLayers = () => olMap().getLayers().getArray() || null;

export const getLayerByName = (name) =>
	olMap()
		?.getLayers()
		?.getArray()
		?.find((layer) => layer.get('name') === name) || null;

export const removeLayerFromMap = (nameLayer, isZoomExtent = true) => {
	const mapSomai = olMap();
	const layer = getLayerByName(nameLayer) || undefined;

	if (layer !== undefined) {
		mapSomai.removeLayer(layer);
	}

	if (isZoomExtent) zoomToExtentMap();
};

// Ordena a lista pelo z-Index maior pro menor
export const orderLayers = (activatedLayers) => {
	const newData = activatedLayers.sort((a, b) => {
		const zIndexA = a.getProperties().zIndex;
		const zIndexB = b.getProperties().zIndex;
		return zIndexB - zIndexA;
	});

	return newData;
};

// Adiciona no reducer a lista das camadas ativas
export const setActivatedLayers = () => (dispatch) => {
	const ls = olMap()?.getLayers()?.getArray() || undefined;

	if (ls !== undefined) {
		const activatedLayers = ls.filter((l) => {
			const props = l.getProperties();
			if (l.getVisible() && !props.base && !props.tisFixed && !props.monitoring && !props.webGis) {
				return true;
			}
			return null;
		});

		if (!activatedLayers) return;

		dispatch({
			type: mapType.SET_ACTIVATED_LAYERS,
			payload: orderLayers(activatedLayers)
		});
	}
};

// limpa apenas os itens do checked e toggle
export const closeActivatedLayers = () => (dispatch) => {
	dispatch({
		type: mapType.CLOSE_ACTIVATED_LAYERS
	});
};

export const closeToggleAllLayers = () => (dispatch) => {
	dispatch({
		type: mapType.CLOSE_TOGGLE_ALL_LAYERS
	});
};

export const clearVector = async (name) => {
	const layer = await getLayerByName(name);

	if (layer !== null) {
		const source = await layer.getSource();
		source.clear(true);
	}
};

// efeito de onMouseOver, basta passar o nome da layer e as cores (opcional)
export const onMouseOver = (
	layerName,
	lineColor = 'rgba(255, 255, 255)',
	backgroundColor = 'rgba(255, 255, 255, 0.2)'
) => {
	const selectStyle = new Style({
		fill: new Fill({
			color: backgroundColor
		}),
		stroke: new Stroke({
			color: lineColor,
			width: 2
		})
	});

	let selected = null;

	olMap().on('pointermove', (e) => {
		if (selected !== null) {
			selected.setStyle(undefined);
			selected = null;
		}

		olMap().forEachFeatureAtPixel(
			e.pixel,
			(f) => {
				selected = f;
				selectStyle.getFill().setColor(f.get('COLOR') || backgroundColor);
				f.setStyle(selectStyle);
				return true;
			},
			{
				layerFilter: (layer) => layer.get('name') === layerName
			}
		);
	});
};

/* // Define a function to change the feature's style on hover
const highlightFeature = (feature) => {
	console.log('am point mover');
	const currentStyle = feature.getStyle();
	const newStyle = new Style({
		stroke: new Stroke({ color: 'red' }),
		fill: currentStyle.getFill()
	});
	feature.setStyle(newStyle);
};

// Define a function to reset the feature's style when not hovered
const resetFeatureStyle = (feature) => {
	console.log('am point out');
	const currentStyle = feature.getStyle();
	const newStyle = new Style({
		stroke: currentStyle.getStroke(),
		fill: currentStyle.getFill()
	});
	feature.setStyle(newStyle);
}; */

// efeito de onPointerOut, basta passar o nome da layer e as cores (opcional)
export const onPointerOut = (layerName) => {
	/* const layer = getLayerByName(layerName);
	if (layer) {
		console.log('until am point over ff');
		layer
			.getSource()
			.getFeatures()
			.forEach((feature) => {
				feature.on('pointermove', () => highlightFeature(feature));
				feature.on('pointerout', () => resetFeatureStyle(feature));
			});
	}
 */
	const highlightFeature = (event) => {
		const feature = olMap().forEachFeatureAtPixel(event.pixel, (feature) => feature);
		console.log('highlightFeature');
		console.log(feature);
		if (feature) {
			const currentStyle = feature.getStyle();
			if (currentStyle) {
				const newStyle = new Style({
					stroke: new Stroke({ color: 'red' }),
					fill: currentStyle.getFill()
				});

				// Apply changes made by handleApplyChanges
				if (colorType === 'border') {
					newStyle.setStroke(new Stroke({ color: 'green' }));
				} else {
					newStyle.setFill(new Fill({ color: 'rgba(0, 255, 0, 0.1)' }));
				}

				feature.setStyle(newStyle);
			}
		}
	};

	const resetFeatureStyle = (event) => {
		const feature = olMap().forEachFeatureAtPixel(event.pixel, (feature) => feature);
		console.log('resetFeatureStyle');
		console.log(feature);
		if (feature) {
			const currentStyle = feature.getStyle();
			if (currentStyle) {
				const newStyle = new Style({
					stroke: currentStyle.getStroke(),
					fill: currentStyle.getFill()
				});

				// Apply changes made by handleApplyChanges
				if (colorType === 'border') {
					newStyle.setStroke(new Stroke({ color: 'blue' }));
				} else {
					newStyle.setFill(new Fill({ color: 'rgba(0, 0, 255, 0.1)' }));
				}

				feature.setStyle(newStyle);
			}
		}
	};

	// Add the hover effect to each feature
	/* olMap().on('pointermove', highlightFeature);
	olMap().on('pointermove', resetFeatureStyle); */
	/* olMap().on('pointerout', (e) => {
		console.log('until am point over ff');
		olMap().forEachFeatureAtPixel(
			e.pixel,
			(f) => {
				console.log('am point over ff');
				const currentStyle = f.getStyle();
				const newStyle = new Style({
					stroke: currentStyle.getStroke(),
					fill: currentStyle.getFill()
				});
				f.setStyle(newStyle);
			},
			{
				layerFilter: (layer) => layer.get('name') === layerName
			}
		);
	}); */
};

// movimenta itens de um array entre indexs
export const arrayMove = (arr, fromIndex, toIndex) => {
	const element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);

	return arr;
};

/*
 * altera a Transparência das camadas
 */
export const handleOpacityLayer = (params) => () => {
	const { value, item } = params || {};

	const name_mapfile = item.get('name') || {};
	const layer = getLayerByName(name_mapfile);

	const opacity = (100 - value) / 100;

	if (layer) layer.setOpacity(opacity);
};

/*
 * altera o ano das camadas WMS
 */
export const handleYearLayer =
	({ nameLayer, year, minPeriod }) =>
	async () => {
		if (nameLayer && year) {
			const layer = getLayerByName(nameLayer);
			const source = layer?.getSource();

			if (source !== undefined) {
				// valor mais elevado do Rbg em "COLORRANGE" no arquivo ".map" de desmatamento
				const highestValueRgb = 255;
				// diferença entre o maior valor da primeira cor e o maior valor da última cor
				// em "COLORRANGE" no arquivo ".map" de desmatamento dividido pela quantidade de anos
				// esse valor deve ser sempre inteiro
				const diffValueRgb1Rgb2 = 1;

				if (nameLayer === 'desmatamento') {
					const multiplier = (year - minPeriod) * diffValueRgb1Rgb2;
					const threshold = highestValueRgb - multiplier;
					await source.set('threshold', threshold);
					await source.changed();
				} else {
					await source.updateParams({ YEAR: year });
				}
			}
		}
	};

const getZIndexAndNameLayers = () => {
	const arr = [];
	olMap()
		.getLayers()
		.getArray()
		.forEach((l) => {
			const zIndex = l.getZIndex();
			const name = l.get('name');
			if (zIndex && name) arr.push({ zIndex, name });
		});

	arr.sort(sortArrayOfObject('zIndex'));

	return arr;
};

const renderMinMaxZIndexs = () => {
	const zIndexs = [];
	olMap()
		?.getLayers()
		.getArray()
		.filter(
			(f) =>
				// para não pegar z-Index das camadas principais
				f.getProperties().name !== 'monitoring' &&
				f.getProperties().name !== 'shapes_draw' &&
				f.getProperties().name !== 'measure_draw' &&
				f.getProperties().name !== 'getPoint' &&
				f.getProperties().name !== 'tabAmazonTis' &&
				f.getProperties().name !== 'tisFixed' &&
				f.getProperties().name !== 'baseOsmMap' &&
				f.getProperties().name !== 'baseArcGis' &&
				f.getProperties().name !== 'baseSateliteBing' &&
				f.getProperties().name !== 'googleMaps'
		)
		.forEach((l) => {
			const zIndex = l.getZIndex();

			// para deixar as tis sempre na frente
			if (zIndex) zIndexs.push(zIndex);
		});

	return zIndexs;
};

export const getMinZIndex = () => {
	const zIndexs = renderMinMaxZIndexs();
	return zIndexs.length > 0 ? Math.min.apply(null, zIndexs) : 0;
};

export const getMaxZIndex = () => {
	const zIndexs = renderMinMaxZIndexs();
	return zIndexs.length > 0 ? Math.max.apply(null, zIndexs) : 0;
};
/*
 * altera a ordem das camadas
 */
export const handleOrderLayer = (params) => (dispatch) => {
	const { action, item } = params || {};

	const name_mapfile = item.get('name') || {};
	const layer = getLayerByName(name_mapfile);

	const zIndexLayer = layer.getZIndex();

	const minZIndex = getMinZIndex();
	const maxZIndex = getMaxZIndex();

	const indexsAndNamesLayers = getZIndexAndNameLayers()?.filter((f) => f.name !== 'tisFixed');

	const position = zIndexLayer - minZIndex;
	const newPosition = action === 'down' ? position - 1 : maxZIndex + 1;

	const newIndexsAndNamesLayers =
		newPosition < 0 ? indexsAndNamesLayers : arrayMove(indexsAndNamesLayers, position, newPosition);

	newIndexsAndNamesLayers.forEach((n, i) => {
		const layer = n?.name ? getLayerByName(n.name) : null;
		if (layer) layer.setZIndex(i + 1);
	});

	dispatch(setActivatedLayers());
};

export const getExtentFromFeature = (layerName, id) => {
	const layer = getLayerByName(layerName);

	if (layer !== null) {
		const features = layer.getSource().getFeatures();

		const extent = [];

		features.forEach((e) => {
			const event = e.getProperties();
			const { geometry, props } = event;
			if (props.code == id) {
				extent.push(geometry.getExtent());
			}
		});

		return extent;
	}

	return false;
};

// ------------------ FIM FUNÇÕES GERAIS ------------------

// ------------------ INICIO FUNÇÕES TAB PAINEL (MONITORAMENTO) ------------------

export const onLoadMonitoring = (data) => async () => {
	if (data && data.length > 0) {
		clearVector('getPoint');
		clearVector('monitoring');

		const layer = await getLayerByName('monitoring');

		if (layer !== null) {
			const source = await layer.getSource();

			// Alertas
			const fire = data.filter((f) => f.type === 'fogo');
			const deforestation = data.filter((f) => f.type === 'desmatamento');
			const illegalHunting = data.filter((f) => f.type === 'caca_ilegal');
			const woodExploitation = data.filter((f) => f.type === 'exploracao_ilegal_madeira');
			const illegalMining = data.filter((f) => f.type === 'garimpo_ilegal');
			const invasionLand = data.filter((f) => f.type === 'invasao_terra_indigena');
			const illegalFishing = data.filter((f) => f.type === 'pesca_ilegal');
			const otherAlerts = data.filter((f) => f.type === 'outros');

			source.addFeatures(arrayPoints(fire, 'fire'));
			source.addFeatures(arrayPoints(deforestation, 'deforestation'));
			source.addFeatures(arrayPoints(illegalHunting, 'illegalHunting'));
			source.addFeatures(arrayPoints(woodExploitation, 'woodExploitation'));
			source.addFeatures(arrayPoints(illegalMining, 'illegalMining'));
			source.addFeatures(arrayPoints(invasionLand, 'invasionLand'));
			source.addFeatures(arrayPoints(illegalFishing, 'illegalFishing'));
			source.addFeatures(arrayPoints(otherAlerts, 'otherAlerts'));

			// Uso tradicional
			const aldeia = data.filter((f) => f.type === 'aldeia');
			const caca = data.filter((f) => f.type === 'caca');
			const coleta = data.filter((f) => f.type === 'coleta');
			const pesca = data.filter((f) => f.type === 'pesca');
			const roca = data.filter((f) => f.type === 'roca');
			const outrosUsotradiconal = data.filter((f) => f.type === 'outrosUsotradiconal');

			source.addFeatures(arrayPoints(aldeia, 'aldeia'));
			source.addFeatures(arrayPoints(caca, 'caca'));
			source.addFeatures(arrayPoints(coleta, 'coleta'));
			source.addFeatures(arrayPoints(pesca, 'pesca'));
			source.addFeatures(arrayPoints(roca, 'roca'));
			source.addFeatures(arrayPoints(outrosUsotradiconal, 'outrosUsotradiconal'));

			// Onde estive

			const whereIBeen = data.filter((f) => f.type === 'where_i_been');

			source.addFeatures(arrayPoints(whereIBeen, 'whereIBeen'));

			// Trajetos
			const tracks = data.filter((f) => f.type === 'tracks');

			source.addFeatures(arrayLineString(tracks));
		}
	}
};

export const activeModify = (isEdit) => async () => {
	const mapSomai = olMap();

	mapSomai?.getInteractions().forEach((interaction) => {
		if (interaction instanceof Modify) {
			interaction.setActive(isEdit);
		}
	});
};

export const activeDraw = (openIsRegister) => () => {
	const mapSomai = olMap();
	clearVector('getPoint');

	mapSomai?.getInteractions().forEach((interaction) => {
		if (interaction instanceof Draw) {
			interaction.setActive(openIsRegister);
		}

		if (interaction instanceof Snap) {
			interaction.setActive(openIsRegister);
		}
	});
};

// ------------------ FIM FUNÇÕES TAB PAINEL (MONITORAMENTO) ------------------

// ------------------ INICIO FUNÇÕES TAB AMAZON  ------------------
export const addLayerAmazon = async (item, nameLayer, isClearVector) => {
	if (isClearVector) clearVector(nameLayer);

	const { geom } = item;

	const lines = {
		type: 'FeatureCollection',
		features: []
	};

	lines.features.push({
		type: 'Feature',
		geometry: geom,
		properties: {
			type: nameLayer,
			props: item
		}
	});

	const stateLegalAmazonSelected = new GeoJSON({
		featureProjection: 'EPSG:3857'
	}).readFeatures(lines);

	const layer = await getLayerByName(nameLayer);

	if (layer !== null) {
		const source = await layer.getSource();

		source.addFeatures(stateLegalAmazonSelected);
	}
};

export const extentAmazon = async () => {
	const layer = await getLayerByName('tabAmazon');

	if (layer !== null) {
		const source = await layer.getSource();
		const extent = await source.getExtent();

		return extent;
	}
	return null;
};

// ------------------ FIM FUNÇÕES TAB AMAZON  ------------------

// ------------------ INICIO FUNÇÕES TAB TI  ------------------

export const addLayerTI = async (item, nameLayer, isClearVector) => {
	if (isClearVector) clearVector(nameLayer);

	const { geom } = item;

	const lines = {
		type: 'FeatureCollection',
		features: []
	};

	lines.features.push({
		type: 'Feature',
		geometry: geom,
		properties: {
			type: 'tiSelectedTabTi',
			props: item
		}
	});

	const tiSelected = new GeoJSON({
		featureProjection: 'EPSG:3857'
	}).readFeatures(lines);

	const layer = await getLayerByName(nameLayer);

	if (layer !== null) {
		const source = await layer.getSource();

		source.addFeatures(tiSelected);
	}

	const layerMap = await getLayerByName(nameLayer);
	const source = await layerMap.getSource();
	const extent = source.getExtent();

	setTimeout(() => {
		if (extent && extent[0] !== Infinity) {
			zoomToExtentLayer(extent, 1000, zoomPaddingExtent(), 12);
		}
	}, 10);
};

// ------------------ FIM FUNÇÕES TAB TI  ------------------

// ------------------ INICIO FUNÇÕES Territorios ------------------

export const onLoadDataUsersWebGis =
	({ data, dataMap }) =>
	async () => {
		if (data && data.length > 0) {
			const layer = await getLayerByName(dataMap.name);

			if (layer !== null) {
				const source = await layer.getSource();

				// Alertas
				const fire = data.filter((f) => f.type === 'fogo');
				const deforestation = data.filter((f) => f.type === 'desmatamento');
				const illegalHunting = data.filter((f) => f.type === 'caca_ilegal');
				const woodExploitation = data.filter((f) => f.type === 'exploracao_ilegal_madeira');
				const illegalMining = data.filter((f) => f.type === 'garimpo_ilegal');
				const invasionLand = data.filter((f) => f.type === 'invasao_terra_indigena');
				const illegalFishing = data.filter((f) => f.type === 'pesca_ilegal');
				const otherAlerts = data.filter((f) => f.type === 'outros');

				source.addFeatures(arrayPoints(fire, 'fire'));
				source.addFeatures(arrayPoints(deforestation, 'deforestation'));
				source.addFeatures(arrayPoints(illegalHunting, 'illegalHunting'));
				source.addFeatures(arrayPoints(woodExploitation, 'woodExploitation'));
				source.addFeatures(arrayPoints(illegalMining, 'illegalMining'));
				source.addFeatures(arrayPoints(invasionLand, 'invasionLand'));
				source.addFeatures(arrayPoints(illegalFishing, 'illegalFishing'));
				source.addFeatures(arrayPoints(otherAlerts, 'otherAlerts'));

				// Uso tradicional
				const aldeia = data.filter((f) => f.type === 'aldeia');
				const caca = data.filter((f) => f.type === 'caca');
				const coleta = data.filter((f) => f.type === 'coleta');
				const pesca = data.filter((f) => f.type === 'pesca');
				const roca = data.filter((f) => f.type === 'roca');
				const outrosUsotradiconal = data.filter((f) => f.type === 'outrosUsotradiconal');

				source.addFeatures(arrayPoints(aldeia, 'aldeia'));
				source.addFeatures(arrayPoints(caca, 'caca'));
				source.addFeatures(arrayPoints(coleta, 'coleta'));
				source.addFeatures(arrayPoints(pesca, 'pesca'));
				source.addFeatures(arrayPoints(roca, 'roca'));
				source.addFeatures(arrayPoints(outrosUsotradiconal, 'outrosUsotradiconal'));

				// Onde estive

				const whereIBeen = data.filter((f) => f.type === 'where_i_been');

				source.addFeatures(arrayPoints(whereIBeen, 'whereIBeen'));

				// Trajetos
				const tracks = data.filter((f) => f.type === 'tracker');

				source.addFeatures(arrayLineString(tracks));

				const extent = source.getExtent();

				setTimeout(() => {
					if (extent && extent[0] !== Infinity) {
						zoomToExtentLayer(extent);
					}
				}, 1000);
			}
		}
	};

// gera o icone que ira para a legenda
// o trajeto não tem icone por isso não esta na lista
export function getIconVector({ item }) {
	const pngMap = {
		caca_ilegal: png.cacaIlegalOrange,
		desmatamento: png.desmatamentoIlegalOrange,
		exploracao_ilegal_madeira: png.exploracaoIlegalOrange,
		fogo: png.fogoOrange,
		garimpo_ilegal: png.garimpoIlegalOrange,
		invasao_terra_indigena: png.invasaoTiOrange,
		pesca_ilegal: png.pescaIlegalOrange,
		aldeia: png.aldeiaOrange,
		caca: png.cacaOrange,
		coleta: png.coletaOrange,
		pesca: png.pescaOrange,
		roca: png.rocaOrange,
		outros: png.outrosOrange,
		where_i_been: png.locationRound
	};

	return pngMap[item] || undefined;
}
// gera o estilo que ira para o mapa
export function getStyleVector({ item }) {
	const pngMap = {
		caca_ilegalalert: [StyleLayers.pointBackground, StyleLayers.illegalHunting],
		desmatamentoalert: [StyleLayers.pointBackground, StyleLayers.deforestation],
		exploracao_ilegal_madeiraalert: [StyleLayers.pointBackground, StyleLayers.woodExploitation],
		fogoalert: [StyleLayers.pointBackground, StyleLayers.fire],
		garimpo_ilegalalert: [StyleLayers.pointBackground, StyleLayers.illegalMining],
		invasao_terra_indigenaalert: [StyleLayers.pointBackground, StyleLayers.invasionLand],
		pesca_ilegalalert: [StyleLayers.pointBackground, StyleLayers.illegalFishing],
		outrosalert: [StyleLayers.pointBackground, StyleLayers.otherAlerts],
		aldeiatraditionalUse: [StyleLayers.pointBackground, StyleLayers.aldeia],
		cacatraditionalUse: [StyleLayers.pointBackground, StyleLayers.caca],
		coletatraditionalUse: [StyleLayers.pointBackground, StyleLayers.coleta],
		pescatraditionalUse: [StyleLayers.pointBackground, StyleLayers.pesca],
		rocatraditionalUse: [StyleLayers.pointBackground, StyleLayers.roca],

		outrostraditionalUse: [StyleLayers.pointBackground, StyleLayers.otherAlerts],
		where_i_been: [StyleLayers.pointBackground, StyleLayers.whereIBeen],
		tracker: [StyleLayers.tracks]
	};

	return pngMap[item] || null;
}

export const selectOnMap = (code) => {
	const layerTisThreats = getLayerByName('tisThreats');

	const source = layerTisThreats?.getSource();

	// remove o style das features
	source.getFeatures().forEach((feat) => feat.setStyle(null));

	const feature = source.getFeatureById(code);

	const defaultStyleFeature = layerTisThreats.getStyleFunction()(feature);

	const newStyle = selectedFeatureStyle(defaultStyleFeature);

	feature.setStyle(newStyle);

	zoomToFeature(feature);
};
