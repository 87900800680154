import React from 'react';
import { color } from 'styles/Theme';
import * as S from './styled';

// Passe o estado e a função para alterar checado e não checado

export default function SwitchToggleName({
	isChecked = false,
	getTarget = false,
	name = '',
	setIsChecked = () => {},
	id = Math.random() * 10
}) {
	return (
		<S.containerSwitch>
			<S.labelFather
				style={isChecked ? { backgroundColor: color.interface.green } : null}
				htmlFor={id}
				onChange={(e) =>
					getTarget ? setIsChecked(e) : setIsChecked(!isChecked)
				}
			>
				<input name={name} type="checkbox" id={id} defaultChecked={isChecked} />
				<span style={isChecked ? { right: '0%' } : { left: '0%' }} />
			</S.labelFather>
		</S.containerSwitch>
	);
}
