import styled from 'styled-components';
import { color } from 'styles';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`;

export const BoxButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const Button = styled.button`
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	border: none;
	outline: none;
	background: ${color.interface.orange};
	color: ${color.interface.white};
	height: 50px;
	width: 250px;
	font-weight: 700;
	margin-top: 20px;
	cursor: pointer;
	border-radius: 30px;
	border: 1px solid ${color.interface.orange} !important;
`;

export const ButtonCancel = styled.button`
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	border: none;
	outline: none;
	background: ${color.interface.lightBrown};
	color: ${color.interface.white};
	height: 50px;
	font-weight: 700;
	margin-top: 20px;
	cursor: pointer;
	border-radius: 30px;
	border: 1px solid ${color.interface.lightBrown} !important;
	margin-right: 20px;
	width: 250px;
`;

export const Message = styled.div`
	text-align: justify;
	font-family: 'nunito';
	font-size: 0.89rem;
`;
