import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerTermsOfUse = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	/* background-color: aliceblue; */

	button {
		width: 100%;
	}
`;
export const titleOrange = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	box-sizing: content-box;
	margin-bottom: 0.5rem;
	/* background-color: yellow; */
	gap: 0.5rem;
	svg {
		font-size: 30px;
	}

	h3 {
		height: 100%;
		width: 100%;
		color: ${color.interface.orange};
		font-size: 0.8rem;
		/* background-color: red; */
	}
`;

export const centralizeImg = styled.div`
	align-self: flex-start;
	width: 10%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	/* background-color: blue; */
`;

export const groupTitleLasUpdate = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 10px;
	/* background-color: green; */
`;

export const lastUpdate = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* background-color: greenyellow; */
	p {
		font-weight: 400;
		color: ${color.interface.lightBrown};
		font-size: 13px;
		/* background-color: gray; */
	}
`;
export const termsOfService = styled.div`
	overflow: auto;
	width: 100%;
	font-size: 0.875rem;
	border-bottom: rgb(0, 0, 0);
	padding: 1rem 1rem 0 1rem;
	padding-bottom: 30px;
	min-height: 300px;
	max-height: 300px;
	border-radius: 8px;
	background-color: #ffff;

	p {
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		color: ${color.interface.lightBrown};
	}

	ul {
		padding-left: 1rem;
	}

	li {
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		color: ${color.interface.lightBrown};
	}

	strong {
		font-weight: 900;
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		color: ${color.interface.lightBrown};
		margin-bottom: 2rem;
	}

	b {
		font-weight: 900;
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		color: ${color.interface.lightBrown};
		margin-bottom: 2rem;
	}

	div {
		width: 100%;
		height: 20px;
		background-color: rgb(250, 250, 250, 0.5);
		position: sticky;
		bottom: 0px;
		opacity: 1;
		padding: -1rem -1rem 0 -1rem;

		/* background-image: linear-gradient(
			to bottom,
			rgb(255, 255, 255, 0.5),
			rgb(200, 200, 200, 0.5)
		); */
	}

	::-webkit-scrollbar-track {
		background: ${color.interface.white}; /* color of the tracking area */
	}

	::-webkit-scrollbar-thumb {
		background-color: ${color.interface.gray}99; /* color of the scroll thumb */
		border: 1px solid ${color.interface.gray}99; /* creates padding around scroll thumb */
	}
`;

export const titleRecuseTerms = styled.p`
	width: 100%;
	font-weight: 900;
	font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	color: ${color.interface.brown};
	text-align: left;
	margin-bottom: 0.5rem;
`;

export const subTitleRecuseTerms = styled.p`
	width: 100%;
	font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	color: ${color.interface.gray};
	text-align: left;
`;

export const backtoTheTerm = styled.button`
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;
	margin-top: 2rem;
	cursor: pointer;
	p {
		font-weight: 900;
		color: ${color.interface.orange};
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	}

	img {
		width: 15px;
		transform: rotate(270deg);
	}
`;

export const backtoTheMap = styled.button`
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;
	margin-top: 1rem;
	cursor: pointer;

	p {
		font-weight: 900;
		color: ${color.interface.orange};
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	}

	img {
		width: 15px;
		transform: rotate(270deg);
	}
`;

export const line = styled.div`
	width: 100%;
	min-height: 0.5px;
	border-radius: 10px;
	margin-bottom: 2rem;
	background-color: ${color.interface.lightGray};
`;
