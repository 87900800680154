import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerTitleTab = styled.h3`
	width: 100%;
	display: flex;
	margin-bottom: 0.5rem;
	font-weight: 700;
	color: ${color.interface.orange};
`;
