import React, { useState, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLocalStorage } from 'services/session';
import { update, closeErrorUser } from 'store/actions/userActions';
import InputRegister from 'components/InputRegister';
import {
	formatTis,
	formatTisSelect,
	convertAge,
	convertJoin,
	convDateInverser,
	actionsUsers,
	componentsViewUsers
} from 'helpers/utils';
import Modal from 'components/Modal';
import Select from 'components/Buttons/Select/Select';
import SearchMultiple from 'components/Buttons/SearchMultiple';
import Search from 'components/Buttons/Search/Search';
import { color } from 'styles';
import * as S from '../Register/Register.Styled';

function UpdateUser() {
	const dispatch = useDispatch();
	const { user } = getUserLocalStorage();
	const { userProfiles, loading, rolesPublic, loadingRoles, message } =
		useSelector((state) => state.user);

	const { tisList, isLoading: loandigTis } = useSelector(
		(state) => state.listTis
	);

	const { listOrg } = useSelector((state) => state.organizations);

	// Constroi a role do usuario para o select
	const isRole = { value: user.user_role.id, label: user.user_role.name } || {};

	// Constroi a organização do usuario para o select
	const isOrganizarion =
		{ value: user.user_organization.id, label: user.user_organization.name } ||
		{};

	// Constroi o perfil do usuario para o select
	const isProfile =
		user && user.profile
			? { value: user?.profile.id, label: user?.profile.name }
			: [];

	const tiUser = user.tis ? JSON.parse(user.tis) : [];

	const isTis = formatTisSelect(
		tiUser.map((i) => {
			const data = tisList.filter((ti) => i === ti.code)[0];
			return data;
		})
	);

	// se caso não chegar a data ele coloca uma default
	const dateBirth = user?.birthDate
		? convDateInverser(user.birthDate)
		: convDateInverser('01/01/1990');

	// Dados do formulario
	const [firstName, setFirstName] = useState(user.firstName);
	const [lastName, setLastName] = useState(user.lastName);
	const [birthDate, setBirthDate] = useState(dateBirth);
	const [role, setRole] = useState(isRole || []);
	const [profileUser, setProfileUser] = useState(isProfile || []);
	const [organization, setOrganization] = useState(isOrganizarion || []);
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState(user.email);
	const [tisAlerts, setTisAlerts] = useState([...isTis] || []);

	// remove itens duplicados da lista ao adicionar ou remover um novo item
	const uniqueValue = Array.from(new Set(tisAlerts.map((a) => a.value))).map(
		(value) => tisAlerts.find((a) => a.value === value)
	);
	// Dados para envio
	const formData = {
		id: user.id,
		firstName,
		lastName,
		birthDate: convertAge(birthDate),
		role_id: role?.value,
		researcher_profile_id: profileUser.value || '',
		organization_id: organization?.value || null,
		email,
		password,
		tis: convertJoin(uniqueValue)
	};

	if (!password) {
		delete formData.password;
	}

	const sendData = (e) => {
		e.preventDefault();

		dispatch(update(formData))
			.then(() => {})
			.catch(() => {});
	};

	// Apaga erro de requisição no reducer
	const handleClose = () => {
		dispatch(closeErrorUser());
	};

	useEffect(() => {
		if (loading) {
			actionsUsers({
				category: `plataforma/painel/menu/geral/meuPerfil`,
				action: 'Clique no botão para atualizar o perfil',
				label: 'Atualização de Perfil'
			});
		}
	}, [loading]);

	useEffect(() => {
		componentsViewUsers({
			page: `plataforma/painel/menu/geral/meuPerfil`,
			title: 'Atualização de Perfil'
		});
	}, []);

	return (
		<form onSubmit={sendData} style={{ paddingBottom: '15rem' }}>
			<S.Label>Primeiro nome* </S.Label>
			<InputRegister
				type="text"
				value={firstName}
				setValue={setFirstName}
				required
			/>

			<S.Label>Último nome* </S.Label>
			<InputRegister
				type="text"
				value={lastName}
				setValue={setLastName}
				required
			/>

			<S.Label>Data de nascimento*</S.Label>
			<InputRegister
				type="date"
				value={birthDate}
				setValue={setBirthDate}
				required
			/>

			<S.Label style={{ marginTop: 50 }}>Tipo de usuário*</S.Label>

			<Select
				itemSelected={role}
				setItemSelected={setRole}
				options={rolesPublic}
				style={styledConfg.select}
				isLoading={loadingRoles}
				isLineOnTheList
				isDisabled
			/>
			{role?.value > 0 && role.value !== 5 ? (
				<>
					<S.Label>Organização</S.Label>
					<Search
						itemsList={listOrg}
						setItemSelected={setOrganization}
						styleInput={styledConfg.select}
						placeholder={organization.label || 'Buscar...'}
						isLineOnTheList
					/>
				</>
			) : (
				<>
					<S.Label>Perfil</S.Label>
					<Select
						itemSelected={profileUser}
						setItemSelected={setProfileUser}
						options={userProfiles}
						style={styledConfg.select}
						isLineOnTheList
					/>
				</>
			)}

			<S.Label>E-mail*</S.Label>
			<InputRegister type="email" value={email} setValue={setEmail} required />

			<S.BoxLabelAlerts>
				<S.Label>Alertas</S.Label>
				<S.Span>
					Receba notificações de uma ou mais Terra Indígena por email
				</S.Span>
			</S.BoxLabelAlerts>

			<SearchMultiple
				itemsSelected={uniqueValue}
				setItemsSelected={setTisAlerts}
				itemsList={formatTis(tisList)}
				styleInput={styledConfg.select}
				isLoading={loandigTis}
				textPlaceholder="Selecione uma ou mais TIs"
				isLineOnTheList
			/>

			<S.Label>Senha</S.Label>
			<InputRegister
				type="password"
				value={password}
				setValue={setPassword}
				autoComplete="new-password"
			/>

			<div style={{ marginTop: 25 }}>
				<S.Span>* Preenchimento obrigatório</S.Span>
			</div>

			<S.BoxButton>
				<S.Button type="submit">
					{loading ? 'Aguarde...' : 'Atualizar'}
				</S.Button>
			</S.BoxButton>

			<Modal
				styleContent={{ maxWidth: 700 }}
				isOpen={message?.length > 0}
				setIsOpen={handleClose}
				useBtnEnd={false}
			>
				{message}
			</Modal>
		</form>
	);
}

const styledConfg = {
	select: {
		width: '100%',
		maxHeight: 55,
		minHeight: 55,
		color: color.interface.inputs,
		fontSize: 16,
		fontFamily: 'Nunito',
		border: `1.5px solid ${color.interface.inputs}`,
		padding: '0 22px',
		borderRadius: 10
	}
};
export default memo(UpdateUser);
