/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isNamePeriod } from 'helpers/utils';
import * as mapActions from 'store/actions/mapActions';
import { useDispatch } from 'react-redux';

function valuetext(value) {
	return `Ano ${value}`;
}

export default function SliderYears({
	minPeriod = 0,
	maxPeriod = 100,
	step = 10,
	defaultValue = 0,
	disabled = false,
	color,
	nameLayer = '',
	periods = [],
	setPeriody = () => {}
}) {
	// reducer
	const dispatch = useDispatch();

	const [value, setValue] = useState(defaultValue);

	const handleSliderChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		setPeriody(value);
	}, [value, defaultValue]);

	useEffect(() => {
		// Se a camada for desmatamento não precisa de setTimeout
		if (nameLayer === 'desmatamento') {
			return () =>
				dispatch(
					mapActions.handleYearLayer({
						nameLayer,
						year: value,
						minPeriod
					})
				);
		}

		// sempre que estiver atualizando o ano ele reseta o setTimeout ate parar o slider
		const timer = setTimeout(() => {
			dispatch(
				mapActions.handleYearLayer({
					nameLayer,
					year: value,
					minPeriod
				})
			);
		}, 1500);
		return () => clearTimeout(timer);
	}, [value, disabled]);

	const theme = createTheme({
		palette: {
			primary: {
				main: '#000000'
			},
			secondary: {
				main: color
			}
		}
	});

	// filtra os valores conforme selecionado no slider,mas apenas se for periodo ex: 2000/2010
	const filterPeriodSelected = periods && periods.filter((p) => p.id === value);
	// Traz o label do periodo ex: 2000/2010
	const datePeriod = isNamePeriod(...filterPeriodSelected) || '';

	// Pega a ultima posição do array para mostrar o periodo final
	const ultimo = (periods?.length > 0 && periods.slice(-1)[0]) || [];
	const maxPeriodLabel = (ultimo && ultimo.label) || '';

	return (
		<ThemeProvider theme={theme}>
			<h3 style={{ fontSize: 11, fontFamily: 'Nunito' }}>
				{defaultValue < 100 ? `Período ${datePeriod}` : `Ano ${value}`}
			</h3>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<h3 style={{ fontSize: 10 }}>
					{defaultValue < 100 ? periods[0].label : minPeriod}
				</h3>
				<Slider
					aria-label="Ano"
					defaultValue={defaultValue}
					getAriaValueText={valuetext}
					valueLabelDisplay="auto"
					aria-labelledby="non-linear-slider"
					valueLabelFormat={defaultValue < 100 ? datePeriod : value.toString()}
					onChange={handleSliderChange}
					step={step}
					marks
					min={minPeriod}
					max={maxPeriod}
					color={color ? 'secondary' : 'primary'}
					disabled={disabled}
					sx={{ display: 'flex', marginLeft: 2, marginRight: 2 }}
				/>
				<h3 style={{ fontSize: 10 }}>
					{' '}
					{defaultValue < 100 ? maxPeriodLabel : maxPeriod}
				</h3>
			</div>
		</ThemeProvider>
	);
}
