import { svg } from 'lib/svgList';

export const item = [
	{
		name: 'acesso-twitter-ipam',
		href: 'https://twitter.com/IPAM_Amazonia',
		urlIcon: svg.icon.twitterOrange
	},
	{
		name: 'acesso-facebook-ipam',
		href: 'https://www.facebook.com/IPAMAmazonia/',
		urlIcon: svg.icon.facebookOrange
	},
	{
		name: 'acesso-instagram-ipam',
		href: 'https://www.instagram.com/ipam_amazonia/',
		urlIcon: svg.icon.instagramOrange
	}
];
