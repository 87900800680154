import React from 'react';
import * as S from './styled';

export default function FlasingText({ text = 'Carregando...', style = {} }) {
	return (
		<S.container style={style}>
			<p>{text}</p>
		</S.container>
	);
}
