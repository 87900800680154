import { fireFocusByMonthType } from 'store/typesActions/types';
import { tiGeoType } from 'store/typesActions/types';
import { api } from 'services/api';

export const fireFocusByMonth = (code) => async (dispatch) => {
	dispatch({ type: fireFocusByMonthType.FIRE_FOCUS_BY_MONTH });
	try {
		const res = await api.get(`/ti/${code}/focus_by_month`);
		const { data } = res || {};
		dispatch({
			type: fireFocusByMonthType.FIRE_FOCUS_BY_MONTH_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.statsu === 400) {
			const { message } = response.data;
			dispatch({
				type: fireFocusByMonthType.FIRE_FOCUS_BY_MONTH_ERROR,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: fireFocusByMonthType.FIRE_FOCUS_BY_MONTH_ERROR,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};
