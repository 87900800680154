import React, { memo } from 'react';
// import Default from './contents/Default';
import ThreatAndAlertsNasaAndInpe from './contents/ThreatAndAlertsNasaAndInpe.js';
import { name } from './nameMapFile';

// continue colocando no switch novos conteudos do tooltip conforme necessidade
// sempre passe para o ponto clicado no mapa, o nome que irar decidir o case, como 'nameMapFile'

export const choiceComp = ({ itemSelect }) => {
	const { nameMapFile } = itemSelect;

	switch (nameMapFile) {
		case name.threatAndAlertsNasaAndInpe:
			return <ThreatAndAlertsNasaAndInpe data={itemSelect} />;

		default:
			return null;
	}
};

function TooltipContent({ itemSelect }) {
	return choiceComp({ itemSelect });
}

export default memo(TooltipContent);
