import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerTotalAlerts = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	gap: 1rem;
	padding-top: 0.5rem;
	/* padding-right: 1rem; */
`;

export const informationAndAdd = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 500px) {
		/* flex-direction: column; */
		gap: 1rem;
	}
	/* background-color: antiquewhite; */
`;

export const quantity = styled.p`
	width: 100%;
	font-weight: 400;
	color: ${color.interface.darkGray};
`;

export const noticeACI = styled.p`
	width: 100%;
	color: ${color.interface.orange};
	/* background-color: beige; */
`;
