import React from 'react';

import ListUsers from './ListUsers';
import * as S from './styled';

function ListAndEditUsers({ users = [] }) {
	return (
		<S.ContainerIndex>
			<ListUsers users={users} />
		</S.ContainerIndex>
	);
}

export default ListAndEditUsers;
