import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { svg } from 'lib/svgList';
import useWindowSize from 'hooks/getWindowSize';

import ButtonsAndContent from './ButtonsAndContent';
import * as S from './MenuUser.Styled';

function MenuUser({ open }) {
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const { width } = useWindowSize();
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	return (
		<Box
			sx={[
				{ flexGrow: 0 },
				open === false && width >= 800 ? styleConfig.menuUser : null
			]}
		>
			<S.ButtonUser onClick={handleOpenUserMenu}>
				<img
					alt="Usuário"
					style={{
						width: 36,
						height: 36
					}}
					src={svg.icon.user}
				/>
			</S.ButtonUser>

			<Menu
				sx={{
					mt: !open ? '45px' : 0,
					top: !open && width > 800 ? '-20%' : 0
				}}
				id="menu-appbar"
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
			>
				<S.ContainerItems>
					<ButtonsAndContent handleCloseUserMenu={handleCloseUserMenu} />
				</S.ContainerItems>
			</Menu>
		</Box>
	);
}

const styleConfig = {
	menuUser: {
		position: 'fixed',
		bottom: '8%'
	}
};
export default MenuUser;
