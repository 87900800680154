import { tiGeoType } from 'store/typesActions/types';
import { api } from 'services/api';

export const tisGeo = () => async (dispatch, getState) => {
	const { tisGeoData } = getState().tiGeo;

	if (tisGeoData.length === 0) {
		dispatch({ type: tiGeoType.TIS_GEO_REQUEST });
		try {
			const res = await api.get(`/tis/geo`);
			const { data } = res || {};
			dispatch({
				type: tiGeoType.TIS_GEO_SUCCESS,
				payload: data
			});
		} catch (error) {
			const { response } = error || {};
			const { data } = response || {};
			const { message } = data || {};
			dispatch({
				type: tiGeoType.TIS_GEO_ERROR,
				payload: message || 'Erro desconhecido. Tente novamente mais tarde'
			});
		}
	}
};

export const tiGeoRec = (code) => async (dispatch) => {
	dispatch({ type: tiGeoType.TI_GEO_REC });
	try {
		const res = await api.get(`/ti/${code}/geo`);
		const { data } = res || {};
		dispatch({
			type: tiGeoType.TI_GEO_REC_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: tiGeoType.TI_GEO_REC_ERROR,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: tiGeoType.TI_GEO_REC_ERROR,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};
