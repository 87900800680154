/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */

import React, { useCallback, useEffect, useRef } from 'react';
import { urlLanding } from 'lib/urlLanding';
import styled from 'styled-components';

const Background = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 207px;
	z-index: 9999;
	bottom: 0;
	right: 3.6rem;
	@media (max-width: 800px) {
		bottom: -9.5rem;
		right: 5rem;
	}
`;

const ModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #d44500;
	color: #000;
	border-radius: 6px;
	border: 1px solid #d9d5d2;
	//background-blend-mode: multiply;
	padding: 1rem 1.5rem;
`;

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
`;

const LinkButton = styled.a`
	text-decoration: none;
	color: #fff;
	width: 100%;

	padding: 0.3rem 0;
	text-transform: uppercase;

	font-size: 0.7rem;
	white-space: nowrap;

	& + a {
		border-top: 1px solid;
		border-color: #d9d5d2;
	}
`;

function ListModal({ showModal, setShowModal }) {
	const modalRef = useRef();
	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModal(false);
		}
	};

	const keyPress = useCallback(
		(e) => {
			if (e.key === 'Escape' && showModal) {
				setShowModal(false);
			}
		},
		[setShowModal, showModal]
	);

	useEffect(() => {
		document.addEventListener('keydown', keyPress);
		return () => document.removeEventListener('keydown', keyPress);
	}, [keyPress]);

	return (
		<>
			{showModal ? (
				<Background onClick={closeModal} ref={modalRef}>
					<ModalWrapper showModal={showModal}>
						<ModalContent>
							<LinkButton href={`${urlLanding}/ajuda`}>
								Tutorial de boas-vindas
							</LinkButton>
							<LinkButton href="#">Manual PDF </LinkButton>
							<LinkButton href={`${urlLanding}/ajuda`}>
								Perguntas frequentes
							</LinkButton>
							<LinkButton href={`${urlLanding}/fale_conosco`}>
								Suporte
							</LinkButton>
						</ModalContent>
					</ModalWrapper>
				</Background>
			) : null}
		</>
	);
}

export default ListModal;
