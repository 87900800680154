import styled from 'styled-components';
// import { color } from 'styles/Theme';

const Container = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`;

const ButtonOrder = styled.button`
	display: flex;
	width: 18px;
	height: 18px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	background: #f2ede9;
`;
const Icon = styled.img`
	height: 7px;
	width: 10px;
`;
export { ButtonOrder, Container, Icon };
