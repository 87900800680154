export const terms = {
	title:
		'TERMOS DE USO E POLÍTICA DE PRIVACIDADE DO SITE E DAS PLATAFORMAS DO INSTITUTO DE PESQUISA AMBIENTAL DA AMAZÔNIA (IPAM)',
	lastUpdate: '18/09/2022',
	text: `
    <p>Ol&aacute;, n&oacute;s do <strong>IPAM </strong>queremos proteger a privacidade dos dados de todas as pessoas que s&atilde;o nossas usu&aacute;rias. &Eacute; por isso que nossos sistemas est&atilde;o adequados para cuidar dos seus dados pessoais e fizemos um <strong>TERMO DE USO E POL&Iacute;TICA DE PRIVACIDADE DO SITE E DAS PLATAFORMAS</strong> que em breve estar&aacute; dispon&iacute;vel no site do IPAM.</p>
<p>Mas queremos ir al&eacute;m de observar a Lei Geral de Prote&ccedil;&atilde;o de Dados &ndash; LGPD (<a href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm">Lei n&ordm; 13.709/2018</a>). Preparamos esse resumo com perguntas e respostas para que fique mais f&aacute;cil voc&ecirc; saber <strong>como, quando e para qu&ecirc;</strong> usamos seus dados. Se mesmo assim ficar com alguma d&uacute;vida ou tiver alguma sugest&atilde;o, fale conosco pelo e-mail somai@ipam.org.br. Queremos te ouvir!</p>
<p><strong><u>Antes de tudo, para que serve um termo de uso e uma pol&iacute;tica de privacidade? </u></strong></p>
<p>Para inform&aacute;-la(o) sobre como seus dados s&atilde;o utilizados e firmar um compromisso de transpar&ecirc;ncia com usu&aacute;rios dos nossos servi&ccedil;os em rela&ccedil;&atilde;o &agrave;s formas de tratamento dos dados que coletamos.</p>
<p><strong><u>Quais dados o IPAM utiliza?</u></strong></p>
<p>Ao utilizar o aplicativo Alerta Clima Ind&iacute;gena ou ao fazer o login no SOMAI, os seguintes dados s&atilde;o coletados:</p>
<ul>
<li>Nome e sobrenome;</li>
<li>E-mail;</li>
<li>Geolocaliza&ccedil;&atilde;o;</li>
<li>Alerta registrado por voc&ecirc; indicando o problema/quest&atilde;o que existe no seu territ&oacute;rio;</li>
<li>Registro de voz, se voc&ecirc; nos enviar um &aacute;udio sobre o problema que deseja registrar;</li>
<li>Os usos tradicionais da terra - quando voc&ecirc; registra os usos tradicionais que a sua comunidade faz daquele territ&oacute;rio.</li>
</ul>
<p>Tamb&eacute;m s&atilde;o coletados os seguintes dados sens&iacute;veis:</p>
<ul>
<li>No momento do cadastro: Organiza&ccedil;&atilde;o &agrave; qual pertence (orienta&ccedil;&atilde;o pol&iacute;tico/filos&oacute;fica) &ndash; utilizada para a realiza&ccedil;&atilde;o de relat&oacute;rios de uso dessas ferramentas, poss&iacute;veis pesquisas utilizando dados agregados e para preven&ccedil;&atilde;o &agrave; fraude;</li>
<li>Comunidade tradicional a qual a pessoa pertence (origem &eacute;tnica/racial) &ndash; utilizada para a realiza&ccedil;&atilde;o de relat&oacute;rios de uso dessas ferramentas, poss&iacute;veis pesquisas utilizando dados agregados e para preven&ccedil;&atilde;o &agrave; fraude.</li>
</ul>
<p>Para garantir a sua seguran&ccedil;a, os dados coletados pelo aplicativo ficam salvos na nossa base de dados de forma pseudonimizada, na qual n&atilde;o &eacute; poss&iacute;vel identificar diretamente o usu&aacute;rio.</p>
<p>Isso quer dizer que, em uma tabela mantemos os ID&rsquo;s dos usu&aacute;rios que geraram os alertas por meio do aplicativo e em outra tabela mantemos os seus nomes e informa&ccedil;&otilde;es pessoais. Apenas em caso de necessidade, faremos o cruzamento entre essas duas tabelas, para que possamos identificar quem especificamente fez os alertas e nos forneceu informa&ccedil;&otilde;es.</p>
<p><strong><u>Para qu&ecirc; os meus dados s&atilde;o utilizados?</u></strong></p>
<ul>
<li>Para viabilizar o registro do alerta que voc&ecirc; deseja fazer sobre o seu territ&oacute;rio;</li>
<li>Para relat&oacute;rios com fins estat&iacute;sticos;</li>
<li>Para prevenir fraude e conservar a qualidade dos dados da nossa base;</li>
<li>Para nos comunicarmos com voc&ecirc;;</li>
<li>Para gerir a nossa rela&ccedil;&atilde;o com voc&ecirc; no uso do aplicativo;</li>
<li>Para ajudar a sua comunidade a evidenciar o que est&aacute; acontecendo no seu territ&oacute;rio diante dos &oacute;rg&atilde;os respons&aacute;veis por proteg&ecirc;-lo;</li>
<li>Por fim, utilizamos os dados para auxiliar na gest&atilde;o do nosso trabalho. Assim a gente melhora a nossa comunica&ccedil;&atilde;o com voc&ecirc; e deixa o servi&ccedil;o registrado de maneira adequada.</li>
</ul>
<p>De forma geral, os seus dados ser&atilde;o utilizados de maneira an&ocirc;nima, de forma agregada e n&atilde;o nominal. Por exemplo, em 2020, nossas plataformas atenderam X usu&aacute;rios. Em 2021 foram coletados X alertas de desmatamento na Amaz&ocirc;nia Legal.</p>
<p>Utilizaremos os dados de forma direta, ou seja, identificando voc&ecirc;, s&oacute; se isso for necess&aacute;rio. Por exemplo: se voc&ecirc; ou o representante da sua comunidade nos pedir ou se recebermos algum pedido de autoridades para esse compartilhamento.</p>
<p><strong><u>Meus dados podem ser vendidos?</u></strong></p>
<p>N&atilde;o vendemos, nem compramos dados! Sua privacidade &eacute; respeitada por n&oacute;s.</p>
<p><strong><u>Meus dados podem ser compartilhados com terceiros?</u></strong></p>
<p>Sim, os seus dados s&atilde;o compartilhados:</p>
<ul>
<li>Com a pessoa que &eacute; a representante da organiza&ccedil;&atilde;o a qual voc&ecirc; pertence, que voc&ecirc; nos informou no seu cadastro. Para isso mantemos um registro interno de pessoas que representam cada organiza&ccedil;&atilde;o.</li>
<li>Dentro do pr&oacute;prio IPAM, restringimos esse acesso apenas ao administrador do banco de dados, ou seja, apenas essa pessoa pode acessar diretamente esses dados.</li>
<li>Ainda, os dados poder&atilde;o ser compartilhados, sem o seu consentimento, com as autoridades em caso de requisi&ccedil;&atilde;o judicial ou administrativa ou para o exerc&iacute;cio de direitos legais.</li>
</ul>
<p>O IPAM n&atilde;o se responsabiliza por realizar den&uacute;ncias ou encaminhar alertas e registros realizados pelo usu&aacute;rio para os &oacute;rg&atilde;os ou entidades do poder p&uacute;blico. Contudo, as informa&ccedil;&otilde;es coletadas pelo ACI, ou disponibilizadas na plataforma SOMAI, podem servir como subs&iacute;dio &agrave;s den&uacute;ncias, e encaminhamentos por parte dos usu&aacute;rios para entidades competentes.</p>
<p><strong><u>Origem p&uacute;blica de dados</u></strong></p>
<p>Ao navegar pelo aplicativo e pela plataforma, voc&ecirc; ir&aacute; receber muitos dados importantes que n&oacute;s produzimos ou consolidamos de fontes p&uacute;blicas. Usamos dados disponibilizados publicamente com a finalidade de auxiliar povos ind&iacute;genas a proteger seus territ&oacute;rios.</p>
<p>Se for utiliz&aacute;-los, sempre indique a fonte, dando cr&eacute;ditos de quem &eacute; a autoria da informa&ccedil;&atilde;o.</p>
<p><strong><u>Esse Termo se aplica a quais plataforma, <em>sites </em>e aplicativos? </u></strong></p>
<p>Ao aplicativo Alerta Clima Ind&iacute;gena (ACI) e a plataforma Sistema de Observa&ccedil;&atilde;o e Monitoramento da Amaz&ocirc;nia Ind&iacute;gena (SOMAI).</p>
<p>No ACI e SOMAI h&aacute; <strong>links de outras organiza&ccedil;&otilde;es</strong>. S&atilde;o parceiros ou pessoas que estamos recomendando para voc&ecirc; conhecer mais sobre algum tema. Voc&ecirc; pode acessar esses outros links, mas <strong>neles os Termos de uso no IPAM n&atilde;o se aplicam</strong>. Ent&atilde;o, antes de fornecer quaisquer informa&ccedil;&otilde;es ou dados nestes outros links, leia atentamente a Pol&iacute;tica de uso de dados deles.</p>
<p><strong><u>Tem menos do que 18 anos?</u></strong></p>
<p>Se sim, voc&ecirc; precisa da autoriza&ccedil;&atilde;o de seus pais ou respons&aacute;veis legais para navegar em nossos canais e nos enviar qualquer tipo de dados pessoais.</p>
<p>Por isso, se voc&ecirc; for menor de idade, pedimos a sua data de nascimento e utilizaremos os seus dados para conseguir obter consentimento de uma pessoa maior de idade que possa acompanhar o uso dos seus dados no nosso aplicativo.</p>
<p><strong><u>Suas responsabilidades </u></strong></p>
<p>Ao usar plataformas, sites e aplicativos do <strong>IPAM</strong>, voc&ecirc; tamb&eacute;m se compromete com algumas obriga&ccedil;&otilde;es. Veja as principais:</p>
<ul>
<li>Informar os seus dados de modo verdadeiro e sem erros;</li>
<li>Proteger a sua senha de acesso e n&atilde;o permitir que outras pessoas a utilizem de forma inadequada;</li>
<li>N&atilde;o compartilhar os alertas criados por voc&ecirc; com pessoas de fora da sua organiza&ccedil;&atilde;o sem a autoriza&ccedil;&atilde;o do representante dela;</li>
<li>N&atilde;o insira nenhum material indevido ou utilize qualquer obra protegida por direitos autorais sem a devida autoriza&ccedil;&atilde;o. Al&eacute;m do conte&uacute;do ser exclu&iacute;do, voc&ecirc; pode ter que responder civil e criminalmente.</li>
</ul>
    `
};
