/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import GenericAccordion from 'components/GenericAccordion';
import Search from 'components/Buttons/Search/Search';
import Select from 'components/Buttons/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLocalStorage } from 'services/session';
import {
	formatTisValueAndLabel,
	formatArrayAndReturnValueAndLabel,
	headers,
	takePrintMap,
	ajustZoomExtent,
	isEmptyObject,
	filterDataToReport,
	actionsUsers,
	nameFileDownGeoMonitoring
} from 'helpers/utils';
import { api } from 'services/api';
import CalendarStartAndEnd from 'components/Buttons/CalendarStartAndEnd';
import BoxItemSelected from 'components/Buttons/BoxItemSelected/BoxItemSelected';
import { color } from 'styles/Theme';
import Button from 'components/Buttons/Button/Button';
import { svg } from 'lib/svgList';
import Modal from 'components/Modal';
import { clearVector } from 'store/actions/mapActions';
import {
	updateDataMonitoringAction,
	addFilterTi,
	addFilterType,
	addFilterDate,
	removeFilterTi,
	removeFilterType,
	removeFilterDate,
	geoDataDownload
} from 'store/actions/monitoringDataAction';
import PackReq from 'components/PackReq';
import * as S from './styled';

export default function Export({ typeMonitoringAndMenu = '' }) {
	const dispatch = useDispatch();
	// lista com todas as TIs
	const listTi = useSelector((state) => state.listTis);

	const pointsOnMap = useSelector((state) => state.dataUserToShowOnMap.dataUser);

	const { tisList } = useSelector((state) => state.listTis);

	// Add o nome da ti ao array

	pointsOnMap.forEach((item) => {
		const filterTi = tisList.filter((f) => item.ti_code === f.code);
		return (item.nameTi = filterTi?.length > 0 ? filterTi[0]?.name : 'Não possui');
	});

	const { copyDataMonitoring, dataMonitoring, filterData, routeMonitoring } = useSelector((state) => state.monitoring);

	// TIS
	// localListTis é a lista de TIs filtradas com o intersect de alertas, ou seja, somente as TIs que possuem alertas dentro delas
	const [localListTis, setLocalListTis] = useState([]);
	const [tiSelected, setTiSelected] = useState({});
	const [allTisSelecteds, setAllTisSelecteds] = useState([]);

	// Tipos selecionandos
	const [typesAlert, setTypesAlert] = useState([]);
	const [typeAlertSelected, setTypeAlertSelected] = useState({});
	const [allTypesAlertsSelected, setAllTypesAlertsSelected] = useState([]);

	// datas dos relatórios
	const [dates, setDates] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [isReq, setIsReq] = useState(false);
	const [errorReq, setErrorReq] = useState(false);
	const [linkPdf, setLinkPdf] = useState('');

	// *------------ Funções seleciona TI ------------*

	// adiciona a TI da lista de TIs selecionadas e remove da lista local de TIs (copia da lista do redux)
	function addTiOnList(tiClicked) {
		setAllTisSelecteds([...allTisSelecteds, tiClicked]);
		const newList = localListTis.filter((item) => item.value !== tiClicked.value);
		setLocalListTis(newList);
	}

	// remove a TI da lista de TIs selecionadas e adiciona/devolve da lista local de TIs (copia da lista do redux)
	function removeTiOnList(tiClicked) {
		setTimeout(() => {
			document.getElementById('searchComponent').value = '';
		}, 500);
		setLocalListTis([...localListTis, tiClicked]);
		const newList = allTisSelecteds.filter((item) => item.value !== tiClicked.value);
		setAllTisSelecteds(newList);
	}

	// fica observando o redux para jogar na lista local a lista de TIs
	useEffect(() => {
		if (listTi.tisList.length > 0) {
			setLocalListTis(formatTisValueAndLabel(listTi.tisList));
		} else {
			setLocalListTis([]);
		}

		return () => {
			setLocalListTis([]);
		};
	}, [listTi]);

	// Quando uma ti é selecionada, ele é add ao estado allTisSelecteds
	useEffect(() => {
		if ('value' in tiSelected) {
			addTiOnList(tiSelected);
		}

		return () => {
			setLocalListTis([]);
		};
	}, [tiSelected]);

	// Filtra a lista com todas TIs para somentes as TIs que estão dentro do intersect
	useEffect(() => {
		if (copyDataMonitoring.length > 0 && listTi.tisList.length > 0) {
			const tisWithAlert = [];
			copyDataMonitoring.forEach((item) => {
				if (item.ti_code !== null) {
					tisWithAlert.push(item.ti_code);
				}
			});

			const semRepetidos = tisWithAlert.filter((el, i) => tisWithAlert.indexOf(el) === i);

			const listTisIntersect = listTi.tisList.filter((item) => {
				let exist = false;
				if (item.ti_code !== null) {
					semRepetidos.forEach((position) => {
						if (position === item.code) {
							exist = true;
						}
					});
				}
				return exist ? item : null;
			});

			if (listTisIntersect.length > 0) {
				setLocalListTis([{ value: null, label: 'Alertas fora da TI' }, ...formatTisValueAndLabel(listTisIntersect)]);
			} else {
				setLocalListTis([]);
			}
		} else {
			setLocalListTis([]);
		}
	}, [copyDataMonitoring, listTi]);

	// *------------ Funções select Type TI ------------*

	// função que gera uma lista de tipos de alertas que o usuário possui
	async function getTypesFromUserAlerts(allAlerts) {
		// pegando o tipo dos alertas no array de alertas
		if (typeof allAlerts === 'object' && allAlerts.length > 0) {
			const listAllAlerts = await allAlerts.map((item) => ({
				type: item.type,
				name: item.name
			}));
			const listTypesNoRepeat = [];

			// tirando os alertas repetidos
			await listAllAlerts.forEach((item) => {
				const verifyIfExist = listTypesNoRepeat.filter((alertItem) => alertItem.type === item.type);
				if (verifyIfExist.length === 0) {
					listTypesNoRepeat.push(item);
				}
			});
			setTypesAlert(formatArrayAndReturnValueAndLabel(listTypesNoRepeat));
		}
	}

	// adiciona o tipo na lista de tipos selecionadas e remove da lista local de tipos (copia da lista do redux)
	function addTypeOnList(typeClicked) {
		setAllTypesAlertsSelected([...allTypesAlertsSelected, typeClicked]);
		const newList = typesAlert.filter((item) => item.value !== typeClicked.value);
		setTypesAlert(newList);
	}

	// remove o tipo na lista de tipos selecionadas e adiciona/devolve da lista local de tipos (copia da lista do redux)
	function removeTypeOnList(typeClicked) {
		setTypesAlert([...typesAlert, typeClicked]);
		const newList = allTypesAlertsSelected.filter((item) => item.value !== typeClicked.value);
		setAllTypesAlertsSelected(newList);
	}

	// fica observando a lista de todos os alertas do usuário que está no redux, para poder fazer o filtro e gerar uma lista de tipos de alertas que o usuário possui
	useEffect(async () => {
		await getTypesFromUserAlerts(copyDataMonitoring);

		return () => {
			setTypesAlert([]);
		};
	}, [copyDataMonitoring]);

	// Quando um tipo é selecionado, ele é add ao estado allTypesAlertsSelected
	useEffect(() => {
		if (typesAlert.length > 0) {
			addTypeOnList(typeAlertSelected);
		}

		return () => {
			setTypesAlert([]);
		};
	}, [typeAlertSelected]);

	// *------------ Funções data para relátório TI ------------*

	async function createReport() {
		await ajustZoomExtent('monitoring');
		setShowModal(true);
		setIsReq(true);
		setErrorReq(false);

		// é preciso colocar a função dentro desse setTimeout pq o mapa precisar terminar de ajustar o zoom antes de tirar print
		setTimeout(async () => {
			const { id, firstName, lastName } = getUserLocalStorage().user;
			const data = {
				userId: id,
				photo: takePrintMap() || '',
				userName: `${firstName} ${lastName}`,
				typePdf: typeMonitoringAndMenu,
				typesAlert: allTypesAlertsSelected || null,
				tis: allTisSelecteds || null,
				dates: dates || null,
				data: pointsOnMap.filter((item) => item.isChecked === true)
			};

			await api
				.post(`/createPDF/painel`, data, {
					headers: headers()
				})
				.then((res) => {
					setErrorReq(false);
					setLinkPdf(res.data.url);
					setIsReq(false);
				})
				.catch(() => {
					// const { response: res } = erro;
					setErrorReq(true);
					setIsReq(false);
					setLinkPdf('');
				});
		}, 500);
	}

	// efeitos para filtrar a lista de alertas
	useEffect(() => {
		if (allTisSelecteds.length > 0) {
			dispatch(addFilterTi(allTisSelecteds));
		} else {
			dispatch(removeFilterTi());
		}

		return () => {
			dispatch(removeFilterTi());
		};
	}, [allTisSelecteds]);

	useEffect(() => {
		if (allTypesAlertsSelected.length > 0) {
			dispatch(addFilterType(allTypesAlertsSelected));
		} else {
			dispatch(removeFilterType());
		}

		return () => {
			dispatch(removeFilterType());
		};
	}, [allTypesAlertsSelected]);

	useEffect(() => {
		if (!isEmptyObject(dates) || dates !== null) {
			dispatch(addFilterDate(dates));
		} else {
			dispatch(removeFilterDate());
		}

		return () => {
			dispatch(removeFilterDate());
		};
	}, [dates]);

	useEffect(() => {
		const data = filterDataToReport(copyDataMonitoring, filterData.type, filterData.date, filterData.tis);

		if (data.length === 0) {
			clearVector('monitoring');
			dispatch(updateDataMonitoringAction(data));
		} else {
			dispatch(updateDataMonitoringAction(data));
		}
	}, [filterData]);

	// ------------fim-------------

	useEffect(() => {
		if (
			filterData.type.length === 0 &&
			filterData.tis.length === 0 &&
			filterData.date.initialDateFormated === '' &&
			filterData.date.finalDateFormated === ''
		) {
			dispatch(updateDataMonitoringAction(copyDataMonitoring));
		}
	}, [filterData]);

	const handleGA = () => {
		actionsUsers({
			category: 'plataforma/tabPainel/relatorio',
			action: `Gerou relatório de ${typeMonitoringAndMenu}`,
			label: typeMonitoringAndMenu
		});
	};

	const hashisDownload = dataMonitoring?.map(({ hash }) => hash) || [];

	return (
		<GenericAccordion
			title="EXPORTAR"
			styleTitle={{ color: color.interface.darkGray, fontWeight: '900' }}
			styleLine={{
				backgroundColor: color.interface.darkGray,
				fontWeight: '900',
				height: '1px'
			}}
			isInitiOpen
		>
			<S.containerExport>
				<S.containerInsideAccordion>
					{/* componente de busca  */}
					{/* !!!!! quando o intersect for feito é só apagar a primeira condição, e alterrar de && para || !!!! */}
					{typeMonitoringAndMenu === 'alert' || typeMonitoringAndMenu === 'traditionalUse' ? (
						<Search
							placeholder="Buscar por TI"
							isLoading={listTi.isLoading}
							itemsList={localListTis}
							setItemSelected={setTiSelected}
							styleInput={{ fontSize: '1rem' }}
							isDisable={localListTis.length === 0}
							autoClear
						/>
					) : null}

					{/* esse aviso so pode aparecer quando nao tiver nenhuma TI no instersect ou estiver fora de alert e tradicional use  */}
					{localListTis.length === 0 &&
						(typeMonitoringAndMenu === 'alert' || typeMonitoringAndMenu === 'traditionalUse') && (
							<S.noticeWarning>Você não possui registros dentro do território de uma TI</S.noticeWarning>
						)}

					{/* componente de select  */}
					{typeMonitoringAndMenu === 'alert' || typeMonitoringAndMenu === 'traditionalUse' ? (
						<Select
							style={{
								padding: '1.5rem 0.8rem 1.5rem 0.8rem',
								borderRadius: '30px',
								border: `1px solid ${color.interface.lightGray} `
							}}
							placeHolder="Tipo de alerta"
							options={typesAlert}
							isLoading={false}
							setItemSelected={setTypeAlertSelected}
							isLineOnTheList
						/>
					) : null}

					{/* componente de calendario  */}
					<CalendarStartAndEnd setDates={setDates} dates={dates} />

					{/* componente de TIs selecioandas  */}
					{allTisSelecteds.length > 0 ? (
						<S.containerBoxItemSelected>
							{allTisSelecteds.map((item) => (
								<BoxItemSelected
									onClick={() => removeTiOnList(item)}
									key={item.value || Math.random() * 100}
									text={item.label}
									style={{
										backgroundColor: color.interface.lightGray,
										color: color.interface.darkGray,
										borderRadius: '30px'
									}}
								/>
							))}
						</S.containerBoxItemSelected>
					) : null}

					{/* componente de Alertas selecionados  */}

					{allTypesAlertsSelected.length > 0 ? (
						<S.containerBoxItemSelected>
							{allTypesAlertsSelected.map((item) => (
								<BoxItemSelected
									onClick={() => removeTypeOnList(item)}
									key={item.value || Math.random() * 100}
									text={item.label}
									style={{
										backgroundColor: color.interface.beige,
										color: color.interface.darkGray,
										border: `1px solid ${color.interface.lightGray}`,
										borderRadius: '30px'
									}}
								/>
							))}
						</S.containerBoxItemSelected>
					) : null}

					{/* botoes de gerar relatório e gerar gráfico */}

					<S.buttonsReports>
						<S.boxBtn>
							<Button
								text="Gerar relatório"
								style={{
									width: '100%',
									whiteSpace: 'nowrap',
									border: `2px solid ${color.interface.orange}`,
									padding: '0.5rem 0.8rem 0.5rem 0.8rem'
								}}
								icon={svg.icon.download}
								onClick={() => {
									createReport();
									handleGA();
								}}
								isDisable={dataMonitoring.length === 0}
								isHover={false}
								widthIcon={15}
							/>
						</S.boxBtn>

						<S.boxBtn>
							<Button
								text="Geodados"
								style={{
									width: '100%',
									backgroundColor: 'transparent',
									border: `2px solid ${color.interface.orange}`,
									color: color.interface.orange,
									padding: '0.5rem 0.8rem 0.5rem 0.8rem',
									whiteSpace: 'nowrap'
								}}
								icon={dataMonitoring.length === 0 ? svg.icon.download : svg.icon.downloadOrange}
								onClick={() => {
									dispatch(
										geoDataDownload({
											routeMonitoring,
											hashis: hashisDownload,
											nameFile: nameFileDownGeoMonitoring(routeMonitoring)
										})
									);
									handleGA();
								}}
								isDisable={dataMonitoring.length === 0}
								widthIcon={15}
							/>
						</S.boxBtn>
					</S.buttonsReports>
					{dataMonitoring.length === 0 && (
						<S.noticeWarning>Você não possui registro para gerar relatório</S.noticeWarning>
					)}
				</S.containerInsideAccordion>
			</S.containerExport>

			<Modal isOpen={showModal} btnClose={!isReq} setIsOpen={() => setShowModal(false)} useBtnEnd={false}>
				<PackReq text="Gerando relatório..." isReq={isReq} errorReq={errorReq} linkPdf={linkPdf} />
			</Modal>
		</GenericAccordion>
	);
}
