import { systemType } from '../typesActions/types';
import { transform } from 'ol/proj';
const initialState = {
	coordinates: [],
	geom: [],
	statusLegend: true
};

export default function systemReducer(state = initialState, action) {
	switch (action.type) {
		case systemType.GET_COORDS_ON_MAP:
			return {
				...state,
				coordinates: action.payload
			};
		case systemType.GET_GEOM_ON_MAP:
			const tracker = {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: action.payload.map((e) => {
						const coord = transform(e, 'EPSG:3857', 'EPSG:4326');
						return [
							parseFloat(coord[1]).toFixed(6),
							parseFloat(coord[0]).toFixed(6)
						];
					})
				}
			};
			return {
				...state,
				geom: tracker
			};

		case systemType.VIEW_LEGEND:
			return {
				...state,
				statusLegend: action.payload
			};

		default:
			return state;
	}
}
