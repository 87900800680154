import { layersTisType } from 'store/typesActions/types';

const initialState = {
	toggleTerrasIndigenas: false,
	toggleEstadosAmazonia: false,
	toggleSedesMunicipais: false,
	toggleCoordenacaoRegional: false,
	toggleDistritoSanitario: false
};

export default function layersTisReducer(state = initialState, action) {
	switch (action.type) {
		case layersTisType.TOGGLE_TERRAS_INDIGENAS:
			return {
				...state,
				toggleTerrasIndigenas: action.payload
			};

		case layersTisType.TOGGLE_ESTADOS_AMAZONIA:
			return {
				...state,
				toggleEstadosAmazonia: action.payload
			};

		case layersTisType.TOGGLE_SEDES_MUNICIPAIS:
			return {
				...state,
				toggleSedesMunicipais: action.payload
			};

		case layersTisType.TOGGLE_COORDENACAO_REGIONAL:
			return {
				...state,
				toggleCoordenacaoRegional: action.payload
			};

		case layersTisType.TOGGLE_DISTRITO_SANITARIO:
			return {
				...state,
				toggleDistritoSanitario: action.payload
			};

		default:
			return state;
	}
}
