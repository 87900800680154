import React, { useState } from 'react';
import TitleMenu from 'components/TitleMenu';
import TabSection from './TabSection';
import * as S from './styled';

export default function MenuList({
	subTitle = '',
	content = '',
	theme = '',
	isStyle = {},
	isItemSelected = '',
	setIsItemSelected = () => {},
	size = 0
}) {
	const [isOpenItem, setIsOpenItem] = useState(false);

	if (isItemSelected === '')
		return (
			<TitleMenu
				title={subTitle}
				onClick={() => {
					setIsOpenItem(true);
					setIsItemSelected(subTitle);
				}}
				theme={theme}
				isStyle={isStyle}
			/>
		);

	return isOpenItem && isItemSelected === subTitle ? (
		<S.Box>
			<TabSection
				sectionName={`Total de ${subTitle}: ${size}`}
				isButtonBack
				isActionButton={() => {
					setIsOpenItem(false);
					setIsItemSelected('');
				}}
			/>

			{content}
		</S.Box>
	) : null;
}
