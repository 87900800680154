import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { svg } from 'lib/svgList';
import * as S from './styled';

function ButtonList({
	buttonName,
	buttonText = '',
	content,
	defaultOpen,
	theme = ''
}) {
	const [showContent, setShowContent] = useState(defaultOpen);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<S.Container
			style={theme === 'light' ? { border: 'none', background: 'none' } : {}}
		>
			<S.BoxButton>
				<S.Text>
					<h4>{buttonName}</h4>
					{buttonText && buttonText.length > 0 && (
						<AiOutlineInfoCircle
							size={16}
							color="#69625D"
							onClick={handleClick}
						/>
					)}
				</S.Text>

				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
				>
					<S.boxInformation>{buttonText}</S.boxInformation>
				</Popover>

				<S.IconButton onClick={() => setShowContent(!showContent)}>
					<img
						src={showContent ? svg.icon.toRecallOrange : svg.icon.expandOrange}
						alt={buttonName}
					/>
				</S.IconButton>
			</S.BoxButton>

			{showContent && <S.Content>{content}</S.Content>}
		</S.Container>
	);
}

export default ButtonList;
