import styled from 'styled-components';
import { color } from 'styles/Theme';

export const ContainerIndex = styled.div`
	padding-bottom: 20rem;

	.description-name {
		font-weight: 700;
		margin-right: 5px;
		color: ${color.system.font};
	}
`;
