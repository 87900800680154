import React from 'react';
import GenericAccordion from 'components/GenericAccordion';
import LayersGroup from 'components/LayersGroup';
import { useSelector } from 'react-redux';
import { color } from 'styles/Theme';
import * as S from './styled';

export default function HumanOcupation() {
	const { allLayers } = useSelector((state) => state.allLayersMap);
	const key = 'ocupacaoHumana';
	const filterLayers = allLayers?.filter((item) => item.key === key);

	return (
		<GenericAccordion
			title={filterLayers[0]?.name.toUpperCase() || ''}
			keyAccordion={key}
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontFamily: 'Nunito',
				fontStyle: 'normal',
				fontWeight: '800',
				lineHeight: '0.875rem',
				fontSize: '0.625rem',
				letterSpacing: '0.1em',
				textTransform: 'uppercase'
			}}
		>
			<S.containerAccordionInside>
				<LayersGroup layerGroup={key} useToggle={false} showTitle={false} />
			</S.containerAccordionInside>
		</GenericAccordion>
	);
}
