import styled from 'styled-components';
import { color } from 'styles/Theme';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Search = styled.div`
	width: 100%;
`;

export const line = styled.div`
	width: 100%;
	height: 0.5px;
	margin-top: 0.5rem;
	background-color: ${color.interface.lightGray};
	transition: all ease 0.5s;
`;

export const TextName = styled.p`
	font-family: 'Nunito';
	margin-left: 5px;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	/* or 160% */

	letter-spacing: 0.02em;

	/* Tons-Neutros/Cinza-Medio */

	color: #69625d;
`;

export const ContainerSearch = styled.div`
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;

	.search-name-users {
		width: 100%;
		padding: 0.8rem 3rem 0.8rem 0.8rem;
		background-image: url(${(props) => props.imgSearch});
		background-repeat: no-repeat;
		background-position: calc(100% - 20px);
		background-size: ${(props) => (props.showMagnifying ? '0.7rem' : '0.5rem')};
		outline: 0;
		border: 1px solid ${color.interface.lightGray};
		border-radius: 20px;
		height: 35px;

		input::placeholder {
			font-family: 'Nunito';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
		}

		img {
			width: 15px;
			height: 15px;
		}
		img:active {
			transform: scale(0.9);
		}
	}
`;

export const ListUsers = styled.div`
	width: 100%;
	max-height: inherit;
	overflow: auto;
`;
export const BoxFilter = styled.div`
	@keyframes show {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	font-size: 0.6rem;
	width: 100%;
	max-height: 160px;
	padding: 0.5rem;
	position: absolute;
	top: 115%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border: 1px solid ${color.interface.lightGray};
	border-radius: 10px;
	background-color: ${color.interface.whiteAbsolute};
	z-index: 10;
	font-weight: 400;

	animation-name: show;
	animation-duration: 1s;

	::-webkit-scrollbar-track {
		background: ${color.interface.white}; /* color of the tracking area */
	}

	::-webkit-scrollbar-thumb {
		background-color: ${color.interface.gray}99; /* color of the scroll thumb */
		border: 1px solid ${color.interface.gray}99; /* creates padding around scroll thumb */
	}

	.see-all-button {
		display: flex;
		width: 100%;
		background-color: transparent;
		cursor: pointer;
		border: none;

		align-items: center;
		transition: all 0.3s ease;

		> p {
			font-family: 'Nunito';
			margin-left: 5px;
			margin-right: 5px;
			font-style: normal;
			font-weight: 800;
			font-size: 10px;
			letter-spacing: 0.02em;
		}
	}
`;
