import Load from 'components/Load/Load';
import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './styled';

export default function Loading() {
	const { isLoading } = useSelector((state) => state.deforestationThreat);

	return <S.containerLoading>{isLoading && <Load />}</S.containerLoading>;
}
