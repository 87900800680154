import React from 'react';
import { clearVector } from 'store/actions/mapActions';
import { handleCloseTooltip } from 'helpers/utils';
import * as S from './styled';

export default function TabSection({
	sectionName = '',
	styleConfig = {},
	// lineColor = '',
	isButtonBack = false,
	icon = '',
	isActionButton = () => {}
}) {
	return (
		<S.containerTabSection>
			<S.BoxTitle>
				<h5
					style={
						styleConfig?.color !== '' ? { color: styleConfig?.color } : null
					}
				>
					{icon ? (
						<img
							style={{ width: 12, marginRight: 15 }}
							alt={sectionName}
							src={icon}
						/>
					) : null}
					{sectionName}
				</h5>
				{isButtonBack ? (
					<button
						type="button"
						onClick={() => {
							isActionButton();
							clearVector('monitoring');
							handleCloseTooltip();
						}}
					>
						{'< '} MENU INICIAL
					</button>
				) : null}
			</S.BoxTitle>

			<S.OrangeLine />
		</S.containerTabSection>
	);
}
