import React from 'react';
import Box from '@mui/material/Box';
import ButtonSocial from './ButtonSocial';
import ShareSocial from './ShareSocial';

export default function SocialNetworks({ sizeIcon }) {
	return (
		<Box mt={5} sx={confStyle.container}>
			<ButtonSocial sizeIcon={sizeIcon} />
			<ShareSocial sizeIcon={sizeIcon} />
		</Box>
	);
}

const confStyle = {
	container: {
		flexGrow: 1,
		display: { xs: 'flex', md: 'flex' }
	}
};
