import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formatNumber } from 'helpers/utils';
import { selectOnMap } from 'store/actions/mapActions';
import Load from 'components/Load/Load';
import * as S from './styled';

export default function List10({ title, topTen }) {
	const { isLoading, error } = useSelector((state) => state.threatsTis);

	const [firsItem] = topTen || [];

	const renderLinearTo = (percentage) =>
		(percentage / firsItem?.percentage) * 100;

	return (
		<S.container>
			<S.title>{title}</S.title>

			{error && <S.notice>Erro, tente novamente mais tarde</S.notice>}

			{isLoading && <Load />}

			{!error &&
				!isLoading &&
				topTen.map((d, index) => (
					<S.item
						key={`key-${index}`}
						linearTo={renderLinearTo(d.percentage)}
						onClick={() => selectOnMap(d.code)}
					>
						{index + 1}º - {d.name}: {formatNumber(d.data, 1, '.', ',')} ha - (
						{formatNumber(d.percentage, 2, '.', ',')}%)
					</S.item>
				))}
		</S.container>
	);
}

List10.propTypes = {
	title: PropTypes.string,
	topTen: PropTypes.arrayOf(PropTypes.objectOf)
};

List10.defaultProps = {
	title: '',
	topTen: []
};
