import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerStripedList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	/* background-color: aquamarine; */
`;

export const itemList = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding: 0.2rem;
	cursor: pointer;

	/* background-color: aquamarine; */
`;

export const containerDate = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	> img {
		width: 20px;
		height: 20px;
	}

	> button {
		cursor: pointer;
		background: transparent;

		> p {
			font-weight: 400;
			font-size: 0.9rem;
			color: ${color.interface.lightBrown};
			transition: all ease 0.5s;
			text-align: initial;
			:hover {
				color: ${color.interface.green};
			}
		}
	}

	/* background-color: red; */
`;

export const containerStatus = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	margin: 0.5rem;
	/* background-color: antiquewhite; */
	> img {
		width: 20px;
		height: 20px;
	}
`;

export const line = styled.div`
	position: absolute;
	min-width: 99%;
	height: 0.5px;
	bottom: 0;
	/* background-color: ${color.interface.lightGray}; */
`;

export const checkAll = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0.2rem;
	gap: 1rem;
	padding: 0.5rem;

	> p {
		font-size: 0.8rem;
		color: ${color.interface.gray};
	}
`;
