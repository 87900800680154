/* eslint-disable prettier/prettier */
import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { color } from 'styles/Theme';
import * as S from './styled';

export default function SimpleDialogMenu({
	title,
	icon = '',
	content = '',
	textButton = 'Ver'
}) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<S.containerTitleMenu onClick={handleClickOpen}>
				<S.containerItemAndArrow>
					<S.ItemMenu style={{ color: color.interface.orange }}>
						{icon ? <img src={icon} alt="item menu" /> : null}
						<p>{title}</p>
					</S.ItemMenu>

					<p>{textButton}</p>
				</S.containerItemAndArrow>

				<S.lineMenu />
			</S.containerTitleMenu>

			<Dialog onClose={handleClose} open={open}>
				<DialogTitle>{content}</DialogTitle>
			</Dialog>
		</div>
	);
}
