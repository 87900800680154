import styled from 'styled-components';

export const containerModal = styled.div`
	max-width: 50vh;
	padding: 1rem 1rem 0 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 0.5rem;

	b {
		font-size: 1.1rem;
		opacity: 0.9;
	}

	p {
		opacity: 0.8;
	}
`;
