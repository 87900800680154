import styled, { keyframes } from 'styled-components';

export const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 10px }
  100% { margin-bottom: 0 }
`;

export const DotWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Dot = styled.div`
	background-color: ${(props) => props.color};
	border-radius: 2px 2px 2px 2px;
	width: 20px;
	height: 80px;
	margin: 0 5px;
	box-shadow: 2px -1px 5px #9c9c9c;
	/* Animation */
	animation: ${BounceAnimation} 0.9s linear infinite;
	animation-delay: ${(props) => props.delay};
`;
