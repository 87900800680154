import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useOutsideClick } from '@chakra-ui/react';

// Calendar

export default function CalendarReact({
	calendarOpen = false,
	setCalendarOpen = () => {},
	onChange = () => {}
}) {
	const ref = React.useRef();
	// função chakra para fechar modal quando clicado fora dela
	useOutsideClick({
		ref,
		handler: () => setCalendarOpen(false)
	});
	return calendarOpen ? (
		<div
			style={{
				// width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 100
			}}
			ref={ref}
		>
			<Calendar
				onChange={onChange}
				locale="pt-BR"
				maxDetail="month"
				onClickDay={() =>
					setTimeout(() => {
						setCalendarOpen(false);
					}, 500)
				}
			/>
		</div>
	) : null;
}
