/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { svg } from 'lib/svgList';
import { approve } from 'store/actions/monitoringDataAction';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLocalStorage } from 'services/session';

import * as S from './Styled';

function ApproveOrDisapprove({ itemSelect }) {
	const dispatch = useDispatch();
	const { routeMonitoring, loadingApprove } = useSelector(
		(state) => state.monitoring
	);
	const { id, status } = itemSelect;
	const { firstName, lastName, email } = getUserLocalStorage().user;

	const onApprove = (situation) => {
		const params = {
			id,
			status: situation,
			routeMonitoring,
			approvedBy: {
				name: `${firstName} ${lastName}`,
				email
			}
		};

		dispatch(approve(params));
	};

	if (loadingApprove) return <p className="text2">Aguarde...</p>;

	return (
		<S.RadioInputWrapper props={status}>
			<input
				required
				value={status || 'null'}
				type="radio"
				id="not_approved"
				checked={status === 'not_approved'}
				onChange={() => onApprove('not_approved')}
			/>
			<label htmlFor="not_approved">
				<img
					style={{ width: 20 }}
					src={svg.icon.deniedAlert}
					alt="alerta negado"
				/>
			</label>

			<input
				required
				value={status || 'null'}
				type="radio"
				id="approved"
				checked={status === 'approved'}
				onChange={() => onApprove('approved')}
			/>
			<label htmlFor="approved">
				{' '}
				<img
					style={{ width: 20 }}
					src={svg.icon.acceptAlert}
					alt="alerta aprovado"
				/>
			</label>
		</S.RadioInputWrapper>
	);
}
export default ApproveOrDisapprove;
