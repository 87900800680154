import styled from 'styled-components';
import { color } from 'styles/Theme';

export const container = styled.div`
	display: flex;
	flex-direction: column;
	width: '100%';
	//height: 5.6rem;
	margin-top: 0.63rem;
	margin-bottom: 0.63rem;
`;

export const title = styled.div`
	font-size: 0.875rem;
	color: '#777';
	font-weight: 600;
	padding-bottom: 0.3rem;
`;

export const item = styled.div`
	font-size: 0.875rem;
	margin: 0.15rem 0rem;
	padding: 0.2rem 0 0.2rem 0.2rem;
	cursor: pointer;
	background: ${(props) =>
		`linear-gradient(to right, ${color.interface.red}33 ${
			props.linearTo
		}%, transparent ${100 - props.linearTo}%)`};
`;

export const notice = styled.p`
	color: ${color.interface.red};
`;
