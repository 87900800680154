import React from 'react';
import {
	defaults,
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import Load from 'components/Load/Load';
import { formatNumber } from 'helpers/utils';
import styled from 'styled-components';

ChartJS.register(ArcElement, Tooltip, Legend);

defaults.font.family =
	"Nunito,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif";

// MODELO LABELS
// const labels = [
// 	'January',
// 	'February',
// 	'March',
// ]

// // MODELO DATASETS
// const data=[10,20,30]

// // MODELO DATASETS
// const moreColors=['red', 'blue', 'yellow']

export default function DonutGraph({
	labels,
	dataSets,
	backgroundColor,
	borderColor,
	isLoading,
	valueIsPercente,
	legendDisplay
}) {
	const allZerosDataSets = dataSets.every((item) => item === 0);

	const options = {
		plugins: {
			datalabels: {
				color: 'white',
				labels: {
					title: {
						font: {
							weight: 'bold',
							size: 11
						}
					}
				},
				// borderRadius: 20,
				// backgroundColor: 'rgba(255, 255, 255, 0.8)',
				display: true,
				formatter: (value, context) => {
					const { dataset } = context || {};
					const { data } = dataset || {};

					const total = data?.reduce((ac, d) => ac + parseFloat(d, 10), 0);

					const perc = formatNumber((value / total) * 100, 2, '.', ',');
					return `${perc}%`;
				}
			},
			tooltip: {
				titleFont: {
					size: 11.1
				},
				bodyFont: {
					size: 11.1
				},
				callbacks: {
					label: (context) => {
						const { dataset, label, raw: rawValue } = context || {};
						const { data } = dataset || {};

						const total = data?.reduce((ac, d) => ac + parseFloat(d, 10), 0);

						const perc = formatNumber((rawValue / total) * 100, 2, '.', ',');
						const formatVal = formatNumber(rawValue, 0, '.', ',');

						const info01 = `${label}: ${formatVal} ha (${perc}%)`;
						const info02 = `${label}: ${perc}%`;

						return valueIsPercente ? info02 : info01;
					}
				}
			},
			legend: {
				display: legendDisplay,
				responsive: true,
				position: 'bottom',
				labels: {
					boxWidth: 10,
					padding: 10,
					usePointStyle: true,
					font: {
						size: 12
					}
				},
				align: 'center'
			}
		}
	};

	const data = {
		labels: [...labels],
		datasets: [
			{
				data: [...dataSets],
				backgroundColor,
				borderColor,
				borderWidth: 1
			}
		]
	};

	function returnDonutGraph() {
		if (isLoading) {
			return <Load />;
		}
		return dataSets.length !== 0 && !allZerosDataSets ? (
			<Doughnut data={data} plugins={[ChartDataLabels]} options={options} />
		) : (
			<p className="notes" style={{ textAlign: 'center' }}>
				Não há dados para gerar o gráfico
			</p>
		);
	}

	return <Container>{returnDonutGraph()}</Container>;
}

DonutGraph.propTypes = {
	labels: PropTypes.arrayOf(PropTypes.string),
	dataSets: PropTypes.arrayOf(PropTypes.number),
	backgroundColor: PropTypes.arrayOf(PropTypes.string),
	borderColor: PropTypes.arrayOf(PropTypes.string),
	isLoading: PropTypes.bool,
	valueIsPercente: PropTypes.bool,
	legendDisplay: PropTypes.bool
};

DonutGraph.defaultProps = {
	labels: [],
	dataSets: [],
	backgroundColor: ['#21543D', '#D44500'],
	borderColor: ['#21543D', '#D44500'],
	isLoading: false,
	valueIsPercente: false,
	legendDisplay: true
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
