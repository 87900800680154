import { tiGeoType } from 'store/typesActions/types';

const initialState = {
	tisGeoData: [], // todas as TIs
	tisGeoIsLoading: false,
	tisGeoError: false,
	tisGeoMessage: '',
	dataGeo: {}, // de uma única TI
	isLoading: false,
	error: false,
	message: ''
};

export default function tiGeoreducer(state = initialState, action) {
	switch (action.type) {
		case tiGeoType.TIS_GEO_REQUEST:
			return {
				...state,
				tisGeoIsLoading: true,
				tisGeoError: false,
				tisGeoMessage: 'Buscando dados de geo das TIs'
			};

		case tiGeoType.TIS_GEO_SUCCESS:
			return {
				...state,
				tisGeoData: action.payload,
				tisGeoIsLoading: false,
				tisGeoError: false,
				tisGeoMessage: ''
			};

		case tiGeoType.TIS_GEO_ERROR:
			return {
				...state,
				tisGeoData: [],
				tisGeoIsLoading: false,
				tisGeoError: true,
				tisGeoMessage: action.payload
			};
		case tiGeoType.TI_GEO_REC:
			return {
				...state,
				isLoading: true,
				error: false,
				message: 'Buscando dados do desmatamento'
			};

		case tiGeoType.TI_GEO_REC_SUCCESS:
			return {
				...state,
				dataGeo: action.payload,
				isLoading: false,
				error: false,
				message: ''
			};

		case tiGeoType.TI_GEO_REC_ERROR:
			return {
				...state,
				dataGeo: [],
				isReq: false,
				error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
			};
		default:
			return state;
	}
}
