import { createGlobalStyle } from 'styled-components';
import { color } from './Theme';
import { typo } from './typography';

export default createGlobalStyle`

:root {
    --highlight: ${color.interface.beige};
    --background: ${color.interface.beige};
    --container: 100rem;
    --small: 1.5rem;
    --medium: 3rem;
    --large: 5rem;
}

  * {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
    font-family:${typo.font}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ;
    ::-webkit-scrollbar {
		width: 6px; /* width of the entire scrollbar */

	}
 
	::-webkit-scrollbar-track {
		background: ${color.interface.white}; /* color of the tracking area */
	}

	::-webkit-scrollbar-thumb {
		background-color: ${color.interface.gray}99; /* color of the scroll thumb */
		border-radius: 2px; /* roundness of the scroll thumb */
		border: 1px solid ${color.interface.gray}99; /* creates padding around scroll thumb */
    
	}

  }
  


  html, body {
    background-color: ${color.interface.beige};
    height: 100%;
    width: 100%;
    min-height: 100%;
    max-width: 100%;
    touch-action: pan-x pan-y;
    touch-action: none;
  }
  
h1 {
  font-size: 42px;
}

h2 {   
  font-size: 36px;
}
h3 { 
  font-size: 24px;
}

h4 { 
  font-size: 1rem;
}

h5 { 
  font-size: 0.875rem;
}

h6 { 
  font-size:  0.75rem;

  @media (max-width: 415px) {
    font-size: 0.556rem;
	}

  @media (max-width: 370px) {
		font-size: 0.490rem;
	}

}

p {
  font-size: 0.85rem;
}

a,
input,
textarea,
button,
select {
    outline: none;
}

a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
    text-decoration: none;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
    outline: none;
    text-decoration: none;
}

.text{
  font-size: 0.716rem;
  letter-spacing: 0.12rem;
}
.title {

}
.text2{
  font-size: 0.875rem;
}
.sm-text__medium{
  font-weight:400;
}

.notes{
  font-size: 0.716rem;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
}

/* Custom button */

.layout-paint__btn {
		background-color: transparent;
		cursor: pointer;
	}

.sm__tooltip {
   position: relative;
}
.sm__tooltip::before {
   background-color: #006946;
   border-radius: 4px;
   color: #fff;
   content: attr(data-title);
   display: none;
   font-size:  0.813rem;
   font-weight: 700;
   white-space: nowrap;
   position: absolute;
   padding: 5px 10px;
   top: 5px;
   right: 40px;
   z-index: 1;
   text-transform: uppercase;
}
.sm__tooltip:hover::before {
   display: block;
}

@media (max-width: 600px)
{
  .sm__tooltip:hover::before
   {
    display: none;
   }
}

.ol-attribution.ol-collapsed{
  background-color:#006946;
  border-radius:50%;
  
}

#somai__custom-attribution-mobile,
	#somai__custom-attribution {
    .ol-attribution,
	.ol-control {
		position: static;
		background: none;
		border-radius: 0;
		padding: 0;
	}
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		color: #69625d;
		font-weight: 400;
		font-size: 0.9rem;
		a {
			text-decoration: none;
			color: #006946;
			white-space: nowrap;
		}
		ul {
			list-style-type: none;
			display: flex;
			align-items: center;
			align-content: center;
		}
		/* li {
			display: flex;
		} */
		button {
			background-color: rgba(248, 245, 243, 0) !important;
		}
		button[aria-expanded='true'] {
			display: none !important;
		}
		button[aria-expanded='false'] {
			display: none !important;
		}
	}

.ol-attribution {
  button {
    cursor: pointer;
    background-color:#006946;
    border-radius:50%;
    color: #fff;

    :hover,:focus {
    background-color:#006946;
    border-radius:50%;
    color: #fff;
    }
}
}
 

.ol-scale-line-inner{

  background-color:#fff;
  text-shadow:none;
  color:#69625D;
  font-size:1rem;
 
  
}
.scale-line {
 /*   position: absolute;
   top: 350px; */
 }
 .ol-scale-line {
  /*  position: relative;
   bottom: 0px;
   right: 0px !important; */
   position: static;
   white-space: nowrap;
  width:6rem !important;
  text-transform:uppercase;
  color:#69625D;
  font-size: 13px;
  text-align: center;
  
  /* display: flex;
	justify-content: center; */
	/* align-content: center;
	align-items: center; */
 }


.somai-drag_box{
  border: 2px dashed #291F17
}


/* Drag and drop file */

#form-file-upload {
  height: 11rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2.5px;
  border-radius: .8rem;
  border-style: dashed;
  border-color: #006946;
 // background-color: #f8fafc;
 h2{
    color:#006946;
    font-weight:700;
    font-size:1.19rem;
    letter-spacing:2%;
  }
  p{
    font-weight:400;
    font-size:1.1rem;
    color:#69625D;
  }
 
}
#label-file-upload.input-text-xs {

 h2{
    font-size:1rem;
    letter-spacing:2%;
  }
  p{
    font-size:1rem;

  }
 
}

#label-file-upload.drag-active {
  background-color: #ffffff;
  border-width: 2.5px;
  border-radius: .8rem;
  border-style: dashed;
  border-color: #D44500;
  
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background:none !important;
  margin-top:17px;
    svg{
      width: 40px;
      height:40px
    }
}

.upload-button:hover {
  text-decoration-line: underline;
  background:none;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}



input:checked {
  accent-color: #006946;
}

/* Loading */

.loading-overlay {
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.somai-loading{
  width: 20px;
    height: 20px;
    color: rgb(212, 69, 0);
    animation: somai-spin 1.4s linear infinite;
  -webkit-animation: somai-spin 1.4s linear infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}
.somai-multi-linear-progress-loading{
  height: 4px;
  position: relative;
  z-index: 0;
  display: block;
  overflow:hidden;
  background-color: rgb(238, 184, 158);
}
.somai-multi-linear-progress-bar{
  width: 100%;
  position: absolute;
  z-index: 0;
  display: block;
  bottom:0;
  left: 0;
  top: 0;
  transition:transform .4s linear;
  transform-origin:left;
  background-color: #D44500;
}

.somai-loading-simple {
  width: 20px;
    height: 20px;
    color: rgb(212, 69, 0);
    animation: somai-spin 1.4s linear infinite;
  -webkit-animation: somai-spin 1.4s linear infinite;

}

.somai-loading,.somai-loading-simple svg{
  display: block;
  color: rgb(212, 69, 0);
}
.somai-loading-circle {
    stroke: currentColor;
    stroke-dasharray: 80px,200px;
    stroke-dashoffset: 0;
    animation: loading-circle 1.4s ease-in-out infinite;
    -webkit-animation: loading-circle 1s ease-in-out infinite;
}

.loading-simple {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(212, 69, 0, 0.2);
  border-radius: 50%;
  border-top-color: ${color.interface.orange};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

}

.loading {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid rgba(212, 69, 0, 0.2);
  border-radius: 50%;
  border-top-color: ${color.interface.orange};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}


@keyframes somai-spin {
  0%   { 
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-circle {
  0%   { 
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }
  50% { 
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }
  100% { 
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}


@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

`;
