import { api } from 'services/api';
import { reportsForAdminType } from 'store/typesActions/types';
import { headers } from 'helpers/utils';

export const getAlerts = () => async (dispatch) => {
	dispatch({ type: reportsForAdminType.ALERTS_READ_REQUEST });
	try {
		const res = await api.get('/alerts/alertsReport', { headers: headers() });
		const { data } = res || {};
		dispatch({
			type: reportsForAdminType.ALERTS_READ_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: reportsForAdminType.ALERTS_READ_FAIL,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: reportsForAdminType.ALERTS_READ_FAIL,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const getTraditionalUse = () => async (dispatch) => {
	dispatch({ type: reportsForAdminType.TRADITIONAL_USE_READ_REQUEST });
	try {
		const res = await api.get('/traditionalUseByReport', {
			headers: headers()
		});
		const { data } = res || {};
		dispatch({
			type: reportsForAdminType.TRADITIONAL_USE_READ_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: reportsForAdminType.TRADITIONAL_USE_READ_FAIL,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: reportsForAdminType.TRADITIONAL_USE_READ_FAIL,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const closeMessageAndError = () => {
	return { type: reportsForAdminType.ERROR_READ_CLOSE };
};
