import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Load from 'components/Load/Load';
import ItemLayerByList from './ItemLayerByList';
import * as S from './styled';

// useToggle é para decidir se o comp de carregar layer vai com toggle ou checkbox
// A DIFERENÇA DESSE COMPONENTE PARA O LAYERSGROUP É QUE AQUI, A LISTA É PASSADA COMO PROPS E NO OUTRO A LISTA É PEGA PELO REDUCER
// ISSO ACONTECEU QUANDO A MARTA PEDIU PRA NÃO FICAREM TODAS CAMADAS JUNTAS, AI ACABOU QUE TIVEMOS QUE FAZER UMA NOVA LÓGICA E ACABOU QUE DEPOIS
// VOLTOU A SER COMO ERA ANTES, POR ISSO DEIXAMOS 2 OPÇÕES, CASO MUDE NOVAMENTE

export default function LayersGroupByList({
	layers = [],
	useToggle = true,
	showTitle = true,
	textTitle = 'VER NO MAPA'
}) {
	const {
		isReq,
		isErrorReq,
		message: messageErro
	} = useSelector((state) => state.allLayersMap);

	function returnHTML() {
		if (isErrorReq) {
			return <S.erro>{messageErro}</S.erro>;
		}

		return (
			<S.containerGeral>
				{showTitle && (
					<S.containerAccordionText>
						<h3>{textTitle}</h3>
					</S.containerAccordionText>
				)}

				{layers &&
					layers.length > 0 &&
					layers.map((item) => {
						const [isActiveted, setIsActiveted] = useState(false);
						return (
							<ItemLayerByList
								key={item.name_mapfile}
								item={item}
								isActiveted={isActiveted}
								setIsActiveted={setIsActiveted}
								useToggle={useToggle}
							/>
						);
					})}
			</S.containerGeral>
		);
	}

	return (
		<S.containerCamadas>
			<div>{isReq ? <Load /> : returnHTML()}</div>
		</S.containerCamadas>
	);
}
