/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useCallback, useRef } from 'react';

import styled from 'styled-components';
import AttributionMobileInfoContainer from '..';
import ScaleContainer from '../../../Scale';
import CoordinateContainer from '../../../Coordinate';

const Background = styled.div`
	position: absolute;
	width: 100%;
	@media (max-width: 630px) {
		bottom: -8.7rem;
	}
	bottom: -7.7rem;

	left: 0;
	/* height: 56px; */
	display: ${(props) => (props.showModal ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	z-index: 9999;
	margin: 0 1rem;
	/* @media (max-width: 800px) {
		display: none;
	} */
`;

const ModalWrapper = styled.div`
	/* width: 100%; */
	/* @media (max-width: 630px) {
		width: 100%;
	} */
	/* width: auto; */
	/* height: 100%; */
	display: flex;
	align-items: center;
	align-content: center;
	//box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background-color: #f8f5f3;
	color: #000;
	z-index: 10;
	border-radius: 10px;
	padding: 1rem;
`;
const WWrapper = styled.div`
	display: flex;
	/* align-items: center; */
	align-content: center;
	@media (max-width: 630px) {
		flex-direction: column;
	}
`;

const ScaleCoordinateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: center;
	border-left: 1px solid #d9d5d2;
	border-right: 1px solid #d9d5d2;
	margin: 0 1rem;
	@media (max-width: 630px) {
		margin: 0;
	}
	padding: 0 0.7rem;
	.somai__coordinate-center-container {
		border-bottom: 1px solid #d9d5d2;
	}
	.somai-scale-line__container {
		width: 50%;
		margin: 0 !important;

		font-size: 13px;
		font-weight: 400;

		&:after {
			content: '';
			position: absolute;
			left: 0px;
			bottom: 5px;
			height: 20%;
			border-left: 1px solid #69625d;
		}
		&:after {
			content: '';
			position: absolute;
			right: 0px;
			bottom: 5px;
			height: 20%;
			border-right: 1px solid #69625d;
		}
		&:after {
			content: '';
			position: absolute;
			/* right: 5rem;
			left: 2rem; */
			border-bottom: 1px solid #69625d;
		}
	}
	.ol-scale-line {
		margin: 0 !important;
	}
`;

const CloseBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: #e6dcd3;
	margin-left: 0.7rem;
	width: 48px;
	height: 48px;
	cursor: pointer;
	&:hover {
		height: 100%;
	}
	svg {
		width: 18px;
		height: 18px;
	}
`;

function InfoModal({ showModal, setShowModal, mapSomai }) {
	const closeModal = (e) => {
		setShowModal(false);
	};

	return (
		<Background showModal={showModal}>
			<ModalWrapper>
				<WWrapper>
					<AttributionMobileInfoContainer />
					<ScaleCoordinateWrapper>
						<CoordinateContainer mapSomai={mapSomai} />
						<ScaleContainer />
					</ScaleCoordinateWrapper>
				</WWrapper>
				<CloseBtn onClick={closeModal}>
					<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.7102 0.289751C13.5236 0.104167 13.271 0 13.0078 0C12.7445 0 12.492 0.104167 12.3053 0.289751L7.02927 5.5608L1.75324 0.289751C1.56262 0.126511 1.31743 0.0412114 1.06666 0.0508977C0.815882 0.060584 0.577997 0.164543 0.400541 0.342C0.223084 0.519456 0.119125 0.757341 0.109439 1.00812C0.0997524 1.25889 0.185052 1.50408 0.348292 1.6947L5.61934 6.97073L0.348292 12.2468C0.243985 12.3361 0.159269 12.446 0.099461 12.5696C0.0396527 12.6932 0.00604295 12.8279 0.00074251 12.9651C-0.00455793 13.1024 0.0185649 13.2392 0.0686593 13.3671C0.118754 13.4949 0.194739 13.6111 0.291844 13.7082C0.38895 13.8053 0.50508 13.8812 0.632945 13.9313C0.760811 13.9814 0.89765 14.0046 1.03488 13.9993C1.1721 13.994 1.30675 13.9603 1.43037 13.9005C1.55399 13.8407 1.66391 13.756 1.75324 13.6517L7.02927 8.38066L12.3053 13.6517C12.4959 13.8149 12.7411 13.9002 12.9919 13.8906C13.2427 13.8809 13.4805 13.7769 13.658 13.5995C13.8355 13.422 13.9394 13.1841 13.9491 12.9333C13.9588 12.6826 13.8735 12.4374 13.7102 12.2468L8.4392 6.97073L13.7102 1.6947C13.8958 1.50801 14 1.25546 14 0.992225C14 0.728986 13.8958 0.476442 13.7102 0.289751Z"
							fill="#69625D"
						/>
					</svg>
				</CloseBtn>
			</ModalWrapper>
		</Background>
	);
}

export default InfoModal;
