import { threatsType } from 'store/typesActions/types';

const initialState = {
	checkRadios: {}, // para controlar os inputs do tipo radios e camadas que são tisJson = true
	deforestation: [],
	burned: [],
	isLoading: false,
	error: false,
	message: ''
};

export default function threatsReducer(state = initialState, action) {
	switch (action.type) {
		// qual ameaça foi selecionada
		case threatsType.TIS_THREAT_SELECTED:
			return {
				...state,
				checkRadios: action.checkRadios
			};

		// dados de uso e cobertura
		case threatsType.TIS_USE_AND_COVERAGE_REQUEST:
			return {
				...state,
				isLoading: true,
				error: false,
				message: 'Buscando dados de uso e cobertura'
			};

		case threatsType.TIS_USE_AND_COVERAGE_SUCCESS:
			return {
				...state,
				deforestation: action.payload,
				isLoading: false,
				error: false,
				message: ''
			};

		// dados de área queimada
		case threatsType.TIS_BURNED_REQUEST:
			return {
				...state,
				isLoading: true,
				error: false,
				message: 'Buscando dados de uso e cobertura'
			};

		case threatsType.TIS_BURNED_SUCCESS:
			return {
				...state,
				burned: action.payload,
				isLoading: false,
				error: false,
				message: ''
			};

		case threatsType.TIS_USE_AND_COVERAGE_FAILURE:
		case threatsType.TIS_BURNED_FAILURE:
			const { errors } = action.payload || {};
			const { message } = errors || {};
			return {
				...state,
				isLoading: false,
				error: true,
				message: message
			};
		default:
			return state;
	}
}
