import styled from 'styled-components';
import { color } from '../../styles/Theme';

export const container = styled.div`
	cursor: pointer;
`;

export const line = styled.div`
	width: 100%;
	min-height: 1px;
	max-height: 1px;
	background-color: ${color.interface.lightGray2};
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
`;

export const boxInformation = styled.div`
	width: 300px;
	max-height: 300px;
	background-color: ${color.interface.whiteAbsolute};
	font-size: 0.9rem;
	font-weight: 400;
	padding: 1rem;
`;

export const title = styled.b`
	color: ${color.interface.orange};
`;

export const content = styled.p`
	text-align: left;
`;

export const font = styled.b`
	padding-bottom: 1rem;

	a {
		color: ${color.interface.orange};
	}
`;
