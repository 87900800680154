import React from 'react';
import Feature from './Feature';
import IrregularPolygon from './IrregularPolygon';
import RegularPolygon from './RegularPolygon';

function Draw() {
	return (
		<>
			<RegularPolygon />
			<IrregularPolygon />
			<Feature />
		</>
	);
}

export default Draw;
