import styled from 'styled-components';

export const container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 0.5rem;
	/* background-color: aliceblue; */
`;

export const roundContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	/* background-color: bisque; */
`;

export const ballContainer = styled.div`
	display: flex;
	margin-right: 1.5rem;
	align-items: center;
	gap: 0.5rem;
	text-transform: uppercase;
	p {
		font-size: 0.7rem;
	}
`;

export const ball = styled.div`
	max-width: 0.7rem;
	min-width: 0.7rem;
	max-height: 0.7rem;
	min-height: 0.7rem;
	border-radius: 50%;
	background-color: ${(props) => props.color};
`;
