import React from 'react';
import DataPersonal from './DataPersonal';
import Filters from './Filters';

function MonitoringACI() {
	return (
		<>
			<Filters />
			<DataPersonal />
		</>
	);
}

export default MonitoringACI;
