import React, { useEffect, useState } from 'react';
import { svg } from 'lib/svgList';
import { color } from 'styles/Theme';
import { useSelector } from 'react-redux';
import MenuList from './MenuList';
import * as S from './styled';

// isIntitiOpen = se vc quiser que a modal começe aberta, é só passar essa props como true

export default function NavAccordion({
	title = 'Título',
	imgTitle = '',
	styleTitle = '',
	styleLine = {
		backgroundColor: color.interface.orange,
		fontWeight: '800'
	},
	children,
	onClick = () => {},
	isInitiOpen = false,
	widthIconTitleAccordion = 1,
	dataRouteMenu = []
}) {
	const [open, setOpen] = useState(false);
	const [isItemSelected, setIsItemSelected] = useState('');
	const { clickWithoutButton } = useSelector((state) => state.map);

	useEffect(() => {
		if (isInitiOpen) {
			setOpen(true);
		}
	}, []);

	// Para fechar todos os alertas ao clicar no botão de fechar todas as camadas ativas

	useEffect(() => {
		if (clickWithoutButton) {
			setIsItemSelected('');
		}
	}, [clickWithoutButton]);

	return (
		<S.accordionContainer
			onClick={() => {
				if (!open) {
					onClick();
				}
			}}
		>
			<S.BoxTitle>
				<S.TitleAndImg>
					{imgTitle !== '' ? (
						<S.conteinerImage>
							<S.imgAccordion
								widthIcon={widthIconTitleAccordion}
								src={imgTitle}
								alt="ilustração da opção"
							/>
						</S.conteinerImage>
					) : null}
					<p style={styleTitle !== '' ? styleTitle : null}>{title} </p>
				</S.TitleAndImg>

				{isItemSelected && (
					<S.BackButton>
						<button
							type="button"
							onClick={() => {
								setIsItemSelected('');
							}}
						>
							<S.imageBtnToggleOpenClose
								src={svg.icon.rollScreemOrange}
								alt="Voltar"
								style={{ transform: 'rotate(90deg)', marginRight: 5 }}
							/>
							MENU INICIAL
						</button>
					</S.BackButton>
				)}

				<S.imageBtnToggleOpenClose
					src={open ? svg.icon.zoomOutOrange : svg.icon.zoomInOrange}
					alt="abre e fecha opção"
					style={{ width: 30, height: 25 }}
					onClick={() => setOpen(!open)}
				/>
			</S.BoxTitle>
			<S.line style={styleLine !== '' ? styleLine : null} />

			<S.contentAccordion
				style={open ? { display: 'flex' } : { display: 'none' }}
			>
				{dataRouteMenu?.length > 0 ? (
					<S.containerList>
						{dataRouteMenu.map((item) => (
							<MenuList
								key={item.id}
								theme="dark"
								title={item.title}
								subTitle={item.subTitle}
								content={item.content}
								size={item.size}
								isItemSelected={isItemSelected}
								setIsItemSelected={setIsItemSelected}
							/>
						))}
					</S.containerList>
				) : (
					children || ''
				)}
			</S.contentAccordion>
		</S.accordionContainer>
	);
}
