import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { svg } from 'lib/svgList';
import { filterLetterUsers } from 'store/actions/webGisActions';
import { color } from 'styles';
import * as S from './styled';

function CarouselLetters({ letters = [] }) {
	const dispatch = useDispatch();
	const { filterLetterUser } = useSelector((state) => state.webGis);
	const [array, setArray] = useState([]);
	const carousel = useRef(null);

	function handleRightClick() {
		carousel.current.scrollLeft -= carousel.current.offsetWidth + 1;
	}

	function handleLeftClick() {
		carousel.current.scrollLeft += carousel.current.offsetWidth + 1;
	}

	useEffect(() => {
		setArray(letters);
	}, [letters]);

	const handleClick = (item) => {
		if (filterLetterUser === item) {
			dispatch(filterLetterUsers(''));
		} else {
			dispatch(filterLetterUsers(item));
		}
	};

	return (
		<S.containerCarousel>
			<S.arrowLeft onClick={() => handleRightClick()}>
				<img alt="teste" src={svg.icon.arrowDark} width={60} height={60} />
			</S.arrowLeft>

			<S.containerItemCarousel ref={carousel}>
				{array.length > 0 ? (
					array.map((item) => {
						let styleColor = {};

						if (item.isExist) {
							if (filterLetterUser === item.letter) {
								styleColor = { color: color.interface.orange };
							}
						} else {
							styleColor = { color: color.interface.beige, cursor: 'no-drop' };
						}
						return (
							<S.itemCarousel
								key={Math.random() * 100}
								onClick={() => (item.isExist ? handleClick(item.letter) : {})}
							>
								<p style={styleColor}>{item.letter}</p>
							</S.itemCarousel>
						);
					})
				) : (
					<S.itemCarousel key={Math.random() * 100}>
						<p className="textOne">Não há resultados.</p>
					</S.itemCarousel>
				)}
			</S.containerItemCarousel>

			<S.arrowRight onClick={() => handleLeftClick()}>
				<img alt="teste" src={svg.icon.arrowDark} width={60} height={60} />
			</S.arrowRight>
		</S.containerCarousel>
	);
}

export default CarouselLetters;
