import styled from 'styled-components';

export const containerPack = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	overflow: hidden;
`;

export const pEffect = styled.p`
	@keyframes flashing {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
	font-weight: 400;
	animation: linear;
	animation-name: flashing;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
`;

export const textP = styled.p`
	font-weight: 400;
`;
