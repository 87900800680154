import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { color } from 'styles/Theme';
import ColLayout from 'components/Grid/Col';
import { useSelector } from 'react-redux';

function LegendModel({ children, layerOnMap, expandedLegend }) {
	const { statusLegend } = useSelector((state) => state.system);

	const { innerWidth, innerHeight } = window;
	const [width, setWidth] = useState(innerWidth);
	const [height, setHeight] = useState(innerHeight);

	const [newPosition, setNewPosition] = useState(null);

	const heightCal = height * 0.2;

	const hDragLegend = width - 500;

	const vDragLegend = width > 800 ? height - Math.round(heightCal) : 0;

	const position = width < 800 ? 15 : hDragLegend;

	useEffect(() => {
		if (expandedLegend) {
			setWidth(innerWidth);
			setHeight(innerHeight);
			setNewPosition({ x: position, y: width < 800 ? 35 : -4 });
			// Para liberar a nova posição pelo usuario
			setTimeout(() => {
				setNewPosition(null);
			}, 500);
		}

		if (!expandedLegend && width > 800) {
			setNewPosition({ x: Math.floor(width / 1.8), y: height - 50 });
		}
	}, [innerWidth, innerHeight, expandedLegend]);

	return (
		<StyledLegendModel
			style={{
				display: layerOnMap && statusLegend ? 'block' : 'none',
				height: 0
			}}
		>
			<Draggable
				handle="strong"
				defaultPosition={{ x: position, y: width < 800 ? -28 : 0 }}
				position={newPosition}
				grid={[25, 25]}
				scale={1}
				bounds={{
					left: width < 800 ? 20 : 100,
					right: width - 500,
					top: -4,
					bottom: vDragLegend
				}}
			>
				{children}
			</Draggable>
		</StyledLegendModel>
	);
}

const StyledLegendModel = styled.div`
	position: fixed;
	z-index: 1;
	top: 2.2%;
	max-width: 90vw;
	max-height: 90vh;

	@media (max-width: 800px) {
		top: 90px;
	}
`;

const LegendContainer = styled.div`
	position: relative;
	border-radius: ${({ isExpanded }) =>
		isExpanded ? '10px 10px 10px 10px' : '10px 10px 0px 0px'};
	transition: max-width 1s, max-height 0.5s;
	width: auto;
	max-width: ${({ isExpanded }) => (isExpanded ? 400 : 282)}px;
	background-color: ${color.interface.white};
	max-height: ${({ maxHeight, isExpanded }) => (isExpanded ? maxHeight : 62)}px;
	overflow: hidden;
`;

const LegendTitle = styled.strong`
	font-family: 'Nunito';
	cursor: pointer;
	font-style: normal;
	font-weight: 700;
	font-size: 0.875rem;

	letter-spacing: 0.02em;
	color: #f2ede9;
`;

const LegendContent = styled.div`
	max-height: ${({ maxHeight }) => maxHeight - 45}px;
	min-width: 356px;
	overflow: auto;
	color: rgb(68, 79, 99);

	abbr:hover {
		cursor: default;
	}
`;

const LegendLabel = styled.div`
	font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

	max-width: 220px;
	font-style: normal;
	font-weight: 700;
	font-size: 0.75rem;
	line-height: 16px;
	letter-spacing: 0.02em;
`;

const LegendVectorItem = styled.span`
	display: flex;
	align-items: center;
	justify-content: initial;
`;

const LegendVectorSymbol = styled.span`
	display: inline-block;
	vertical-align: middle;
	width: 17px;
	height: 17px;
	margin-right: 5px;
	background: ${({ colorVector }) => colorVector};
	border-width: 1px;
	border-color: ${({ colorVectorBorder }) => colorVectorBorder};
	border-style: solid;
`;

const LegendDeforestation = styled.span`
	display: inline-block;
	vertical-align: middle;
	width: 17px;
	height: 17px;
	margin-right: 5px;
	background: rgb(245, 89, 14);
	background: linear-gradient(
		120deg,
		rgba(254, 189, 0, 1) 0%,
		rgba(245, 89, 14, 1) 90%
	);
	border-width: 1px;
	border-color: rgba(254, 189, 0, 1);
	border-style: solid;
`;

const LegendVectorIcon = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	width: 17px;
	height: 17px;
	margin-right: 6.4px;
	background: ${color.interface.white};

	> img {
		width: 14px;
	}
`;

const LegendVectorName = styled.span`
	font-weight: 400;
	padding-top: 3px;
	font-size: 0.75rem;
	margin-right: 5px;
`;

const ContainerItem = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 0.2rem;
	height: 21px;
`;

const Cell = styled(ColLayout)`
	display: flex;
	align-items: center;
	padding: 0.2rem;
	border-bottom: 1px solid #69625d90;
`;

const CellLegend = styled(ColLayout)`
	padding: 0.3rem;
	border-bottom: ${({ isExpanded }) =>
		isExpanded ? '1px solid #69625d90' : 'none'};
	height: ${({ isExpanded }) => (isExpanded ? 'auto' : '35px')};
`;

const CellDescription = styled(ColLayout)`
	padding: 10px 0px 5px 0px;

	font-family: 'nunito-extra-bold', -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
		sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 0.575rem;
	line-height: 0.875rem;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #69625d;
	background: #f2ede9;
`;

export {
	LegendModel,
	LegendContainer,
	LegendTitle,
	LegendContent,
	LegendVectorItem,
	LegendLabel,
	LegendVectorSymbol,
	LegendVectorName,
	ContainerItem,
	LegendVectorIcon,
	LegendDeforestation,
	Cell,
	CellDescription,
	CellLegend
};
