import styled from 'styled-components';
import { color } from 'styles/Theme';
// import { svg } from 'lib/svgList';

export const ContainerSearch = styled.div`
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;

	input {
		padding: 0.8rem 3rem 0.8rem 0.8rem;
		background-image: url(${(props) => props.imgSearch});
		background-repeat: no-repeat;
		background-position: calc(100% - 20px);
		background-size: ${(props) => (props.showMagnifying ? '1rem' : '0.6rem')};
		outline: 0;
		border: 1px solid ${color.interface.lightGray};
		border-radius: 30px;
		font-weight: 400;
		width: 100%;
	}
	input::placeholder {
		font-weight: 400;
	}
	button {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		align-items: center;
		justify-content: space-between;
		background-color: ${color.interface.white};
		cursor: pointer;
		transition: 0.5 all ease;
		border-radius: 7px;
		border: 1px solid ${color.interface.lightGray};
	}
	img {
		width: 15px;
		height: 15px;
	}
	img:active {
		transform: scale(0.9);
	}
`;
export const ListTi = styled.div`
	@keyframes show {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	font-size: 1rem;
	width: 100%;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	top: 115%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border: 1px solid ${color.interface.lightGray};
	border-radius: 10px;
	background-color: ${color.interface.whiteAbsolute};
	z-index: 10;
	font-weight: 400;

	animation-name: show;
	animation-duration: 1s;

	::-webkit-scrollbar-track {
		background: ${color.interface.white}; /* color of the tracking area */
	}

	::-webkit-scrollbar-thumb {
		background-color: ${color.interface.gray}99; /* color of the scroll thumb */
		border: 1px solid ${color.interface.gray}99; /* creates padding around scroll thumb */
	}
`;

export const itemList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #d9d5d2;

	> button {
		border-radius: 0;
		border: none;
		display: flex;
		align-items: flex-start;
		width: 100%;
		padding: 8px 0px 8px 0px;
		text-align: left;
		font-size: 0.875rem;
		background-color: transparent;
		font-weight: 500;
		cursor: pointer;
		transition: all 0.5s ease;
		padding-left: 15px;
		color: ${color.system.font};

		:hover {
			background-color: ${color.interface.orange};
			color: ${color.interface.white};
		}
	}
`;
