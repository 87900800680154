import styled from 'styled-components';
// import {color} from 'styles/Theme'

export const containerPanel = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 1rem;
	overflow: auto !important;
`;
