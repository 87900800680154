import styled from 'styled-components';

export const Container = styled.div`
	//display: none;
	display: flex;
	z-index: 1;
	align-items: center;
	svg {
		margin-right: 0.5rem;
		width: 20px;
		height: 20px;
	}
`;
