import DonutGraph from 'components/Graphs/DonutGraph/DonutGraph';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getDeforestation,
	deleteDeforestation
} from 'store/actions/deforestationThreatBufferAction';
import Loading from './Loading';
import * as S from './styled';

// caso queira usar o dado na frente da legenda
// desmate = desmate.toLocaleString('pt-br', {
// 	maximumFractionDigits: 2
// });

export default function DeforestationPercentageDonoutGraph() {
	const dispatch = useDispatch();
	const { tiSelected } = useSelector((state) => state.tiSelectedTabTi);
	const { data, isLoading, error } = useSelector(
		(state) => state.deforestationThreat
	);
	const { desmate, conservada, bufferDesmate, bufferConservada } = data;

	function returnHtml() {
		if ('noTI' in data) {
			return <S.notice>Erro, tente novamente mais tarde</S.notice>;
		}

		if (!error && !isLoading) {
			return (
				<>
					<S.graphContainer>
						<b>Desmatamento dentro da TI</b>

						<S.containerlegend>
							<S.legend>
								<S.legendGreen />
								<p>CONSERVADO</p>
							</S.legend>

							<S.legend>
								<S.legendOrange />
								<p>DESMATADA</p>
							</S.legend>
						</S.containerlegend>
						<S.graph>
							<DonutGraph
								labels={['Conservado', 'Desmatado']}
								dataSets={[conservada || 0, desmate || 0]}
								valueIsPercente
								legendDisplay={false}
							/>
							<p>Fonte: PRODES</p>
						</S.graph>
					</S.graphContainer>

					<S.line />

					<S.graphContainer>
						<b>Desmatado no entorno da TI (até 10 km)</b>

						<S.containerlegend>
							<S.legend>
								<S.legendGreen />
								<p>CONSERVADO</p>
							</S.legend>

							<S.legend>
								<S.legendOrange />
								<p>DESMATADA</p>
							</S.legend>
						</S.containerlegend>

						<S.graph>
							<DonutGraph
								labels={['Conservado', 'Desmatado']}
								dataSets={[bufferConservada || 0, bufferDesmate || 0]}
								valueIsPercente
								legendDisplay={false}
							/>
							<p>Fonte: PRODES</p>
						</S.graph>
					</S.graphContainer>
				</>
			);
		}

		return <S.notice>Erro, tente novamente mais tarde</S.notice>;
	}

	// pega dados sobre a ti selecionada
	useEffect(() => {
		if (tiSelected.value) {
			dispatch(getDeforestation(tiSelected.value));
		}
	}, [tiSelected]);

	// limpa dados quando comp é destruido
	useEffect(
		() => () => {
			dispatch(deleteDeforestation());
		},
		[]
	);

	return <S.container>{isLoading ? <Loading /> : returnHtml()}</S.container>;
}
