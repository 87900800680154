import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerReport = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const btnGenerate = styled.button`
	width: 140px;
	height: 37px;
	margin-top: 16px;
	display: ${(props) => (props.isTiSelected === 0 ? 'none' : 'flex')};
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-weight: 400;
	border-radius: 25px;
	color: ${color.interface.white};
	cursor: pointer;
	pointer-events: fill;
	transition: all 0.3s ease;

	background-color: ${(props) =>
		props.isTiSelected === 0 ? color.interface.beige : color.interface.orange};

	:active {
		transform: scale(0.9);
	}
	img {
		width: 18px;
		height: 18px;
		margin: 5px;
	}
`;

export const containerModalNotice = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	p {
		opacity: 0.8;
	}
`;

export const titleModal = styled.p`
	font-size: 1.1rem;
	opacity: 0.9;
	margin-bottom: 1rem;
	font-weight: 800;
`;

export const notice = styled.p`
	color: ${color.interface.red};
`;

export const noticeFlashing = styled.p`
	@keyframes flashing {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
	color: ${color.interface.red};

	animation: linear;
	animation-name: flashing;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
`;
