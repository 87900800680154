import { mapType } from '../typesActions/types';

const initialState = {
	loadingLayer: false,
	loadingImage: false,
	loadingLayerError: false,
	nameLayer: '',
	activatedLayers: [],
	clickWithoutButton: '',
	loadingInfo: false
};

const mapReducer = (state = initialState, action) => {
	switch (action.type) {
		// LOAD_START
		case mapType.LOAD_LAYER_START:
			return { ...state, loadingLayer: true, loadingLayerError: false };

		// LOAD_END
		case mapType.LOAD_LAYER_END:
			return { ...state, loadingLayer: false };

		// LOAD_ERROR;
		case mapType.LOAD_LAYER_ERROR:
			return {
				...state,
				loadingLayer: false,
				loadingLayerError: true,
				nameLayer: action.payload
			};

		// LOAD_START_IMAGE
		case mapType.LOAD_IMAGE_START:
			return { ...state, loadingImage: true };

		// LOAD_END_IMAGE
		case mapType.LOAD_IMAGE_END:
			return { ...state, loadingImage: false };

		// LOAD_ERROR_IMAGE;
		case mapType.LOAD_IMAGE_ERROR:
			return {
				...state,
				loadingImage: false
			};

		// SET_ACTIVATED_LAYERS
		case mapType.SET_ACTIVATED_LAYERS:
			return { ...state, activatedLayers: action.payload };

		case mapType.LOAD_INFO_START:
			return { ...state, loadingInfo: true };

		case mapType.LOAD_INFO_END:
			return { ...state, loadingInfo: false };

		// LIMPA TODAS AS CAMADAS SELECIONADAS
		case mapType.CLOSE_ACTIVATED_LAYERS:
			return {
				...state,
				loadingLayer: false,
				activatedLayers: [],
				nameLayer: ''
			};

		// LIMPA TODAS AS CAMADAS SELECIONADAS
		case mapType.CLOSE_TOGGLE_ALL_LAYERS:
			// Para mudar o status do useEffect apenas após o primeiro carregamento para manter as camadas ativas que vem do banco
			const random = Math.random();
			return {
				...state,
				activatedLayers: [],
				clickWithoutButton: random,
				nameLayer: ''
			};

		// fecha a mensagem de erro;
		case mapType.ERROR_LAYER_CLOSE:
			return {
				...state,
				loadingLayer: false,
				loadingLayerError: false,
				nameLayer: ''
			};

		default:
			return state;
	}
};

export default mapReducer;
