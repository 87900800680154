import styled from 'styled-components';
import { color } from 'styles/Theme';

export const ContainerList = styled.div`
	padding: 0;
	width: 100%;
`;

export const ContainerSubTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 6px;
	padding-bottom: 6px;

	@media (max-width: 768px) {
		display: flow-root;

		> p {
			@media (max-width: 768px) {
				margin-bottom: 15px;
			}
		}
	}
`;

export const ButtonInfo = styled.button`
	background: transparent;
	display: flex;
	width: 50px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const BoxFilter = styled.div`
	margin-bottom: 20px;
	margin-top: 10px;
`;

export const Item = styled.div`
	border-bottom: 1px solid ${color.interface.beige};
	padding: 7px;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin-bottom: 7px;
	width: 100%;
	display: flex;

	justify-content: space-between;
	align-items: center;

	:hover {
		background-color: ${color.interface.beige};
		border-radius: 2px;
	}
`;

export const Name = styled.p`
	font-size: 0.875rem;
	justify-content: start;
	display: flex;
	text-align: left;
	width: 80%;
	text-overflow: ellipsis;
	padding-left: 10px;
`;
