import { api } from 'services/api';
import { user } from '../typesActions/types';
import { getUserLocalStorage, setUserLocalStorage } from 'services/session';
import { headers } from 'helpers/utils';

// Realiza as ações para o login de usuarios

export const signIn = (params) => async (dispatch) => {
	dispatch({ type: user.USER_AUTHENTICATE_REQUEST });
	try {
		const res = await api.post('/user/authenticate', params);
		const { data } = res || {};
		dispatch({
			type: user.USER_AUTHENTICATE_SUCCESS,
			payload: data
		});
		return data, setUserLocalStorage(data);
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.USER_AUTHENTICATE_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.USER_AUTHENTICATE_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const vefifyUpdate = (idUser) => async (dispatch) => {
	dispatch({ type: user.USERS_VERIFY_UPDATE_REQUEST });
	try {
		const res = await api.get(`/user/verifyUpdate/${idUser}`, {
			headers: headers()
		});
		const { data } = res || {};
		dispatch({
			type: user.USERS_VERIFY_UPDATE_SUCCESS,
			payload: data
		});
		return data, setUserLocalStorage(data);
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.USERS_VERIFY_UPDATE_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.USERS_VERIFY_UPDATE_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

// Realiza as ações para o cadastro de novos usuarios

export const register = (params) => async (dispatch) => {
	dispatch({ type: user.USER_SAVE_REQUEST });
	try {
		const res = await api.post('/user', params);
		const { data } = res || {};
		dispatch({
			type: user.USER_SAVE_SUCCESS,
			payload: data
		});

		// Permite login no cadastro apenas se maior de idade
		const loginData = { email: params.email, password: params.password };
		if (!data.blockLogin) dispatch(signIn(loginData));
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.USER_SAVE_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.USER_SAVE_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const update = (params) => async (dispatch) => {
	// carrega dados antes do update para somar com os novos
	const { token } = getUserLocalStorage();

	dispatch({ type: user.USER_UPDATE_REQUEST });
	try {
		const res = await api.put(`/user/update/plataform`, params, {
			headers: headers()
		});
		const { data } = res || {};
		dispatch({
			type: user.USER_UPDATE_SUCCESS,
			payload: data
		});
		const newUpdate = { user: data.user, token };
		setUserLocalStorage(newUpdate);
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.USER_UPDATE_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.USER_UPDATE_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const updateByADM = (params) => async (dispatch) => {
	dispatch({ type: user.USER_UPDATE_ADM_REQUEST });
	try {
		const res = await api.put(`/user/update/monitoring`, params, {
			headers: headers()
		});
		const { data } = res || {};
		dispatch({
			type: user.USER_UPDATE_ADM_SUCCESS,
			payload: data,
			params
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.USER_UPDATE_ADM_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.USER_UPDATE_ADM_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const getProfiles = () => async (dispatch) => {
	dispatch({ type: user.USER_PROFILES_REQUEST });
	try {
		const res = await api.get('/user/profiles');
		const { data } = res || {};
		dispatch({
			type: user.USER_PROFILES_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.USER_PROFILES_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.USER_PROFILES_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const getRoles = () => async (dispatch) => {
	dispatch({ type: user.ROLES_REQUEST });
	try {
		const res = await api.get('/roles');
		const { data } = res || {};
		dispatch({
			type: user.ROLES_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.ROLES_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.ROLES_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const getUsersByManager = () => async (dispatch, getState) => {
	const { listUsers } = getState().user;

	if (listUsers.length === 0) {
		dispatch({ type: user.USERS_LIST_REQUEST });
		try {
			const res = await api.get(`/allUsersManager`, { headers: headers() });
			const { data } = res || {};
			dispatch({
				type: user.USERS_LIST_SUCCESS,
				payload: data
			});
			return data;
		} catch (error) {
			const { response } = error;
			if (response && response.status === 400) {
				const { message } = response.data;
				dispatch({
					type: user.USERS_LIST_FAILURE,
					payload: { errors: { message } }
				});
			} else {
				dispatch({
					type: user.USERS_LIST_FAILURE,
					payload: {
						errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
					}
				});
			}
		}
	}
};

export const getAllUsersADMPartners = () => async (dispatch) => {
	dispatch({ type: user.USERS_LIST_REQUEST });
	try {
		const res = await api.get(`/allUsersADMPartners`, { headers: headers() });
		const { data } = res || {};

		dispatch({
			type: user.USERS_LIST_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.USERS_LIST_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.USERS_LIST_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const getAllUsersADM = () => async (dispatch) => {
	dispatch({ type: user.USERS_LIST_REQUEST });
	try {
		const res = await api.get(`/allUsersADM`, { headers: headers() });
		const { data } = res || {};

		dispatch({
			type: user.USERS_LIST_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: user.USERS_LIST_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: user.USERS_LIST_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

// Remove a seção do usuário

export const logout = () => {
	return { type: user.USER_LOGOUT };
};

// Remove mensagem do reducer

export const closeErrorUser = () => {
	return { type: user.USER_CLOSE_ERROR };
};
