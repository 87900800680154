import React, { memo } from 'react';

import * as S from '../../LegendAndAdjustments.style';

function LegendVector({ layer }) {
	const label = layer.get('label');
	const styles = layer.getStyle();

	const { icon } = layer.getProperties();

	if (typeof styles === 'object') {
		if (icon !== undefined) {
			return (
				<S.LegendVectorItem>
					<S.LegendVectorIcon>
						<img src={icon} alt={`Legenda ${label}`} />
					</S.LegendVectorIcon>

					<S.LegendVectorName>{label}</S.LegendVectorName>
				</S.LegendVectorItem>
			);
		}

		return Object.keys(styles).map((key) => {
			const fill = styles[key].getFill();

			const stroke = styles[key].getStroke();
			return (
				fill && (
					<S.LegendVectorItem key={key}>
						<S.LegendVectorSymbol
							{...{
								colorVector: fill.getColor(),
								colorVectorBorder: stroke.getColor()
							}}
						/>
						<S.LegendVectorName>{label}</S.LegendVectorName>
					</S.LegendVectorItem>
				)
			);
		});
	}
	return null;
}

export default memo(LegendVector);
