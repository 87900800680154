import React, { useState } from 'react';
import TitleMenu from 'components/TitleMenu';
import TabSection from 'components/Tabs/TabSection/TabSection';
import { actionsUsers } from 'helpers/utils';
import TitleTab from 'components/TitleTab';
import { zoomToExtentLayer } from 'components/Map/ControlMap';
import { getLayerByName } from 'store/actions/mapActions';
import * as S from './styled';

export default function ItensMenu({
	title,
	subTitle = '',
	icon = '',
	content = '',
	theme = '',
	isStyle = {}
}) {
	const [isOpenItem, setIsOpenItem] = useState(false);

	const onOpenItem = () => {
		setIsOpenItem(true);

		actionsUsers({
			category: `plataforma/painel/menu`,
			action: `Menu ${title}`,
			label: subTitle
		});

		setTimeout(async () => {
			const layer = await getLayerByName('monitoring');
			const source = await layer.getSource();
			const extent = source.getExtent();

			if (extent && extent[0] !== Infinity) {
				zoomToExtentLayer(extent);
			}
		}, 100);
	};

	return (
		<>
			<TitleMenu
				title={subTitle}
				icon={icon}
				onClick={() => onOpenItem()}
				theme={theme}
				isStyle={isStyle}
			/>

			{isOpenItem ? (
				<S.Box>
					<TitleTab title={title} />

					<TabSection
						sectionName={subTitle}
						isButtonBack
						isActionButton={() => setIsOpenItem(false)}
						icon={icon}
					/>

					{content}
				</S.Box>
			) : null}
		</>
	);
}
