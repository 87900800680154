import styled from 'styled-components';
import { color } from '../../../styles/Theme';

export const containerBoxItemSelected = styled.div`
	button {
		min-width: 100px;
		display: flex;
		flex-direction: row !important;
		align-items: center;
		justify-content: flex-start !important;
		gap: 1rem;
		padding: 0.8rem 1.5rem;
		border-radius: 10px;
		color: ${color.interface.red};
		font-weight: 200;
		background-color: ${color.interface.white};
		font-weight: 400;
		cursor: pointer;
		pointer-events: fill;
		transition: all 0.3s ease;
		text-transform: uppercase;

		:hover {
			background-color: ${color.interface.lightGray};
			color: ${color.interface.white};
		}
		:active {
			transform: scale(0.9);
		}
		img {
			width: 15px;
			height: 15px;
			z-index: 0;
		}
	}
`;
