/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import TileLayer from 'ol/layer/Tile';
import { OSM, XYZ } from 'ol/source';
import BingMaps from 'ol/source/BingMaps';

/*
 * Street map
 */
const baseOsmMap = new TileLayer({
	source: new OSM(),
	base: true,
	visible: false,
	name: 'baseOsmMap',
	label: 'Open Street Map'
});

/*
 * Satellite Map
 */
const baseArcGis = new TileLayer({
	source: new XYZ({
		attributions:
			'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
			'rest/services/ESRI_Imagery_World_2D/MapServer">ArcGIS</a>',
		url:
			'https://server.arcgisonline.com/ArcGIS/rest/services/' +
			'World_Imagery/MapServer/tile/{z}/{y}/{x}',
		crossOrigin: 'anonymous'
	}),
	base: true,
	visible: false,
	name: 'baseArcGis',
	label: 'Mapa de Satélite Arcgis'
});

/*
 * Label Satellite Map
 */
const baseArcGisLabel = new TileLayer({
	source: new XYZ({
		attributions:
			'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
			'rest/services/ESRI_Imagery_World_2D/MapServer">ArcGIS</a>',
		url:
			'https://server.arcgisonline.com/ArcGIS/rest/services/' +
			'Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}',
		crossOrigin: 'anonymous'
	}),
	base: true,
	visible: false,
	name: 'baseArcGis',
	label: 'Mapa de Satélite Arcgis'
});

export const satelliteBing = new TileLayer({
	source: new BingMaps({
		key: 'AgFCHvy5-TrsTMfo2mfOHzQxp5hpZ8Yab-XcImKlSp0S0cutpnMbPyv9mNr1eTbQ',
		imagerySet: 'AerialWithLabelsOnDemand',
		maxZoom: 19
	}),
	preload: Infinity,
	base: true,
	visible: false,
	name: 'baseSateliteBing',
	label: 'Mapa de Satélite Bing'
});

/*
 * Google Satellite Map
 */
const sateliteGoogleMaps = new TileLayer({
	source: new XYZ({
		attributions:
			'<a href="https://developers.google.com/maps?hl=pt-br" target="_blank">Google Maps ©</a>',
		url: 'http://mt0.google.com/vt/lyrs=p&hl=pt&x={x}&y={y}&z={z}',
		crossOrigin: 'anonymous'
	}),
	base: true,
	visible: true,
	name: 'googleMaps',
	label: 'Google Maps'
});

function BaseMaps({ mapSomai }) {
	useEffect(() => {
		if (mapSomai) {
			mapSomai.addLayer(baseOsmMap);
			mapSomai.addLayer(baseArcGis);
			mapSomai.addLayer(baseArcGisLabel);
			mapSomai.addLayer(satelliteBing);
			mapSomai.addLayer(sateliteGoogleMaps);
		}
	}, [mapSomai]);

	return null;
}
export default BaseMaps;
