import { isTabRef } from '../typesActions/types';

export default function isTabRefReducer(currentTab = 'tab1', action) {
	switch (action.type) {
		case isTabRef.SELECTED_TAB:
			return (currentTab = action.payload);
		default:
			return currentTab;
	}
}
