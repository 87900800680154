/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import SwitchToggle from 'components/Buttons/SwitchToggleGeneral/toggleName';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Background = styled.div`
	position: absolute;
	width: 230px;
	z-index: 1;
	top: 0;
	right: 3rem;
	@media (max-width: 800px) {
		top: 3.5rem;
		right: 7rem;
		max-width: 200px;
	}
`;

const ModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #fff;
	color: #000;
	border-radius: 10px;
	padding: 1rem;
`;

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	//justify-content: center;
	//align-items: center;
`;

const ModalContentTitle = styled.h1`
	font-size: 13px;
	font-weight: 800;
	color: #69625d;
	text-transform: uppercase;
	padding-top: 7px;
	padding-bottom: 5px;
	border-bottom: 1px solid;
	border-color: #d9d5d2;
	margin-bottom: 8px;
`;
const SwitchToggleContainer = styled.div`
	display: flex;
	gap: 0.7rem;
	align-items: center;
	height: 29px;

	p {
		font-size: 15px;
		color: #69625d;
		font-weight: 400;
	}
	& + div {
		border-top: 1px solid;
		border-color: #d9d5d2;
	}
`;

function ListModal({ showModal, setShowModal, toggleBaseLayer, checked }) {
	const modalRef = useRef();
	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModal(false);
		}
	};

	const keyPress = useCallback(
		(e) => {
			if (e.key === 'Escape' && showModal) {
				setShowModal(false);
			}
		},
		[setShowModal, showModal]
	);

	useEffect(() => {
		document.addEventListener('keydown', keyPress);
		return () => document.removeEventListener('keydown', keyPress);
	}, [keyPress]);

	return (
		<>
			{showModal ? (
				<Background onClick={closeModal} ref={modalRef}>
					<ModalWrapper showModal={showModal}>
						<ModalContent>
							<ModalContentTitle>Mapa de fundo</ModalContentTitle>
							<SwitchToggleContainer>
								<SwitchToggle
									isChecked={checked.googleMaps}
									setIsChecked={(ev) => toggleBaseLayer(ev)}
									getTarget
									name="googleMaps"
								/>
								<p>Google Maps </p>
							</SwitchToggleContainer>
							{/* <SwitchToggleContainer>
								<SwitchToggle
									isChecked={checked.baseArcGis}
									setIsChecked={(ev) => toggleBaseLayer(ev)}
									getTarget
									name="baseArcGis"
								/>
								<p>ArcGis</p>
							</SwitchToggleContainer> */}
							<SwitchToggleContainer>
								<SwitchToggle
									isChecked={checked.baseSateliteBing}
									setIsChecked={(ev) => toggleBaseLayer(ev)}
									getTarget
									name="baseSateliteBing"
								/>
								<p>Satélite</p>
							</SwitchToggleContainer>
							{/* <SwitchToggleContainer>
								<SwitchToggle
									isChecked={checked.baseOsmMap}
									setIsChecked={(ev) => toggleBaseLayer(ev)}
									getTarget
									name="baseOsmMap"
								/>
								<p>Open Street Map </p>
							</SwitchToggleContainer> */}
						</ModalContent>
					</ModalWrapper>
				</Background>
			) : null}
		</>
	);
}

export default ListModal;
