/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Load from 'components/Load/Load';
import PropTypes from 'prop-types';
import * as S from './styled';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

// MODELO DATASETS
// const dataBar = [
// 	{
// 		label: 'Queimadas dentro da TI',
// 		data: [2, 10, 3, 50],
// 		backgroundColor: '#D44500'
// 	},
// 	{
// 		label: 'Queimadas fora da TI',
// 		data: [20, 30, 41, 2],
// 		backgroundColor: '#21543D'
// 	}
// ];

// MODELO LABELS
// const labels = ['January', 'February', 'March'];

export default function BarGraph({
	title = 'IPAM',
	labelsProps = [],
	datasetsProps = [],
	isLoading = false,
	isMinWidth = 0,
	useLegend = true,
	useLegendRound = false
}) {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: useLegend,
				position: 'top'
			},
			title: {
				display: false,
				text: title
			}
		}
	};

	const data = {
		labels: [...labelsProps],
		datasets: [...datasetsProps]
	};

	function returnBarGraph() {
		if (isLoading) {
			return <Load />;
		}
		return datasetsProps.length !== 0 ? (
			<Bar options={options} data={data} />
		) : (
			<p className="notes">Não há dados para gerar gráfico</p>
		);
	}

	function returnLegend(array) {
		return array.map((item) => (
			<S.ballContainer key={Math.random() * 10000}>
				<S.ball color={item.backgroundColor} />
				<p>{item.label}</p>
			</S.ballContainer>
		));
	}

	return (
		<S.container style={{ minWidth: isMinWidth }}>
			{useLegendRound && (
				<S.roundContainer>{returnLegend(datasetsProps)}</S.roundContainer>
			)}

			{returnBarGraph()}
		</S.container>
	);
}
/**
 * Cor de fundo do load.
 */
BarGraph.propTypes = {
	/**
	 * Título do gráfico.
	 */
	title: PropTypes.string,
	/**
	 * Títulos dos dados que ficam na parte inferior do gráfico
	 */
	labelsProps: PropTypes.arrayOf(PropTypes.string),
	/**
	 * Array de objetos, onde o objeto precisa ter: label: string, data: array, backgroundColor: string.
	 */
	datasetsProps: PropTypes.arrayOf(PropTypes.object),
	/**
	 * Estado para sabe se os dados estão chegando ou já chegaram
	 */
	isLoading: PropTypes.bool,
	/**
	 * Não lembro kk.
	 */
	isMinWidth: PropTypes.number,
	/**
	 * Se vai usar a legenda defaut do gráfico.
	 */
	useLegend: PropTypes.bool,
	/**
	 * Se vai usar a legenda personalizada do gráfico.
	 */
	useLegendRound: PropTypes.bool
};

BarGraph.defaultProps = {
	title: 'IPAM',
	labelsProps: ['January', 'February', 'March'],
	datasetsProps: [
		{
			label: 'Queimadas dentro da TI',
			data: [2, 10, 3, 50],
			backgroundColor: '#D44500'
		},
		{
			label: 'Queimadas fora da TI',
			data: [20, 30, 41, 2],
			backgroundColor: '#21543D'
		}
	],
	isLoading: false,
	isMinWidth: 0,
	useLegend: true,
	useLegendRound: false
};
