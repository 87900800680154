/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/button-has-type */
import React from 'react';
import styled from 'styled-components';
import { actionsUsers } from 'helpers/utils';
import { getLayerByName } from 'store/actions/mapActions';
import { AlertDialog } from 'components/AlertDialog';
import { CgClose } from 'react-icons/cg';
import * as S from '../ControlButton/styles';

const ModalWrapper = styled.div`
	position: relative;
`;
const CloseModalButton = styled(CgClose)`
	cursor: pointer;
	position: absolute;
	top: -10px;
	right: -18px;
	width: 38px;
	height: 38px;
	padding: 6px;
	z-index: 10;
	background-color: #d44500;
	border-radius: 50%;
	color: #fff;
	@media (max-width: 450px) {
		& {
			right: -5px;
			width: 30px;
			height: 30px;
		}
	}
`;

function DeleteAllShapes() {
	const handleGA = () => {
		actionsUsers({
			category: 'lataforma/mapa/webgis',
			action: 'Click no botão',
			label: 'Excluir todos os desenhos'
		});
	};

	function deleteFeatures(layer) {
		if (layer) {
			const source = layer.getSource();

			const features = source.getFeatures();
			features.forEach((feature) => {
				const featureId = feature.get('id');
				const tooltipcontainer = document.getElementById(featureId);
				if (tooltipcontainer) {
					tooltipcontainer.remove();
				}
			});
			source.clear();
			// encaminha a ação para o google GA
			handleGA();
		}
	}

	const deleteAllFeatures = () => {
		// limpa apenas os itens do checked e toggle
		const layershapes = getLayerByName('shapes_draw');
		const layermeasure = getLayerByName('measure_draw');
		deleteFeatures(layershapes);
		deleteFeatures(layermeasure);
		// encaminha a ação para o google GA
		handleGA();
	};

	const handleClick2 = () => {
		AlertDialog({
			title: 'Todos os desenhos no mapa',
			message: 'Você tem certeza de fazer isso ?',
			buttons: [
				{
					label: 'Não Limpar desenho'
				},
				{
					label: 'Sim Limpar desenhos',
					onClick: () => deleteAllFeatures()
				}
			]
		});
		actionsUsers({
			category: 'plataforma/mapa/webgis',
			action: 'Click no botão',
			label: 'Excluir todos os desenhos'
		});
	};
	const handleClick = () => {
		AlertDialog({
			customUI: ({ onClose }) => (
				<ModalWrapper>
					<div className="somai-confirm-alert-body">
						<h1>Todos os desenhos no mapa</h1>
						<p>Você tem certeza de fazer isso ?</p>
						<div className="somai-confirm-alert-button-group">
							<button onClick={onClose}>Não Limpar desenhos</button>
							<button
								onClick={() => {
									deleteAllFeatures();
									onClose();
								}}
							>
								Sim Limpar desenhos
							</button>
						</div>
					</div>
					<CloseModalButton aria-label="Close modal" onClick={onClose} />
				</ModalWrapper>
			)
		});
		actionsUsers({
			category: 'plataforma/mapa/webgis',
			action: 'Click no botão',
			label: 'Excluir todos os desenhos'
		});
	};

	return (
		<S.Container>
			<button
				type="button"
				className="sm__tooltip"
				data-title="Limpar Todos os desenhos no mapa"
				onClick={handleClick2}
			>
				<svg
					width="22"
					height="22"
					viewBox="0 0 32 32"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M28 7H21V5C20.9474 4.22169 20.6144 3.48884 20.0628 2.93723C19.5112 2.38562 18.7783 2.05265 18 2H14C13.2217 2.05265 12.4888 2.38562 11.9372 2.93723C11.3856 3.48884 11.0526 4.22169 11 5V7H4C3.73478 7 3.48043 7.10536 3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8C3 8.26522 3.10536 8.51957 3.29289 8.70711C3.48043 8.89464 3.73478 9 4 9H5.125L7.89 29.27C7.98542 29.7588 8.24836 30.1991 8.63352 30.5149C9.01868 30.8307 9.50193 31.0022 10 31H22C22.4981 31.0022 22.9813 30.8307 23.3665 30.5149C23.7516 30.1991 24.0146 29.7588 24.11 29.27L26.875 9H28C28.2652 9 28.5196 8.89464 28.7071 8.70711C28.8946 8.51957 29 8.26522 29 8C29 7.73478 28.8946 7.48043 28.7071 7.29289C28.5196 7.10536 28.2652 7 28 7ZM13 5C13.0524 4.75458 13.1747 4.52957 13.3521 4.35212C13.5296 4.17468 13.7546 4.05238 14 4H18C18.2454 4.05238 18.4704 4.17468 18.6479 4.35212C18.8253 4.52957 18.9476 4.75458 19 5V7H13V5ZM22.135 28.945C22.095 28.9738 22.0487 28.9927 22 29H10C9.95127 28.9927 9.90499 28.9738 9.865 28.945L7.145 9H24.855L22.135 28.945Z"
						fill="black"
					/>
					<path
						d="M16 11C15.7348 11 15.4804 11.1054 15.2929 11.2929C15.1054 11.4804 15 11.7348 15 12V25C15 25.2652 15.1054 25.5196 15.2929 25.7071C15.4804 25.8946 15.7348 26 16 26C16.2652 26 16.5196 25.8946 16.7071 25.7071C16.8946 25.5196 17 25.2652 17 25V12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1054 16.2652 11 16 11Z"
						fill="black"
					/>
					<path
						d="M19.5 11.9249L18.5 24.9249C18.4898 25.0563 18.5056 25.1884 18.5467 25.3137C18.5877 25.4389 18.653 25.5548 18.739 25.6547C18.825 25.7546 18.9298 25.8365 19.0476 25.8958C19.1653 25.955 19.2936 25.9904 19.425 25.9999H19.5C19.7527 26.0006 19.9964 25.9056 20.1819 25.734C20.3674 25.5624 20.4811 25.3269 20.5 25.0749L21.5 12.0749C21.5199 11.8097 21.4336 11.5474 21.2602 11.3458C21.0867 11.1442 20.8402 11.0198 20.575 10.9999C20.3098 10.98 20.0476 11.0663 19.846 11.2397C19.6443 11.4132 19.5199 11.6597 19.5 11.9249V11.9249Z"
						fill="black"
					/>
					<path
						d="M11.425 11.0001C11.2936 11.0096 11.1653 11.045 11.0476 11.1042C10.9298 11.1634 10.825 11.2453 10.739 11.3452C10.653 11.4452 10.5877 11.5611 10.5467 11.6863C10.5056 11.8116 10.4898 11.9437 10.5 12.0751L11.5 25.0751C11.519 25.3271 11.6326 25.5626 11.8182 25.7342C12.0037 25.9058 12.2473 26.0008 12.5 26.0001H12.575C12.7065 25.9906 12.8348 25.9552 12.9525 25.896C13.0702 25.8367 13.1751 25.7548 13.261 25.6549C13.347 25.555 13.4124 25.4391 13.4534 25.3139C13.4944 25.1886 13.5103 25.0565 13.5 24.9251L12.5 11.9251C12.4905 11.7936 12.4552 11.6654 12.3959 11.5476C12.3367 11.4299 12.2548 11.325 12.1549 11.2391C12.055 11.1531 11.939 11.0877 11.8138 11.0467C11.6885 11.0057 11.5564 10.9899 11.425 11.0001Z"
						fill="black"
					/>
				</svg>
			</button>
		</S.Container>
	);
}

export default DeleteAllShapes;
