/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { actionsUsers } from 'helpers/utils';
import { svg } from 'lib/svgList';
import CheckInput from 'components/Buttons/Check';
import { color } from 'styles/Theme';

const Background = styled.div`
	position: absolute;
	width: 164px;
	z-index: 1300;
	bottom: 0.1rem;
	right: 1.6rem;
`;

const ModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	border: 1px solid #d9d5d2 !important;
	background: #fff;
	color: #000;
	border-radius: 10px;
	padding: 1rem;
`;

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	//justify-content: center;
	//align-items: center;
	margin: 0.3rem 0;
`;

const ModalContentTitle = styled.h1`
	font-size: 13px;
	font-weight: 900;
	color: #69625d;
`;

const ModalFooter = styled.div`
	display: flex;
	align-items: center;

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70px;
		height: 22px;
		border-radius: 20px;
		color: ${color.interface.white};
		font-weight: 800;
		background-color: ${color.interface.orange};
		cursor: pointer;
		pointer-events: fill;
		transition: all 0.3s ease;

		font-size: 10px;
		@media (max-width: 800px) {
			width: 9rem !important;
		}

		:hover {
			background-color: ${color.interface.green};
			border-radius: 20px;
		}
		:disabled {
			background-color: #cccccc;
			color: #fff;
			cursor: not-allowed;
		}
	}
`;

const CheckContainer = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	min-height: 28px;
`;

function ListModal({
	showModal,
	setShowModal,
	toggleBaseLayer,
	options,
	disabled,
	item,
	period
}) {
	const modalRef = useRef();
	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModal(false);
		}
	};

	const keyPress = useCallback(
		(e) => {
			if (e.key === 'Escape' && showModal) {
				setShowModal(false);
			}
		},
		[setShowModal, showModal]
	);

	useEffect(() => {
		document.addEventListener('keydown', keyPress);
		return () => document.removeEventListener('keydown', keyPress);
	}, [keyPress]);

	const toggleSaveMap = () => {
		actionsUsers({
			category: `plataforma/territorio`,
			action: 'Download de camada',
			label: item.name_mapfile
		});

		const hasRaster = options.some(
			(option) => option.format === 'raster' && option.isChecked
		);

		const tiffPath = item.tiffPath
			? item.tiffPath.replace('<year>', period)
			: null;

		const urlRaster =
			tiffPath && hasRaster
				? `${process.env.REACT_APP_HOST_MAPDOWNLOAD}/${tiffPath}`
				: null;

		if (urlRaster) {
			window.open(`${urlRaster}`, '_blank');
		}

		if (!hasRaster) {
			window.open(
				`${
					process.env.REACT_APP_BASE_URL
				}/v1/layersDownload?format=zip&options=${JSON.stringify({
					ip: `${process.env.REACT_APP_HOST_MAPSERVER}`,
					nameLayer: item.name_mapfile,
					folderPath: item.folderPath,
					hasKml: options.some(
						(option) => option.format === 'kml' && option.isChecked
					),
					hasShp: options.some(
						(option) => option.format === 'shp' && option.isChecked
					)
				})}`,
				'_blank'
			);
		}
	};

	return showModal ? (
		<Background onClick={closeModal} ref={modalRef}>
			<ModalWrapper showModal={showModal}>
				<ModalContentTitle>Selecione as opções de download</ModalContentTitle>
				<ModalContent>
					{options.map(({ name, isChecked }, i) => (
						<CheckContainer key={i}>
							<CheckInput
								id={i}
								onClick={() => toggleBaseLayer(isChecked, i)}
								isChecked={isChecked}
								text={name}
								defaultCheckColor={false}
								widthCheck="16px"
							/>
						</CheckContainer>
					))}
				</ModalContent>
				<ModalFooter>
					<button onClick={toggleSaveMap} type="button" disabled={disabled}>
						Baixar
						<img
							style={{ width: 12, marginLeft: 5 }}
							src={svg.icon.download}
							alt="icone ilustrativo"
						/>
					</button>
				</ModalFooter>
			</ModalWrapper>
		</Background>
	) : null;
}

export default ListModal;
