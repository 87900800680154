/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import ViewItem from './ViewItem';
import * as S from '../Tooltip.style';

function TooltipMonitoring({ itemSelect }) {
	const { openIsRegister } = useSelector((state) => state.monitoring);
	const { coordinates } = useSelector((state) => state.system);
	const { isEdit } = useSelector((state) => state.editDataUserOnMap);
	return (
		<>
			{!isEdit && !openIsRegister && <ViewItem itemSelect={itemSelect} />}
			{isEdit && (
				<p style={{ textAlign: 'center' }}>
					Clique no ponto azul e arraste para alterar a localização.
				</p>
			)}
			{openIsRegister && coordinates && (
				<>
					<S.Row>
						<S.Col>
							<h3 style={{ fontSize: 12 }}>LAT: {coordinates?.latitude}</h3>
						</S.Col>
					</S.Row>
					<S.Row>
						<S.Col>
							<h3 style={{ fontSize: 12 }}>LONG: {coordinates?.longitude}</h3>
						</S.Col>
					</S.Row>
				</>
			)}
		</>
	);
}

export default memo(TooltipMonitoring);
