import React from 'react';
import * as S from './styled';

export default function NotAuthorized() {
	return (
		<S.containerNotAuthorized>
			<p className="notes">
				Algum erro aconteceu, tente logar novamente ou entre em contato no email{' '}
				<b>somai@ipam.org.br</b>, relatando o problema.
			</p>
		</S.containerNotAuthorized>
	);
}
