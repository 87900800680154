import React from 'react';
import * as S from './styled';

// ONDE FOR USAR ESTE COMPONENTE, COLOQUE A DIV PAI COMO POSITION RELATIVE!!!!!!!!!!!!!!!!!!

export default function PopUp({ text = 'vazio', isVisiable = false }) {
	return (
		isVisiable && (
			<S.containerPopUp>
				<p>{text}</p>
			</S.containerPopUp>
		)
	);
}
