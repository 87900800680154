import React from 'react';
import PropTypes from 'prop-types';
import { deforestedTisStyle } from 'components/Map/Styles/js/StyleLayers';
import * as S from './styled';

export default function LegendMap({ labels }) {
	const [label01, label02, label03] = labels;

	const style = deforestedTisStyle();
	const { low, average, high } = style || {};

	return (
		<S.container>
			<S.title>Legenda para o mapa</S.title>
			<S.item>
				<S.symbol backgroundColor={high.getFill().getColor()} />
				<S.label>{label01}</S.label>
			</S.item>
			<S.item>
				<S.symbol backgroundColor={average.getFill().getColor()} />
				<S.label>{label02}</S.label>
			</S.item>
			<S.item>
				<S.symbol backgroundColor={low.getFill().getColor()} />
				<S.label>{label03}</S.label>
			</S.item>
		</S.container>
	);
}

LegendMap.propTypes = {
	labels: PropTypes.arrayOf(PropTypes.string)
};

LegendMap.defaultProps = {
	labels: []
};
