import styled from 'styled-components';
import { color } from 'styles';

export const Title = styled.h5`
	color: ${color.interface.orange};
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 13.32px;
`;

export const BoxLogo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	width: 100%;
	height: 48px;
	background: #d3c1b250;

	border-radius: 5px;
	margin-bottom: 1rem;
`;

export const Container = styled.form`
	padding-bottom: 52px;
`;

export const ResetPassword = styled.div`
	color: ${color.interface.darkGray};
	text-decoration: underline ${color.interface.darkGray};
	padding: 4px 0px 0px 12px;
	background-color: transparent;
	cursor: pointer;

	p {
		font-weight: 400;
	}
`;

export const ButtonLogin = styled.button`
	border: none;
	outline: none;
	background: ${color.interface.orange};
	color: ${color.interface.white};
	height: 40px;
	width: 100%;
	font-weight: 700;
	margin-top: 20px;
	cursor: pointer;
	border-radius: 30px;
`;

export const ButtonRegister = styled.button`
	border: none;
	outline: none;
	background: #f8f5f3;
	height: 40px;
	width: 100%;
	font-weight: 700;
	color: ${color.interface.orange};
	cursor: pointer;
	border-radius: 30px;
	margin-top: 7px;
	border: 1px solid ${color.interface.orange} !important;
`;
