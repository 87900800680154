import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Platform from 'pages/Platform';
import RecoveryPassword from 'pages/RecoveryPassword/RecoveryPassword';
import Error404 from 'pages/Error404/Error404';
import { initGA } from 'utils/ga-utils';

function verificaEnderecoIP(ipAddress) {
	const regexNumeros = /^[0-9.]+$/; // Regex para verificar se contém apenas números e pontos
	const regexLetras = /^[A-Za-z0-9.]+$/; // Regex para verificar se contém números, pontos e letras

	if (regexNumeros.test(ipAddress)) {
		return false;
	}
	if (
		regexLetras.test(ipAddress) &&
		ipAddress !== 'localhost' &&
		ipAddress !== 'hmgplataforma.somai.org.br'
	) {
		return true;
	}
	return false;
}

function App() {
	const ipAddress = window.location.hostname;
	useEffect(() => {
		// Retorna true se o ip contiver pontos com letras, caso contrário, retorna false
		if (verificaEnderecoIP(ipAddress)) {
			initGA('G-QQTSBCLKRF');
		}
	}, [ipAddress]);

	return (
		<Routes>
			<Route exact path="/" element={<Platform />} />
			<Route
				exact
				path="/resetpassword/:token/:email"
				element={<RecoveryPassword />}
			/>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
}

export default App;
