/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addRefRouteAction } from 'store/actions/isRouteRefActions';
import { api } from 'services/api';
import Load from 'components/Load/Load';
import { vectorsMonitoring } from 'store/actions/layersAction/monitoring/activeMonitoringAction';
import { componentsViewUsers } from 'helpers/utils';
import General from './TypeProfileUsers/General';
import Monitoring from './TypeProfileUsers/Monitoring';
import MyOrganization from './TypeProfileUsers/MyOrganization';
import GroupedRecords from './TypeProfileUsers/GroupedRecords';
import OrganizationAngCommunities from './TypeProfileUsers/OrganizationAngCommunities';
import Management from './TypeProfileUsers/Management';
import * as S from './styled';
import NotAuthorized from './TypeProfileUsers/NotAuthorized';

function Panel() {
	const dispatch = useDispatch();
	const [token, setToken] = useState(0);
	const [userData, setUserData] = useState({});
	const [isValidToken, setIsValidToken] = useState({
		isValid: false,
		message: 'Erro de conexão atualize a pagina e tente novamente.'
	});
	const [isReqToken, setIsReqToken] = useState(false);
	const { isUp } = useSelector((state) => state.system);

	useEffect(() => {
		componentsViewUsers({
			page: `plataforma/painel`,
			title: 'Painel'
		});
	}, []);

	useEffect(() => {
		dispatch(vectorsMonitoring());
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [isUp]);

	// verifica se há dados no localStorage
	function getDataLocalStorage() {
		const data = JSON.parse(window.localStorage.getItem('user'));
		if (data !== null) {
			setToken(data.token);
			setUserData(data.user);
		}
	}

	// Uma verificação de segurança a mais
	async function verifyToken() {
		setIsReqToken(true);
		await api
			.get('/user/checkToken', {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((res) => {
				const { data } = res;
				setIsReqToken(false);
				setIsValidToken({ isValid: data, message: 'Token Válido' });
			})
			.catch((error) => {
				const { response: erro } = error;
				setIsReqToken(false);
				setIsValidToken({
					isValid: false,
					message: erro.data.message || 'Erro desconhecido, tente novamente.'
				});
			});
	}

	// retorna menu de acessos que o usuário tem acesso
	function returnMenuOptions(user) {
		const typeUser = user.user_role.role;

		switch (typeUser) {
			case 'user':
				return (
					<>
						<General />
						<Monitoring />
					</>
				);

			case 'member':
				return (
					<>
						<General />
						<Monitoring />
					</>
				);

			case 'manager':
				return (
					<>
						<General />
						<Monitoring />
						<MyOrganization />
						<Management />
					</>
				);

			case 'adminPartners':
				return (
					<>
						<General />
						<Monitoring />
						<OrganizationAngCommunities />
					</>
				);

			case 'admin':
				return (
					<>
						<General />
						<Monitoring />
						<GroupedRecords />
						<Management />
					</>
				);

			case 'waitingForDocuments':
				return <General />;

			case 'todos':
				return (
					<>
						<General />
						<Monitoring />
						<MyOrganization />
					</>
				);

			default:
				return <NotAuthorized />;
		}
	}

	// função necessário pq eslint não permite ternário aninhado como: isLoad? <Load/> : isData ? <Exemplo /> : não ha dado
	function whenFinishedLoad() {
		if (isValidToken.isValid === false && isReqToken === false) {
			return <p>{isValidToken.message}</p>;
		}
		return returnMenuOptions(userData);
	}

	useEffect(() => {
		getDataLocalStorage();
		dispatch(addRefRouteAction('painel'));
	}, []);

	useEffect(() => {
		if (token !== 0) {
			verifyToken();
		}
	}, [token]);

	return (
		<S.containerPanel>
			{isReqToken === true ? <Load /> : whenFinishedLoad()}
		</S.containerPanel>
	);
}

export default Panel;
