import React, { useEffect } from 'react';
import GenericAccordion from 'components/GenericAccordion';
import { color } from 'styles/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { getDataClimate } from 'store/actions/climateAction';
import * as S from './styled';
import Aridity from './Aridity';
import Dry from './Dry';
import Rain from './Rain';
import Temperature from './Temperature';

export default function Climate() {
	const { allLayers } = useSelector((state) => state.allLayersMap);
	const key = 'clima';
	const filterLayers = allLayers?.filter((item) => item.key === key);

	const dispatch = useDispatch();

	const { dataGeo } = useSelector((state) => state.tiGeo);

	useEffect(() => {
		if (dataGeo?.centroid !== []) {
			const { centroid } = dataGeo;
			dispatch(getDataClimate(centroid));
		}
	}, [dataGeo]);

	return (
		<GenericAccordion
			title={filterLayers[0]?.name.toUpperCase() || ''}
			keyAccordion={key}
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontFamily: 'Nunito',
				fontStyle: 'normal',
				fontWeight: '800',
				lineHeight: '0.875rem',
				fontSize: '0.625rem',
				letterSpacing: '0.1em',
				textTransform: 'uppercase'
			}}
		>
			<S.containerAccordionInside>
				<Temperature climateList={filterLayers[0]} />
				<Rain climateList={filterLayers[0]} />
				<Dry climateList={filterLayers[0]} />
				<Aridity climateList={filterLayers[0]} />
			</S.containerAccordionInside>
		</GenericAccordion>
	);
}
