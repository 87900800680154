import { apiClimate, climateConfig } from 'services/api';
import { apiClimateType } from 'store/typesActions/types';
// `${apiClimate}?lat=${coords.latitude}&lon=${coords.longitude}&units=metric&exclude=${climateConfig.exclude}&lang=${climateConfig.language}&appid=${climateConfig.key}`

// dados de clima
export const getDataClimate = (coords) => (dispatch) => {
	dispatch({ type: apiClimateType.CLIMATE_REC });
	try {
		fetch(
			`${apiClimate}?lat=${coords.latitude}&lon=${coords.longitude}&units=metric&exclude=${climateConfig.exclude}&lang=${climateConfig.language}&appid=${climateConfig.key}`
		)
			.then((resp) => resp.json())
			.then(
				(json) => {
					dispatch({ type: apiClimateType.CLIMATE_REC_SUCCESS, payload: json });
				},
				() => {
					dispatch({ type: apiClimateType.CLIMATE_REC_ERROR });
				}
			);
	} catch (error) {
		dispatch({ type: apiClimateType.CLIMATE_REC_ERROR });
	}
};
