import styled from 'styled-components';

export const container = styled.div`
	width: 100%;
	display: flex;

	gap: 1rem;
`;

export const Box = styled.div`
	width: 100%;
	height: 100%;
`;
