import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerPopUp = styled.div`
	position: absolute;
	width: auto;
	min-height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0.1rem solid ${color.interface.lightGray};
	border-radius: 0.5rem;
	padding: 0.5rem;
	transform: translateY(-100%);
	transition: all ease 0.5s;

	@keyframes show {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	animation-name: show;
	animation-duration: 1s;

	background-color: ${color.interface.whiteAbsolute};
	text-align: center;

	> p {
		font-weight: 400;
		color: ${color.interface.gray};
	}
`;
