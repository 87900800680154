import React, { useEffect, useState } from 'react';
import { svg } from 'lib/svgList';
import ItensMenu from 'components/ItensMenu';
import { getUsersByManager } from 'store/actions/userActions';
import { getUserLocalStorage } from 'services/session';
import { filterUsers } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import TitleTab from 'components/TitleTab';
import DataUser from './DataUsers';
import * as S from './styled';

export default function MyOrganization() {
	const [session, setSession] = useState({});
	const roleManager = session?.user?.user_role?.role || '';

	useEffect(() => {
		setSession(getUserLocalStorage());
	}, []);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getUsersByManager());
	}, []);

	const { listUsers } = useSelector((state) => state.user);

	// retorna usuários apenas se tiver aprovado
	const listFilter = filterUsers(roleManager, listUsers).filter(
		(item) => item.situation_id === 1
	);

	const data = listFilter.map((user) => ({
		id: user.id,
		title: 'Minha organização',
		subTitle: `${user.firstName} ${user.lastName}`,
		icon: svg.icon.profileOrange,
		content: <DataUser user={user} />
	}));

	return data && data.length > 0 ? (
		<S.containerGeralManagement>
			<S.lineGray />
			<S.containerList>
				<TitleTab title="Minha organização" />
				{data.map((item) => (
					<ItensMenu
						key={item.id}
						title={item.title}
						subTitle={item.subTitle}
						icon={item.icon}
						content={item.content}
					/>
				))}
			</S.containerList>
		</S.containerGeralManagement>
	) : null;
}
