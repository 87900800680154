/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import useWindowSize from 'hooks/getWindowSize';
import { useDispatch, useSelector } from 'react-redux';
import { toggleOpenOrCloseNavBar } from 'store/actions/isNavBarOpenAction';
import LegendAndAdjustments from 'components/Map/LegendAndAdjustments';
import DrawerLeft from './DrawerLeft';
import DrawerBotton from './DrawerBotton';

function Drawer({ content }) {
	const open = useSelector((state) => state.isNavBarOpen);
	const dispatch = useDispatch();
	const { width } = useWindowSize();

	return (
		<>
			{width >= 800 ? (
				<DrawerLeft
					open={open}
					setOpen={() => dispatch(toggleOpenOrCloseNavBar({ status: !open }))}
					content={content}
				/>
			) : (
				width !== 0 && (
					<DrawerBotton
						open={open}
						setOpen={() => dispatch(toggleOpenOrCloseNavBar({ status: !open }))}
						content={content}
					/>
				)
			)}
			<LegendAndAdjustments />
		</>
	);
}

export default memo(Drawer);
