import styled from 'styled-components';
import { color } from 'styles';

export const Actions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const BoxHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 25px;
	margin-top: 15px;
`;

export const BoxForm = styled.div`
	display: contents;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 25px;
	margin-top: 15px;
`;

export const GridCont = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	color: ${color.interface.orange};
	width: 20%;
`;

export const GridTitle = styled.div`
	display: flex;
	align-items: self-end;
	width: 80%;
	color: ${color.interface.orange};
	img {
		width: 18px;
		margin-right: 10px;
	}
`;

export const Button = styled.button`
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	background: #f8f5f3;
	height: 50px;
	font-size: 1rem;
	width: 100%;
	font-weight: 400;
	color: ${color.interface.orange};
	cursor: pointer;
	border-radius: 30px;
	margin-top: 7px;
	border: 1px solid ${color.interface.orange} !important;
	margin-bottom: 30px;
	img {
		width: 11px;
		margin-left: 10px;
		margin-right: 10px;
		transform: rotate(-90deg);
	}

	.icon-next {
		transform: rotate(90deg);
	}
`;
