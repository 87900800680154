import React from 'react';
import * as S from './styled';

export default function UncheckAnimation() {
	return (
		<S.ContainerUncheck>
			<S.CircleUncheck>
				<S.leg1X />
				<S.leg2X />
			</S.CircleUncheck>
		</S.ContainerUncheck>
	);
}
