import styled from 'styled-components';

export const containerGeralMonitoring = styled.div`
	width: 100%;
	padding-bottom: 20rem;
`;

export const lineGray = styled.div`
	width: 100%;
	height: 0.5px;
`;
