import React from 'react';
import GenericAccordion from 'components/GenericAccordion';
import { color } from 'styles/Theme';
import { svg } from 'lib/svgList';
import DeforestationPercentageDonoutGraph from '../../Graphs/DeforestationPercentageGraph/DeforestationPercentageDonoutGraph';
import * as S from './styled';

export default function DeforestationData() {
	return (
		<GenericAccordion
			imgTitle={svg.icon.desmatamentoIlegalOrange}
			title="Dados do desmatamento"
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontWeight: '300'
			}}
		>
			<S.container>
				<DeforestationPercentageDonoutGraph />
			</S.container>
		</GenericAccordion>
	);
}
