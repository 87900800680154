import styled from 'styled-components';
import { color } from 'styles/Theme';

export const Container = styled.div`
	padding-top: 1rem;
	display: inline-grid;
	width: 100%;
	gap: 0.5rem;
`;

export const Text = styled.div`
	width: 100%;
	font-style: normal;
	font-weight: 800;
	font-size: 0.625rem;
	line-height: 0.875rem;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #69625d;
`;

export const line = styled.div`
	width: 100%;
	height: 0.5px;
	margin-top: 0.5rem;
	background-color: ${color.interface.lightGray};
	transition: all ease 0.5s;
`;
