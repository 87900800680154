import styled from 'styled-components';
import ColLayout from 'components/Grid/Col';
import { color } from 'styles';

export const BoxFilter = styled(ColLayout)`
	display: flex;
	align-items: center;
	padding: 0.2rem;
`;

export const BoxBottom = styled.div`
	display: flex;
	align-items: center;
	padding-top: 6px;
	justify-content: space-around;
`;

export const Label = styled.div`
	padding-left: 10px;
	margin-bottom: 5px;
	margin-top: 15px;
	font-size: 0.875rem;
	color: ${color.interface.orange};
	font-weight: 400;
`;

export const Title = styled.div`
	display: flex;
	gap: 0.4rem;
	align-items: center;
	justify-content: center;

	> p {
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.02em;
		color: ${color.interface.orange};
	}
`;
export const line = styled.div`
	min-width: 100%;
	height: 0.5px;
	background-color: ${color.interface.lightGray};
	margin-bottom: 1rem;
`;
