import React, { memo } from 'react';
import { addRefTabAction } from 'store/actions/isTabRefActions';
import { useDispatch, useSelector } from 'react-redux';
import { addRefRouteAction } from 'store/actions/isRouteRefActions';
import useWindowSize from 'hooks/getWindowSize';
import { actionsUsers, handleCloseTooltip } from 'helpers/utils';
import { clearVector } from 'store/actions/mapActions';
import { tabBgColor } from 'helpers/tabBgColor';

import * as S from './Tabs.Styled';

function Tab({ TabTerritory, TabPanel }) {
	const dispatch = useDispatch();
	const currentTab = useSelector((state) => state.isTabRef);

	const { width } = useWindowSize();
	const { tab1, tab2 } = tabBgColor(currentTab);

	const tabList = [
		{
			name: 'tab1',
			label: 'Territórios',
			content: <TabTerritory />,
			width: '60%',
			theme: tab1.theme
		},
		{
			name: 'tab2',
			label: 'Painel',
			content: <TabPanel />,
			width: '40%',
			theme: tab2.theme
		}
	];

	const handleGA = ({ label, name }) => {
		actionsUsers({
			category: `plataforma/tab/${name}`,
			action: `Click na tab ${label}`,
			label
		});
	};
	return (
		<div style={{ height: '100%' }}>
			<S.TabsContainer screenWidth={width}>
				{tabList.map((tab) => (
					<S.Button
						key={tab.label}
						onClick={() => {
							dispatch(addRefTabAction(tab.name));
							dispatch(addRefRouteAction('login'));
							clearVector('monitoring');
							handleCloseTooltip();
							handleGA({ label: tab.label, name: tab.name });
						}}
						tab={tab}
						currentTab={currentTab}
						style={{
							width: tab.width,
							marginRight: 7,
							color: tab.theme.color,
							background: tab.theme.bg
						}}
					>
						<h4 style={{ fontSize: '0.85rem' }}>{tab.label}</h4>
					</S.Button>
				))}
			</S.TabsContainer>

			{tabList.map((tab) => {
				if (tab.name === currentTab) {
					return (
						<S.Components key={tab.name} props={{ screenSize: width }}>
							{tab.content}
						</S.Components>
					);
				}
				return null;
			})}
		</div>
	);
}

export default memo(Tab);
