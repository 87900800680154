/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import { MeasureStore, useMeasureStore } from 'services/map/measure.context';
import { useShapeStore } from 'services/map/shapes.context';
import styled from 'styled-components';

const Background = styled.div`
	position: absolute;
	width: 6rem;
	z-index: 1300;
	top: 0;
	right: 3rem;
	@media (max-width: 800px) {
		top: 3.5rem;
		right: 9rem;
		width: 4rem;
	}
`;

const ModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #fff;
	color: #000;
	border-radius: 6px;
	padding: 0.2rem;
	border: 2px #d9d5d2 solid;
`;

const ModalContent = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	height: 1.8rem;
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
		height: 6rem;
	}
`;

const Container = styled.div`
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	& + div {
		border-left: 1px solid;
		border-color: #d9d5d2;
	}
	@media (max-width: 800px) {
		& + div {
			border-left: 0;
			border-top: 1px solid;
			border-color: #d9d5d2;
		}
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		width: 100%;
		height: 100%;
		background: none !important;
		cursor: pointer;
		:hover {
			background-color: none;
			height: 100%;
			/* border-radius: 4px; */
		}
		/* 	&.active {
			height: 85%;
			background-color: #e6dcd3;
			border-radius: 4px;
		} */
	}

	img,
	svg {
		width: 20px;
		height: 20px;
	}
`;

function ListModal() {
	const { handleChangeInteractionType, showModalMeasure, setShowModalMeasure } =
		useMeasureStore();
	const { state: stateDraw, resetInteraction: resetDrawInteraction } =
		useShapeStore();

	const modalRef = useRef();
	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModalMeasure(false);
		}
	};

	const handlerLineStringInteraction = () => {
		if (stateDraw.drawShapesIsActive) {
			resetDrawInteraction();
		}
		handleChangeInteractionType('LineString');
	};
	const handlerPolygonInteraction = () => {
		if (stateDraw.drawShapesIsActive) {
			resetDrawInteraction();
		}
		handleChangeInteractionType('Polygon');
	};

	return (
		<>
			{showModalMeasure ? (
				<Background onClick={closeModal} ref={modalRef}>
					<ModalWrapper showModal={showModalMeasure}>
						<ModalContent>
							<Container>
								<button
									/* className={
										type === 'Drag' && !active
											? `active sm__tooltip`
											: 'sm__tooltip'
									} */
									type="button"
									/* data-title={title}
				onClick={HandleControl} */
									onClick={handlerLineStringInteraction}
								>
									<svg
										width="27"
										height="24"
										viewBox="0 0 27 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M26.0521 18.4998C25.6929 17.8821 25.1462 17.3949 24.4912 17.1089C23.8363 16.8229 23.1072 16.753 22.4098 16.9093L18.1971 9.63715C17.9974 9.2943 17.67 9.04446 17.2865 8.9423C16.903 8.84013 16.4947 8.89395 16.1508 9.09198L9.70163 12.7981L5.80914 6.09609C6.29225 5.57312 6.59477 4.909 6.67222 4.20137C6.74967 3.49374 6.59797 2.77994 6.23941 2.16492C5.79106 1.39964 5.0587 0.842173 4.20149 0.613662C3.34427 0.38515 2.43148 0.50406 1.66148 0.944552C0.971345 1.34592 0.447752 1.98102 0.185471 2.73488C-0.0768108 3.48875 -0.0604497 4.31157 0.231592 5.05442C0.523635 5.79727 1.07206 6.41106 1.7776 6.7847C2.48315 7.15833 3.29919 7.26713 4.07803 7.09139L8.2407 14.2335C8.44039 14.5764 8.76779 14.8262 9.15128 14.9284C9.53476 15.0305 9.94312 14.9767 10.287 14.7787L16.7361 11.0726L20.6787 17.8996C20.1956 18.4226 19.893 19.0867 19.8156 19.7943C19.7381 20.502 19.8898 21.2158 20.2484 21.8308C20.6968 22.5988 21.431 23.1582 22.2906 23.3868C23.1502 23.6155 24.0654 23.4948 24.8363 23.0511C25.2151 22.8321 25.547 22.5406 25.8131 22.1934C26.0792 21.8461 26.2743 21.4498 26.3872 21.0272C26.5001 20.6046 26.5286 20.1638 26.4711 19.7302C26.4136 19.2965 26.2712 18.8784 26.0521 18.4998V18.4998Z"
											fill="#291F17"
										/>
									</svg>
								</button>
							</Container>
							<Container>
								<button
									/* className={
										type === 'Drag' && !active
											? `active sm__tooltip`
											: 'sm__tooltip'
									} */
									type="button"
									/* data-title={title}
				onClick={HandleControl} */
									onClick={handlerPolygonInteraction}
								>
									<svg
										width="28"
										height="24"
										viewBox="0 0 28 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M19.6898 2.5L25.3348 12L19.6898 21.5H8.30976L2.66476 12L8.30976 2.5H19.4998M20.2598 0.5H7.75976C7.58525 0.501053 7.41405 0.547759 7.26318 0.635475C7.11231 0.72319 6.98702 0.848862 6.89976 1L0.644763 11.5C0.55132 11.6555 0.501953 11.8336 0.501953 12.015C0.501953 12.1964 0.55132 12.3745 0.644763 12.53L6.87976 23C6.96702 23.1511 7.09231 23.2768 7.24318 23.3645C7.39405 23.4522 7.56525 23.4989 7.73976 23.5H20.2398C20.4143 23.4989 20.5855 23.4522 20.7363 23.3645C20.8872 23.2768 21.0125 23.1511 21.0998 23L27.3548 12.5C27.4482 12.3445 27.4976 12.1664 27.4976 11.985C27.4976 11.8036 27.4482 11.6255 27.3548 11.47L21.1198 1C21.0325 0.848862 20.9072 0.72319 20.7563 0.635475C20.6055 0.547759 20.4343 0.501053 20.2598 0.5Z"
											fill="#291F17"
										/>
									</svg>
								</button>
							</Container>
						</ModalContent>
					</ModalWrapper>
				</Background>
			) : null}
		</>
	);
}

export default ListModal;
