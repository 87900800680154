import styled from 'styled-components';
import { color } from '../../styles/Theme';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	background: #f2ede9;
	border-top: 1px solid #f2ede9;
	border-width: 1px 0px;
	border-style: solid;
	border-color: #d9d5d2;
`;

export const BoxButton = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	height: 72px;
`;

export const Text = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	h4 {
		font-family: 'Nunito';
		color: ${color.interface.orange};
		margin-right: 5px;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.02em;
	}
`;

export const IconButton = styled.button`
	background: transparent;
	text-decoration: none;
	cursor: pointer;
`;

export const boxInformation = styled.div`
	width: 300px;
	max-height: 300px;
	background-color: ${color.interface.whiteAbsolute};
	color: ${color.interface.darkGray};

	font-size: 13px;
	font-weight: 400;
	padding: 1rem;
`;

export const Content = styled.div`
	padding: 0.1rem;
	padding-top: 0;
`;
