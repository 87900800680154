import React from 'react';
import { svg } from 'lib/svgList';
import * as S from './styled';

export default function AddItem({ name = 'item', onClink = () => {} }) {
	return (
		<S.containerAddItem onClick={() => onClink()}>
			<S.addText>Adicionar {name}</S.addText>
			<img src={svg.icon.maximizeOrange} alt="adicionar item" />
		</S.containerAddItem>
	);
}
