import { api } from 'services/api';
import { organizationsType } from 'store/typesActions/types';
import { headers } from 'helpers/utils';

export const getOrganizations = () => async (dispatch) => {
	dispatch({ type: organizationsType.ORGANIZATIONS_REQUEST });
	try {
		const res = await api.get('/organizations');
		const { data } = res || {};
		dispatch({
			type: organizationsType.ORGANIZATIONS_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: organizationsType.ORGANIZATIONS_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: organizationsType.ORGANIZATIONS_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const emailRequestOrgRegistration = (params) => async (dispatch) => {
	dispatch({ type: organizationsType.EMAIL_REGISTRATION_REQUEST });
	try {
		const res = await api.post('/requestOrgRegistration', params);
		const { data } = res || {};
		dispatch({
			type: organizationsType.EMAIL_REGISTRATION_SUCCESS,
			payload: data
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: organizationsType.EMAIL_REGISTRATION_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: organizationsType.EMAIL_REGISTRATION_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const orgRegistration = (params) => async (dispatch) => {
	dispatch({ type: organizationsType.ORGANIZATIONS_SAVE_REQUEST });
	try {
		const res = await api.post('/organization', params, {
			headers: headers()
		});
		const { data } = res || {};

		dispatch({
			type: organizationsType.ORGANIZATIONS_SAVE_SUCCESS,
			payload: data,
			message: 'Organização criada com sucesso!'
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: organizationsType.ORGANIZATIONS_SAVE_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: organizationsType.ORGANIZATIONS_SAVE_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const orgUpdate = (params) => async (dispatch) => {
	dispatch({ type: organizationsType.ORGANIZATIONS_UPDATE_REQUEST });
	try {
		const res = await api.put('/organization/update', params, {
			headers: headers()
		});
		const { data } = res || {};
		dispatch({
			type: organizationsType.ORGANIZATIONS_UPDATE_SUCCESS,
			payload: data,
			message: 'Organização editada com sucesso!'
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: organizationsType.ORGANIZATIONS_UPDATE_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: organizationsType.ORGANIZATIONS_UPDATE_FAILURE,
				payload: {
					errors: { message: 'Erro desconhecido. Tente novamente mais tarde' }
				}
			});
		}
	}
};

export const destroy = (params) => async (dispatch) => {
	dispatch({ type: organizationsType.ORGANIZATIONS_DESTROY_REQUEST });
	try {
		const res = await api.delete(`/organization/delete/${params.id}`, {
			headers: headers()
		});
		const { data } = res || {};
		dispatch({
			type: organizationsType.ORGANIZATIONS_DESTROY_SUCCESS,
			payload: {
				id: params.id,
				message: data.message
			}
		});
		return data;
	} catch (error) {
		const { response } = error;
		if (response && response.status === 400) {
			const { message } = response.data;
			dispatch({
				type: organizationsType.ORGANIZATIONS_DESTROY_FAILURE,
				payload: { errors: { message } }
			});
		} else {
			dispatch({
				type: organizationsType.ORGANIZATIONS_DESTROY_FAILURE,
				payload: {
					errors: { message: 'Sem permissão para apagar' }
				}
			});
		}
	}
};
export const closeMessageAndError = () => {
	return { type: organizationsType.ORGANIZATIONS_CLOSE };
};
