/* eslint-disable no-useless-escape */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import { transform } from 'ol/proj';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import GeoJSON from 'ol/format/GeoJSON';
import { getLayerByName } from 'store/actions/mapActions';

export function hideshowfeatures(layerName, showFeatures = true) {
	const layer = getLayerByName(layerName);
	if (layer) {
		// Set the visibility of the layer
		layer.setVisible(showFeatures);
	} else {
		// console.log(`Layer ${layerName} not found in the map.`);
	}
}

export function getOptions(props) {
	const options = {};
	for (const key in props) {
		if (
			key !== 'children' &&
			typeof props[key] !== 'undefined' && // exclude undefined ones
			!key.match(/^on[A-Z]/) // exclude events
		) {
			options[key] = props[key];
		}
	}
	return options;
}

function getPropsKey(eventName) {
	return `on${eventName
		.replace(/(\:[a-z])/g, ($1) => $1.toUpperCase())
		.replace(/^[a-z]/, ($1) => $1.toUpperCase())
		.replace(':', '')}`;
}

export function getEvents(events, props) {
	const prop2EventMap = {};
	for (const key in events) {
		prop2EventMap[getPropsKey(key)] = key;
	}

	const ret = {};
	for (const propName in props) {
		const eventName = prop2EventMap[propName];
		const prop = props[propName];
		if (
			typeof prop !== 'undefined' &&
			propName.match(/^on[A-Z]/) &&
			eventName
		) {
			ret[eventName] = prop;
		}
	}

	return ret;
}

export const transformCoords = (points) => {
	for (let i = 0; i < points.length; i++) {
		points[i] = transform(points[i], 'EPSG:4326', 'EPSG:3857');
	}
	return points;
};

export const arrayPoints = (list, typeRegister) => {
	const features = [];

	list.forEach((d) => {
		const { latitude, longitude, isChecked } = d;
		const point = transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857');
		const feature = [
			new Feature({
				geometry: new Point(point),
				type: typeRegister,
				props: d
			})
		];
		if (isChecked === true) {
			features.push(...feature);
		}
	});
	return features;
};

export const arrayLineString = (list) => {
	const lines = {
		type: 'FeatureCollection',
		features: []
	};

	list.forEach((item) => {
		const { geom, isChecked, hash, id, name, distance, user, type } = item;
		if (isChecked === true) {
			lines.features.push({
				type: 'Feature',
				geometry: geom,
				properties: {
					type: 'tracks',
					props: {
						isChecked,
						hash,
						id,
						name,
						distance,
						created_at: item.created_at,
						geom,
						user,
						type
					}
				}
			});
		}
	});

	const states = new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(
		lines
	);

	return states;
};
