import { riskFireType } from 'store/typesActions/types';

const initialState = {
    dataRiskFire: '',
    isLoading: false,
    error: false,
    message: ''
};

export default function riskFireReducer(state = initialState, action) {
    const { errors } = action?.payload || { errors: {} };

    switch (action.type) {
        case riskFireType.RISK_FIRE:
            return {
                ...state,
                isLoading: true,
                error: false,
                message: 'Buscando dados do desmatamento'
            };

        case riskFireType.RISK_FIRE_SUCCESS:
            return {
                ...state,
                dataRiskFire: action.payload,
                isLoading: false,
                error: false,
                message: ''
            };

        case riskFireType.RISK_FIRE_ERROR:
            return {
                ...state,
				dataRiskFire: [],
				isReq: false,
                error: true,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
            };
        default:
            return state;
    }
}