import React, { useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { register, closeErrorUser } from 'store/actions/userActions';
import Step from 'components/StepperRegister/Step';
import Stepper from 'components/StepperRegister/Stepper';
import InputRegister from 'components/InputRegister';
import {
	formatTis,
	convertAge,
	convertJoin,
	componentsViewUsers
} from 'helpers/utils';
import { addRefRouteAction } from 'store/actions/isRouteRefActions';
import Select from 'components/Buttons/Select/Select';
import { color } from 'styles';
import Search from 'components/Buttons/Search/Search';
import SearchMultiple from 'components/Buttons/SearchMultiple';
// import CheckAnimation from 'components/Animations/CheckAnimation/CheckAnimation';
import Modal from 'components/Modal';
import * as S from './Register.Styled';
import SubmitOrganization from '../SubmitOrganization';

function Register({
	loading,
	currentStep,
	userProfiles,
	rolesPublic,
	loadingRoles,
	tisList,
	loandigTis,
	listOrg,
	message,
	blockLogin
}) {
	const dispatch = useDispatch();

	// referencia o step inicial
	const [stepNumber, setStepNumber] = useState('1');
	const [orgModal, setOrgModal] = useState(false);
	const [isError, setIsError] = useState(false);
	const [step, setStep] = useState(currentStep);

	// Dados do formulario
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [birthDate, setBirthDate] = useState('');
	const [typeUser, setTypeUser] = useState('');
	const [profileUser, setProfileUser] = useState('');
	const [organization, setOrganization] = useState('');
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [tisAlerts, setTisAlerts] = useState([]);

	// remove o erro de item obrigatório após preencher o campo
	useEffect(() => {
		if (typeUser !== '') {
			setIsError(false);
		}
	}, [typeUser]);

	// remove itens duplicados da lista ao adicionar ou remover um novo item
	const uniqueValue = Array.from(new Set(tisAlerts.map((a) => a.value))).map(
		(value) => tisAlerts.find((a) => a.value === value)
	);

	// Dados para envio
	const formData = {
		firstName,
		lastName,
		birthDate: convertAge(birthDate),
		role_id: typeUser?.value,
		researcher_profile_id: profileUser.value || '',
		organization_id: organization?.value || null,
		password,
		email,
		tis: convertJoin(uniqueValue)
	};

	// Dispatch para a APi
	const sendData = (e) => {
		e.preventDefault();
		dispatch(register(formData));
	};

	// Apaga erro de requisição no reducer
	const handleClose = () => {
		dispatch(closeErrorUser());
		if (blockLogin) {
			dispatch(addRefRouteAction('login'));
		}
	};

	// Avança para o passo 2
	const handleNextClick = (e) => {
		e.preventDefault();
		if (typeUser?.value) {
			setStep(step + 1);
			setStepNumber('2');
			setOrganization([]);
			setProfileUser([]);
		} else {
			setIsError(true);
		}
	};

	// Retorna para o passo 1
	const handlePreviousClick = (e) => {
		e.preventDefault();
		setStep(step - 1);
		setStepNumber('1');
	};

	useEffect(() => {
		componentsViewUsers({
			page: `plataforma/painel/register`,
			title: 'Cadastro'
		});
	}, []);
	return (
		<Stepper
			sendData={sendData}
			stepNumber={stepNumber}
			setStepNumber={setStepNumber}
			handleNextClick={handleNextClick}
			handlePreviousClick={handlePreviousClick}
			step={step}
		>
			{/* Primeiro Step do formulario */}

			<Step stepNumber={stepNumber}>
				<S.SubTitle style={{ fontSize: 23, marginBottom: 20 }}>
					Dados pessoais
				</S.SubTitle>

				<S.Label>Primeiro nome* </S.Label>
				<InputRegister
					type="text"
					value={firstName}
					setValue={setFirstName}
					required
				/>

				<S.Label>Último nome* </S.Label>
				<InputRegister
					type="text"
					value={lastName}
					setValue={setLastName}
					required
				/>

				<S.Label>Data de nascimento*</S.Label>
				<InputRegister
					type="date"
					value={birthDate}
					setValue={setBirthDate}
					required
				/>

				<S.Label style={{ marginTop: 50 }}>Tipo de usuário*</S.Label>

				<Select
					itemSelected={typeUser}
					setItemSelected={setTypeUser}
					options={rolesPublic}
					style={styledConfg.select}
					isLoading={loadingRoles}
					isLineOnTheList
					isError={!typeUser?.value && isError}
				/>

				<S.Label>E-mail*</S.Label>
				<InputRegister
					type="email"
					value={email}
					setValue={setEmail}
					required
				/>

				<S.Label>Senha*</S.Label>
				<InputRegister
					type="password"
					value={password}
					setValue={setPassword}
					required
				/>
				<div style={{ marginBottom: 30 }}>
					<S.Span>* Preenchimento obrigatório</S.Span>
				</div>
			</Step>

			{/* Segundo Step do formulario */}

			<Step stepNumber={stepNumber}>
				<S.SubTitle style={{ fontSize: 23, marginBottom: 20 }}>
					{typeUser?.label || 'Usuário comum'}
				</S.SubTitle>

				{typeUser?.value > 0 && typeUser.value !== 5 ? (
					<>
						<S.Label>Organização</S.Label>

						<Search
							itemsList={listOrg}
							setItemSelected={setOrganization}
							styleInput={styledConfg.select}
							placeholder={organization.label || 'Buscar...'}
							isLineOnTheList
						/>
						<div style={{ marginBottom: 10, marginTop: 7 }}>
							<S.Span>
								Não encontrou a sua organização?
								<button
									onClick={() => setOrgModal(true)}
									className="register-organizations"
									type="button"
								>
									Solicite o cadastro
								</button>
							</S.Span>
						</div>
						<SubmitOrganization
							orgModal={orgModal}
							setOrgModal={setOrgModal}
							emailRequester={email}
							firstName={firstName}
							lastName={lastName}
						/>
					</>
				) : (
					<>
						<S.Label>Perfil</S.Label>
						<Select
							itemSelected={profileUser}
							setItemSelected={setProfileUser}
							options={userProfiles}
							style={styledConfg.select}
							isLineOnTheList
						/>
					</>
				)}

				<S.BoxLabelAlerts>
					<S.Label>Alertas</S.Label>
					<S.Span>
						Receba notificações de uma ou mais Terra Indígena por email
					</S.Span>
				</S.BoxLabelAlerts>

				<SearchMultiple
					itemsSelected={uniqueValue}
					setItemsSelected={setTisAlerts}
					itemsList={formatTis(tisList)}
					styleInput={styledConfg.select}
					isLoading={loandigTis}
					textPlaceholder="Selecione uma ou mais TIs"
					isLineOnTheList
				/>

				<S.BoxButton>
					<S.Button type="submit" disabled={loading}>
						{loading ? 'Aguarde...' : 'Cadastrar'}
					</S.Button>
				</S.BoxButton>

				<Modal
					isOpen={message?.length > 0}
					setIsOpen={handleClose}
					useBtnEnd={false}
				>
					{message}
				</Modal>
			</Step>
		</Stepper>
	);
}

const styledConfg = {
	select: {
		width: '100%',
		height: 55,
		color: color.interface.inputs,
		fontSize: 16,
		fontFamily: 'Nunito',
		border: `1.5px solid ${color.interface.inputs}`,
		padding: '0 22px',
		borderRadius: 10
	}
};

Register.defaultProps = {
	currentStep: 0
};

export default memo(Register);
