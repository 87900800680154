/* eslint-disable no-nested-ternary */
import React from 'react';
import { actionsUsers } from 'helpers/utils';
import * as S from './styles';

function ControlButton({ title, Action, type, active, directAction }) {
	const actionButton = active ? ' ativar função' : ' desativar função';

	const HandleControl = () => {
		Action();

		actionsUsers({
			category: `plataforma/mapa/webgis`,
			action: `Click no botão${!directAction ? actionButton : ''}`,
			label: title
		});
	};

	/* 	<FaExpandAlt style={{ width: 22, color: color.interface.brown }} />
	 */
	return (
		<S.Container>
			<button
				className={
					type === 'Drag' && !active ? `active sm__tooltip` : 'sm__tooltip'
				}
				type="button"
				data-title={title}
				onClick={HandleControl}
			>
				{type === 'In' ? (
					<svg
						width="22"
						height="22"
						viewBox="0 0 32 32"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M23 15H17V9C17 8.73478 16.8946 8.48043 16.7071 8.29289C16.5196 8.10536 16.2652 8 16 8C15.7348 8 15.4804 8.10536 15.2929 8.29289C15.1054 8.48043 15 8.73478 15 9V15H9C8.73478 15 8.48043 15.1054 8.29289 15.2929C8.10536 15.4804 8 15.7348 8 16C8 16.2652 8.10536 16.5196 8.29289 16.7071C8.48043 16.8946 8.73478 17 9 17H15V23C15 23.2652 15.1054 23.5196 15.2929 23.7071C15.4804 23.8946 15.7348 24 16 24C16.2652 24 16.5196 23.8946 16.7071 23.7071C16.8946 23.5196 17 23.2652 17 23V17H23C23.2652 17 23.5196 16.8946 23.7071 16.7071C23.8946 16.5196 24 16.2652 24 16C24 15.7348 23.8946 15.4804 23.7071 15.2929C23.5196 15.1054 23.2652 15 23 15Z"
							fill="black"
						/>
					</svg>
				) : type === 'Out' ? (
					<svg
						width="22"
						height="22"
						viewBox="0 0 32 32"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M23 17H9C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5196 8 16.2652 8 16C8 15.7348 8.10536 15.4804 8.29289 15.2929C8.48043 15.1054 8.73478 15 9 15H23C23.2652 15 23.5196 15.1054 23.7071 15.2929C23.8946 15.4804 24 15.7348 24 16C24 16.2652 23.8946 16.5196 23.7071 16.7071C23.5196 16.8946 23.2652 17 23 17Z"
							fill="black"
						/>
					</svg>
				) : type === 'Drag' ? (
					<svg
						width="22"
						height="22"
						viewBox="0 0 64 64"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M35 50H29V54H35V50Z" fill="#291F17" />
						<path d="M25 50H17V54H25V50Z" fill="#291F17" />
						<path d="M10 48H6V54H12V50H10V48Z" fill="#291F17" />
						<path d="M10 24H6V32H10V24Z" fill="#291F17" />
						<path d="M10 12H6V20H10V12Z" fill="#291F17" />
						<path d="M10 36H6V44H10V36Z" fill="#291F17" />
						<path d="M6 8H10V6H12V2H6V8Z" fill="#291F17" />
						<path d="M24 2H16V6H24V2Z" fill="#291F17" />
						<path d="M48 2H40V6H48V2Z" fill="#291F17" />
						<path d="M36 2H28V6H36V2Z" fill="#291F17" />
						<path d="M52 2V6H54V8H58V2H52Z" fill="#291F17" />
						<path d="M58 12H54V20H58V12Z" fill="#291F17" />
						<path d="M58 24H54V30H58V24Z" fill="#291F17" />
						<path
							d="M44.73 37.8672C46.5634 35.4965 47.6968 32.6584 48.0015 29.6749C48.3063 26.6914 47.7702 23.6821 46.4541 20.9885C45.138 18.2949 43.0946 16.0248 40.5558 14.436C38.0171 12.8472 35.0846 12.0033 32.0913 12H31.9312C27.6848 12.0213 23.6207 13.7318 20.6331 16.7552C17.6455 19.7786 15.979 23.8674 16.0002 28.1219C16.0214 32.3764 17.7287 36.4482 20.7463 39.4415C23.764 42.4349 27.8449 44.1046 32.0913 44.0833H32.2514C35.749 44.043 39.1372 42.8563 41.898 40.7046L54.6968 53.5178C55.0797 53.8463 55.5722 54.018 56.0759 53.9985C56.5796 53.979 57.0574 53.7698 57.4138 53.4127C57.7702 53.0556 57.979 52.5768 57.9985 52.0722C58.018 51.5675 57.8466 51.0741 57.5188 50.6905L44.73 37.8672ZM32.2113 40.0729H32.0913C29.7265 40.073 27.4143 39.3734 25.4447 38.0621C23.4751 36.7507 21.9359 34.8859 21.02 32.7015C20.1041 30.5171 19.8523 28.1103 20.2962 25.7831C20.7401 23.4559 21.86 21.3118 23.5154 19.6198C24.6159 18.4869 25.9301 17.5844 27.3817 16.9648C28.8333 16.3452 30.3934 16.0208 31.9712 16.0104H32.0913C35.2761 15.9945 38.3367 17.2467 40.6 19.4917C42.8632 21.7368 44.1436 24.7906 44.1596 27.9815C44.1755 31.1724 42.9256 34.2389 40.6849 36.5065C38.4442 38.7741 35.3961 40.057 32.2113 40.0729Z"
							fill="#291F17"
						/>
						<path
							d="M38 27H33V22C33 21.7348 32.8946 21.4804 32.7071 21.2929C32.5196 21.1054 32.2652 21 32 21C31.7348 21 31.4804 21.1054 31.2929 21.2929C31.1054 21.4804 31 21.7348 31 22V27H26C25.7348 27 25.4804 27.1054 25.2929 27.2929C25.1054 27.4804 25 27.7348 25 28C25 28.2652 25.1054 28.5196 25.2929 28.7071C25.4804 28.8946 25.7348 29 26 29H31V34C31 34.2652 31.1054 34.5196 31.2929 34.7071C31.4804 34.8946 31.7348 35 32 35C32.2652 35 32.5196 34.8946 32.7071 34.7071C32.8946 34.5196 33 34.2652 33 34V29H38C38.2652 29 38.5196 28.8946 38.7071 28.7071C38.8946 28.5196 39 28.2652 39 28C39 27.7348 38.8946 27.4804 38.7071 27.2929C38.5196 27.1054 38.2652 27 38 27Z"
							fill="#291F17"
						/>
					</svg>
				) : (
					<svg
						width="22"
						height="22"
						viewBox="0 0 32 32"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.5 6.00017C14.5 5.73496 14.3947 5.4806 14.2071 5.29307C14.0196 5.10553 13.7652 5.00017 13.5 5.00017H6.00001C5.93522 4.99278 5.8698 4.99278 5.80501 5.00017L5.72001 5.02517L5.61501 5.05517L5.52501 5.10517C5.49731 5.11825 5.47058 5.13329 5.44501 5.15017C5.3344 5.2207 5.24054 5.31456 5.17001 5.42517C5.15319 5.44904 5.13815 5.4741 5.12501 5.50017C5.12501 5.53517 5.09001 5.56517 5.07501 5.60017L5.04501 5.70017C5.03371 5.72755 5.02533 5.75604 5.02001 5.78517C5.00434 5.85572 4.99762 5.92795 5.00001 6.00017V13.5002C5.00001 13.7654 5.10537 14.0197 5.29291 14.2073C5.48044 14.3948 5.7348 14.5002 6.00001 14.5002C6.26523 14.5002 6.51958 14.3948 6.70712 14.2073C6.89466 14.0197 7.00001 13.7654 7.00001 13.5002V8.41517L12.795 14.2052C12.9863 14.369 13.2324 14.4546 13.4841 14.4449C13.7357 14.4352 13.9745 14.3308 14.1526 14.1527C14.3307 13.9746 14.435 13.7359 14.4447 13.4842C14.4544 13.2326 14.3688 12.9865 14.205 12.7952L8.41501 7.00017H13.5C13.7652 7.00017 14.0196 6.89482 14.2071 6.70728C14.3947 6.51974 14.5 6.26539 14.5 6.00017Z"
							fill="black"
						/>
						<path
							d="M26.925 26.3801L26.955 26.2801C26.9663 26.2528 26.9747 26.2243 26.98 26.1951C26.9941 26.1311 27.0008 26.0657 27 26.0001V19.1801C27 18.9149 26.8946 18.6606 26.7071 18.473C26.5196 18.2855 26.2652 18.1801 26 18.1801C25.7348 18.1801 25.4804 18.2855 25.2929 18.473C25.1053 18.6606 25 18.9149 25 19.1801V23.5851L19.205 17.7951C19.0137 17.6313 18.7676 17.5457 18.5159 17.5554C18.2643 17.5652 18.0255 17.6695 17.8474 17.8476C17.6693 18.0257 17.565 18.2644 17.5553 18.5161C17.5456 18.7678 17.6312 19.0138 17.795 19.2051L23.585 25.0001H19.18C18.9148 25.0001 18.6604 25.1055 18.4729 25.293C18.2853 25.4806 18.18 25.7349 18.18 26.0001C18.18 26.2654 18.2853 26.5197 18.4729 26.7073C18.6604 26.8948 18.9148 27.0001 19.18 27.0001H26C26.0648 27.0075 26.1302 27.0075 26.195 27.0001L26.28 26.9751L26.38 26.9451L26.475 26.8951C26.5032 26.8831 26.5301 26.8681 26.555 26.8501C26.6656 26.7796 26.7595 26.6858 26.83 26.5751C26.8471 26.5524 26.8606 26.5271 26.87 26.5001C26.8924 26.4621 26.9108 26.4219 26.925 26.3801V26.3801Z"
							fill="black"
						/>
					</svg>
				)}
			</button>
		</S.Container>
	);
}

export default ControlButton;
