import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { urlLanding } from 'lib/urlLanding';
import { svg } from 'lib/svgList';
import MenuLanding from '../../MenuLanding';
import MenuUser from '../../MenuUser';

function ResponsiveAppBar({ open }) {
	return (
		<AppBar style={styleConfig.container}>
			<Toolbar style={styleConfig.toolbar} disableGutters>
				<Box sx={{ flexGrow: 1 }}>
					<MenuLanding open={open} />
				</Box>
				<Box sx={[{ flexGrow: 1 }, open === false ? styleConfig.logo : null]}>
					<a href={urlLanding}>
						<img
							style={{ width: open === false ? 90 : 120, height: 32 }}
							alt="Logo"
							src={svg.img.logoSomaiMultiColor}
						/>
					</a>
				</Box>
				<MenuUser open={open} />
			</Toolbar>
		</AppBar>
	);
}

const styleConfig = {
	container: {
		display: 'flex',
		background: 'none',
		boxShadow: 'none',
		height: 106,
		justifyContent: 'center',
		position: 'static',
		zIndex: 100
	},
	toolbar: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center'
	},
	logo: {
		position: 'fixed',
		bottom: '48%',
		left: -19,
		transform: 'rotate(-90deg)',
		marginLeft: 0
	}
};
export default ResponsiveAppBar;
