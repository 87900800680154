import React from 'react';

import ListOrgs from './ListOrgs';
import * as S from './styled';

function ListAndEditOrgs({ orgs = [] }) {
	return (
		<S.ContainerIndex>
			<ListOrgs orgs={orgs} />
		</S.ContainerIndex>
	);
}

export default ListAndEditOrgs;
