import React from 'react';
import NavAccordion from 'components/NavAccordion';
import Camadas from 'components/Camadas/Camadas';

export default function Layers() {
	return (
		<NavAccordion title="CAMADAS" isInitiOpen>
			<Camadas />
		</NavAccordion>
	);
}
