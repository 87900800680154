import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerTitleMenu = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 0.75rem;
	cursor: pointer;

	/* background-color: aquamarine; */
`;
export const containerItemAndArrow = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.3rem;
	img {
		width: 15px;
	}

	/* background-color: azure; */
`;
export const ItemMenu = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;

	/* background-color: blue; */

	p {
		text-transform: uppercase;
		/* text-align: justify; */
		/* background-color: yellow; */
		width: 100%;

		/* height: 1.5rem; */
		/* overflow: auto; */
	}
`;
export const lineMenu = styled.div`
	width: 100%;
	height: 0.5px;
	background-color: ${color.interface.brown};
`;
