/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import { useMeasureStore } from 'services/map/measure.context';
import { ShapeStore } from 'services/map/shapes.context';
import styled from 'styled-components';

const Background = styled.div`
	position: absolute;
	width: 9rem;
	z-index: 1300;
	top: 0;
	right: 3rem;
	@media (max-width: 800px) {
		top: 3.5rem;
		right: 15rem;
		width: 4rem;
	}
`;

const ModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #fff;
	color: #000;
	border-radius: 6px;
	padding: 0.2rem;
	border: 2px #d9d5d2 solid;
`;

const ModalContent = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	height: 1.8rem;
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
		height: 9rem;
	}
`;

const Container = styled.div`
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	& + div {
		border-left: 1px solid;
		border-color: #d9d5d2;
	}
	@media (max-width: 800px) {
		& + div {
			border-left: 0;
			border-top: 1px solid;
			border-color: #d9d5d2;
		}
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		width: 100%;
		height: 100%;
		background: none !important;
		cursor: pointer;
		:hover {
			background-color: none;
			height: 100%;
			/* border-radius: 4px; */
		}
		/* 	&.active {
			height: 85%;
			background-color: #e6dcd3;
			border-radius: 4px;
		} */
	}

	input[type='radio'] {
		display: none;
	}
	label {
		svg,
		img:hover {
			cursor: pointer;
		}
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		width: 100%;
		height: 100%;
	}

	img,
	svg {
		width: 20px;
		height: 20px;
	}
`;

function ListModal() {
	const { handleChangeInteractionType, showModalDraw, setShowModalDraw } =
		useContext(ShapeStore);
	const modalRef = useRef();
	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModalDraw(false);
		}
	};

	const { state: stateMeasure, resetInteraction: resetMeasureInteraction } =
		useMeasureStore();

	const handlerPointInteraction = () => {
		if (stateMeasure.drawMeasuresIsActive) {
			resetMeasureInteraction();
		}
		handleChangeInteractionType('Point');
	};
	const handlerLineStringInteraction = () => {
		if (stateMeasure.drawMeasuresIsActive) {
			resetMeasureInteraction();
		}
		handleChangeInteractionType('LineString');
	};
	const handlerPolygonInteraction = () => {
		if (stateMeasure.drawMeasuresIsActive) {
			resetMeasureInteraction();
		}
		handleChangeInteractionType('Polygon');
	};

	return (
		<>
			{showModalDraw ? (
				<Background onClick={closeModal} ref={modalRef}>
					<ModalWrapper showModal={showModalDraw}>
						<ModalContent>
							<Container>
								<button type="button" onClick={handlerPointInteraction}>
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10 5.5C9.10999 5.5 8.23996 5.76392 7.49994 6.25839C6.75992 6.75285 6.18314 7.45566 5.84254 8.27792C5.50195 9.10019 5.41283 10.005 5.58647 10.8779C5.7601 11.7508 6.18869 12.5526 6.81802 13.182C7.44736 13.8113 8.24918 14.2399 9.1221 14.4135C9.99501 14.5872 10.8998 14.4981 11.7221 14.1575C12.5443 13.8169 13.2471 13.2401 13.7416 12.5001C14.2361 11.76 14.5 10.89 14.5 10C14.5 8.80653 14.0259 7.66193 13.182 6.81802C12.3381 5.97411 11.1935 5.5 10 5.5Z"
											fill="#291F17"
										/>
										<path
											d="M10 0.5C8.12108 0.5 6.28435 1.05717 4.72209 2.10104C3.15982 3.14491 1.94218 4.62861 1.22315 6.36451C0.504116 8.10041 0.315985 10.0105 0.682544 11.8534C1.0491 13.6962 1.95389 15.3889 3.28249 16.7175C4.61109 18.0461 6.30383 18.9509 8.14665 19.3175C9.98947 19.684 11.8996 19.4959 13.6355 18.7769C15.3714 18.0578 16.8551 16.8402 17.899 15.2779C18.9428 13.7157 19.5 11.8789 19.5 10C19.5 7.48044 18.4991 5.06408 16.7175 3.28249C14.9359 1.50089 12.5196 0.5 10 0.5V0.5ZM10 17.5C8.51664 17.5 7.0666 17.0601 5.83323 16.236C4.59986 15.4119 3.63857 14.2406 3.07091 12.8701C2.50325 11.4997 2.35473 9.99168 2.64411 8.53682C2.9335 7.08196 3.64781 5.74559 4.6967 4.6967C5.7456 3.64781 7.08197 2.9335 8.53683 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.0709C14.2406 3.63856 15.4119 4.59985 16.236 5.83322C17.0601 7.06659 17.5 8.51664 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5Z"
											fill="#291F17"
										/>
									</svg>
								</button>
							</Container>
							<Container>
								<button type="button" onClick={handlerLineStringInteraction}>
									<svg
										width="23"
										height="23"
										viewBox="0 0 23 23"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1.41421 0.0857865L0 1.5L20.8597 22.3597L22.2739 20.9454L1.41421 0.0857865Z"
											fill="#291F17"
										/>
									</svg>
								</button>
							</Container>
							<Container>
								<button onClick={handlerPolygonInteraction} type="button">
									<svg
										width="28"
										height="24"
										viewBox="0 0 28 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M19.6948 2.5L25.3348 12L19.6948 21.5H8.30476L2.66476 12L8.30476 2.5H19.4998M20.2598 0.5H7.75976C7.5844 0.50018 7.41217 0.546471 7.26034 0.634231C7.10852 0.72199 6.98244 0.848131 6.89476 1L0.644763 11.5C0.55132 11.6555 0.501953 11.8336 0.501953 12.015C0.501953 12.1964 0.55132 12.3745 0.644763 12.53L6.87476 23C6.96244 23.1519 7.08852 23.278 7.24034 23.3658C7.39217 23.4535 7.5644 23.4998 7.73976 23.5H20.2398C20.4151 23.4998 20.5874 23.4535 20.7392 23.3658C20.891 23.278 21.0171 23.1519 21.1048 23L27.3548 12.5C27.4482 12.3445 27.4976 12.1664 27.4976 11.985C27.4976 11.8036 27.4482 11.6255 27.3548 11.47L21.1248 1C21.0371 0.848131 20.911 0.72199 20.7592 0.634231C20.6074 0.546471 20.4351 0.50018 20.2598 0.5Z"
											fill="#291F17"
										/>
									</svg>
								</button>
							</Container>
						</ModalContent>
					</ModalWrapper>
				</Background>
			) : null}
		</>
	);
}

export default ListModal;
