import { isRouteRef } from '../typesActions/types';

export default function isRouteRefReducer(isRoute = '', action) {
	switch (action.type) {
		case isRouteRef.SELECTED_ROUTE:
			return (isRoute = action.payload);
		default:
			return isRoute;
	}
}
