import React from 'react';

import * as S from './CustomInput.Styled';

function InputRegister({
	value,
	type,
	name,
	placeholder = 'Digite aqui',
	setValue = () => {},
	required = false,
	isError = false,
	disabled = false,
	autoComplete = '',
	styled = {}
}) {
	// const error = isError ? color.interface.red : 'none';
	return (
		<>
			<S.BoxInput props={{ disabled, value, isError }} style={styled}>
				<S.Input
					value={value}
					type={type}
					name={name}
					placeholder={placeholder}
					required={required}
					onChange={(e) => setValue(e.target.value)}
					disabled={disabled}
					autoComplete={autoComplete}
				/>
			</S.BoxInput>

			{!value && isError ? (
				<S.ContainerDivError>
					<span>Campo obrigatório</span>
				</S.ContainerDivError>
			) : null}
		</>
	);
}

export default InputRegister;
