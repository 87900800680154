import { nabBar } from '../typesActions/types';

export default function isNavBarOpenReducer(state = true, action) {
	switch (action.type) {
		case nabBar.OPEN_OR_CLOSE:
			return (state = action.status);

		default:
			return state;
	}
}
