import { apiClimateType } from '../typesActions/types';

const INITIAL_STATE = {
    climate: [],
    isLoading: true,
    error: false,
    message: ''
};

export default function climateReducer(state = INITIAL_STATE, action) {
    const { error } = action?.payload || { erros: {} };

    switch (action.type) {
        case apiClimateType.CLIMATE_REC:
            return {
                isLoading: true,
                error: false,
                ...state
            };
        case apiClimateType.CLIMATE_REC_SUCCESS:
            return {
                climate: action.payload,
                isLoading: false,
                error: false,
                message: 'Sucesso ao pegar os dados do clima'
            };
        case apiClimateType.CLIMATE_REC_ERROR:
            return {
                climate: action.payload,
                isLoading: false,
                error: true,
                message: 'Algum erro aconteceu, tente novamente mais tarde'
            };
        default:
            return state
    };
};