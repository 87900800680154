import styled from 'styled-components';

export const container = styled.div`
	margin-top: 1rem;
	min-height: 370px;

	table {
		@media (max-width: 800px) {
			display: inline-block;
			overflow: auto;
			overflow: scroll;
			height: 38vh;
		}
	}
`;

export const StyledTable = styled.table`
	border-collapse: collapse;
	width: 100%;
	@media (max-width: 800px) {
		display: inline-block;
		overflow: auto;
	}
	th {
		display: table-cell;
		border-bottom: 1px solid #d9d5d2;
		padding: 7px;
		text-align: top;
		text-align: initial;

		font-family: 'Nunito';
		font-style: normal;
		font-weight: 800;
		font-size: 0.75rem;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: #006946;
	}

	td {
		border-bottom: 1px solid #d9d5d2;
		padding: 7px;
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 500;
		font-size: 0.688rem;
		letter-spacing: 0.02em;
	}

	tfoot td {
		text-align: center;
		vertical-align: middle;
	}

	.light-style {
		font-weight: 500;
		color: #291f17;
		letter-spacing: 0.02em;
	}

	.light-bold {
		font-weight: 800;
		color: #69625d;
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}
`;
