import styled from 'styled-components';
import { color } from 'styles';

export const BoxTextArea = styled.div`
	box-sizing: border-box;
	width: 100%;
	border-radius: 6px;
	background: #f9f9f9;
	border: 1px solid #ebebeb;
	border-radius: 30px;
	background: #f9f9f9;

	:hover {
		border: none;
		border: 1px solid ${color.interface.orange};
	}

	input[type='date'] {
		font-weight: 400 !important;
		text-transform: uppercase;
	}
`;

export const Icon = styled.div`
	color: ${color.interface.darkGray};
	margin-right: 8px;
`;

export const TextArea = styled.textarea`
	width: 100% !important;
	max-width: 100% !important;

	border: none;
	padding: 15px;
	background: transparent;
	color: ${color.interface.darkGray};
	font-size: 0.875rem;
	font-weight: 500;

	:focus {
		outline: none;
		border: none;
	}
	::placeholder {
		font-weight: 400;
	}
`;
