import styled from 'styled-components';
import { color } from 'styles';

export const StyledDrawer = styled.nav`
	display: initial;
	flex-direction: grid;
	justify-content: center;
	background: ${color.interface.beige};
	width: ${({ props }) => (props.open ? '45vw' : '53px')};
	max-width: 400px;
	padding-top: 2px;
	height: 100vh;
	flex: 1 0 auto;
	z-index: 1;
	position: fixed;
	outline: 0px;
	top: 0;
	left: 0;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: none;
`;

export const ButtonArrow = styled.button`
	background-color: ${color.interface.orange};
	color: white;
	height: 45px;
	width: 34px;
	position: absolute;
	right: -34px;
	top: 138px;
	border-radius: 0px 5px 5px 0px;
	box-shadow: -1px 0px 1px 0px rgb(0 0 0 / 20%);
	padding-top: 5px;

	img {
		width: 18px;
		transform: ${({ open }) => (open ? `rotate(180deg)` : `rotate(0deg)`)};
	}

	:hover {
		background-color: ${color.auxiliary.coral};
		cursor: pointer;
	}
`;
