import React from 'react';
import styled from 'styled-components';
import AttributionWebContainer from '../Attribution/web';
import ScaleContainer from '../Scale';
import CoordinateContainer from '../Coordinate';

export const ContainerWeb = styled.div`
	position: absolute;
	z-index: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	right: 1rem;
	bottom: 0;
	border: 0;
	height: 28px;
	@media (max-width: 800px) {
		display: none;
	}
`;

const ScaleCoordinateWrapper = styled.div`
	display: flex;
	align-items: center;
	background: rgba(248, 245, 243, 0.9);
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	height: 100%;
	border-radius: 5px 5px 0 0;
	padding: 1rem;
	@media (max-width: 800px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
`;

function MapInfo({ mapSomai }) {
	return (
		<ContainerWeb>
			<AttributionWebContainer />
			<ScaleCoordinateWrapper>
				<CoordinateContainer mapSomai={mapSomai} />
				<ScaleContainer />
			</ScaleCoordinateWrapper>
		</ContainerWeb>
	);
}

export default MapInfo;
