import styled from 'styled-components';
// import { color } from 'styles/Theme';

export const Container = styled.div`
	width: 35px;
	height: 35px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	& + div {
		border-top: 1px solid;
		border-color: #d9d5d2;
	}
	@media (max-width: 800px) {
		position: static !important;
		border-right: 1px solid;
		border-color: #d9d5d2;
	}
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		width: 100%;
		height: 100%;
		background: none;
		cursor: pointer;
		:hover {
			text-decoration: none;
			background-color: #e6dcd3;
			height: 85%;
			border-radius: 4px;
		}
		&.active {
			height: 85%;
			background-color: #e6dcd3;
			border-radius: 4px;
		}
	}

	img {
		width: 20px;
		height: 20px;
	}
`;
