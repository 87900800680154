import styled from 'styled-components';
import { color } from 'styles/Theme';

export const dataTerritory = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 5px;
	background-color: ${color.interface.whiteAbsolute};
	gap: 0.8rem;
	padding-bottom: 1rem;
	margin-top: 0.5rem;
`;

export const titleDataTerritory = styled.p`
	width: 100%;
	padding: 0.5rem 0.5rem 0.2rem 1rem;
	background-color: ${color.interface.beige2};

	font-family: 'Nunito';
	font-style: normal;
	font-weight: 900;
	font-size: 10px;

	letter-spacing: 0.1em;
	text-transform: uppercase;

	color: #291f17;
`;

export const title = styled.b`
	width: 100%;
`;

export const selectAnyState = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0rem;
`;

export const itemDataTerritory = styled.div`
	box-sizing: border-box;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.9rem;
	padding: 8px 0px 0px 15px;

	img {
		width: 15px;
		max-height: 15px;
	}
`;

export const dataAndLine = styled.div`
	width: 100%;
	position: relative;

	div {
		position: absolute;
		bottom: -5px;
		height: 1.4px;
		width: 100%;
		background-color: ${color.interface.beige2};
	}

	p {
		font-family: 'Nunito';
		font-weight: 300;
		font-size: 0.855rem;
		color: #69625d;
	}
`;

export const errorMessage = styled.p`
	color: ${color.interface.red};
`;

export const containerTitle = styled.div`
	width: 100%;
	height: 40px;
	background-color: ${color.interface.beige2};
	padding: 0.8rem;
	margin-top: 0.5rem;

	border-radius: 10px 10px 0px 0px;

	p {
		color: ${color.interface.orange};
		font-weight: 800;
	}
`;

export const containerList = styled.div`
	width: 100%;
	background-color: ${color.interface.beige3};

	ul {
		width: 100%;
		padding: 0.8rem;
		background-color: ${color.interface.whiteAbsolute};
		font-weight: 400;
		color: ${color.interface.gray};
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 1rem;

		border-radius: 0px 0px 10px 10px;
	}

	li {
		list-style-type: none;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 0.5rem;
	}

	img {
		width: 20px;
		padding: 2px;
	}

	div {
		height: 10px;
		background-color: ${color.interface.darkGreen};
	}
`;

export const containerTotal = styled.div`
	width: 100%;
`;

export const notice = styled.p`
	padding: 10px 0px 10px 0px;
	font-size: 13px;
	color: ${color.interface.red};
`;

export const boxListTis = styled.section`
	width: 100%;
	margin-top: 1.1rem;
	padding-left: 0.8rem;
`;
