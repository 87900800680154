import React from 'react';
import { useSelector } from 'react-redux';
import ThreatsDonoutGraph from '../Graphs/ThreatsGraph/ThreatsDonoutGraph';
import LegendMap from './LegendMap';
import List10 from './List10';
import * as S from './styled';

export default function DeforestationData() {
	const { burned, isLoading, error } = useSelector((state) => state.threatsTis);

	// ordena os dados da TI que mais queimou para a que menos queimou
	burned.sort((a, b) => b.percentage_burned - a.percentage_burned);

	// soma área queimada de todas a tis
	const totalBurned = burned?.reduce((ac, d) => ac + d.burned, 0);

	// soma área que não queimaram de todas a tis
	const totalUnburned = burned?.reduce((ac, d) => ac + d.unburned, 0);

	// pega os dez primeiros e ronomeia "burned" para "data" e "percentage_burned" para "percentage"
	const topTen = burned
		.slice(0, 10)
		.map(({ burned: b, percentage_burned: pb, ...rest }) => ({
			...rest,
			data: b,
			percentage: pb
		}));

	return (
		<S.container>
			<LegendMap
				labels={[
					'Queimou mais que a média de todas as TIs',
					'Queimou menos que a média de todas as TIs',
					'Queimou menos de 5% da média de todas as TIs'
				]}
			/>
			<S.line />
			<ThreatsDonoutGraph
				isLoading={isLoading}
				error={error}
				labels={['Não queimou', 'Queimou']}
				data={[totalUnburned, totalBurned]}
				title="Área queimada e não queimada nas TIs da Amazônia Legal"
				source="Mapbiomas"
			/>
			<S.line />
			<List10 title="10 Terras Indígenas que mais queimaram" topTen={topTen} />
		</S.container>
	);
}
