/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useEffect, memo, useRef } from 'react';
import Map from 'ol/Map';
import 'ol/ol.css';
import OlView from 'ol/View';
import { transform } from 'ol/proj';
import ModalMultiFilter from 'components/MultiFilter/SeeAll/ModalMultiFilter';
import { useDispatch } from 'react-redux';
import { stateMap } from 'store/actions/mapLoadedAction';
import { defaults as defaultControls } from 'ol/control';
import { mapCenter, mapZoom } from './ControlMap';
import './Styles/css/styleMap.css';
import SideBar from './SideBar';
import TooltipGlobal from './Tooltips/TooltipGlobal';
import TooltipMapServer from './Tooltips/TooltipMapServer';
import TooltipThreats from './Tooltips/TooltipThreats';
import MessageUsers from './Tooltips/MessageUsers';
import ControlMonitoring from './ControlMonitoring';
import MessageMonitoring from './Tooltips/MessageMonitoring';
import LayerError from './Tooltips/LayerError';
import LoadingLayer from './LoadingLayer';
import BaseMaps from './BaseMaps';
import FilterDataUsersAci from './FilterDataUsersAci';

function OpenLayer() {
	const dispatch = useDispatch();
	const mapRef = useRef();

	// Monta o mapa
	const mapSomai = new Map({
		controls: defaultControls({
			zoom: false,
			attribution: false
			/* attributionOptions: {  } */
		}).extend([
			/* attribution */
			/* , scaleControl */
		]),
		layers: [],
		target: null,
		view: new OlView({
			center: transform(mapCenter, 'EPSG:4326', 'EPSG:3857'),
			zoom: mapZoom,
			maxZoom: 19
		})
	});

	useEffect(() => {
		window.onresize = () => {
			setTimeout(() => {
				mapSomai.updateSize();
			}, 3000);
		};
	}, []);

	useEffect(() => {
		mapSomai.setTarget('mapSomai');
		document.getElementById('mapSomai').data = mapSomai;
	}, []);
	useEffect(() => {
		mapSomai.setTarget('mapSomai');
		document.getElementById('mapSomai').data = mapSomai;
	}, []);

	// informa ao reducer que o mapa foi carregado
	mapSomai.once('postrender', () => {
		dispatch(stateMap());
	});

	return (
		<div ref={mapRef} id="mapSomai" className="map">
			<SideBar mapSomai={mapSomai} />
			<TooltipGlobal mapSomai={mapSomai} />
			<TooltipMapServer mapSomai={mapSomai} />
			<TooltipThreats mapSomai={mapSomai} />
			<ControlMonitoring />
			<MessageMonitoring />
			<ModalMultiFilter />
			<MessageUsers />
			<LoadingLayer />
			<LayerError />
			<FilterDataUsersAci />
			<BaseMaps mapSomai={mapSomai} />
		</div>
	);
}

export default memo(OpenLayer);
