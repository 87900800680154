import { monitoringDataType } from '../typesActions/types';
import { filterDeleted } from 'helpers/utils';
const INITIAL_STATE = {
	monitoraingFormActivated: false,
	dataMonitoring: [],
	copyDataMonitoring: [],
	isLoading: false,
	success: false,
	loadingDestroy: false,
	error: false,
	message: '',
	messageDestroy: '',
	routeMonitoring: '',

	loadingApprove: false,
	messageApprove: '',

	loadingSave: false,
	messageSave: '',

	loadingUpdate: false,
	messageUpdate: '',

	openIsRegister: false,
	messageIsRegister: '',

	filterData: {
		tis: [],
		type: [],
		date: []
	},

	loadingDownload: false,
	messageDownload: ''
};

export default function monitoringDataReducer(state = INITIAL_STATE, action) {
	const { errors, type, data } = action?.payload || { erros: {} };

	if (data && (type === 'where_i_been' || type === 'tracks')) {
		data.type = type;
	}

	if (data && data.id) {
		data.isChecked = true;
	}

	switch (action.type) {
		case monitoringDataType.MONITORING_FORM_ACTIVATED:
			return {
				...state,
				monitoraingFormActivated: action.payload
			};
		case monitoringDataType.MONITORING_REQ:
			return {
				...state,
				dataMonitoring: [],
				isLoading: true,
				error: false,
				message: 'Buscando dados...'
			};
		case monitoringDataType.MONITORING_CLEAR_DATA:
			return {
				...state,
				dataMonitoring: [],
				copyDataMonitoring: [],
				message: 'Dados limpos...'
			};
		case monitoringDataType.MONITORING_REC_SUCCESS:
			return {
				...state,
				dataMonitoring: action.payload,
				copyDataMonitoring: action.payload,
				isLoading: false,
				error: false,
				message: 'Sucesso ao pegar os dados de monitoramento'
			};

		case monitoringDataType.MONITORING_UPDATE:
			return {
				...state,
				dataMonitoring: action.payload,
				message: 'Sucesso ao atualizar os dados de monitoramento'
			};

		case monitoringDataType.MONITORING_REC_ERROR:
			return {
				...state,
				dataMonitoring: action.payload || [],
				copyDataMonitoring: action.payload || [],
				isLoading: false,
				error: true,
				message: 'Algum erro aconteceu, tente novamente mais tarde'
			};

		// Realiza as ações para o post

		case monitoringDataType.SAVE_REGISTER_REQUEST:
			return {
				...state,
				loadingSave: true
			};
		case monitoringDataType.SAVE_REGISTER_SUCCESS:
			return {
				...state,
				loadingSave: false,
				success: true,
				messageSave: action.payload.message,
				dataMonitoring: [...state.dataMonitoring, data],
				copyDataMonitoring: [...state.copyDataMonitoring, data]
			};
		case monitoringDataType.SAVE_REGISTER_RESET:
			return {
				...state,
				success: false
			};
		case monitoringDataType.SAVE_REGISTER_FAILURE:
			return {
				...state,
				loadingSave: false,
				messageSave: errors.message
			};

		// Realiza as ações para o update

		case monitoringDataType.EDIT_REGISTER_REQUEST:
			return {
				...state,
				loadingUpdate: true
			};

		case monitoringDataType.EDIT_REGISTER_SUCCESS:
			state.dataMonitoring.forEach((g, i) => {
				if (g.id === data.id) {
					state.dataMonitoring[i] = data;
				}
			});

			state.copyDataMonitoring.forEach((g, i) => {
				if (g.id === data.id) {
					state.copyDataMonitoring[i] = data;
				}
			});

			return {
				...state,
				loadingUpdate: false,
				messageUpdate: action.payload.message,
				dataMonitoring: [...state.dataMonitoring],
				copyDataMonitoring: [...state.copyDataMonitoring]
			};

		case monitoringDataType.EDIT_REGISTER_FAILURE:
			return {
				...state,
				loadingUpdate: false,
				messageUpdate: errors.message
			};

		case monitoringDataType.APPROVE_ITEM_REQUEST:
			return {
				...state,
				loadingApprove: true
			};
		case monitoringDataType.APPROVE_ITEM_SUCCESS:
			const { dataMonitoring } = state;

			dataMonitoring.forEach((g, i) => {
				if (g.id === action?.payload?.register?.id) {
					// Substitui apenas itens modificado
					dataMonitoring[i].status = action.payload.register.status;
				}
			});

			return {
				...state,
				loadingApprove: false,
				messageApprove: action.payload.message
			};
		case monitoringDataType.APPROVE_ITEM_FAILURE:
			return {
				...state,
				loadingApprove: false,
				messageApprove: errors.message
			};

		// Apaga um item da lista

		case monitoringDataType.REMOVE_REGISTER_REQUEST:
			return {
				...state,
				loadingDestroy: true
			};
		case monitoringDataType.REMOVE_REGISTER_SUCCESS:
			const { hashis } = action.payload;
			return {
				...state,
				loadingDestroy: false,
				dataMonitoring: filterDeleted(state.dataMonitoring, hashis),
				copyDataMonitoring: filterDeleted(state.copyDataMonitoring, hashis),
				messageDestroy: action.payload.message
			};
		case monitoringDataType.REMOVE_REGISTER_FAILURE:
			return {
				...state,
				loadingDestroy: false,
				messageDestroy: errors.message
			};

		case monitoringDataType.ADD_ROUTE_DESTROY:
			return {
				...state,
				routeMonitoring: action.payload
			};

		case monitoringDataType.OPEN_IS_REGISTER:
			return {
				...state,
				openIsRegister: action.payload,
				messageIsRegister: action.payload
					? 'Clique no mapa para pegar a localização'
					: ''
			};

		//filtros do optionsMonitoring

		case monitoringDataType.ADD_FILTER_TI_MONITORING:
			return {
				...state,
				filterData: {
					...state.filterData,
					tis: action.payload
				}
			};

		case monitoringDataType.REMOVE_FILTER_TI_MONITORING:
			return {
				...state,
				filterData: {
					...state.filterData,
					tis: []
				}
			};

		case monitoringDataType.ADD_FILTER_TYPE_MONITORING:
			return {
				...state,
				filterData: {
					...state.filterData,
					type: action.payload
				}
			};

		case monitoringDataType.REMOVE_FILTER_TYPE_MONITORING:
			return {
				...state,
				filterData: {
					...state.filterData,
					type: []
				}
			};

		case monitoringDataType.ADD_FILTER_DATE_MONITORING:
			return {
				...state,
				filterData: {
					...state.filterData,
					date: action.payload
				}
			};

		case monitoringDataType.REMOVE_FILTER_DATE_MONITORING:
			return {
				...state,
				filterData: {
					...state.filterData,
					date: []
				}
			};

		// Realiza as ações para o DOWNLOAD

		case monitoringDataType.DOWNLOAD_ITEM_REQUEST:
			return {
				...state,
				loadingDownload: true
			};
		case monitoringDataType.DOWNLOAD_ITEM_SUCCESS:
			return {
				...state,
				loadingDownload: false,
				messageDownload: action.payload.message
			};
		case monitoringDataType.DOWNLOAD_ITEM_FAILURE:
			return {
				...state,
				loadingDownload: false,
				messageDownload: action.payload.message
			};

		case monitoringDataType.CLOSE_ERROR_DESTROY:
			return {
				...state,
				isLoading: false,
				loadingDownload: false,
				messageDownload: '',
				loadingDestroy: false,
				error: false,
				message: '',
				messageDestroy: '',
				loadingApprove: false,
				messageApprove: '',
				loadingSave: false,
				messageSave: '',
				loadingUpdate: false,
				messageUpdate: '',
				messageIsRegister: ''
			};
		default:
			return state;
	}
}
