import React, { memo } from 'react';
import * as S from '../../LegendAndAdjustments.style';

function LegendDeforestation({ layer }) {
	const label = layer.get('label');

	return (
		<S.LegendVectorItem>
			<S.LegendDeforestation />
			<S.LegendVectorName>{label}</S.LegendVectorName>
		</S.LegendVectorItem>
	);
}

export default memo(LegendDeforestation);
