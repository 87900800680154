import React from 'react';
import { svg } from 'lib/svgList';
import EditAlertTradicionalTrackEndWhereIBeen from 'components/EditAlertTradicionalTrackEndWhereIBeen';
import { useDispatch, useSelector } from 'react-redux';
import { finishEdit } from 'store/actions/editDataUserOnMapAction';
import { clearVector, onLoadMonitoring } from 'store/actions/mapActions';
// import { zoomToExtentMap } from 'components/Map/ControlMap';
import * as S from './styled';

export default function ItemEditFromMap({ type = 'Alerta' }) {
	const { dataUser } = useSelector((state) => state.dataUserToShowOnMap);

	const dispatch = useDispatch();

	function closeModalEdit() {
		dispatch(finishEdit());
		// clear limpa todos os alertas do mapa
		clearVector('monitoring');
		// zoomToExtentMap();
		setTimeout(() => {
			// carrega todos os pontos do mapa novamente
			dispatch(onLoadMonitoring(dataUser));
		}, 500);
	}

	function returnTextTitle(typeReq) {
		switch (typeReq) {
			case 'alert':
				return 'Alerta';
			case 'traditionalUse':
				return 'Uso tradicional';
			case 'tracker':
				return 'Trajeto';
			case 'whereIBeen':
				return 'Onde estive';
			default:
				return '';
		}
	}

	return (
		<S.containerEditItemFromMap>
			<S.headerEdit>
				<h5>Edição de {returnTextTitle(type)}</h5>
				<button type="button" onClick={closeModalEdit}>
					<img src={svg.icon.closeOrange} alt="Fechar" />
				</button>
			</S.headerEdit>

			<EditAlertTradicionalTrackEndWhereIBeen type={type} />
		</S.containerEditItemFromMap>
	);
}
