import styled from 'styled-components';
import { color } from 'styles/Theme';

export const ContainerUncheck = styled.div`
	min-width: 50px;
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CircleUncheck = styled.div`
	position: relative;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	border: 3px solid ${color.interface.red};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const leg1X = styled.div`
	position: absolute;
	margin: 0 !important;
	width: 25px;
	height: 2px;
	background-color: ${color.interface.red};
	transform: rotate(45deg);

	animation: grow1 ease 1s;

	@keyframes grow1 {
		0% {
			width: 1px;
		}

		100% {
			width: 25px;
		}
	}
`;

export const leg2X = styled.div`
	position: absolute;
	margin: 0 !important;
	width: 25px;
	height: 2px;
	background-color: ${color.interface.red};
	transform: rotate(-45deg);

	animation: grow1 ease 1s;

	@keyframes grow1 {
		0% {
			width: 1px;
		}

		100% {
			width: 25px;
		}
	}
`;
