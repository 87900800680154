/* eslint-disable no-unused-vars */
import React from 'react';
import { svg } from 'lib/svgList';
import { stateViewLegend } from 'store/actions/systemActions';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './ControlButtons.style';

function ControlButtons({
	expandedLegend = false,
	setExpandedLegend = () => {}
}) {
	const { statusLegend } = useSelector((state) => state.system);

	const dispatch = useDispatch();

	const HandleControl = () => {
		dispatch(stateViewLegend(!statusLegend));
	};

	return (
		<S.Container>
			<button type="button" onClick={HandleControl}>
				<img style={styles.btnExpand} src={svg.icon.fechar} alt="Expandir" />
			</button>

			{!expandedLegend && (
				<button type="button" onClick={() => setExpandedLegend(true)}>
					<img
						style={styles.btnExpand}
						src={svg.icon.expandir}
						alt="Expandir"
					/>
				</button>
			)}
			{/* icon para sair do modo expandido */}
			{expandedLegend && (
				<button type="button" onClick={() => setExpandedLegend(false)}>
					<img
						style={styles.btnExpand}
						src={svg.icon.recolher}
						alt="Expandir"
					/>
				</button>
			)}
		</S.Container>
	);
}

const styles = {
	btnExpand: { height: 20 }
};

export default ControlButtons;
