import { listTisType } from '../typesActions/types';

const INITIAL_STATE = {
	tisList: [],
	isLoading: false,
	error: false,
	message: ''
};

export default function listTisReducer(state = INITIAL_STATE, action) {
	const { errors } = action?.payload || { erros: {} };

	switch (action.type) {
		case listTisType.LIST_TIS_REC:
			return {
				isLoading: true,
				error: false,
				...state
			};
		case listTisType.LIST_TIS_REC_SUCCESS:
			return {
				tisList: action.payload,
				isLoading: false,
				error: false,
				message: 'Sucesso ao pegar a lista de TIs'
			};
		case listTisType.LIST_TIS_REC_ERROR:
			return {
				tisList: action.payload,
				isLoading: false,
				error: true,
				message: 'Algum erro aconteceu, tente novamente mais tarde'
			};
		default:
			return state;
	}
}
