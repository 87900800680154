import React from 'react';
import { svg } from 'lib/svgList';
import ItensMenuModal from 'components/ItensMenuModal';
import ItensMenu from 'components/ItensMenu';
import { getUserLocalStorage } from 'services/session';
import TitleTab from 'components/TitleTab';
import { filterUsers } from 'helpers/utils';
import ListAndEditOrgs from 'components/ListAndEditOrgs';
import { useSelector } from 'react-redux';
import ListAndEditUsers from 'components/ListAndEditUsers';
import * as S from './styled';

export default function Management() {
	const session = getUserLocalStorage();
	const roleManager = session?.user?.user_role?.role || '';

	const { loadingListUsers, listUsers } = useSelector((state) => state.user);
	const { listOrg } = useSelector((state) => state.organizations);

	const users = filterUsers(roleManager, listUsers) || [];

	return (
		<S.container>
			<S.lineGray />
			<S.containerList>
				<TitleTab title="Gestão" />
				{users && users.length > 0 ? (
					<ItensMenu
						title="Gestão de usuários"
						subTitle="Lista de usuários"
						icon={svg.icon.userOrange}
						content={<ListAndEditUsers users={users} />}
					/>
				) : (
					<S.noticeEmptyUser>
						{loadingListUsers
							? 'Aguarde...'
							: 'Não há nenhum usuário gerenciável.'}
					</S.noticeEmptyUser>
				)}

				{roleManager === 'admin' && listOrg && listOrg.length > 0 ? (
					<ItensMenuModal
						title="Gestão de organizações"
						subTitle="Organizações "
						icon={svg.icon.usersOrange}
						content={<ListAndEditOrgs orgs={listOrg} />}
					/>
				) : null}
			</S.containerList>
		</S.container>
	);
}
