import React from 'react';
import * as S from './styled';

function Button({
	text = 'Button',
	onClick = () => {},
	style = null,
	icon = '',
	widthIcon = 20,
	isDisable = false,
	isHover = true
}) {
	return isDisable ? (
		<S.ContainerButtonDisable isHover={isHover}>
			<button disabled={isDisable} type="button">
				{text}
				{icon !== '' ? (
					<S.iconImg widthIcon={widthIcon} src={icon} alt="icone ilustrativo" />
				) : null}
			</button>
		</S.ContainerButtonDisable>
	) : (
		<S.ContainerButton isHover={isHover}>
			<button
				onClick={() => onClick()}
				type="button"
				style={style}
				disabled={isDisable}
			>
				{text}
				{icon !== '' ? (
					<S.iconImg widthIcon={widthIcon} src={icon} alt="icone ilustrativo" />
				) : null}
			</button>
		</S.ContainerButton>
	);
}

export default Button;
