import styled from 'styled-components';

export const containerOption = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1.5rem;
`;

export const containerAddData = styled.div`
	width: 100%;
`;
