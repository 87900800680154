import React, { useState } from 'react';
import Modal from 'components/Modal';
import PackReq from 'components/PackReq';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/Buttons/Button/Button';
import { ajustZoomExtent, actionsUsers } from 'helpers/utils';
import { generatePdf } from 'store/actions/statesAmazonLegalAction';
import { textAdviceState } from '../text';

import * as S from './styled';

export default function ModalReportAllTisAmazon({
	stateSelected = {},
	isOpenModal = false,
	setIsOpenModal = () => {}
}) {
	const dispatch = useDispatch();

	const { isReqPdf, isReqPdfError } = useSelector((state) => state.amazonLegal);

	const [showPackReq, setShowPackReq] = useState(false);

	const handleGAReport = ({ label }) => {
		actionsUsers({
			category: `plataforma/tabAmazon/relatorios`,
			action: 'Relatório do Estado selecionado',
			label
		});
	};

	return (
		<Modal isOpen={isOpenModal} setIsOpen={setIsOpenModal} useBtnEnd={false} isClose={() => setShowPackReq(false)}>
			<S.containerModal>
				{showPackReq ? (
					<PackReq
						isReq={isReqPdf}
						errorReq={isReqPdfError}
						functionBtn={() => {
							setShowPackReq(false);
							setIsOpenModal(false);
						}}
					/>
				) : (
					<>
						{textAdviceState()}
						<Button
							style={{ marginTop: '0.5rem' }}
							text="Gerar relatório"
							onClick={() => {
								ajustZoomExtent('tabAmazon');
								setShowPackReq(true);
								handleGAReport({ label: stateSelected.label });
								dispatch(generatePdf(stateSelected.value));
							}}
						/>
					</>
				)}
			</S.containerModal>
		</Modal>
	);
}
