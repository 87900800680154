import styled from 'styled-components';
import { color } from 'styles/Theme';

const Container = styled.div`
	display: flex;
	grid-gap: 4px;

	> button {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${color.interface.white};
		border-radius: 50%;
		width: 20px;
		cursor: pointer;
	}
`;

export { Container };
