import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerAccordionText = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 0.2rem;
	padding-bottom: 0.5rem;

	h4 {
		font-weight: 700;
		color: ${color.interface.darkGray};
		text-transform: uppercase;
		font-size: 13px;
	}

	p {
		width: 100%;
		font-weight: 200;
		color: ${color.interface.darkGray};

		text-align: left;
		font-size: 11px;
	}
`;

export const boxGraph = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const boxLayers = styled.div`
	width: 100%;
`;
export const container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding-top: 0.5rem;
`;
