import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerAddItem = styled.div`
	position: relative;
	width: max-content;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	padding: 0.4rem 0rem 0.4rem 0.5rem;
	border: 1px solid ${color.interface.orange};
	border-radius: 10px;
	cursor: pointer;

	/* background-color: red; */

	img {
		position: absolute;
		width: 40px;
		height: 40px;
		right: 0;
		transform: translateX(25%);
	}
`;

export const addText = styled.p`
	font-weight: 400;
	color: ${color.interface.orange};
	font-weight: 500;
	margin-right: 50px;
`;
