import { statesAmazonLegal } from '../typesActions/types';

const initialState = {
	statesAmazonLegal: [],
	selectedStateAmazonLegal: {},
	dataAboutStateSelected: {},
	dataAboutTiSelected: {},
	isReq: false,
	isReqDataState: false,
	isErrorDataState: false,
	message: '',
	messageState: '',
	isReqPdf: false,
	isReqPdfError: false,
	messagePdf: '',
	isOpenModalTi: false
};

export default function isLoggedReducer(state = initialState, action) {
	switch (action.type) {
		case statesAmazonLegal.GET_STATES_AMAZON_LEGAL:
			return {
				...state,
				isReq: true,
				message: 'Buscando por estados'
			};

		case statesAmazonLegal.SUCCESS_GET_STATES_AMAZON_LEGAL:
			return {
				...state,
				statesAmazonLegal: action.payload,
				isReq: false,
				message: ''
			};

		case statesAmazonLegal.DELETE_STATES_AMAZON_LEGAL:
			return {
				...state,
				statesAmazonLegal: []
			};

		case statesAmazonLegal.FAILURE_GET_STATES_AMAZON_LEGAL:
			return {
				...state,
				statesAmazonLegal: [],
				isReq: false,
				message: 'Erro ao buscar por dados, tente novamente mais tarde'
			};

		case statesAmazonLegal.SELECT_STATE_AMAZON_LEGAL:
			return {
				...state,
				selectedStateAmazonLegal: action.payload
			};

		case statesAmazonLegal.DELETE_SELECT_STATE_AMAZON_LEGAL:
			return {
				...state,
				selectedStateAmazonLegal: {}
			};

		case statesAmazonLegal.REQ_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL:
			return {
				...state,
				isReqDataState: true,
				isErrorDataState: false,
				messageState: 'Buscando dados sobre o estado selecionado.'
			};

		case statesAmazonLegal.FAIL_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL:
			return {
				...state,
				isReqDataState: false,
				isErrorDataState: true,
				messageState: 'Ocorreu algum erro ao buscar os dados sobre o estado selecionado. Tente novamente mais tarde.'
			};

		case statesAmazonLegal.SUCCESS_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL:
			return {
				...state,
				dataAboutStateSelected: { ...state.dataAboutStateSelected, ...action.payload },
				isReqDataState: false,
				isErrorDataState: false,
				messageState: 'Dados sobre o estado, baixados com sucesso.'
			};

		case statesAmazonLegal.DELETE_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL:
			return {
				...state,
				isReqDataState: false,
				isErrorDataState: false,
				messageState: 'Dados limpos.'
			};

		case statesAmazonLegal.REQ_PDF:
			return {
				...state,
				isReqPdf: true,
				isReqPdfError: false,
				messagePdf: 'Buscando PDF...'
			};

		case statesAmazonLegal.SUCCESS_PDF:
			return {
				...state,
				isReqPdf: false,
				isReqPdfError: false,
				messagePdf: 'PDF gerado com sucesso.'
			};

		case statesAmazonLegal.ERROR_PDF:
			return {
				...state,
				isReqPdf: false,
				isReqPdfError: true,
				messagePdf: 'Erro ao gerar o PDF, tente novamente mais tarde.'
			};

		case statesAmazonLegal.TOGGLE_MODAL_TI_AMAZON_LEGAL:
			return {
				...state,
				isOpenModalTi: !state.isOpenModalTi,
				dataAboutTiSelected: action.payload || {}
			};

		default:
			return state;
	}
}
