/* eslint-disable no-nested-ternary */
import React from 'react';
import * as S from './styled';
import { color } from '../../../styles/Theme';
import { svg } from '../../../lib/svgList';

export default function Check({
	isChecked = false,
	isError = false,
	defaultCheckColor = true,
	setIsChecked = () => {},
	id = Math.random() * 1000,
	onClick = () => {},
	text,
	widthCheck = '18px',
	inputType = 'checkbox'
}) {
	return (
		<S.Container onClick={onClick}>
			<S.containerCheck
				onClick={() => setIsChecked(!isChecked)}
				isError={isError}
				widthCheck={widthCheck}
				defaultCheckColor={defaultCheckColor}
				inputType={inputType}
			>
				<input type={inputType} id={id} />
				<span
					htmlFor={id}
					style={
						isChecked && defaultCheckColor
							? { backgroundColor: color.interface.green }
							: isChecked && !defaultCheckColor
							? { backgroundColor: color.interface.orange, border: 0 }
							: null
					}
				/>
				{isChecked ? <img src={svg.icon.check} alt="check" /> : null}
			</S.containerCheck>
			{text ? (
				<S.Box>
					<span>
						{text}
						{!isChecked && isError ? (
							<span className="error-send ">Campo obrigatório</span>
						) : null}
					</span>
				</S.Box>
			) : null}
		</S.Container>
	);
}
