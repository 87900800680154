import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { olMap } from 'components/Map/ControlMap/index';
import { StyleLayers } from 'components/Map/Styles/js/StyleLayers';
import { addLayerTI, getLayerByName, setActivatedLayers, getMaxZIndex } from 'store/actions/mapActions';
import { isEmptyObject } from 'helpers/utils';

export const tiSelectedTabTiLayerAction = (tiSelected) => async (dispatch) => {
	const isVectorExist = getLayerByName('tiSelectedTabTi');
	const lastZIndex = getMaxZIndex() + 2;

	// antes de add a layer preciso verificar se ela existe, para evitar de add a mesma layer várias vezes
	if (isVectorExist === null) {
		const sourceTiSelectedTabTi = new VectorSource({ wrapX: false });

		const vectorTiSelectedTabTi = new VectorLayer({
			name: 'tiSelectedTabTi',
			source: sourceTiSelectedTabTi,
			style: [StyleLayers.tiSelectedTabTi],
			label: `TI: ${tiSelected.name}`,
			zIndex: lastZIndex,
			// Se mostra ou não o titulo da legenda ex: rasters
			haveTitle: false
		});

		// so adicionado a layer ao mapa, quando o mesmo termina de carregar
		olMap().addLayer(vectorTiSelectedTabTi);
		dispatch(setActivatedLayers());
	}

	// add o source ao layer quando o estado selecionado chega
	if (!isEmptyObject(tiSelected || {})) {
		addLayerTI(tiSelected, 'tiSelectedTabTi', true);
	}
};
