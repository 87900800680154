import { statesAmazonLegal } from '../typesActions/types';
import { api } from 'services/api';
import { headers, takePrintMap } from 'helpers/utils';

export const getStatesAmazonLegal = () => {
	return { type: statesAmazonLegal.GET_STATES_AMAZON_LEGAL };
};

export const successGetStatesAmazonLegal = (data) => {
	return {
		type: statesAmazonLegal.SUCCESS_GET_STATES_AMAZON_LEGAL,
		payload: data
	};
};

export const failureGetStatesAmazonLegal = () => {
	return { type: statesAmazonLegal.FAILURE_GET_STATES_AMAZON_LEGAL };
};

// função que pega os estados da amazonia legal
export const getStates = () => async (dispatch, getState) => {
	const { statesAmazonLegal } = getState().amazonLegal;

	if (statesAmazonLegal.length === 0) {
		dispatch(getStatesAmazonLegal());

		await api
			.get('/legalAmazonState')
			.then(({ data }) => {
				dispatch(successGetStatesAmazonLegal(data));
			})
			.catch((erro) => {
				dispatch(failureGetStatesAmazonLegal(erro));
			});
	}
};

export const deleteStatesAmazonLegal = (data) => {
	return {
		type: statesAmazonLegal.DELETE_STATES_AMAZON_LEGAL,
		payload: data
	};
};

export const selectStateAmazonLegal = (data) => {
	return {
		type: statesAmazonLegal.SELECT_STATE_AMAZON_LEGAL,
		payload: data
	};
};
export const deleteSelectStateAmazonLegal = (data) => {
	return {
		type: statesAmazonLegal.DELETE_SELECT_STATE_AMAZON_LEGAL
	};
};

export const reqDataAboutStateAmazonSelected = () => {
	return { type: statesAmazonLegal.REQ_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL };
};

export const successDataAboutStateAmazonSelected = (value) => {
	return {
		type: statesAmazonLegal.SUCCESS_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL,
		payload: value
	};
};

export const failDataAboutStateAmazonSelected = () => {
	return {
		type: statesAmazonLegal.FAIL_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL
	};
};

export const deleteDataAboutStateAmazonSelected = () => {
	return {
		type: statesAmazonLegal.DELETE_DATA_ABOUT_STATE_SELECTED_AMAZON_LEGAL
	};
};

export const getDataAboutStateSelected =
	(idState = null) =>
	async (dispatch, getState) => {
		const { statesAmazonLegal: states, dataAboutStateSelected } = getState().amazonLegal;

		// só busca os dados no backend se ainda não tiver buscado nenhuma vez
		if (!dataAboutStateSelected[idState]) {
			const state = states.find((state) => state.id === idState);

			dispatch(reqDataAboutStateAmazonSelected());
			await api
				.get(`dataStateAmazonLegal/${state?.geocode || ''}`)
				.then((res) => {
					dispatch(successDataAboutStateAmazonSelected({ [idState]: res.data }));
				})
				.catch((error) => {
					console.log(error.response);
					dispatch(failDataAboutStateAmazonSelected());
				});
		}
	};

export const reqPDF = () => {
	return {
		type: statesAmazonLegal.REQ_PDF
	};
};

export const successPDF = (value) => {
	return {
		type: statesAmazonLegal.SUCCESS_PDF,
		payload: value
	};
};

export const failPDF = () => {
	return {
		type: statesAmazonLegal.ERROR_PDF
	};
};

export const toggleModalTi = (value) => {
	return {
		type: statesAmazonLegal.TOGGLE_MODAL_TI_AMAZON_LEGAL,
		payload: value
	};
};

/*
  Gera pdf para tudo, por estado ou por TI.
  Para gerar por TI passar true o parâmetro TI.
  Parâmetros:
  idItem: inteiro (id do estado ou TI)
  TI: boolean (default false)
*/
export const generatePdf =
	(idItem, TI = false) =>
	async (dispatch, getState) => {
		const { statesAmazonLegal: states } = getState().amazonLegal;

		const state = !TI ? states.find((state) => state.id === idItem) : {};

		const data = !TI
			? {
					uf_geocode: state?.geocode || null
			  }
			: {};

		dispatch(reqPDF());

		setTimeout(async () => {
			// só tira o print depois do mapa ser ajustado
			data.base64 = takePrintMap();

			const url = !TI ? `pdf` : `pdf/${idItem}`;

			await api
				.post(url, data, {
					headers: headers(),
					responseType: 'arraybuffer'
				})
				.then((res) => {
					// Cria um Blob a partir dos dados do PDF
					const blob = new Blob([res.data], { type: 'application/pdf' });

					// Cria um objeto URL para o Blob
					const url = URL.createObjectURL(blob);

					// Abre uma nova aba com o PDF
					window.open(url, '_blank');

					dispatch(successPDF());
				})
				.catch(() => {
					dispatch(failPDF());
				});
		}, 1000);
	};
