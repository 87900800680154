import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import { actionsUsers } from 'helpers/utils';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import * as S from './styled';

export default function BasicPopover({ item }) {
	const { infor, name } = item || {};
	const { content, font } = infor || {};

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const windowSize = window.innerWidth;

	useEffect(() => {
		if (open) {
			actionsUsers({
				category: `plataforma/mapa/menu`,
				action: 'Clique no componente de informação',
				label: name
			});
		}
	}, [open]);

	return (
		<S.container>
			<AiOutlineInfoCircle color="#69625D" onClick={handleClick} />
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				style={{
					marginLeft: windowSize <= 777 ? 0 : 20
				}}
			>
				<S.boxInformation>
					<S.title>{name}</S.title>
					<S.line />
					<S.content> {content}</S.content>
					<S.line />

					<S.font>Fonte: {font}</S.font>
					{/* SE TIVER LINK, USAR LINHAS A SEGUIR */}
					{/* <S.font>
						Fonte:
						<a href={link} target="_blanck">
							{font}
						</a>
					</S.font> */}
				</S.boxInformation>
			</Popover>
		</S.container>
	);
}
