import styled from 'styled-components';
import { color } from '../../../styles/Theme';

export const container = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

export const containerSelect = styled.div`
	width: 100%;
	position: relative;
	display: ${({ props }) => (props.displayListInitial ? 'initial' : 'flex')};

	/* background-color: ${color.interface.orange}; */
`;

export const itemSelected = styled.div`
	position: relative;
	width: 100%;
	min-height: 45px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.2rem 2rem 0.2rem 1rem;
	cursor: pointer;
	border-radius: 25px;

	border: 1px solid ${color.interface.lightGray};
	background-color: ${color.interface.whiteAbsolute};
	font-weight: 400;
	font-size: 1rem !important;
	color: ${color.interface.darkGray};
	/* background-color: red; */

	p {
		/* background-color: red; */
		height: 2rem;
		max-width: 100%;
		white-space: nowrap;
		display: flex;
		align-items: center;
	}

	img {
		position: absolute;
		right: 7%;
		width: 15px;
		height: 15px;
		transition: all ease 0.5s;
	}
`;

export const List = styled.div`
	@keyframes show {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	overflow: auto;

	width: 100%;
	max-height: 300px;
	/* padding: 1rem; */
	position: ${({ props }) =>
		props.displayListInitial ? 'initial' : 'absolute'};
	/* margin-top: ${({ props }) =>
		props.displayListInitial ? '0.4rem' : '0'}; */
	top: 115%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	border: 1px solid ${color.interface.lightGray};
	border-radius: 10px;
	background-color: ${color.interface.whiteAbsolute};
	z-index: 10;
	font-weight: 400;

	animation-name: show;
	animation-duration: 1s;

	button {
		width: 100%;
		padding: 8px 0px 8px 0px;
		text-align: left;
		font-size: 0.875rem;
		background-color: transparent;
		font-weight: 500;
		border-bottom: 1px solid #d9d5d2;

		cursor: pointer;
		transition: all 0.5s ease;
		/* padding: 0.2rem; */
		padding-left: 15px;
		color: ${color.system.font};

		:hover {
			background-color: ${color.interface.orange};
			color: ${color.interface.white};
			border-radius: 2px;
		}
	}

	::-webkit-scrollbar-track {
		background: ${color.interface.white}; /* color of the tracking area */
	}

	::-webkit-scrollbar-thumb {
		background-color: ${color.interface.gray}99; /* color of the scroll thumb */
		border: 1px solid ${color.interface.gray}99; /* creates padding around scroll thumb */
	}
`;

export const itemOnList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const itemOnTheListSelect = styled.p`
	color: ${color.interface.orange};
`;

export const line = styled.div`
	width: 100%;
	height: 0.5px;
	background-color: ${color.interface.lightGray};
`;

export const buttonDonwload = styled.button`
	box-sizing: border-box;
	width: 140px;
	height: 37px;
	margin-top: 16px;
	display: ${(props) => (props.disabled ? 'none' : 'flex')};
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-weight: 400;
	border-radius: 25px;

	color: ${color.interface.white};

	background-color: ${(props) =>
		props.disabled ? color.interface.darkGray : color.interface.orange};

	cursor: pointer;
	pointer-events: fill;
	transition: all 0.3s ease;

	:active {
		transform: scale(0.95);
	}
	p {
	}
	img {
		width: 18px;
		height: 18px;
		margin: 5px;
	}
`;

export const ContainerDivError = styled.div`
	justify-content: flex-start;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	font-weight: 700;
	font-size: 0.625rem;
	color: ${color.interface.red};
	padding-left: 10px;
	height: 10px;
	margin-top: 5px;
`;
