import styled from 'styled-components';

const SliderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 0.5rem;

	width: 88px;
	height: 16px;
	background: #f2ede9;
	border-radius: 2px;
`;

const SliderInput = styled.input`
	-webkit-appearance: none;

	width: 45px;
	height: 3px;
	background: #69625d;
	border-radius: 2px;
	outline: none;

	transition: opacity 0.2s;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid #bfb9b4;
		background: #ffffff;
		cursor: pointer;
	}
`;

const SliderValue = styled.div`
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 500;
	font-size: 0.625rem;
	line-height: 0.875rem;
	letter-spacing: 0.02em;
	color: #69625d;
	width: 27px;
`;

export { SliderContainer, SliderInput, SliderValue };
