import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerEditItemFromMap = styled.div`
	width: 100%;
	height: 100vh;
	padding: 2rem 1rem 1rem 1rem;
	z-index: 100;
	position: absolute;
	top: 0;
	background-color: ${color.interface.white};
`;

export const headerEdit = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	> button {
		background-color: transparent;

		> img {
			width: 1.2rem;
			height: 1.2rem;
			cursor: pointer;
		}
	}

	> h5 {
		font-weight: 400;
		font-size: 0.9375rem;
		color: ${color.interface.orange};
	}
`;
