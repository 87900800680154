/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { color } from '../../../styles/Theme';

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const containerCheck = styled.div`
	position: relative;
	width: ${(props) => props.widthCheck};
	height: ${(props) => props.widthCheck};
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	cursor: pointer;

	input {
		display: none;
	}
	span {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		width: ${(props) => props.widthCheck};
		height: ${(props) => props.widthCheck};
		border: ${({ isError, defaultCheckColor }) =>
			isError
				? `1px solid ${color.interface.red}`
				: !defaultCheckColor
				? `2px solid #BFB9B4`
				: `1px solid ${color.interface.green}`};

		border-radius: ${(props) => (props.inputType === 'radio' ? '50%' : '15%')};
	}

	img {
		position: absolute;
		width: 10px;
	}

	/* background-color: red; */
`;

export const Box = styled.div`
	display: flex;

	> span {
		font-size: 0.813rem;
		font-weight: 400;
		padding-left: 5px;
	}

	text-transform: uppercase;
	.error-send {
		font-weight: 700;
		font-size: 0.625rem;
		color: ${color.interface.red};
		padding-left: 5px;
		height: 10px;
	}
`;
