import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LineGraph from 'components/Graphs/LineGraph/LineGraph';

export default function TemperatureLineGraph() {
	const { temperatureApi, isLoading: loadingTemperatureLineGraph } =
		useSelector((state) => state.temperature);

	const [temperatureYear, setTemperatureYear] = useState([]);
	const [minTempData, setMinTempData] = useState([]);
	const [avgTempData, setAvgTempData] = useState([]);
	const [maxTempData, setMaxTempData] = useState([]);

	useEffect(() => {
		if (temperatureApi.length > 0) {
			setTemperatureYear(temperatureApi.map((item) => item.year));
			setMinTempData(temperatureApi.map((item) => item.min));
			setAvgTempData(temperatureApi.map((item) => item.avg));
			setMaxTempData(temperatureApi.map((item) => item.max));
		}
	}, [temperatureApi]);

	const lineData = [
		{
			label: 'Min',
			data: minTempData,
			borderColor: '#D44500',
			backgroundColor: '#D44500'
		},
		{
			label: 'Med',
			data: avgTempData,
			borderColor: '#21543D',
			backgroundColor: '#21543D'
		},
		{
			label: 'Max',
			data: maxTempData,
			borderColor: 'black',
			backgroundColor: 'black'
		}
	];

	return (
		<LineGraph
			labelsProps={temperatureYear}
			datasetsProps={temperatureApi.length === undefined ? [] : lineData}
			isLoading={loadingTemperatureLineGraph}
			useLegend={false}
			useLegendRound
		/>
	);
}
