import styled from 'styled-components';
import { color } from 'styles/Theme';

export const containerTabSection = styled.div`
	gap: 0.5rem;
	width: 100%;
	margin-bottom: 8px;
	justify-content: space-between;
	/* background-color: yellow; */
`;

export const BoxTitle = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	> p {
		color: #69625d;
		font-family: 'Nunito';
		font-style: normal;
		font-weight: 800;
		line-height: 0.875rem;
		font-size: 0.625rem;
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}

	button {
		width: 25px;
		height: 30px;
		background-color: transparent;
		font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		display: flex;
		align-items: center;

		color: ${color.interface.gray};
		font-size: 0.75rem;

		cursor: pointer;
	}
`;

export const Line = styled.div`
	width: 100%;
	height: 0.5px;
	background-color: ${color.interface.gray};
`;

export const imageBtnToggleOpenClose = styled.img`
	width: 10px;
	height: 6px;
	box-sizing: content-box;
	padding-bottom: 5px;
	transition: all 0.5s ease;
`;
