import React, { useEffect, useRef } from 'react';
import Attribution from 'ol/control/Attribution';
import { olMap } from 'components/Map/ControlMap';

function Attributions() {
	const container = useRef();

	useEffect(() => {
		setTimeout(() => {
			const attributionControl = new Attribution({
				className: 'somai_custom-ol-attribution',
				target: container.current,
				collapsible: false
			});
			olMap()?.addControl(attributionControl);
		}, 1000);
	}, []);
	return <div ref={container} id="somai__custom-attribution" />;
}

export default Attributions;
